import NftHistoryLogs from "./history-logs";
import NftHistorySales from "./history-sales";
import NftHistoryTransfers from "./history-transfers";
import NftHistoryMining from "./history-mining";

export const NftHistory = (props) => {
  const type = props.type;

  return (
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">History</h4>

        <ul class="nav nav-tabs nav-bordered nav-justified">
          {type == "land" || type == "tool" ? (
            <li class="nav-item">
              <a
                href="#nft-history-mining"
                data-bs-toggle="tab"
                class="nav-link active"
              >
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-home-variant"></i>
                </span>
                <span class="d-none d-sm-inline-block">Mining</span>
              </a>
            </li>
          ) : null}
          <li class="nav-item">
            <a
              href="#nft-history-transfers"
              data-bs-toggle="tab"
              class={`nav-link ${
                type != "land" && type != "tool" ? "active" : ""
              }`}
            >
              <span class="d-inline-block d-sm-none">
                <i class="mdi mdi-home-variant"></i>
              </span>
              <span class="d-none d-sm-inline-block">Transfers</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#nft-history-logs" data-bs-toggle="tab" class="nav-link">
              <span class="d-inline-block d-sm-none">
                <i class="mdi mdi-home-variant"></i>
              </span>
              <span class="d-none d-sm-inline-block">Logs</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#nft-history-sales" data-bs-toggle="tab" class="nav-link">
              <span class="d-inline-block d-sm-none">
                <i class="mdi mdi-account"></i>
              </span>
              <span class="d-none d-sm-inline-block">Sales</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          {type == "land" || type == "tool" ? (
            <div class="tab-pane active" id="nft-history-mining">
              <NftHistoryMining type={type} />
            </div>
          ) : null}
          <div
            class={`tab-pane ${
              type != "land" && type != "tool" ? "active" : ""
            }`}
            id="nft-history-transfers"
          >
            <NftHistoryTransfers />
          </div>
          <div class="tab-pane" id="nft-history-logs">
            <NftHistoryLogs />
          </div>
          <div class="tab-pane" id="nft-history-sales">
            <NftHistorySales />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftHistory;
