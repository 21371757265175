import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const GuardSignedTransactionsChart = (props) => {
  const componentProps = {
    component: "components/guard/guard-signed-transactions-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState({ dates: [], data: [] });

  const transactions = useQuery(
    ["guard_signed_transactions_chart", filters],
    async () => {
      const response = await ApiService.get("guard/signed-transactions-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      setData({ dates: result.dates, data: result.data });
    }
  );

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Signed transactions chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Signed transactions chart tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {transactions.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {transactions.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "column",
                        backgroundColor: "transparent",
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      legend: {
                        enabled: false,
                      },
                      xAxis: {
                        categories: data.dates,
                        title: {
                          text: "Date",
                        },
                      },
                      yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                          text: "Count",
                        },
                      },
                      series: [
                        {
                          name: "Transactions Count",
                          data: data.data,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GuardSignedTransactionsChart;
