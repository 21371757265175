import ApiService from "app/services/api";
import { useQuery } from "react-query";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import moment from "moment";
import { useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3d from "highcharts/highcharts-3d";
import { useRef } from "react";
import { useEffect } from "react";
import { shortNumberFormat } from "app/utils/format";
import { useHelpTexts } from "app/hooks/useSession";

Highcharts3d(Highcharts);

(function (H) {
  H.addEvent(H.Axis, "afterInit", function () {
    const logarithmic = this.logarithmic;

    if (
      logarithmic &&
      this.options.custom &&
      this.options.custom.allowNegativeLog
    ) {
      // Avoid errors on negative numbers on a log axis
      this.positiveValuesOnly = false;

      // Override the converter functions
      logarithmic.log2lin = (num) => {
        const isNegative = num < 0;

        let adjustedNum = Math.abs(num);

        if (adjustedNum < 10) {
          adjustedNum += (10 - adjustedNum) / 10;
        }

        const result = Math.log(adjustedNum) / Math.LN10;
        return isNegative ? -result : result;
      };

      logarithmic.lin2log = (num) => {
        const isNegative = num < 0;

        let result = Math.pow(10, Math.abs(num));
        if (result < 10) {
          result = (10 * (result - 1)) / (10 - 1);
        }
        return isNegative ? -result : result;
      };
    }
  });
})(Highcharts);

export const TlmDistributionTableChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/tlm/tlm-distribution-table-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    chart_component: chartComponent,
  };

  const helpTexts = useHelpTexts();
  const [hideSystemAccounts, setHideSystemAccounts] = useState(false);
  const [hideLesserRange, setHideLesserRange] = useState(false);
  const [showLinear, setShowLinear] = useState(true);

  const [categoriesData, setCategoriesData] = useState([]);
  const [seriesAddressData, setSeriesAddressData] = useState([]);
  const [seriesWealthData, setSeriesWealthData] = useState([]);

  const tlmStats = useQuery(["tlm_stats"], async () => {
    const response = await ApiService.get("tlm/stats", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!tlmStats.data) return;

    var total_address =
      tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .reduce((total_address, stat) => total_address + stat.total_address, 0);
    var total_balance =
      tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .reduce((total_balance, stat) => total_balance + stat.total_balance, 0);

    tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .map((stat) => {
          stat.percentage_address = parseFloat(
            (stat.total_address / total_address) * 100 || 0
          ).toFixed(2);
          stat.percentage_balance = parseFloat(
            (stat.total_balance / total_balance) * 100 || 0
          ).toFixed(2);
        });

    const categories =
      tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .map((stats, index) => {
          if (stats.rangeEnd === 1) {
            return "< 1";
          } else {
            return `${new Intl.NumberFormat("en", {}).format(
              stats.rangeStart
            )} to ${new Intl.NumberFormat("en", {}).format(stats.rangeEnd)}`;
          }
        });

    const seriesAddress =
      tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .map((stats, index) => {
          return {
            y: parseFloat(stats.percentage_address),
            total: shortNumberFormat(stats.total_address),
          };
        });

    const seriesWealth =
      tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .map((stats, index) => {
          return {
            y: parseFloat(stats.percentage_balance),
            total: shortNumberFormat(stats.total_balance, 2),
          };
        });

    setCategoriesData(categories);
    setSeriesAddressData(seriesAddress);
    setSeriesWealthData(seriesWealth);
    chartComponent.current.chart.hideLoading();
  }, [tlmStats.data, hideLesserRange, hideSystemAccounts]);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    exporting: false,
    credits: {
      enabled: false,
      href: "https://yeomen.ai/",
      text: "yeomen.ai",
    },
    xAxis: {
      categories: categoriesData,
      crosshair: true,
    },
    yAxis: {
      type: showLinear ? "linear" : "logarithmic",
      custom: {
        allowNegativeLog: true,
      },
      //min: 0,
      //max: 100,
      title: {
        text: "Percentage (%)",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.2f} % ({point.total})</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Wealth",
        data: seriesWealthData,
      },
      {
        name: "Address",
        data: seriesAddressData,
      },
    ],
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lesserrange-check"
                  defaultChecked={hideLesserRange}
                  onChange={(el) => setHideLesserRange(!hideLesserRange)}
                />
                <label className="form-check-label" htmlFor="lesserrange-check">
                  Hide &#60;100
                </label>
              </div>
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideSystemAccounts}
                  onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide system accounts
                </label>
              </div>
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="linear-check"
                  defaultChecked={showLinear}
                  onChange={(el) => setShowLinear(!showLinear)}
                />
                <label className="form-check-label" htmlFor="linear-check">
                  Show linear
                </label>
              </div>
            </form>
          </div>
          <h4
            className="header-title"
            data-ht-title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `TLM Distibution Chart`
            }
          >
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM Distibution Chart`}{" "}
            (
            {tlmStats.data
              ? moment(tlmStats.data.date).format("Do MMM YYYY")
              : ""}
            )
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "TLM Distribution Chart"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>

          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponent}
          />
        </div>
      </div>
    </>
  );
};

export default TlmDistributionTableChart;
