import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";

export const MiningPlanetsNumMines = () => {
  const componentProps = {
    component: "components/mining/planets_num_mines",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  //const [planets, setPlanets] = useState(new Array(6).fill(0));
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const stats = useQuery(
    [`planets_mining_stats`, filters],
    async () => {
      const response = await ApiService.get("planets/mining/stats", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        return;
      }

      return response.json();
    },
    {
      initialData: new Array(6).fill(0),
    }
  );
  console.log(stats);
  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Number of mining actions per planet`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows the number of mining actions on each planet in the selected time-frame."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
        </div>
      </div>
      <div className="row">
        {stats.data &&
          stats.data.map((planet, index) => (
            <div className="col-xl-3 col-md-6" key={index}>
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {planet.mines_count === undefined ? (
                      <Skeleton width={50} />
                    ) : (
                      <span >{planet.mines_count}</span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">
                    {!planet.name ? <Skeleton width={200} /> : planet.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default MiningPlanetsNumMines;
