import { Skeleton } from "@material-ui/lab";
import config from "app/config";
import { useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import { useQuery } from "react-query";

const PlanetsPoolRates = () => {
  const componentProps = {
    component: "components/mining-pools/planets-pool-rates",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();

  const results = useQuery(
    ["planets-pool-rates"],
    async () => {
      const response = await ApiService.get("planets/pool_rates", {});
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      initialData: new Array(6).fill(0),
    }
  );

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Pool Rates`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Pool Rates"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>

        {results.data &&
          results.data.map((planet, index) => (
            <div className="row">
              <div className="col-lg-12">
                <div className="card bg-transparent">
                  <div className="card-body">
                    <h4 className="header-title">
                      Planet:{" "}
                      {!planet.name ? <Skeleton width={200} /> : planet.name}{" "}
                    </h4>
                    <div className="row">
                      {[
                        ...(planet.pool_rates
                          ? planet.pool_rates.sort((a,b) => a.order - b.order)
                          : new Array(6).fill(0)),
                      ].map((pool_rate) => (
                        <div className="col-lg-2 col-md-3">
                          <div className="widget-simple text-center card">
                            <div className="card-body">
                              <h3 className={`text-success mt-0}`}>
                                <span>
                                  {!pool_rate.value ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {parseFloat(
                                        planet.pool_buckets.find(
                                          (pool_bucket) =>
                                            pool_bucket.key === pool_rate.key
                                        )?.value || 0
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </>
                                  )}
                                </span>
                              </h3>
                              <small class="text-success">
                                {!pool_rate.value ? (
                                  <Skeleton />
                                ) : (
                                  <>
                                    {parseFloat(pool_rate.value).toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                    %
                                  </>
                                )}
                              </small>
                              <p className="text-muted mb-0">
                                {!pool_rate.key ? (
                                  <Skeleton />
                                ) : (
                                  config.planet_pools.find(
                                    (planet_pool) =>
                                      planet_pool.key === pool_rate.key
                                  )?.name || pool_rate.key
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlanetsPoolRates;
