import NftsTransferFlow from "../components/graphs/NftsTransferFlow";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import NftTransfers from "admin/components/forensics/nft-transfers";

function AdminForensicsChartNfts() {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">NFTs Forensics</BreadcrumbsItem>
      {/* <div>TLM Detective</div> */}

      <div className="row">
        <div className="col-lg-12">
        <NftsTransferFlow />
        </div>
      </div>     
    </>
  );
}

export default AdminForensicsChartNfts;
