import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const BigqueryMineActions = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/bigquery-mine-actions",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    //copy_option: { id: "bigquery-mine-actions" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          order: [[0, 'desc']],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/account/bigquery-mine-actions/${props.account}`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = filters.dates.startDate;
              data.endDate = filters.dates.endDate;
            },
          },
          columns: [
            { data: "event_timestamp", name: "event_timestamp" },
            { data: "category", name: "category" },
            { data: "country", name: "country" },
            { data: "region", name: "region" },
            { data: "device_brand", name: "device_brand" },
            { data: "device_model", name: "device_model" },
            { data: "device_os", name: "device_os" }
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.event_timestamp/1000, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.timestamp).fromNow());
              },
            }
          ],
          dom: "Blfrtip",
          buttons: [           
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.account,filters]);

 
  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `play.alienworlds.io mine actions`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `play.alienworlds.io mine actions`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the account play.alienworlds.io mine actions."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="bigquery-mine-actions"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">
                Category &nbsp;                
              </th>
              <th className="no-sort">
                Country &nbsp;                
              </th> 
              <th className="no-sort">
                Region &nbsp;                
              </th>   
              <th className="no-sort">
                Device brand &nbsp;                
              </th> 
              <th className="no-sort">
                Device model &nbsp;                
              </th> 
              <th className="no-sort">
                Device OS &nbsp;                
              </th> 
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default BigqueryMineActions;
