import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const PlanetsSetpoolratesActions = (props) => {
  const componentProps = {
    component: "components/mining-pools/planets-setpoolrates-actions",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  const actions = useQuery(
    [`planets-setpoolrates-actions`, filters],
    async () => {
      const response = await ApiService.get("planets/actions/setpoolrates", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!actions.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          columnDefs: [{ targets: "no-sort", orderable: false }],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [],
        });
      //window.$(".buttons-copy").hide();
      //window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [actions.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Planets Setpoolrates Actions`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "Planets Setpoolrates Actions"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table class="table dt-responsive w-100" ref={tableRef} id="activity">
            <thead>
              <tr>
                <th className="no-sort"> Trx Id </th>
                <th>Date</th>
                {/* <th>Data</th> */}
                <th>Planet</th>
                {config.planet_pools
                  .sort((a, b) => a.order - b.order)
                  .map((planet_pool) => (
                    <th>{planet_pool.name}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {actions.data &&
                actions.data.map((action, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          href={`${config.explorer.url}/transaction/${action._source.trx_id}`}
                          target="_blank"
                        >
                          {action._source.trx_id.substring(0, 5)}...
                        </a>
                      </td>
                      <td> {action._source["@timestamp"]}</td>
                      {/* <td>{JSON.stringify(action._source.act.data)}</td> */}
                      <td>{action._source.act.data.planet_name}</td>
                      {config.planet_pools
                        .sort((a, b) => a.order - b.order)
                        .map((planet_pool) => (
                          <td>
                            {
                              action._source.act.data.rates.find(
                                (planet_rate) =>
                                  planet_rate.key === planet_pool.key
                              )?.value
                            }
                          </td>
                        ))}
                    </tr>
                  );
                })}

              {actions.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      {config.planet_pools
                        .sort((a, b) => a.order - b.order)
                        .map((planet_pool) => (
                          <td>
                            <Skeleton />
                          </td>
                        ))}
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {actions.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default PlanetsSetpoolratesActions;
