import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";
import AdminTemplatesGridCard from "../../nfts/templates-grid-card";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeAtomicassetsMaxMintedTemplates = (props) => {
  const componentProps = {
    component: "components/realtime/atomicassets/max-minted-templates",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const [isPaused, setPaused] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [hideTemplates, setHideTemplates] = useState(true);

  useEffect(() => {
    var socket = SocketService.getSocket("/atomicassets");

    if (socket.connected) {
      socket.emit("join", "maxMintedTemplates");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "maxMintedTemplates");
      });
    }

    if (isPaused) {
      socket.off("maxMintedTemplates");
    } else {
      socket.on("maxMintedTemplates", function (data) {
        setTemplates(data);
      });
    }

    return () => {
      socket.off("maxMintedTemplates");
      socket.emit("leave", "maxMintedTemplates");
    };
  }, [isPaused]);

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideTemplates}
                  onChange={(el) => setHideTemplates(!hideTemplates)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide maxed templates
                </label>
              </div>
            </form>
          </div>
          <div className="float-end me-2">
            <form className="d-flex align-items-center flex-wrap">
              <button
                type="button"
                className="btn btn-sm btn-success mb-2 mb-sm-0"
                onClick={() => setPaused(!isPaused)}
              >
                {isPaused ? "Unpause" : "Pause"}
              </button>
            </form>
          </div>
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Max minted templates`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "Max minted templates tooltip"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div className="row">
            {templates.map((template, index) => (
              <>
                {!hideTemplates || template.minted_percentage < 100 ? (
                  <div className="col-xl-3 col-md-6" key={index}>
                    <AdminTemplatesGridCard
                      template={template}
                      sort={"created_at_time"}
                      badge={template.minted_percentage + "%"}
                    />
                  </div>
                ) : null}
              </>
            ))}
            {templates.length === 0 ? (
              <div className="col-12">
                <p>No records</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeAtomicassetsMaxMintedTemplates;
