import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DailyActiveUsersChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/users/daily-active-users-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const [data, setData] = useState([]);
  const [showLinear, setShowLinear] = useState(true);

  const users = useQuery(["daily_active_users_chart", filters], async () => {
    const response = await ApiService.get("users/daily-active-users-chart", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!users.data) return;

    setData(users.data);

    chartComponent.current.chart.hideLoading();
  }, [users.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Daily active users`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Daily active users tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {users.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "line",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: false,
                  },
                  xAxis: {
                    type: "category",
                    title: {
                      text: "Date",
                    },
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    title: {
                      text: "Count",
                    },
                  },
                  series: [
                    {
                      name: "Active users",
                      data: data,
                    },
                  ],
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyActiveUsersChart;
