import ApiService from "app/services/api";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useRef } from "react";
import CardMenu from "../card-menu";
import { copyToClipboard } from "app/utils/datatables";

export const TagLookupResults = (props) => {
  const componentProps = {
    component: "components/tag-lookup/tag-lookup-results",
    options: { props: { keyword: props.keyword } },
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "tag-lookup-results" },
  };

  const keyword = props.keyword;
  const history = useHistory();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const lookupResult = useQuery([`tag_lookup`, keyword], async () => {
    if (keyword === "") {
      return null;
    }
    const response = await ApiService.get("bots/lookup", { keyword: keyword });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  useEffect(() => {
    if (lookupResult.data && lookupResult.data.account.length === 1) {
      setTimeout(() => {
        // setKeyword(lookupResult.data.account[0].tag);
        history.push({
          pathname: "/admin/tag-lookup/" + lookupResult.data.account[0].tag,
        });
      }, 1000);
    }
  }, [lookupResult.data, history]);

  useEffect(() => {
    if (!lookupResult.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Tag lookup results` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Account")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      setTimeout(() => {
        //dataTableRef.current.draw(true);
        window.$(tableRef.current).DataTable();
      }, 500);
    }
  }, [lookupResult.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tag-lookup-results_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("tag-lookup-results", 1);
  };

  return (
    <>
      {lookupResult.status === "loading" ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          {/* {lookupResult.data && lookupResult.data.stats ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="card  mt-2">
                  <div className="card-body">
                    <h4 class="header-title">{lookupResult.data.stats.done} of {lookupResult.data.stats.total} processed</h4>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}

          {lookupResult.data && lookupResult.data.account.length ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card  mt-2">
                    <div className="card-body">
                      <CardMenu {...componentProps} {...props} />
                      <h4 class="header-title">Account: {keyword}</h4>
                      <table
                        className="table dt-responsive nowrap w-100"
                        ref={tableRef}
                        id="tag-lookup-results"
                      >
                        <thead>
                          <tr>
                            <th>Tag</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th>Updated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lookupResult.data.account.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={`/admin/tag-lookup/${row.tag}`}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/admin/tag-lookup/" + row.tag,
                                    });
                                  }}
                                >
                                  {row.tag}
                                </Link>
                              </td>
                              <td>{row.status}</td>
                              <td>
                                {moment
                                  .tz(row.created_at, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td>
                                {moment
                                  .tz(row.updated_at, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {lookupResult.data && lookupResult.data.tag.length ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card  mt-2">
                    <div className="card-body">
                      <CardMenu {...componentProps} {...props} />
                      <h4 class="header-title">Tag: {keyword}</h4>
                      <table
                        className="table dt-responsive nowrap w-100"
                        ref={tableRef}
                        id="tag-lookup-results"
                      >
                        <thead>
                          <tr>
                            <th>
                              Account &nbsp;
                              <Link to="" onClick={copyWalletsToClipboard}>
                                <i
                                  className="fas fa-copy"
                                  title="Copy accounts to clipboard"
                                ></i>
                              </Link>
                            </th>
                            <th>Status</th>
                            <th>Created</th>
                            <th>Updated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lookupResult.data.tag.map((row) => (
                            <tr>
                              <td>
                                <Link
                                  to={`/admin/account-scanner/${row.wallet}`}
                                >
                                  {row.wallet}
                                </Link>
                              </td>
                              <td>{row.status}</td>
                              <td>
                                {moment
                                  .tz(row.created_at, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td>
                                {moment
                                  .tz(row.updated_at, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card  mt-2">
                    <div className="card-body">
                      <h4 class="header-title">Questions before unblock</h4>
                      <ul>
                        {lookupResult.data &&
                        lookupResult.data.tagReason &&
                        lookupResult.data.tagReason.flag_type ===
                          "rule-yeomenwarder-false-flag-type1" ? (
                          <>
                            <li>
                              What interface do you use to play the game. It
                              does not look like you are using the AW UI
                            </li>
                          </>
                        ) : null}
                        {lookupResult.data &&
                        lookupResult.data.tagReason &&
                        lookupResult.data.tagReason.flag_type ===
                          "intel-holistic-type-1" ? (
                          <>
                            <li>What browser or device are you on?</li>
                            <li>
                              Do you use any VPNs or software to connect to
                              Alien Worlds?
                            </li>
                            <li>
                              Any extensions or tools on your browser which
                              could make the system appear as a bot?
                            </li>
                          </>
                        ) : null}
                        {lookupResult.data &&
                        lookupResult.data.tagReason &&
                        lookupResult.data.tagReason.flag_type ===
                          "mnl-input-field" ? (
                          <>
                            <li>{lookupResult.data.tagReason.reason}</li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {lookupResult.data &&
          !lookupResult.data.account.length &&
          !lookupResult.data.tag.length ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card  mt-2">
                    <div className="card-body">
                      <h4 class="header-title">Not Found</h4>
                      <p>This tag or account name was not found on the list</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default TagLookupResults;
