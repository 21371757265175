import { createSlice } from '@reduxjs/toolkit'

export const ipfsImageSlice = createSlice({
  name: 'ipfsImage',
  initialState: {
    loaded: [],  
    failed: [],
  },
  reducers: {
    setLoaded: (state, action) => {      
      state.loaded.push(action.payload)
    },
    setFailed: (state, action) => {
      state.failed.push(action.payload)
    },
    resetLoaded: (state) => {
      state.loaded = []
    },
    resetFailed: (state) => {
      state.failed = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoaded, setFailed, resetLoaded, resetFailed } = ipfsImageSlice.actions

export default ipfsImageSlice.reducer