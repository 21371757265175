import CardMenu from "admin/components/card-menu";
import WaxTlm from "./wax-tlm";
import BscTlm from "./bsc-tlm";
import EthTlm from "./eth-tlm";
import { useHelpTexts } from "app/hooks/useSession";

export const TlmPositions = (props) => {
  const componentProps = {
    component: "components/teleport/tlm-positions",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `TLM across chain positions`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the TLM across all networks"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <WaxTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <BscTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <EthTlm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TlmPositions;
