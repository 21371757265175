import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useFilters } from "app/hooks/useSession";
import ApiService from "app/services/api";

export const MiningPlanetsMinMine = () => {
  //const [planets, setPlanets] = useState(new Array(6).fill(0));

  const filters = useFilters();

  const stats = useQuery([`planets_mining_stats`, filters], async () => {
    const response = await ApiService.get("planets/mining/stats", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      return;
    }
    
    return response.json();
  },{
    initialData: new Array(6).fill(0)
  });
console.log(stats)
  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h4>Minimum mine per planet
          <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This shows the minimum mine per planet."
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
        </div>
      </div>
      <div className="row">
        {stats.data && stats.data.map((planet, index) => (
          <div className="col-xl-3 col-md-6" key={index}>
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {planet.min_mine === undefined  ? (
                    <Skeleton width={50} />
                  ) : (
                    <span data-plugin="counterup">
                      {planet.min_mine}
                    </span>
                  )}
                </h3>
                <p className="text-muted mb-0">
                  {!planet.name ? <Skeleton width={200} /> : planet.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MiningPlanetsMinMine;
