import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts3d from "highcharts/highcharts-3d";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import { useHelpTexts } from "app/hooks/useSession";

Highcharts3d(Highcharts);

export const TlmDistributionChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/tlm/tlm-distribution-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };

  const helpTexts = useHelpTexts();
  const [seriesData, setSeriesData] = useState([]);
  const { code, name } = { code: "alien.worlds", name: "TLM" };
  const [hideSystemAccounts, setHideSystemAccounts] = useState(false);

  const token = useQuery([`token_${code}_${name}`, code, name], () => {
    return ApiService.get(`token/${code}/${name}`, {}).then((res) =>
      res.json()
    );
  });

  const topHolders = useQuery(
    [`tlm_distribution`, code, name, hideSystemAccounts],
    () => {
      return ApiService.get(`token/topHolders/${code}/${name}`, {
        hideSystemAccounts: hideSystemAccounts ? 1 : 0,
      }).then((res) => res.json());
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!token.data || !topHolders.data) return;

    var seriesData = [];
    topHolders.data.map((holder, key) => {
      if (key <= 100) {
        var percentage = parseFloat(
          ((holder[1] / token.data.supply) * 100).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        );
        if (key === 0) {
          seriesData.push({
            name: holder[0],
            y: percentage,
            selected: true,
            sliced: true,
          });
        } else {
          seriesData.push({ name: holder[0], y: percentage });
        }
      }
    });

    setSeriesData(seriesData);
    chartComponent.current.chart.hideLoading();
  }, [token.data, topHolders.data]);

  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
      },
      styledMode: false,
      allowPointSelect: true,
      height: 350,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    exporting: false,
    credits: {
      enabled: false,
      href: "https://yeomen.ai/",
      text: "yeomen.ai",
    },
    tooltip: {
      hideDelay: 3000,
      useHTML: true,
      followPointer: false,
      style: {
        padding: 0,
        pointerEvents: "auto",
      },
      formatter: function () {
        var ret =
          '<a href="/account/' +
          this.point.name +
          '" style="color:inherit"><b>' +
          this.point.name +
          "</b></a><br>";
        ret +=
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": ";
        ret += "<b>" + Highcharts.numberFormat(this.point.y, 2) + "</b>";
        return ret;
      },
    },
    plotOptions: {
      pie: {
        innerSize: 110,
        depth: 50,
        allowPointSelect: true,
        cursor: "pointer",
        size: "100%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y:,.2f} %",
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        name: "Tokens",
        data: seriesData,
      },
    ],
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideSystemAccounts}
                  onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide system accounts
                </label>
              </div>
            </form>
          </div>
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Top TLM Holders (Latest)`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "Top TLM Holders"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponent}
          />
        </div>
      </div>
    </>
  );
};

export default TlmDistributionChart;
