import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
// import HC_exporting from 'highcharts/modules/exporting'
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

HighchartsHeatmap(Highcharts);
DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const UniqueMinersPerToolsHeatmap = (props) => {
  const componentProps = {
    component: "components/mining/unique_miners_per_tools_heatmap",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [rarity, setRarity] = useState({
    common: true,
    rare: true,
    epic: true,
    legendary: true,
    mythical: true,
    abundant: true,
  });
  const [shine, setShine] = useState({
    stone: true,
    gold: true,
    stardust: true,
    antimatter: true,
    xDimension: true,
  });

  const [showLinear, setShowLinear] = useState(true);

  const getPointCategoryName = (point, dimension) => {
    let series = point.series,
      isY = dimension === "y",
      axis = series[isY ? "yAxis" : "xAxis"];
    return axis.categories[point[isY ? "y" : "x"]];
  };

  const miners = useQuery(
    ["unique_miners_per_tools_heatmap", filters, rarity, shine],
    async () => {
      const response = await ApiService.get(
        "logmines/unique-miners-per-tools-heatmap",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
          rarity: JSON.stringify(rarity),
          shine: JSON.stringify(shine),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  const setRarityFilters = (type) => {
    rarity[type] = rarity[type] ? false : true;
    setRarity({ ...rarity });
  };
  const setShineFilters = (type) => {
    shine[type] = shine[type] ? false : true;
    setShine({ ...shine });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!miners.data) return;

    setData(miners.data);

    chartComponent.current.chart.hideLoading();
  }, [miners.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="outliers-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Unique miners per tools heatmap`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This is the heatmap of the unique miners per tools in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Rarity:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="common"
              onChange={() => setRarityFilters("common")}
              defaultChecked={rarity.common ? true : false}
            />
            <label class="form-check-label" for="common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="rare"
              onChange={() => setRarityFilters("rare")}
              defaultChecked={rarity.rare ? true : false}
            />
            <label class="form-check-label" for="rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="epic"
              onChange={() => setRarityFilters("epic")}
              defaultChecked={rarity.epic ? true : false}
            />
            <label class="form-check-label" for="epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="legendary"
              onChange={() => setRarityFilters("legendary")}
              defaultChecked={rarity.legendary ? true : false}
            />
            <label class="form-check-label" for="legendary">
              Legendary
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mythical"
              onChange={() => setRarityFilters("mythical")}
              defaultChecked={rarity.mythical ? true : false}
            />
            <label class="form-check-label" for="mythical">
              Mythical
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="abundant"
              onChange={() => setRarityFilters("abundant")}
              defaultChecked={rarity.abundant ? true : false}
            />
            <label class="form-check-label" for="abundant">
              Abundant
            </label>
          </div>
        </div>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Shine:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="stone"
              onChange={() => setShineFilters("stone")}
              defaultChecked={shine.stone ? true : false}
            />
            <label class="form-check-label" for="stone">
              Stone
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="gold"
              onChange={() => setShineFilters("gold")}
              defaultChecked={shine.gold ? true : false}
            />
            <label class="form-check-label" for="gold">
              Gold
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="stardust"
              onChange={() => setShineFilters("stardust")}
              defaultChecked={shine.stardust ? true : false}
            />
            <label class="form-check-label" for="stardust">
              Stardust
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="antimatter"
              onChange={() => setShineFilters("antimatter")}
              defaultChecked={shine.antimatter ? true : false}
            />
            <label class="form-check-label" for="antimatter">
              Antimatter
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="xDimension"
              onChange={() => setShineFilters("xDimension")}
              defaultChecked={shine.xDimension ? true : false}
            />
            <label class="form-check-label" for="xDimension">
              XDimension
            </label>
          </div>
        </div>
        {miners.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "heatmap",
                    backgroundColor: "transparent",
                    plotBorderWidth: 1,
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                    height: "100%",
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  exporting: {
                    csv: {
                      columnHeaderFormatter: function (item, key) {
                        if (!item || item instanceof Highcharts.Axis) {
                          return "Shine";
                        }
                        return {
                          topLevelColumnTitle: "Shine",
                          columnTitle: key === "y" ? "Tool" : "Unique miners",
                        };
                      },
                    },
                  },
                  xAxis: {
                    categories: data.xAxis,
                  },
                  yAxis: {
                    categories: data.yAxis,
                    title: null,
                  },
                  accessibility: {
                    point: {
                      descriptionFormatter: function (point) {
                        var ix = point.index + 1,
                          xName = getPointCategoryName(point, "x"),
                          yName = getPointCategoryName(point, "y"),
                          val = point.value;
                        return (
                          ix +
                          ". " +
                          xName +
                          " sales " +
                          yName +
                          ", " +
                          val +
                          "."
                        );
                      },
                    },
                  },
                  colorAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    // min: 0,
                    // minColor: '#FFFFFF',
                    // maxColor: '#1abc9c',
                    stops: [
                      [0, "#0000FF"],
                      [0.3, "#008000"],
                      [0.6, "#FFFF00"],
                      [0.9, "#FF0000"],
                    ],
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b>" +
                        getPointCategoryName(this.point, "y") +
                        "</b> has <br><b>" +
                        this.point.value +
                        "</b> unique miners on <br><b>" +
                        getPointCategoryName(this.point, "x") +
                        "</b>"
                      );
                    },
                  },
                  series: [
                    {
                      name: "Unique miners per tool",
                      borderWidth: 1,
                      dataLabels: {
                        enabled: true,
                        color: "#000000",
                      },
                      data: data.points,
                    },
                  ],
                  responsive: {
                    rules: [
                      {
                        condition: {
                          maxWidth: 500,
                        },
                        chartOptions: {
                          yAxis: {
                            labels: {
                              formatter: function () {
                                return this.value.charAt(0);
                              },
                            },
                          },
                        },
                      },
                    ],
                  },
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UniqueMinersPerToolsHeatmap;
