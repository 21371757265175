import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminTowerAppealsList from "./components/tower_appeals_list";
import AdminTowerAppealsStats from "./components/tower_appeals_stats";
import AdminTowerAppealsOverallStats from "./components/tower_appeals_overall_stats";
import TowerAppealsStatsChart from "./components/tower_appeals_stats_chart";
import { useQueryParams } from "app/hooks/useQueryParams";
import moment from "moment";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

export const AdminTowerAppeals = () => {
  const queryParams = useQueryParams();
  const [formFilters, setFormFilters] = useState({
    startDate:
      queryParams.get("startDate") ||
      moment().utc().subtract(1, "week").format("YYYY-MM-DD"),
    endDate: queryParams.get("endDate") || moment().utc().format("YYYY-MM-DD"),
  });
  return (
    <>
      <BreadcrumbsItem to="/admin/tower">Tower</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/tower/appeals">Appeals</BreadcrumbsItem>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div class="form-floating mb-3">
                <DateRangePicker
                  id={`daterangepicker`}
                  initialSettings={{
                    format: "DD/MM/YYYY",
                    startDate: formFilters.startDate,
                    endDate: formFilters.endDate,
                    showDropdowns: true,
                    showWeekNumbers: true,
                    timePicker: false,
                    timePickerIncrement: 1,
                    timePicker12Hour: true,
                    ranges: {
                      Today: [moment(), moment()],
                      "Last 24 Hours": [
                        moment().subtract(24, "hours"),
                        moment(),
                      ],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [
                        moment().subtract(7, "days"),
                        moment().subtract(0, "days"),
                      ],
                      "Last 30 Days": [
                        moment().subtract(30, "days"),
                        moment().subtract(0, "days"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().subtract(0, "days"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(0, "month").endOf("month"),
                      ],
                      "Last 3 Months": [
                        moment().subtract(3, "month"),
                        moment().subtract(0, "days"),
                      ],
                      "Last 6 Months": [
                        moment().subtract(6, "month"),
                        moment().subtract(0, "days"),
                      ],
                      "All time": [
                        moment("2020-07-01"),
                        moment().subtract(0, "days"),
                      ],
                    },
                    opens: "left",
                    drops: "down",
                    buttonClasses: ["btn", "btn-sm"],
                    applyClass: "btn-success",
                    cancelClass: "btn-secondary",
                    separator: " to ",
                    locale: {
                      format: "YYYY/MM/DD",
                      applyLabel: "Submit",
                      cancelLabel: "Cancel",
                      fromLabel: "From",
                      toLabel: "To",
                      customRangeLabel: "Custom",
                      daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                      monthNames: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ],
                      firstDay: 1,
                    },
                  }}
                  onCallback={(start, end, label) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      startDate: start.format("YYYY-MM-DD"),
                      endDate: end.format("YYYY-MM-DD"),
                    }));
                    // window.history.pushState(
                    //   {},
                    //   "",
                    //   `/admin/tower/appeals?startDate=${start.format(
                    //     "YYYY-MM-DD"
                    //   )}&endDate=${end.format("YYYY-MM-DD")}`
                    // );
                  }}
                  // onApply={(event, picker) => {
                  //   setFormFilters((prevState) => ({
                  //     ...prevState,
                  //     startDate: picker.startDate.format("YYYY-MM-DD"),
                  //     endDate: picker.startDate.format("YYYY-MM-DD"),
                  //   }));

                  //   window
                  //     .$(event.currentTarget)
                  //     .val(picker.startDate.format("YYYY-MM-DD"));
                  // }}
                >
                  <input
                    type="text"
                    className="form-control"
                    //defaultValue={formFilters.date}
                  />
                </DateRangePicker>
                <label for={`daterangepicker`}>Date</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminTowerAppealsOverallStats />
      <AdminTowerAppealsStats
        filters={{
          dates: {
            startDate: formFilters.startDate,
            endDate: formFilters.endDate,
          },
        }}
      />
      <AdminTowerAppealsList
        filters={{
          dates: {
            startDate: formFilters.startDate,
            endDate: formFilters.endDate,
          },
        }}
      />
      <TowerAppealsStatsChart
        filters={{
          dates: {
            startDate: formFilters.startDate,
            endDate: formFilters.endDate,
          },
        }}
      />
    </>
  );
};

export default AdminTowerAppeals;
