import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "admin/services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";
import { useHelpTexts } from "app/hooks/useSession";
import ApiService, { apiHeaders } from "app/services/api";
import config from "app/config";
import usePrevious from "app/hooks/usePrevious";

export const AdminTowerSignalsStats = ({ filters }) => {
  const componentProps = {
    component: "views/tower/debug/components/tower-signals-stats",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();
  const prevFilters = usePrevious(filters);
  const [towerBot, setTowerBot] = useState(-1);

  if (dataTableRef.current && prevFilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!results.data) {
    //   return;
    // }
    
    setTimeout(() => {
      if (dataTableRef.current) {
        dataTableRef.current.destroy();
        dataTableRef.current = null;
      }

      
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500, 1000],
          searching: false,
          processing: true,
          serverSide: false,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/tower/tower-signals-stats`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
            data: { ...filters, tower_bot: towerBot },
          },
          columns: [
            {
              data: "signal",
              name: "signal",
              render: function (data, type, row) {
                return `<a href="/admin/account-scanner/${data}" target="_blank">${data}</a>`;
              },
            },
            {
              data: "count",
              name: "count",
              render: function (data, type, row) {
                return parseInt(data).toLocaleString(undefined, {});
              },
            },
            {
              data: "percentage",
              name: "percentage",
              render: function (data, type, row) {
                return parseFloat(data).toFixed(2);
              },
            },
          ],
          columnDefs: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [filters, towerBot]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title">Tower Signals Stats</h4>
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                <div className="form-check me-2">
                  <select
                    className="form-select form-select-sm"
                    name="type"
                    onChange={(e) => {
                      setTowerBot(e.target.value);
                    }}
                    value={towerBot}
                  >
                    <option value="-1">--All--</option>
                    <option value="1">Blocked</option>
                    <option value="0">Not blocked</option>
                  </select>
                </div>
              </form>
            </div>
            <table
              class="table dt-responsive  w-100"
              ref={tableRef}
              id="tower_signals_stats"
            >
              <thead>
                <tr>
                  <th>Signal</th>
                  <th>Count</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTowerSignalsStats;
