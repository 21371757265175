import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import AccountService from "../../../services/account";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "admin/components/card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const MiningChart = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/mining-chart",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);

  const mining = useQuery(
    [`mining_chart_${props.account}`, filters],
    async () => {
      const response = await AccountService.getMiningChart(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!mining.data) return;

    setData(mining.data);

    chartComponent.current.chart.hideLoading();
  }, [mining.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Mining chart`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mining chart`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the mines per day, hours mined per day and TLM mined per day in the selected time-frame for this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {mining.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: [
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#2b908f",
                      title: {
                        text: "Mines per day",
                        style: {
                          color: "#2b908f",
                        },
                      },
                      labels: {
                        style: {
                          color: "#2b908f",
                        },
                      },
                    },
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#90ee7e",
                      title: {
                        text: "Hours mined per day",
                        style: {
                          color: "#90ee7e",
                        },
                      },
                      labels: {
                        style: {
                          color: "#90ee7e",
                        },
                      },
                    },
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#f45b5b",
                      title: {
                        text: "TLM mined per day",
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      labels: {
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      opposite: true,
                    },
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#7798BF",
                      title: {
                        text: "Average mines per hour",
                        style: {
                          color: "#7798BF",
                        },
                      },
                      labels: {
                        style: {
                          color: "#7798BF",
                        },
                      },
                    },
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#F7B84B",
                      title: {
                        text: "Daily User points",
                        style: {
                          color: "#F7B84B",
                        },
                      },
                      labels: {
                        style: {
                          color: "#F7B84B",
                        },
                      },
                      opposite: true,
                    },
                    {
                      allowDecimals: false,
                      min: 0,
                      lineColor: "#058DC7",
                      title: {
                        text: "Claimmines per day",
                        style: {
                          color: "#058DC7",
                        },
                      },
                      labels: {
                        style: {
                          color: "#058DC7",
                        },
                      },
                      opposite: true,
                    },
                  ],
                  tooltip: {
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MiningChart;
