import { useEffect } from "react";
import { useIpfsImageFailed } from "app/hooks/useIpfsImage";

const ErrorIpfsImage = () => {
  const ipfsImageFailed = useIpfsImageFailed();

  useEffect(() => {
    if (ipfsImageFailed.length === 1) {
      // window.$("#ipfsImageResolveModal").modal("show");
      window.$.toast({
        heading: "Error",
        text: "Please solve a captcha to resolve issues loading IPFS images from CloudFlare. <a href='"+ipfsImageFailed[0]+"' target='_blank'><button class='btn btn-xs btn-success'>View image</button></a>",
        position: "top-right",
        icon: "error",
      });
    }
  }, [ipfsImageFailed]);

  const resolve = () => {
    window.open(ipfsImageFailed[0]);
  };

  return (
    <>
      <div
        className="modal fade"
        id="ipfsImageResolveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ipfsImageResolveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ipfsImageResolveModalLabel">
                IPFS Image
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Please solve a captcha to resolve issues loading IPFS images
                from CloudFlare
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={resolve}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ErrorIpfsImage;
