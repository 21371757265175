import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import NftsService from "../../../services/nfts";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { useRef } from "react";
import { useEffect } from "react";

export const NftHistoryTransfers = () => {
  let { assetId } = useParams();

  const tableRef = useRef();
  const dataTableRef = useRef();

  const transfers = useQuery(
    [`nft_${assetId}_history_transfers`, assetId],
    () => {
      return NftsService.getNftTransfers(assetId);
    }
  );

  console.log(transfers);

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 50, 500],
          order: [[0, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  return (
    <div class="responsive-table-plugin">
      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">
          <table className="table mb-0" ref={tableRef} id="nft_history_transfers">
            <thead>
              <tr>
                <th>Event</th>
                <th>Data</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transfers.data &&
                transfers.data.map((transfer, index) => {
                  return (
                    <tr key={index}>
                      <td>Transfer</td>
                      <td>
                        <Link to={`/admin/account-scanner/${transfer.sender}`}>
                          {transfer.sender}
                        </Link>{" "}
                        <i class="fas fa-arrow-circle-right" />{" "}
                        <Link
                          to={`/admin/account-scanner/${transfer.recipient}`}
                        >
                          {transfer.recipient}
                        </Link>
                      </td>
                      <td>
                        {moment
                          .tz(parseInt(transfer.created_at_time), "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                    </tr>
                  );
                })}

              {transfers.isFetching ? (
                <>
                  {new Array(5).fill(0).map((transfer, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NftHistoryTransfers;
