import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ApiService from "app/services/api";
import LatestMines from "./components/latest-mines";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import {Query, Builder, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';

export const MiningExplorer = () => {

    const InitialConfig = MaterialConfig;
    const config = {
        ...InitialConfig,
        fields: {
          act_data_miner: {
              label: 'Miner',
              type: 'text',
              valueSources: ['value'],
              excludeOperators: ['starts_with', 'ends_with', 'proximity', 'like', 'not_like']
          },
          act_data_planet_name: {
            label: 'Planet',
            type: 'select',
            valueSources: ['value'],
            fieldSettings: {
              listValues: [
                { value: "eyeke.world", title: "eyeke" },
                { value: "kavian.world", title: "kavian" },
                { value: "magor.world", title: "magor" },
                { value: "naron.world", title: "naron" },
                { value: "neri.world", title: "neri" },
                { value: "veles.world", title: "veles" }
              ]
            },
            excludeOperators: ['starts_with', 'ends_with', 'proximity', 'like', 'not_like']
          },
          act_data_bag_items_0: {
            label: 'Tool 1 - NFT ID',
            type: 'number',
            fieldSettings: {
              min: 0
            },
            valueSources: ['value'],
            preferWidgets: ["number"]
          },
          act_data_bag_items_1: {
            label: 'Tool 2 - NFT ID',
            type: 'number',
            fieldSettings: {
              min: 0
            },
            valueSources: ['value'],
            preferWidgets: ["number"]
          },
          act_data_bag_items_2: {
            label: 'Tool 3 - NFT ID',
            type: 'number',
            fieldSettings: {
              min: 0
            },
            valueSources: ['value'],
            preferWidgets: ["number"]
          },
          act_data_templates: {
            label: 'Tool type (Template ID)',
            type: 'select',
            valueSources: ['value'],
            fieldSettings: {
              listValues: [],
              showSearch: true
            },
            widgets: {
              select: {
                operators: ['equal', 'not_equal'],
                defaultOperator: 'equal',
                opProps: {
                  equal: {
                    label: 'With'
                  },
                  not_equal: {
                    label: 'Without'
                  }
                }
              }
            }
          },
          act_data_land_id: {
            label: 'Land - NFT ID',
            type: 'number',
            fieldSettings: {
              min: 0
            },
            valueSources: ['value'],
            preferWidgets: ["number"]
          },
          act_data_landowner: {
            label: 'Land owner',
            type: 'text',
            valueSources: ['value'],
            excludeOperators: ['starts_with', 'ends_with', 'proximity', 'like', 'not_like']
          },
          act_data_bounty: {
            label: 'Bounty',
            type: "number",
            fieldSettings: {
              min: 0
            },
            valueSources: ["value"],
            preferWidgets: ["number"]
          },
          act_data_params_commission: {
            label: 'Commission',
            type: "number",
            fieldSettings: {
              min: 0,
              // max: 100
            },
            valueSources: ["value"],
            preferWidgets: ["number"]
          },
          timestamp: {
            label: 'Timestamp',
            type: 'datetime',          
            valueSources: ['value'],
            fieldSettings: {
              dateFormat: 'YYYY-MM-DD',
              valueFormat: 'YYYY-MM-DDTHH:mm:ss.000'
            }
          },
          act_data_templates_0: {
            label: 'Tool 1 (Template ID)',
            type: 'select',
            valueSources: ['value'],
            fieldSettings: {
              listValues: [],
              showSearch: true
            },
            widgets: {
              select: {
                operators: ['equal', 'not_equal'],
                defaultOperator: 'equal',
                opProps: {
                  equal: {
                    label: '=='
                  },
                  not_equal: {
                    label: '!='
                  }
                }
              }
            }
          },
          act_data_templates_1: {
            label: 'Tool 2 (Template ID)',
            type: 'select',
            valueSources: ['value'],
            fieldSettings: {
              listValues: [],
              showSearch: true
            },
            widgets: {
              select: {
                operators: ['equal', 'not_equal'],
                defaultOperator: 'equal',
                opProps: {
                  equal: {
                    label: '=='
                  },
                  not_equal: {
                    label: '!='
                  }
                }
              }
            }
          },
          act_data_templates_2: {
            label: 'Tool 3 (Template ID)',
            type: 'select',
            valueSources: ['value'],
            fieldSettings: {
              listValues: [],
              showSearch: true
            },
            widgets: {
              select: {
                operators: ['equal', 'not_equal'],
                defaultOperator: 'equal',
                opProps: {
                  equal: {
                    label: '=='
                  },
                  not_equal: {
                    label: '!='
                  }
                }
              }
            }
          }
        }
    };
    config.settings.showNot = false;

    const templates = useQuery([`mining_explorer_templates`], async () => {
      const response = await ApiService.get("mining/templates");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    });

    const queryValue = {"id": QbUtils.uuid(), "type": "group"};
    const params = new URLSearchParams(useLocation().search);
    const tree = params.get("tree") ? JSON.parse(params.get("tree")) : {"id":"98aaaaab-cdef-4012-b456-717ebe3af3a9","type":"group","children1":{"9988a88b-4567-489a-bcde-f17ebe3bbb44":{"type":"rule","properties":{"field":"timestamp","operator":"between","value":[moment().subtract(6, 'month').format('YYYY-MM-DDTHH:mm:ss.000'), moment().format('YYYY-MM-DDTHH:mm:ss.000')],"valueSrc":["value",null],"valueType":["datetime","datetime"]}}}};
    const [state, setState] = useState({});
    const [elasticQuery, setElasticQuery] = useState(params.get("tree") ? JSON.stringify(QbUtils.elasticSearchFormat(QbUtils.checkTree(QbUtils.loadTree(tree, config), config), config)) : '');

    const onChange = (immutableTree, config) => {
        setState({ tree: immutableTree, config: config });
    };

    const applyFilter = () => {
      let query = JSON.stringify(QbUtils.elasticSearchFormat(state.tree, state.config));
      if(query) {
        setElasticQuery(query);
        let tree = JSON.stringify(QbUtils.getTree(state.tree));
        let url = "/admin/mining-explorer?tree="+tree;
        window.history.pushState({}, null, url);
      }
    }
    
    const renderBuilder = (props) => (
        <div className="query-builder-container">
          <div className="query-builder">
            <Builder {...props} />
          </div>
        </div>
    );
  
    useEffect(() => {
      if(!templates.data)
        return

      config.fields.act_data_templates.listValues = templates.data;
      setState({
        tree: tree ? QbUtils.checkTree(QbUtils.loadTree(tree, config), config) : QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
        config: config
      });

      config.fields.act_data_templates_0.listValues = templates.data;
      config.fields.act_data_templates_1.listValues = templates.data;
      config.fields.act_data_templates_2.listValues = templates.data;
    }, [templates.data])

    useEffect(() => {
        setTimeout(() => {
            window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/mining-explorer">Mining Explorer</BreadcrumbsItem>
      
      {/* <p className="text-warning mt-2">
        <i class="fas fa-exclamation-triangle me-2"></i>
        This section is under active development. Please use only for feedback.
      </p> */}
      {state.tree && state.config ? (
      <>
      <Query
        {...state.config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      <button type="button" className="btn btn-primary mt-1 mb-1" onClick={applyFilter}>
        Apply filter
      </button>
      </>
      ) : (
        <Skeleton/>
      )}
      {/* <div className="query-builder-result">
        <div>
            Elastic search query:{" "}
            <pre>
              {JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config))}
            </pre>
        </div>
      </div> */}
      
      <div className="row">
        <div class="col-12">
          <LatestMines query={elasticQuery} />
        </div>
      </div>
      
    </>
  );
};

export default MiningExplorer;
