import { Skeleton } from "@material-ui/lab";
import ApiService from "app/services/api";
import moment from "moment";
import { useQuery } from "react-query";

export const AdminSelfTestTowerTimeline = (props) => {
  const { filters } = props;
  const componentProps = {
    component: "components/self-test/tower-timeline",
    options: { props: {} },
  };

  const towerStatsResponse = useQuery(
    ["tower_timeline", filters],
    async () => {
      const response = await ApiService.get("self-test/tower-timeline", {
        date: filters ? filters.date : moment.utc().subtract(1, 'days').format("YYYY-MM-DD"),
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {}
  );

  const towerStats = towerStatsResponse.data;

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h5 class="mb-4">
              Tower Timeline
              {/* {towerStats
                ? moment(towerStats.date).format("YYYY-MM-DD")
                : "N/A"} */}
            </h5>
            <ul className="list-unstyled timeline-sm">
              <li className="timeline-sm-item">
                <span className="timeline-sm-date">
                  {towerStats ? (
                    towerStats.stats["active_miners"] ? (
                      `${
                        towerStats.stats["active_miners"].start_time
                          ? moment(
                              towerStats.stats["active_miners"].start_time
                            ).format("HH:mm")
                          : "N/A"
                      } - ${
                        towerStats.stats["active_miners"].end_time
                          ? moment(
                              towerStats.stats["active_miners"].end_time
                            ).format("HH:mm")
                          : "N/A"
                      }`
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </span>
                <h5 className="mt-0 mb-1">Step1: Active miners</h5>
                <p>
                  {towerStats ? (
                    towerStats.stats["active_miners"] ? (
                      <>
                        <span
                          class={`badge ${
                            {
                              active: "bg-warning",
                              completed: "bg-success",
                              failed: "bg-danger",
                            }[towerStats.stats["active_miners"].status]
                          }`}
                        >
                          {towerStats.stats["active_miners"].status}
                        </span>
                        &nbsp;
                        {JSON.stringify(towerStats.stats["active_miners"].data)}
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </p>
              </li>
              <li className="timeline-sm-item">
                <span className="timeline-sm-date">
                  {towerStats ? (
                    towerStats.stats["signals_manager"] ? (
                      `${
                        towerStats.stats["signals_manager"].start_time
                          ? moment(
                              towerStats.stats["signals_manager"].start_time
                            ).format("HH:mm")
                          : "N/A"
                      } - ${
                        towerStats.stats["signals_manager"].end_time
                          ? moment(
                              towerStats.stats["signals_manager"].end_time
                            ).format("HH:mm")
                          : "N/A"
                      }`
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </span>
                <h5 className="mt-0 mb-1">Step2: Signals Manager</h5>
                <p>
                  {" "}
                  {towerStats ? (
                    towerStats.stats["signals_manager"] ? (
                      <>
                        <span
                          class={`badge ${
                            {
                              active: "bg-warning",
                              completed: "bg-success",
                              failed: "bg-danger",
                            }[towerStats.stats["signals_manager"].status]
                          }`}
                        >
                          {towerStats.stats["signals_manager"].status}
                        </span>
                        &nbsp;
                        {JSON.stringify(
                          towerStats.stats["signals_manager"].data
                        )}
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </p>
              </li>
              <li className="timeline-sm-item">
                <span className="timeline-sm-date">
                  {towerStats ? (
                    towerStats.stats["signals_processor"] ? (
                      `${
                        towerStats.stats["signals_processor"].start_time
                          ? moment(
                              towerStats.stats["signals_processor"].start_time
                            ).format("HH:mm")
                          : "N/A"
                      } - ${
                        towerStats.stats["signals_processor"].end_time
                          ? moment(
                              towerStats.stats["signals_processor"].end_time
                            ).format("HH:mm")
                          : "N/A"
                      }`
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </span>
                <h5 className="mt-0 mb-1">Step3: Signals Processor</h5>
                <p>
                  {towerStats ? (
                    towerStats.stats["signals_processor"] ? (
                      <>
                        <span
                          class={`badge ${
                            {
                              active: "bg-warning",
                              completed: "bg-success",
                              failed: "bg-danger",
                            }[towerStats.stats["signals_processor"].status]
                          }`}
                        >
                          {towerStats.stats["signals_processor"].status}
                        </span>
                        &nbsp;
                        {JSON.stringify(
                          towerStats.stats["signals_processor"].data
                        )}
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </p>
              </li>
              <li className="timeline-sm-item">
                <span className="timeline-sm-date">
                  {towerStats ? (
                    towerStats.stats["adjudicator"] ? (
                      `${
                        towerStats.stats["adjudicator"].start_time
                          ? moment(
                              towerStats.stats["adjudicator"].start_time
                            ).format("HH:mm")
                          : "N/A"
                      } - ${
                        towerStats.stats["adjudicator"].end_time
                          ? moment(
                              towerStats.stats["adjudicator"].end_time
                            ).format("HH:mm")
                          : "N/A"
                      }`
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </span>
                <h5 className="mt-0 mb-1">Step4: Adjudicator</h5>
                <p>
                  {towerStats ? (
                    towerStats.stats["adjudicator"] ? (
                      <>
                        <span
                          class={`badge ${
                            {
                              active: "bg-warning",
                              completed: "bg-success",
                              failed: "bg-danger",
                            }[towerStats.stats["adjudicator"].status]
                          }`}
                        >
                          {towerStats.stats["adjudicator"].status}
                        </span>
                        &nbsp;
                        {JSON.stringify(towerStats.stats["adjudicator"].data)}
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton />
                  )}
                </p>
              </li>
              {/* <li className="timeline-sm-item">
                <span className="timeline-sm-date">2010 - 12</span>
                <h5 className="mt-0 mb-1">Step5: Gaoler</h5>
                <p>Coderthemes LLP</p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSelfTestTowerTimeline;
