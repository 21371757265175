import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import { copyToClipboard } from "app/utils/datatables";

export const OneHopAccounts = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/one-hop-accounts",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const [hideSystemAccounts, setHideSystemAccounts] = useState(true);

  const accounts = useQuery(
    [
      `one-hop_accounts${props.account}`,
      props.account,
      filters,
      hideSystemAccounts,
    ],
    async () => {
      const response = await AccountService.getOneHopAccounts(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        hideSystemAccounts: hideSystemAccounts ? 1 : 0,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!accounts.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            {
              extend: "csv",
              title: `One hop connections for ${props.account}`,
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Wallet")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [accounts.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#one_hop_accounts_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("one_hop_accounts", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="systemaccounts-check"
                defaultChecked={hideSystemAccounts}
                onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
              />
              <label
                className="form-check-label"
                htmlFor="systemaccounts-check"
              >
                Hide system accounts
              </label>
            </div>
          </form>
        </div>
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `One hop connections`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `One hop connections`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "One hop connections tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="one_hop_accounts"
        >
          <thead>
            <tr>
              <th className="no-sort">
                Wallet &nbsp;
                <Link to="" onClick={copyWalletsToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy accounts to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Number of children</th>
            </tr>
          </thead>
          <tbody>
            {accounts.data &&
              accounts.data.map((account, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${account.account}`}
                        target="_blank"
                      >
                        {account.account}
                      </Link>
                    </td>
                    <td>{account.childrens.toLocaleString()}</td>
                  </tr>
                );
              })}

            {accounts.isFetching ? (
              <>
                {new Array(5).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {accounts.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default OneHopAccounts;
