import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import { copyToClipboard } from "app/utils/datatables";

export const ConnectedExchangeMemo = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/connected-exchange-memo",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "connected_exchange_memos" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const transfers = useQuery(
    [`connected_exchange_memo${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getExchangeMemo(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            {
              extend: "csv",
              title: `Connected accounts: Same exchange memo for ${props.account}`,
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Account")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#connected_exchange_memos_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("connected_exchange_memos", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Connected accounts: Same exchange memo`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Connected accounts: Same exchange memo`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Connected accounts: Same exchange memo tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="connected_exchange_memos"
        >
          <thead>
            <tr>
              <th className="no-sort">
                Account &nbsp;
                <Link to="" onClick={copyWalletsToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy accounts to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Created date</th>
              <th>Memo</th>
              <th>Number of transfers</th>
              <th>Transfer amount</th>
              <th>Exchange name</th>
            </tr>
          </thead>
          <tbody>
            {transfers.data &&
              transfers.data.map((transfer, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${transfer.account}`}
                        target="_blank"
                      >
                        {transfer.account}
                      </Link>
                    </td>
                    <td title={moment(transfer.created_date).fromNow()}>
                      {moment
                        .tz(transfer.created_date, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>{transfer.memo}</td>
                    <td>{transfer.no_of_transfers}</td>
                    <td>
                      {parseFloat(transfer.transfer_amount).toLocaleString()}
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${transfer.transfer_to}`}
                        target="_blank"
                      >
                        {transfer.transfer_to}
                      </Link>
                    </td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(5).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default ConnectedExchangeMemo;
