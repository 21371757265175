import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";
import PendingTeleports from "./pending-teleports";
import PendingTlm from "./pending-tlm";
import StuckTeleports from "./stuck-teleports";
import UnclaimedTeleports from "./unclaimed-teleports";

export const AdminRealtimeTeleportStats = (props) => {
  const componentProps = {
    component: "components/realtime/teleport/teleport-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Teleport stats (last 1000 actions)`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Teleport stats tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <PendingTeleports />
          </div>
          <div class="col-xl-4 col-md-6">
            <PendingTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <StuckTeleports />
          </div>
          <div class="col-xl-4 col-md-6">
            <UnclaimedTeleports />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRealtimeTeleportStats;
