import Sankey from "../components/charts/sankey";
import PlanetChart from "../components/charts/planet-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import TlmTransfers from "../components/tlm/tlm-transfers";
import TlmPriceChart from "../components/tlm/tlm-price-chart";
import Highcharts from "highcharts";
import { useEffect } from "react";
import TlmDistributionChart from "admin/components/tlm/tlm-distribution-chart";
import TlmDistributionTable from "admin/components/tlm/tlm-distribution-table";
import TlmDistributionTableChart from "admin/components/tlm/tlm-distribution-table-chart";
import TlmLorenzCurve from "admin/components/tlm/tlm-lorenz-curve";


function AdminDashboardTLM() {  

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$('.button-menu-mobile').click(function(){ 
      setTimeout(() => {
        window.$(Highcharts.charts).each(function(i,chart){
          chart.reflow();
        });
      }, 1000);
    });
  })

  return (
    <>     
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-tlm">TLM</BreadcrumbsItem>
      <div className="row">
        <div className="col-lg-12">
           <Sankey /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <PlanetChart /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmTransfers />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmPriceChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmDistributionChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmDistributionTable />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmDistributionTableChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmLorenzCurve />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardTLM;
