//import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { unsetUser } from "../../../app/state/session";
import AuthService from "../../../app/services/auth";
import { useLoggedIn, useUser } from "../../../app/hooks/useSession";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "app/config";
import { useDashboardData } from "admin/hooks/useDashboardData";
import AdminSearch from "./search";

function AdminTopbar(props) {
  const dispatch = useDispatch();
  let history = useHistory();

  const sessionLoggedIn = useLoggedIn();
  const sessionUser = useUser();

  const dashboardData = useDashboardData();

  const onLogoutClick = (e) => {
    e.preventDefault();
    AuthService.logout().then(() => {
      dispatch(unsetUser());
      history.push("/admin/login");
    });
  };

  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <AdminSearch />

          <li className="dropdown d-none d-lg-inline-block">
            <a
              className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
              data-toggle="fullscreen"
              href="#"
            >
              <i className="fe-maximize noti-icon"></i>
            </a>
          </li>

          <li className="dropdown notification-list topbar-dropdown">
            <Link
              to="/admin/my-alerts"
              className="nav-link waves-effect waves-light"
            >
              <i className="fe-bell noti-icon" />
              {dashboardData && dashboardData.alertsCount ? (
                <span className="badge bg-danger rounded-circle noti-icon-badge">
                  {dashboardData.alertsCount}
                </span>
              ) : null}
            </Link>
          </li>

          {sessionLoggedIn ? (
            <li className="dropdown notification-list topbar-dropdown">
              <a
                className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                data-bs-toggle="dropdown"
                href="true"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  src="/assets/images/users/avatar.jpg"
                  alt="user-image"
                  className="rounded-circle"
                />
                <span className="pro-user-name ms-1">
                  {sessionUser.name} <i className="mdi mdi-chevron-down"></i>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome !</h6>
                </div>

                <Link
                  to="/admin/my-account"
                  className="dropdown-item notify-item"
                >
                  <i className="ri-account-circle-line"></i>
                  <span>My Account</span>
                </Link>

                <Link
                  to="/admin/dashboard"
                  className="dropdown-item notify-item"
                >
                  <i className="ri-wallet-line"></i>
                  <span>My Dashboard</span>
                </Link>
                <Link
                  to="/admin/my-alerts"
                  className="dropdown-item notify-item"
                >
                  <i className="ri-notification-line"></i>
                  <span>My Alerts</span>
                </Link>
                {sessionUser.role === "super_admin" ||
                sessionUser.level === "level2" ? (
                  <Link
                    to="/admin/admin-users"
                    className="dropdown-item notify-item"
                  >
                    <i className="ri-wallet-line"></i>
                    <span>Admin users</span>
                  </Link>
                ) : null}
                {sessionUser.role === "super_admin" ? (
                  <a
                    href={`${config.admin.url}/admin/queues`}
                    target="_blank"
                    className="dropdown-item notify-item"
                  >
                    <i className="ri-wallet-line"></i>
                    <span>Bull Dashboard</span>
                  </a>
                ) : null}

                <div className="dropdown-divider"></div>

                <a
                  href="#"
                  className="dropdown-item notify-item"
                  onClick={onLogoutClick}
                >
                  <i className="ri-logout-box-line"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          ) : null}
        </ul>

        <div className="logo-box">
          <Link to="/admin/dashboard" className="logo logo-dark text-center">
            <span className="logo-sm">
              <img src="/assets/images/logo-sm-dark.png" alt="" height="24" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/logo-dark.png" alt="" height="20" />
            </span>
          </Link>
          <Link to="/admin/dashboard" className="logo logo-light text-center">
            <span className="logo-sm">
              <img src="/assets/images/logo-sm.png" alt="" height="24" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/logo-light.png" alt="" height="20" />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button className="button-menu-mobile waves-effect waves-light">
              <i className="fe-menu"></i>
            </button>
          </li>

          <li>
            <a
              className="navbar-toggle nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </li>
          <li className="dropdown d-none d-xl-block">
            <Link
              className="nav-link waves-effect waves-light"
              to="/admin/nfts"
              role="button"
            >
              NFT Explorer
            </Link>
          </li>
        </ul>

        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default AdminTopbar;
