import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const KpisDau = (props) => {
  const componentProps = {
    component: "components/kpis/kpis-dau",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };

  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);

  const stats = useQuery(["kpis-dau", filters], async () => {
    const response = await ApiService.get("kpis/dau", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!stats.data) return;

    setData(stats.data);

    chartComponent.current.chart.hideLoading();
  }, [stats.data]);

    

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />     
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `DAU`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "DAU"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {stats.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "",
                     
                    },
                  },
                  yAxis: {
                    type: "logarithmic",
                    allowDecimals: false,
                    title: {
                      text: "",
                    },
                  },
                  tooltip: {
                    valueDecimals: 0,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KpisDau;
