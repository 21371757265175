import { useEffect } from "react";
import NewUsersChart from "../components/users/new-users-chart";
import CumulativeUsersChart from "../components/users/cumulative-users-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import NewUsersHistogramChart from "../components/users/new-users-histogram-chart";
import DailyActiveUsersChart from "../components/users/daily-active-users-chart";
import MonthlyActiveUsersChart from "../components/users/monthly-active-users-chart";
import Highcharts from "highcharts";
import DailyActiveUsersAllChart from "admin/components/users/daily-active-users-all-chart";

function AdminDashboardMissions() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });

        window.$('.button-menu-mobile').click(function(){ 
          setTimeout(() => {
            window.$(Highcharts.charts).each(function(i,chart){
              chart.reflow();
            });
          }, 1000);
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-users">Users</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <NewUsersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <CumulativeUsersChart />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <NewUsersHistogramChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailyActiveUsersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MonthlyActiveUsersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailyActiveUsersAllChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardMissions;
