import KpisDailyActiveWallets from "admin/components/kpis/kpis-daily-active-wallets";
import KpisDailyLogins from "admin/components/kpis/kpis-daily-logins";
import KpisDau from "admin/components/kpis/kpis-dau";
import KpisDauCountry from "admin/components/kpis/kpis-dau-country";
import KpisMauCalendar from "admin/components/kpis/kpis-mau-calendar";
import KpisParty from "admin/components/kpis/kpis-party";
import KpisPartyCountry from "admin/components/kpis/kpis-party-country";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

export const AdminDashboardKpis = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-kpis">KPIs & Party</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <KpisDailyLogins />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisMauCalendar />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisDau />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisDauCountry />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisDailyActiveWallets />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisParty />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KpisPartyCountry />
        </div>
      </div>
    </>
  );
};

export default AdminDashboardKpis;
