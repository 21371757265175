import { useEffect } from "react";
import SystemAccountsTransactionsChart from "../components/energy/system-accounts-transactions-chart";
import SystemAccountsEnergyChart from "../components/energy/system-accounts-energy-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function AdminDashboardEnergy() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-energy">Energy</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <SystemAccountsTransactionsChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <SystemAccountsEnergyChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardEnergy;
