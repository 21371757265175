import { useQuery } from "react-query";
import AccountService from "admin/services/account";
import { useEffect, useState } from "react";
import { formatBytes } from "app/utils/format";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";
import { helpTextWrapper } from "app/utils/string";

export const AdminSystemAccountStats = (props) => {
  const componentProps = {
    component: "views/dashboard-system/components/account-stats",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();

  const [breakDown, setBreakDown] = useState({});
  const accountInfoQuery = useQuery(
    [`chain_account_${props.account}`, props.account],
    () => {
      return AccountService.getChainAccount(props.account, {
        timestamp: moment.utc().valueOf(),
      });
    },
    { refetchInterval: 30 * 1000 }
  );

  const userresQuery = useQuery(
    [`chain_userres_${props.account}`, props.account],
    () => {
      return AccountService.getChainUserres(props.account, {
        timestamp: moment.utc().valueOf(),
      });
    },
    { refetchInterval: 30 * 1000 }
  );

  const delbandQuery = useQuery(
    [`chain_delband_${props.account}`, props.account],
    () => {
      return AccountService.getChainDelband(props.account, {
        timestamp: moment.utc().valueOf(),
      });
    },
    { refetchInterval: 30 * 1000 }
  );

  useEffect(() => {
    let breakDown = {
      balance: 0,
      liquidBalance: 0,
      unstaked: 0,
      unstaking: 0,
      staked: 0,
      stakedSelf: 0,
      stakedByOthers: 0,
      stakedToOthers: 0,
      cpuStaked: 0,
      cpuStakedSelf: 0,
      cpuStakedByOthers: 0,
      cpuStakedToOthers: 0,
      netStaked: 0,
      netStakedSelf: 0,
      netStakedByOthers: 0,
      netStakedToOthers: 0,
      ramQuota: 0,
      ramUsage: 0,
      ramUsagePercentage: 0,
      ramAvailable: 0,
      cpuLimitUsed: 0,
      cpuLimitAvailable: 0,
      cpuLimitMax: 0,
      cpuUsagePercentage: 0,
      netLimitUsed: 0,
      netLimitAvailable: 0,
      netLimitMax: 0,
      netUsagePercentage: 0,
    };

    let accountInfo = accountInfoQuery.data;
    let userres = userresQuery.data;
    let delband = delbandQuery.data;

    if (accountInfo) {
      breakDown.liquidBalance = accountInfo.core_liquid_balance
        ? parseFloat(accountInfo.core_liquid_balance)
        : 0;

      breakDown.cpuStakedSelf += accountInfo.self_delegated_bandwidth
        ? parseFloat(accountInfo.self_delegated_bandwidth.cpu_weight)
        : 0;
      breakDown.netStakedSelf += accountInfo.self_delegated_bandwidth
        ? parseFloat(accountInfo.self_delegated_bandwidth.net_weight)
        : 0;

      breakDown.ramQuota = accountInfo.ram_quota
        ? parseFloat(accountInfo.ram_quota)
        : 0;
      breakDown.ramUsage = accountInfo.ram_usage
        ? parseFloat(accountInfo.ram_usage)
        : 0;
      breakDown.ramAvailable = breakDown.ramQuota - breakDown.ramUsage;
      breakDown.ramUsagePercentage =
        (breakDown.ramUsage / breakDown.ramQuota) * 100;

      breakDown.cpuLimitUsed = accountInfo.cpu_limit.used;
      breakDown.cpuLimitAvailable = accountInfo.cpu_limit.available;
      breakDown.cpuLimitMax = accountInfo.cpu_limit.max;
      breakDown.cpuUsagePercentage =
        (breakDown.cpuLimitUsed / breakDown.cpuLimitMax) * 100;

      breakDown.netLimitUsed = accountInfo.net_limit.used;
      breakDown.netLimitAvailable = accountInfo.net_limit.available;
      breakDown.netLimitMax = accountInfo.net_limit.max;
      breakDown.netUsagePercentage =
        (breakDown.netLimitUsed / breakDown.netLimitMax) * 100;

      if (userres) {
        breakDown.cpuStakedByOthers = userres.rows[0]
          ? parseFloat(userres.rows[0].cpu_weight) - breakDown.cpuStakedSelf
          : 0;
        breakDown.netStakedByOthers = userres.rows[0]
          ? parseFloat(userres.rows[0].net_weight) - breakDown.netStakedSelf
          : 0;
      }

      if (delband) {
        for (let index in delband.rows) {
          var accountStakedToOther = delband.rows[index];
          if (
            accountStakedToOther.from !== accountInfo.account_name ||
            accountStakedToOther.to === accountInfo.account_name
          ) {
            continue;
          }
          breakDown.cpuStakedToOthers += accountStakedToOther.cpu_weight
            ? parseFloat(accountStakedToOther.cpu_weight)
            : 0;
          breakDown.netStakedToOthers += accountStakedToOther.net_weight
            ? parseFloat(accountStakedToOther.net_weight)
            : 0;
        }
      }

      breakDown.cpuStaked =
        breakDown.cpuStakedSelf +
        breakDown.cpuStakedByOthers +
        breakDown.cpuStakedToOthers;
      breakDown.netStaked =
        breakDown.netStakedSelf +
        breakDown.netStakedByOthers +
        breakDown.netStakedToOthers;

      breakDown.stakedSelf = breakDown.cpuStakedSelf + breakDown.netStakedSelf;
      breakDown.stakedByOthers =
        breakDown.cpuStakedByOthers + breakDown.netStakedByOthers;
      breakDown.stakedToOthers =
        breakDown.cpuStakedToOthers + breakDown.netStakedToOthers;

      breakDown.staked += breakDown.cpuStaked;
      breakDown.staked += breakDown.netStaked;

      breakDown.unstaked = accountInfo.core_liquid_balance
        ? parseFloat(accountInfo.core_liquid_balance)
        : 0;

      breakDown.unstaking += accountInfo.refund_request
        ? parseFloat(accountInfo.refund_request.net_amount)
        : 0;
      breakDown.unstaking += accountInfo.refund_request
        ? parseFloat(accountInfo.refund_request.cpu_amount)
        : 0;

      breakDown.balance = 0;
      breakDown.balance += breakDown.stakedSelf;
      breakDown.balance += breakDown.stakedToOthers;
      breakDown.balance += breakDown.unstaked;
      breakDown.balance += breakDown.unstaking;

      setBreakDown(breakDown);
    }
  }, [accountInfoQuery.data, userresQuery.data, delbandQuery.data]);

  const getPercentageClass = (type, percentage) => {
    if (percentage > 90) {
      return "text-danger";
    } else if (percentage > 70) {
      return "text-warning";
    } else {
      return "text-success";
    }
  };

  const [ramAvailableValue, ramAvailableUnit] = breakDown.ramAvailable
    ? formatBytes(breakDown.ramAvailable || 0, 2).split(" ")
    : "";

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4
            className="header-title"
            data-ht-title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || ` Account stats of ACCOUNT`
            }
          >
            {helpTextWrapper(
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || ` Account stats of ACCOUNT`,
              {
                ACCOUNT: props.account,
              }
            )}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              data-ht-value={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || `Account stats of ACCOUNT Tooltip`
              }
              title={helpTextWrapper(
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || `Account stats of ACCOUNT Tooltip`,
                {
                  ACCOUNT: props.account,
                }
              )}
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3
                    className={`mt-0 ${getPercentageClass(
                      "ram",
                      breakDown.ramUsagePercentage
                    )}`}
                  >
                    <span data-plugin="counterup">{ramAvailableValue}</span>
                    &nbsp;{ramAvailableUnit}
                  </h3>
                  <p className="text-muted mb-0">RAM</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3
                    className={`mt-0 ${getPercentageClass(
                      "net",
                      breakDown.netUsagePercentage
                    )}`}
                  >
                    <span data-plugin="counterup">
                      {parseFloat(breakDown.netStaked || 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 4,
                        }
                      )}
                    </span>
                  </h3>
                  <p className="text-muted mb-0">NET</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3
                    className={`mt-0 ${getPercentageClass(
                      "cpu",
                      breakDown.cpuUsagePercentage
                    )}`}
                  >
                    <span data-plugin="counterup">
                      {parseFloat(breakDown.cpuStaked || 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 4,
                        }
                      )}
                    </span>
                  </h3>
                  <p className="text-muted mb-0">CPU</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    <span data-plugin="counterup">
                      {parseFloat(breakDown.balance || 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 4,
                        }
                      )}
                    </span>
                  </h3>
                  <p className="text-muted mb-0">WAX</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSystemAccountStats;
