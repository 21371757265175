import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "admin/services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminSelfTestLatestReports = () => {
  const componentProps = {
    component: "components/self-test/latest-reports",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();

  const reports = useQuery(
    "latest_reports",
    async () => {
      const response = await ReportsService.getLatestReports({
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {}
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!reports.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          bPaginate: false,
          lengthChange: false,
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [reports.data]);

  const humanize = (str) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const displayStatus = (report) => {
    if (report.status === "new") {
      return <span className="badge badge-outline-primary">New</span>;
    } else if (report.status === "pending") {
      return <span className="badge badge-outline-warning">Pending</span>;
    } else if (report.status === "processing") {
      return <span className="badge badge-outline-warning">Processing</span>;
    } else if (report.status === "completed") {
      return <span className="badge badge-outline-success">Completed</span>;
    } else if (report.status === "failed") {
      return <span className="badge badge-outline-danger">Failed</span>;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title">Latest 10 Reports</h4>
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="list_reports"
            >
              <thead>
                <tr>
                  <th>Report Type</th>
                  <th>Request Date</th>
                  <th>Processed Date </th>
                  <th>User</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {reports.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {reports.data &&
                      reports.data.map((report, index) => {
                        var type;
                        switch (report.type) {
                          // case "reportSample1":
                          //   var type = "Report sample1";
                          //   break;
                          // case "reportSample2":
                          //   var type = "Report sample2";
                          //   break;
                          // case "reportSample3":
                          //   var type = "Report sample3";
                          //   break;
                          case "reportMiningActions":
                            type = "Report Mining Actions";
                            break;
                          case "reportTlmTransfers":
                            type = "Report TLM Transfers";
                            break;
                          case "reportTlmMiners":
                            type = "Top 100 TLM miners (non-bot)";
                            break;
                          case "reportBotScoreUsers":
                            type = "Report bot score users";
                            break;
                          case "reportNfts":
                            type = "Report NFTs with Template ID";
                            break;
                          case "reportAccountsMiningFrequency":
                            type = "Accounts mining frequency";
                            break;
                          case "reportMostExchangeMemos":
                            type = "Most used exchange memos";
                            break;
                          case "reportExportNftMovementsSummary":
                            type = "Export NFT movements (Summary)";
                            break;
                          case "reportExportNftMovementsInterval":
                            type = "Export NFT movements (Interval)";
                            break;
                          case "reportMinesWithTemplate":
                            type = "Peoples mine with tool (Template ID)";
                            break;
                          case "reportExportMining":
                            type = "Export csv from mining explorer";
                            break;
                          case "reportMultipleAuthorizationsMine":
                            type = "Multiple Authorizations Mine";
                            break;
                          case "reportContractLandowners":
                            type = "Landowners with Contract";
                            break;
                          case "reportExportTlmTransfers":
                            type = "Export csv from Tlm transfers lookup";
                            break;
                          case "reportExportWaxTransfers":
                            type = "Export csv from Wax transfers lookup";
                            break;
                          case "reportExportNftTransfers":
                            type = "Export csv from NFT transfers lookup";
                            break;
                          case "reportExportNftsTransfersDeduped":
                            type = "Export csv from NFTs Transfers Deduped";
                            break;
                          case "reportCreatedAccountsUsingNewuser":
                            type = "Accounts created using newuser.wax";
                            break;
                          case "reportNftsForensics":
                            type = "NFTs Forensics";
                            break;
                          case "reportMiningActiveUsersMonthly":
                            type = "Mining Active Users Monthly";
                            break;
                          case "reportTlmEarnedPerMiner":
                            type = "Monthly Avg TLM amount for a user by month";
                            break;
                          case "reportTlmEarnedPerMiners":
                            type = "Monthly Avg. TLM amount earned per user";
                            break;
                          case "reportActiveMinersUniqueAnalysis":
                            type = "Active miners unique analysis";
                            break;
                          case "reportActiveMinersCohortAnalysis":
                            type = "Active miners cohort analysis";
                            break;
                          case "reportEarnSpentRatio":
                            type = "Earn Vs Spent, per user, monthly";
                            break;
                          case "reportEarnOverThreshold":
                            type =
                              "% of active users earning more than X TLM per month";
                            break;
                          case "reportSecondaryMarketPurchase":
                            type =
                              "*Secondary Market* Average spending in WAX per user per month";
                            break;
                          case "reportSecondaryMarketSale":
                            type =
                              "*Secondary Market* Average earning in WAX per user per month";
                            break;
                          case "reportTopTlmMinersPerPlanet":
                            type = "Top 100 TLM miners per planet";
                            break;
                          case "reportTopNftPointsMinersPerPlanet":
                            type = "Top 100 NFT Points miners per planet";
                            break;
                          case "reportTopTlmMinersPerLand":
                            type = "Top 100 TLM miners per land";
                            break;
                          case "reportTopNftPointsMinersPerLand":
                            type = "Top 100 NFT Points miners per land";
                            break;
                          case "reportMiningActiveUsersPerInterval":
                            type = "New miners based on first mine";
                            break;
                          case "reportActiveMinersAverageAnalysis":
                            type = "Active Users Retention";
                            break;
                          case "reportNftsHeldByActiveMiners":
                            type = "% NFTs held by active miners";
                            break;
                          case "reportActiveMinersSpendCluster":
                            type =
                              "Segment Active User in 4 Cluster for TLM Spending";
                            break;
                          case "reportActiveMinersChurnAnalysis":
                            type = "Churn Rate as %";
                            break;
                          default:
                            break;
                        }
                        var filters = [];
                        if (report.filters) {
                          Object.entries(JSON.parse(report.filters)).forEach(
                            function (entry) {
                              const [key, value] = entry;
                              filters.push(humanize(key) + ": " + value);
                            }
                          );
                        }
                        return (
                          <tr key={index}>
                            <td>{type}</td>
                            <td
                              title={moment(report.request_datetime).fromNow()}
                            >
                              {moment
                                .tz(report.request_datetime, "UTC")
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              {report.generate_datetime
                                ? moment
                                    .tz(report.generate_datetime, "UTC")
                                    .format("YYYY-MM-DD HH:mm:ss")
                                : null}
                            </td>
                            <td>{report.user_fullname}</td>
                            <td>{displayStatus(report)}</td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {reports.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSelfTestLatestReports;
