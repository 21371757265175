import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import config from "app/config";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DaoPlanetDistributionChart = (props) => {
  const componentProps = {
    component: "components/dao/dao-planet-distribution-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const dac_ids = config.dac_ids;

  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [showLinear, setShowLinear] = useState(true);
  const [type, setType] = useState("all");

  const stats = useQuery(
    ["dao-planet-distribution_chart", filters, type],
    async () => {
      const response = await ApiService.get("dao/dao-planet-distribution-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        type: type,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!stats.data) return;

    setData(stats.data);

    chartComponent.current.chart.hideLoading();
  }, [stats.data]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={handleChange}
              >
                <option value="all">All</option>
                {dac_ids.map((dac_id) => {
                  return <option value={dac_id}>{dac_id}</option>;
                })}
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          Daily distribution chart
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Daily distribution chart"
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {stats.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    allowDecimals: false,
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    valueDecimals: 0,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaoPlanetDistributionChart;
