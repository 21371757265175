import SampleLongApi from "admin/components/sample-long-api";
import SampleTable from "admin/components/tables/sample";

export const AdminTest = () => {
  return (
    <>
      <h4>Test page</h4>
      <SampleTable />
      <SampleLongApi/>
    </>
  );
};

export default AdminTest;
