import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const Appeals = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/appeals",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const filters = useFilters();
  const [content, setContent] = useState(null);

  const appeals = useQuery(
    [`appeals_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getAppeals(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      return result;
    }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!appeals.data) {
      return;
    }

    window.$(document).on("click", "table#appeals .btn-read-more", function () {
      var content = window.$(this).attr("data-content");
      setContent(content);
      window.$("#read-more-appeals-modal").modal("show");
    });

    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Appeals for ${props.account}` },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [appeals.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4
            className="header-title"
            data-ht-title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `Appeals`
            }
          >
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Appeals`}{" "}
            for {props.account}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "List of appeals done by account"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="appeals"
          >
            <thead>
              <tr>
                <th>Reason</th>
                <th>Created On</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {appeals &&
                appeals.data &&
                Array.isArray(appeals.data) &&
                appeals.data.map((appeal, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {appeal.reason.slice(0, 150)}
                        {appeal.reason.length > 150 ? (
                          <>
                            ...
                            <button
                              class="btn btn-link btn-read-more"
                              data-content={appeal.reason}
                            >
                              Read more
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{appeal.created_at}</td>
                      <td>{appeal.status}</td>
                    </tr>
                  );
                })}

              {appeals.isFetching ? (
                <>
                  {new Array(5).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {appeals.isError ? <Error500 /> : null}
        </div>
      </div>
      <div
        id="read-more-appeals-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content" style={{ height: "400px" }}>
            {/* <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div> */}
            <div className="modal-body" style={{ whiteSpace: "pre-line" }}>
              {content}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#read-more-appeals-modal").modal("hide");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appeals;
