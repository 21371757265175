import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const AdminNftsList = (props) => {
  const componentProps = {
    component: "components/nfts/nfts-list",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window
          .$(tableRef.current)
          .DataTable({
            language: {
              paginate: {
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>",
              },
              emptyTable: "No results found within the selected time frame",
            },
            lengthMenu: [10, 100, 500],
            order: [[4, "desc"]],
            searching: false,
            processing: true,
            serverSide: true,
            //ordering: false,
            ajax: {
              url: `${config.api.url}/api/${config.api.version}/nfts/list`,
              cache: true,
              headers: {
                ...apiHeaders(),
              },
              data: function (data) {
                data.startDate = filters.dates.startDate;
                data.endDate = filters.dates.endDate;
                //data.timestamp = moment().utc().valueOf();
              },
            },
            columns: [
              { data: "name", name: "name" },
              { data: "template_id", name: "template_id" },
              { data: "assets_count", name: "assets_count" },
              { data: "usd_prize", name: "usd_prize" },
              { data: "valuation", name: "valuation" },
              { data: "shine", name: "shine" },
              { data: "rarity", name: "rarity" },
            ],
            columnDefs: [
              {
                targets: 0,
                orderable: false,
              },
              {
                targets: 1,
                orderable: false,
              },
              {
                targets: 3,
                orderable: false,
              },
              {
                targets: 5,
                orderable: false,
              },
              {
                targets: 6,
                orderable: false,
              },
            ],
            dom: "Blfrtip",
            buttons: ["copy"],
          })
          .on("xhr.dt", function (e, settings, json, xhr) {
            window
              .$("#nfts_list_title")
              .html(
                `Items: ${json.totalAssetsCount} | Valuation: $${
                  json.totalValuation
                } | Date: ${moment.utc(json.datetime).format("YYYY-MM-DD")}`
              );
          });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title">
          NFTs
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "All NFTs"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <h6 class="header-title" id="nfts_list_title"></h6>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="nfts_list"
        >
          <thead>
            <tr>
              {/* <th>Image</th> */}
              <th>Name</th>
              <th>Template ID</th>
              <th>Number of Items</th>
              <th>USD Price</th>
              <th>Valuation $</th>
              <th>Shine</th>
              <th>Rarity</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminNftsList;
