import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDashboardComponentsCount } from "admin/hooks/useDashboardComponentsCount";
import { useUser } from "../../../app/hooks/useSession";

function AdminLeftSidebar() {
  // const [path, setPath] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState({
    first: null,
    second: null,
  });
  const sessionUser = useUser();

  const dashboardComponentsCount = useDashboardComponentsCount();

  const location = useLocation();

  useEffect(() => {
    var url = new URL(window.location.href);
    handleSelecteMenu(url.pathname);
    setTimeout(() => {
      window.$("#sidebar-menu #side-menu li a").on("click", function (el) {
        var href =
          el.target.href ||
          (window.$(el.target).parent()[0] &&
            window.$(el.target).parent()[0].href);

        if (href) {
          url = new URL(href);
          handleSelecteMenu(url.pathname);
        }
      });
    }, 1000);
  }, []);

  useEffect(() => {
    var url = new URL(window.location.href);
    handleSelecteMenu(url.pathname);
  }, [location]);

  const handleSelecteMenu = (path) => {
    switch (true) {
      case path.includes("/admin/dashboard-tlm"):
        setSelectedMenu({ first: "dashboard", second: "tlm" });
        break;
      case path.includes("/admin/dashboard-planet"):
        setSelectedMenu({ first: "dashboard", second: "planet" });
        break;
      case path.includes("/admin/dashboard-mining-pools"):
        setSelectedMenu({ first: "dashboard", second: "mining-pools" });
        break;
      case path.includes("/admin/dashboard-mining"):
        setSelectedMenu({ first: "dashboard", second: "mining" });
        break;
      case path.includes("/admin/dashboard-landowner"):
        setSelectedMenu({ first: "dashboard", second: "landowner" });
        break;
      case path.includes("/admin/dashboard-nfts"):
        setSelectedMenu({ first: "dashboard", second: "nfts" });
        break;
      case path.includes("/admin/dashboard-guard"):
        setSelectedMenu({ first: "dashboard", second: "guard" });
        break;
      case path.includes("/admin/dashboard-system"):
        setSelectedMenu({ first: "dashboard", second: "system" });
        break;
      case path.includes("/admin/dashboard-missions"):
        setSelectedMenu({ first: "dashboard", second: "missions" });
        break;
      case path.includes("/admin/dashboard-users"):
        setSelectedMenu({ first: "dashboard", second: "users" });
        break;
      case path.includes("/admin/dashboard-teleport"):
        setSelectedMenu({ first: "dashboard", second: "teleport" });
        break;
      case path.includes("/admin/dashboard-zendesk"):
        setSelectedMenu({ first: "dashboard", second: "zendesk" });
        break;
      case path.includes("/admin/dashboard-energy"):
        setSelectedMenu({ first: "dashboard", second: "energy" });
        break;
      case path.includes("/admin/dashboard-sinks"):
        setSelectedMenu({ first: "dashboard", second: "sinks" });
        break;
      case path.includes("/admin/dashboard-dao"):
        setSelectedMenu({ first: "dashboard", second: "dao" });
        break;
      case path.includes("/admin/dashboard-packs"):
        setSelectedMenu({ first: "dashboard", second: "packs" });
        break;
      case path.includes("/admin/dashboard-user-points"):
        setSelectedMenu({ first: "dashboard", second: "user-points" });
        break;
      case path.includes("/admin/dashboard-bot-report"):
        setSelectedMenu({ first: "dashboard", second: "bot-report" });
        break;
      case path.includes("/admin/dashboard-kpis"):
        setSelectedMenu({ first: "dashboard", second: "kpis" });
        break;
      case path.includes("/admin/dashboard"):
        setSelectedMenu({ first: "dashboard", second: "my-dashboard" });
        break;
      case path.includes("/admin/forensics-chart/nfts"):
        setSelectedMenu({ first: "forensics", second: "forensics-nfts" });
        break;
      case path.includes("/admin/forensics"):
        setSelectedMenu({ first: "forensics", second: "forensics" });
        break;
      case path.includes("/admin/mining-explorer"):
        setSelectedMenu({ first: "mining-explorer", second: null });
        break;
      case path.includes("/admin/account-scanner"):
        setSelectedMenu({ first: "account-scanner", second: null });
        break;
      case path.includes("/admin/realtime"):
        setSelectedMenu({ first: "realtime", second: null });
        break;
      case path.includes("/admin/token/holders/alien.worlds/TLM"):
        setSelectedMenu({ first: "tokenHolders", second: "TLM" });
        break;
      case path.includes("/admin/token/holders/token.worlds/EYE"):
        setSelectedMenu({ first: "tokenHolders", second: "EYE" });
        break;
      case path.includes("/admin/token/holders/token.worlds/KAV"):
        setSelectedMenu({ first: "tokenHolders", second: "KAV" });
        break;
      case path.includes("/admin/token/holders/token.worlds/MAG"):
        setSelectedMenu({ first: "tokenHolders", second: "MAG" });
        break;
      case path.includes("/admin/token/holders/token.worlds/NAR"):
        setSelectedMenu({ first: "tokenHolders", second: "NAR" });
        break;
      case path.includes("/admin/token/holders/token.worlds/NER"):
        setSelectedMenu({ first: "tokenHolders", second: "NER" });
        break;
      case path.includes("/admin/token/holders/token.worlds/VEL"):
        setSelectedMenu({ first: "tokenHolders", second: "VEL" });
        break;
      case path.includes("/admin/oracle-nfts"):
        setSelectedMenu({ first: "oracle-nfts", second: null });
        break;
      case path.includes("/admin/tool-calculator"):
        setSelectedMenu({ first: "tool-calculator", second: null });
        break;
      case path.includes("/admin/reports/reports"):
        setSelectedMenu({ first: "reports", second: "reports" });
        break;
      case path.includes("/admin/reports/check-bots"):
        setSelectedMenu({ first: "reports", second: "check-bots" });
        break;
      case path.includes("/admin/reports/compound-reports"):
        setSelectedMenu({ first: "reports", second: "compound-reports" });
        break;
      case path.includes("/admin/self-test"):
        setSelectedMenu({ first: "self-test", second: null });
        break;
      case path.includes("/admin/tag-lookup"):
        setSelectedMenu({ first: "intelligence", second: "tag-lookup" });
        break;
      case path.includes("/admin/tag-bulk"):
        setSelectedMenu({ first: "intelligence", second: "tag-bulk" });
        break;
      case path.includes("/admin/transfers-lookup"):
        setSelectedMenu({ first: "intelligence", second: "transfers-lookup" });
        break;
      case path.includes("/admin/stakes-lookup"):
        setSelectedMenu({ first: "intelligence", second: "stakes-lookup" });
        break;
      case path.includes("/admin/visitors-lookup"):
        setSelectedMenu({ first: "intelligence", second: "visitors-lookup" });
        break;
      case path.includes("/admin/tower/report"):
        setSelectedMenu({ first: "tower", second: "report" });
        break;
      case path.includes("/admin/tower/manage"):
        setSelectedMenu({ first: "tower", second: "manage" });
        break;
      case path.includes("/admin/tower/debug"):
        setSelectedMenu({ first: "tower", second: "debug" });
        break;
      case path.includes("/admin/tower/appeals"):
        setSelectedMenu({ first: "tower", second: "appeals" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="left-side-menu">
      <div className="logo-box">
        <Link to="/admin/dashboard" className="logo logo-dark text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm-dark.png" alt="" height="24" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-dark.png" alt="" height="20" />
          </span>
        </Link>

        <Link to="/admin/dashboard" className="logo logo-light text-center">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="24" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.png" alt="" height="20" />
          </span>
        </Link>
      </div>
      <div className="h-100" data-simplebar>
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navigation</li>
            {sessionUser.role !== "reporter" ? (
              <>
                <li
                  className={
                    selectedMenu.first === "dashboard"
                      ? "menuitem-active"
                      : null
                  }
                >
                  <a
                    href="#sidebarDashboards"
                    aria-expanded="false"
                    data-bs-toggle="collapse"
                    aria-controls="sidebarDashboards"
                    className="waves-effect"
                  >
                    <i className="ri-dashboard-line"></i>
                    <span>Dashboards </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className={`collapse ${
                      selectedMenu.first === "dashboard" ? "show" : null
                    }`}
                    id="sidebarDashboards"
                  >
                    <ul className="nav-second-level">
                      {/* <li>
                    <Link to="/admin/dashboard-overview">Overview</Link>
                  </li> */}
                      {dashboardComponentsCount > 0 ? (
                        <li
                          className={
                            selectedMenu.second === "my-dashboard"
                              ? "menuitem-active"
                              : null
                          }
                        >
                          <Link to="/admin/dashboard">
                            My Dashboard{" "}
                            <span className="badge bg-success rounded-pill float-end">
                              {dashboardComponentsCount}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      <li
                        className={
                          selectedMenu.second === "dao"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-dao">DAO</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "energy"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-energy">Energy</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "guard"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-guard">Guard</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "kpis"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-kpis">KPIs & Party</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "landowner"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-landowner">Landowner</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "landratings"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-landratings">
                          Landratings
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "mining"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-mining">Mining</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "mining-pools"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-mining-pools">
                          Mining Pools
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "missions"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-missions">Missions</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "nfts"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-nfts">NFTs</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "packs"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-packs">Packs</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "planet"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-planet">Planet</Link>
                      </li>

                      <li
                        className={
                          selectedMenu.second === "sinks"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-sinks">Sinks</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "system"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-system">
                          System Accounts
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "teleport"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-teleport">Teleport</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "tlm"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-tlm">TLM</Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "bot-report"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-bot-report">
                          User Analytics
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "user-points"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-user-points">
                          User points
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "users"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-users">Users</Link>
                      </li>

                      {/* <li
                        className={
                          selectedMenu.second === "zendesk"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/dashboard-zendesk">Zendesk</Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
                {sessionUser.level == "level2" ||
                sessionUser.role == "super_admin" ? (
                  <li
                    className={
                      selectedMenu.first === "forensics"
                        ? "menuitem-active"
                        : null
                    }
                  >
                    <a
                      href="#sidebarForensics"
                      aria-expanded="false"
                      data-bs-toggle="collapse"
                      aria-controls="sidebarForensics"
                      className="waves-effect"
                    >
                      <i className="ri-search-eye-line"></i>
                      <span>Forensics </span>
                      <span className="menu-arrow"></span>
                    </a>
                    <div
                      className={`collapse ${
                        selectedMenu.first === "forensics" ? "show" : null
                      }`}
                      id="sidebarForensics"
                    >
                      <ul className="nav-second-level">
                        <li
                          className={
                            selectedMenu.second === "forensics"
                              ? "menuitem-active"
                              : null
                          }
                        >
                          <Link to="/admin/forensics">Forensics</Link>
                        </li>
                        <li
                          className={
                            selectedMenu.second === "forensics-nfts"
                              ? "menuitem-active"
                              : null
                          }
                        >
                          <Link to="/admin/forensics-chart/nfts">
                            NFTs Forensics
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : null}
                <li
                  className={
                    selectedMenu.first === "mining-explorer"
                      ? "menuitem-active"
                      : null
                  }
                >
                  <Link to="/admin/mining-explorer">
                    <i className="ri-goblet-line"></i>
                    <span>Mining Explorer</span>
                  </Link>
                </li>
                {sessionUser.level == "level1" ||
                sessionUser.level == "level2" ||
                sessionUser.role == "super_admin" ? (
                  <li
                    className={
                      selectedMenu.first === "account-scanner"
                        ? "menuitem-active"
                        : null
                    }
                  >
                    <Link to="/admin/account-scanner">
                      <i className="ri-account-box-line"></i>
                      <span>Account Scanner</span>
                    </Link>
                  </li>
                ) : null}
                <li
                  className={
                    selectedMenu.first === "realtime" ? "menuitem-active" : null
                  }
                >
                  <Link to="/admin/realtime">
                    <i className="ri-time-line"></i>
                    <span>Realtime</span>
                  </Link>
                </li>

                <li
                  className={
                    selectedMenu.first === "tokenHolders"
                      ? "menuitem-active"
                      : null
                  }
                >
                  <a
                    href="#sidebarTokenHolders"
                    aria-expanded="false"
                    data-bs-toggle="collapse"
                    aria-controls="sidebarTokenHolders"
                    className="waves-effect"
                  >
                    <i className="ri-bank-line"></i>
                    <span>Token Holders </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className={`collapse ${
                      selectedMenu.first === "tokenHolders" ? "show" : null
                    }`}
                    id="sidebarTokenHolders"
                  >
                    <ul className="nav-second-level">
                      <li
                        className={
                          selectedMenu.second === "EYE"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/EYE">
                          EYE
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "KAV"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/KAV">
                          KAV
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "MAG"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/MAG">
                          MAG
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "NAR"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/NAR">
                          NAR
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "NER"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/NER">
                          NER
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "TLM"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/alien.worlds/TLM">
                          TLM
                        </Link>
                      </li>
                      <li
                        className={
                          selectedMenu.second === "VEL"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/token/holders/token.worlds/VEL">
                          VEL
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className={
                    selectedMenu.first === "tool-calculator"
                      ? "menuitem-active"
                      : null
                  }
                >
                  <Link to="/admin/tool-calculator">
                    <i className="ri-calculator-line"></i>
                    <span>Tool Calculator</span>
                  </Link>
                </li>
              </>
            ) : null}
            {/* <li
              className={
                selectedMenu.first === "export" ? "menuitem-active" : null
              }
            >
              <Link to="/admin/reports">
                <i className="ri-account-box-line"></i>
                <span>Reports</span>
              </Link>
            </li> */}
            <li
              className={
                selectedMenu.first === "reports" ? "menuitem-active" : null
              }
            >
              <a
                href="#sidebarReport"
                aria-expanded="false"
                data-bs-toggle="collapse"
                aria-controls="sidebarReport"
                className="waves-effect"
              >
                <i className="ri-file-excel-2-line"></i>
                <span>Reports </span>
                <span className="menu-arrow"></span>
              </a>
              <div
                className={`collapse ${
                  selectedMenu.first === "reports" ? "show" : null
                }`}
                id="sidebarReport"
              >
                <ul className="nav-second-level">
                  <li
                    className={
                      selectedMenu.second === "reports"
                        ? "menuitem-active"
                        : null
                    }
                  >
                    <Link to="/admin/reports/reports">Reports</Link>
                  </li>
                  {sessionUser.level !== "level0" ||
                  sessionUser.role == "super_admin" ? (
                    <li
                      className={
                        selectedMenu.second === "check-bots"
                          ? "menuitem-active"
                          : null
                      }
                    >
                      <Link to="/admin/reports/check-bots">Check Bots</Link>
                    </li>
                  ) : null}
                  {/* <li
                    className={
                      selectedMenu.second === "compound-reports"
                        ? "menuitem-active"
                        : null
                    }
                  >
                    <Link to="/admin/reports/compound-reports">
                      Compound Reports
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
            {sessionUser.role !== "reporter" ? (
              <li
                className={
                  selectedMenu.first === "export" ? "menuitem-active" : null
                }
              >
                <Link to="/admin/self-test">
                  <i className="ri-list-check-2"></i>
                  <span>Self Test</span>
                </Link>
              </li>
            ) : null}

            {["level0", "level1", "level2"].includes(sessionUser.level) ||
            ["super_admin"].includes(sessionUser.role) ? (
              <li
                className={
                  selectedMenu.first === "intelligence"
                    ? "menuitem-active"
                    : null
                }
              >
                <a
                  href="#sidebarIntelligence"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  aria-controls="sidebarIntelligence"
                  className="waves-effect"
                >
                  <i className="ri-robot-line"></i>
                  <span>Intelligence </span>
                  <span className="menu-arrow"></span>
                </a>
                <div
                  className={`collapse ${
                    selectedMenu.first === "intelligence" ? "show" : null
                  }`}
                  id="sidebarIntelligence"
                >
                  <ul className="nav-second-level">
                    {["level0", "level1", "level2"].includes(
                      sessionUser.level
                    ) || ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "tag-bulk"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tag-bulk">Bulk Input</Link>
                      </li>
                    ) : null}
                    {["level0", "level1", "level2"].includes(
                      sessionUser.level
                    ) || ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "tag-lookup"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tag-lookup">Tag Lookup</Link>
                      </li>
                    ) : null}
                    {["level0", "level1", "level2"].includes(
                      sessionUser.level
                    ) || ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "transfers-lookup"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/transfers-lookup">
                          Transfers Lookup
                        </Link>
                      </li>
                    ) : null}
                    {["level0", "level1", "level2"].includes(
                      sessionUser.level
                    ) || ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "stakes-lookup"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/stakes-lookup">Stakes Lookup</Link>
                      </li>
                    ) : null}
                    {["level0", "level1", "level2"].includes(
                      sessionUser.level
                    ) || ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "visitors-lookup"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/visitors-lookup">Visitors Lookup</Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </li>
            ) : null}

            {["level2"].includes(sessionUser.level) ||
            ["super_admin"].includes(sessionUser.role) ? (
              <li
                className={
                  selectedMenu.first === "tower" ? "menuitem-active" : null
                }
              >
                <a
                  href="#sidebarTower"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  aria-controls="sidebarTower"
                  className="waves-effect"
                >
                  <i className="ri-robot-line"></i>
                  <span>Tower </span>
                  <span className="menu-arrow"></span>
                </a>
                <div
                  className={`collapse ${
                    selectedMenu.first === "tower" ? "show" : null
                  }`}
                  id="sidebarTower"
                >
                  <ul className="nav-second-level">
                    {["level2"].includes(sessionUser.level) ||
                    ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "report"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tower/report">Report</Link>
                      </li>
                    ) : null}
                    {["level2"].includes(sessionUser.level) ||
                    ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "manage"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tower/manage">Manage</Link>
                      </li>
                    ) : null}
                    {[].includes(sessionUser.level) ||
                    ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "debug"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tower/debug">Debug</Link>
                      </li>
                    ) : null}
                    {["level2"].includes(sessionUser.level) ||
                    ["super_admin"].includes(sessionUser.role) ? (
                      <li
                        className={
                          selectedMenu.second === "appeals"
                            ? "menuitem-active"
                            : null
                        }
                      >
                        <Link to="/admin/tower/appeals">Appeals</Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AdminLeftSidebar;
