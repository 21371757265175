import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Highcharts from "highcharts";
import { useEffect } from "react";
import PlanetsPoolRates from "admin/components/mining-pools/planets-pool-rates";
import PlanetsSetpoolratesActions from "admin/components/mining-pools/planets-setpoolrates-actions";
import PlanetsPerPoolsChart from "admin/components/mining-pools/planets_per_pools_chart";

function AdminDashboardMiningPools() {
  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$(".button-menu-mobile").click(function () {
      setTimeout(() => {
        window.$(Highcharts.charts).each(function (i, chart) {
          chart.reflow();
        });
      }, 1000);
    });
  });
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-tlm">Mining</BreadcrumbsItem>

      <div className="row">
        <div class="col-12">
          <PlanetsPoolRates />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <PlanetsSetpoolratesActions />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <PlanetsPerPoolsChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardMiningPools;
