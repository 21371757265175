import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import TowerSettings from "./components/settings";
import AdminTowerSignalsEntities from "./components/signals-entities";
import { useUser } from "app/hooks/useSession";
import AdminTowerSignalsEntitiesMultiple from "./components/signals-entities-multiple";
import TowerBotControlSettings from "./components/bot-control-settings";

export const AdminTowerManage = () => {
  const sessionUser = useUser();
  return (
    <>
      <BreadcrumbsItem to="/admin/tower">Tower</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/tower/manage">Manage</BreadcrumbsItem>

      {["super_admin"].includes(sessionUser.role) ? (
        <div class="row">
          <div class="col-lg-10">
            <TowerSettings />
          </div>
        </div>
      ) : null}

      {["level2"].includes(sessionUser.level) ||
      ["super_admin"].includes(sessionUser.role) ? (
        <TowerBotControlSettings />
      ) : null}

      <AdminTowerSignalsEntities />
      <AdminTowerSignalsEntitiesMultiple />
    </>
  );
};

export default AdminTowerManage;
