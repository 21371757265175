import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import NftsService from "../../../services/nfts";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { useRef } from "react";
import { useEffect } from "react";

export const NftHistoryLogs = () => {
  let { assetId } = useParams();

  const tableRef = useRef();
  const dataTableRef = useRef();

  const logs = useQuery([`nft_${assetId}_history_logs`, assetId], () => {
    return NftsService.getNftLogs(assetId);
  });

  console.log(logs);

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!logs.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 50, 500],
          order: [[0, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [logs.data]);

  return (
    <div class="responsive-table-plugin">
      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">
          <table className="table mb-0" ref={tableRef} id="nft_history_logs">
            <thead>
              <tr>
                <th>Event</th>
                <th>Data</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {logs.data && logs.data.map((log, index) => {
                return (
                  <tr key={index}>
                    <td>{log.name}</td>
                    <td><pre>{JSON.stringify(log.data, null, 2) }</pre></td>
                    <td>{moment.tz(parseInt(log.created_at_time),"UTC").format("YYYY-MM-DD HH:mm:ss")}</td>
                  </tr>
                );
              })}

              {logs.isFetching ? (
                <>
                  {new Array(5).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NftHistoryLogs;
