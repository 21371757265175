import { useState } from "react";
import * as waxjs from "@waxio/waxjs/dist";
import PublicApiService from "app/services/public_api";
import moment from "moment";
import { Helmet } from "react-helmet";

function TowerAppeals() {
  const [formData, setFormData] = useState({
    wallet: "",
    reason: "",
    message: "",
    signature: "",
  });
  const [formResponse, setFormResponse] = useState(null);

  const towerAppeal = async (e) => {
    e.preventDefault();
    try {
      setFormResponse(null);
      const response = await PublicApiService.post(
        `tower/appeal?timestamp=${moment.utc().valueOf()}`,
        formData
      );
      const formResponse = await response.json();
      setFormResponse(formResponse);
    } catch (err) {}
  };

  const connectWallet = async () => {
    const wax = new waxjs.WaxJS({
      rpcEndpoint: "https://wax.greymass.com",
    });

    const userAccount = await wax.login();

    const nonce = generateNonce();
    const waxProofRes = await wax.waxProof(nonce, false);

    setFormData((prevState) => ({
      ...prevState,
      wallet: userAccount,
      message: waxProofRes.message,
      signature: waxProofRes.signature,
    }));
  };

  const generateNonce = () => {
    var length = 20;
    var nonce = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return nonce;
  };

  return (
    <>
      <Helmet>
        <title>Alien Worlds - Ban Appeal Form - Yeomen</title>
        <meta
          name="description"
          content="This form is to be used to appeal a ban on Alien Worlds."
        />
      </Helmet>
      <div class="account-pages mt-5 mb-5">
        <div class="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <div className="auth-logo">
                      <a href className="logo logo-dark text-center">
                        <span className="logo-lg">
                          <img
                            src="../assets/images/logo-dark.png"
                            alt
                            height={22}
                          />
                        </span>
                      </a>
                      <a href className="logo logo-light text-center">
                        <span className="logo-lg">
                          <img
                            src="../assets/images/logo-light.png"
                            alt
                            height={22}
                          />
                        </span>
                      </a>
                    </div>
                    <p className="text-muted mb-4 mt-3">Ban Appeal Form</p>
                  </div>
                  {formResponse && formResponse.status === "ok" ? (
                    <div className="text-center">
                      <div className="mt-4">
                        <div className="logout-checkmark">
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 161.2 161.2"
                            enableBackground="new 0 0 161.2 161.2"
                            xmlSpace="preserve"
                          >
                            <path
                              className="logout-path"
                              fill="none"
                              stroke="#d1dee4"
                              strokeMiterlimit={10}
                              d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                         c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                         c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                            />
                            <circle
                              className="logout-path"
                              fill="none"
                              stroke="#1abc9c"
                              strokeWidth={4}
                              strokeMiterlimit={10}
                              cx="80.6"
                              cy="80.6"
                              r="62.1"
                            />
                            <polyline
                              className="logout-path"
                              fill="none"
                              stroke="#1abc9c"
                              strokeWidth={6}
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              points="113,52.8
                                         74.1,108.4 48.2,86.4 "
                            />
                            <circle
                              className="logout-spin"
                              fill="none"
                              stroke="#d1dee4"
                              strokeWidth={4}
                              strokeMiterlimit={10}
                              strokeDasharray="12.2175,12.2175"
                              cx="80.6"
                              cy="80.6"
                              r="73.9"
                            />
                          </svg>
                        </div>
                      </div>
                      <h3>Success !</h3>
                      <p className="text-muted">{formResponse.message}</p>
                    </div>
                  ) : null}

                  {formResponse && formResponse.status === "info" ? (
                    <div className="text-center">
                      <div className="mt-4">
                        <div className="logout-checkmark">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width={100}
                            height={100}
                            viewBox="0,0,256,256"
                          >
                            <g
                              fill="#ea8147"
                              fillRule="nonzero"
                              stroke="none"
                              strokeWidth={1}
                              strokeLinecap="butt"
                              strokeLinejoin="miter"
                              strokeMiterlimit={10}
                              strokeDasharray
                              strokeDashoffset={0}
                              fontFamily="none"
                              fontWeight="none"
                              fontSize="none"
                              textAnchor="none"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <g transform="scale(5.12,5.12)">
                                <path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h3>Information !</h3>
                      <p className="text-muted">{formResponse.message}</p>
                    </div>
                  ) : null}

                  {!formResponse ||
                  (formResponse && formResponse.status === "error") ? (
                    <>
                      {formResponse && formResponse.status === "error" ? (
                        <div className="alert alert-danger" role="alert">
                          {formResponse.message}
                        </div>
                      ) : null}

                      <form action="#">
                        {formData.wallet ? (
                          <>
                            <div className="mb-2">
                              <label htmlFor="wallet" className="form-label">
                                Wallet ID
                              </label>
                              <input
                                type="text"
                                readonly
                                className="form-control-plaintext"
                                value={formData.wallet}
                              ></input>
                            </div>
                            <div className="mb-2">
                              <label htmlFor="reason" className="form-label">
                                Reason for Appeal
                              </label>
                              <textarea
                                class="form-control"
                                rows="5"
                                placeholder="Please explain in detail why you believe your account was wrongly flagged as a bot. We would appreciate it if you can respond in simple English for clarity. Thank you."
                                required
                                value={formData.reason}
                                onChange={(e) =>
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    reason: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            <div className="d-grid text-center">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                onClick={towerAppeal}
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className=" text-center">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={connectWallet}
                            >
                              {/* <span class="btn-label"><i class="mdi mdi-check-all"></i></span> */}
                              Login with MyCloudWallet
                            </button>
                          </div>
                        )}

                        {/* <div className="mb-2">
                    <label htmlFor="wallet" className="form-label">
                      Wallet
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Connect your wallet"
                      required
                      disabled
                      value={formData.wallet}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          wallet: e.target.value,
                        }))
                      }
                    />
                  </div> */}
                      </form>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TowerAppeals;
