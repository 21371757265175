import { useEffect, useState } from "react";
import SocketService from "../../../app/services/socket";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import ApiService from "app/services/api";

export const AdminSelfTestTimeLatestUserPoints = () => {
  const stats = useQuery(
    [`self-test_index-catchup_stats_timeLatestUserPoints`],
    async () => {
      const response = await ApiService.get(
        "self-test/index-catchup/stats/timeLatestUserPoints",
        {}
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    }
  );
/*   const [value, setValue] = useState(null);
  useEffect(() => {
    var socket = SocketService.getSocket("/selfTest");

    if (socket.connected) {
      socket.emit("join", "timeLatestUserPoints");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "timeLatestUserPoints");
      });
    }

    socket.on("timeLatestUserPoints", function (data) {
      setValue(data);
    });

    return () => {
      socket.off("timeLatestUserPoints");
      socket.emit("leave", "timeLatestUserPoints");
    };
  }, []); */
  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {!stats.data ? (
            <Skeleton />
          ) : (
            <span>
              {moment.tz(stats.data.timeLatestUserPoints, "UTC").fromNow()}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">Latest User Points</p>
      </div>
    </div>
  );
};

export default AdminSelfTestTimeLatestUserPoints;
