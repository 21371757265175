//import { useQuery } from "react-query";
//import { useFilters } from "../../../../app/hooks/useSession";
//import NftsService from "../../../services/nfts";
//import moment from "moment-timezone";
//import KnobChart from "./knob-chart";

import { AdminNftsStatsMinted } from "./nfts-stats-minted";
import { AdminNftsStatsBurned } from "./nfts-stats-burned";
import { AdminNftsStatsShined } from "./nfts-stats-shined";
import { AdminNftsStatsTransferred } from "./nfts-stats-transferred";
import { useHelpTexts } from "app/hooks/useSession";
import CardMenu from "../card-menu";

export const AdminNftsStats = (props) => {
  const componentProps = {
    component: "components/nfts/nfts-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();

  //  const filters = useFilters();

  // const stats = useQuery(["nfts_stats", filters], () => {
  //   return NftsService.getStats('all',{
  //     startAtTime: moment.tz(filters.dates.startDate,'UTC').startOf('day').valueOf(),
  //     endAtTime: moment.tz(filters.dates.endDate,'UTC').endOf('day').valueOf(),
  //   });
  // });

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `NFTs stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "NFTs stats tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i className="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <AdminNftsStatsMinted />
            {/* <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              {stats.status === "loading" ? (
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              ) : (
                <KnobChart
                  data={stats.data.minted}
                  title={"Total Minted"}
                  options={{ color: "#1abc9c", background: "#d1f2eb" }}
                />
              )}
            </div>
          </div>
        </div> */}
          </div>
          <div className="col-xl-3 col-md-6">
            <AdminNftsStatsBurned />
            {/* <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              {stats.status === "loading" ? (
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              ) : (
                <KnobChart
                  data={stats.data.burned}
                  title={"Total Burned"}
                  options={{ color: "#3bafda", background: "#d8eff8" }}
                />
              )}
            </div>
          </div>
        </div> */}
          </div>
          <div className="col-xl-3 col-md-6">
            <AdminNftsStatsShined />
            {/* <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              {stats.status === "loading" ? (
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              ) : (
                <KnobChart
                  data={stats.data.shined}
                  title={"Total Shined"}
                  options={{ color: "#f672a7", background: "#fde3ed" }}
                />
              )}
            </div>
          </div>
        </div> */}
          </div>
          <div className="col-xl-3 col-md-6">
            <AdminNftsStatsTransferred />
            {/* <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              {stats.status === "loading" ? (
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              ) : (
                <KnobChart
                  data={stats.data.transferred}
                  title={"Total Transferred"}
                  options={{ color: "#6c757d", background: "#e2e3e5" }}
                />
              )}
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNftsStats;
