import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import config from "app/config";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const PlanetsPerPoolsChart = (props) => {
  const componentProps = {
    component: "components/mining-pools/planets_per_pools_chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);
  // console.log(
  //   config.planet_pools
  //     .sort((a, b) => a.order - b.order)
  //     .reduce(
  //       (pool, planet_pool) => ((pool[planet_pool.name] = true), pool),
  //       {}
  //     )
  // );

  const [data, setData] = useState([]);
  const [pool, setPool] = useState({
    Abundant: true,
    Common: true,
    Epic: true,
    Legendary: true,
    Rare: true,
    Mythical: true,
  });
  const [planet, setPlanet] = useState({
    Eyeke: true,
    Kavian: true,
    Magor: true,
    Naron: true,
    Neri: true,
    Veles: true,
  });
 
  const [showLinear, setShowLinear] = useState(true);
  const [showAllSeries, setShowAllSeries] = useState(true);

  const miners = useQuery(
    ["planets_per_pools_chart", filters, pool, planet],
    async () => {
      const response = await ApiService.get("planets/planets-per-pools-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        pool: JSON.stringify(pool),
        planet: JSON.stringify(planet),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  const setPoolFilters = (type) => {
    pool[type] = pool[type] ? false : true;
    setPool({ ...pool });
  };
  const setPlanetFilters = (type) => {
    planet[type] = planet[type] ? false : true;
    setPlanet({ ...planet });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!miners.data) return;

    setData(miners.data);

    chartComponent.current.chart.hideLoading();
  }, [miners.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="series-check"
                defaultChecked={showAllSeries}
                onChange={(el) => setShowAllSeries(!showAllSeries)}
              />
              <label className="form-check-label" htmlFor="series-check">
                Show all/none
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Planets per pools chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the planets per pools on each day in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Pool:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Abundant"
              onChange={() => setPoolFilters("Abundant")}
              defaultChecked={pool.Abundant ? true : false}
            />
            <label class="form-check-label" for="Abundant">
              Abundant
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Common"
              onChange={() => setPoolFilters("Common")}
              defaultChecked={pool.Common ? true : false}
            />
            <label class="form-check-label" for="Common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Rare"
              onChange={() => setPoolFilters("Rare")}
              defaultChecked={pool.Rare ? true : false}
            />
            <label class="form-check-label" for="Rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Epic"
              onChange={() => setPoolFilters("Epic")}
              defaultChecked={pool.Epic ? true : false}
            />
            <label class="form-check-label" for="Epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Legendary"
              onChange={() => setPoolFilters("Legendary")}
              defaultChecked={pool.Legendary ? true : false}
            />
            <label class="form-check-label" for="Legendary">
              Legendary
            </label>
          </div>          
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Mythical"
              onChange={() => setPoolFilters("Mythical")}
              defaultChecked={pool.Mythical ? true : false}
            />
            <label class="form-check-label" for="Mythical">
              Mythical
            </label>
          </div>
        </div>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Planet:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Eyeke"
              onChange={() => setPlanetFilters("Eyeke")}
              defaultChecked={planet.Eyeke ? true : false}
            />
            <label class="form-check-label" for="Eyeke">
              Eyeke
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Kavian"
              onChange={() => setPlanetFilters("Kavian")}
              defaultChecked={planet.Kavian ? true : false}
            />
            <label class="form-check-label" for="Kavian">
              Kavian
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Magor"
              onChange={() => setPlanetFilters("Magor")}
              defaultChecked={planet.Magor ? true : false}
            />
            <label class="form-check-label" for="Magor">
              Magor
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Naron"
              onChange={() => setPlanetFilters("Naron")}
              defaultChecked={planet.Naron ? true : false}
            />
            <label class="form-check-label" for="Naron">
              Naron
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Neri"
              onChange={() => setPlanetFilters("Neri")}
              defaultChecked={planet.Neri ? true : false}
            />
            <label class="form-check-label" for="Neri">
              Neri
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Veles"
              onChange={() => setPlanetFilters("Veles")}
              defaultChecked={planet.Veles ? true : false}
            />
            <label class="form-check-label" for="Veles">
              Veles
            </label>
          </div>
        </div>
        {miners.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "top",
                    y: 25,
                    labelFormatter: function () {
                      return this.userOptions.name;
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    allowDecimals: false,
                    // min: 0,
                    title: {
                      text: "Value",
                    },
                  },
                  tooltip: {
                    outside: true,
                    xDateFormat: "%Y-%m-%d",
                    //shared: true
                  },
                  // plotOptions: {
                  //   series: {
                  //     visible: showAllSeries,
                  //   },
                  // },
                  series: data.map((row)=>{return {...row,visible: showAllSeries};}),
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanetsPerPoolsChart;
