import { useEffect } from "react";
import PacksStats from "../components/packs/packs-stats";
import PacksInvoices from "../components/packs/packs-invoices";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function AdminDashboardDao() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-packs">Packs</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <PacksStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <PacksInvoices />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardDao;
