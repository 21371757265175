import { useFilters } from "app/hooks/useSession";
import ApiService from "app/services/api";
import SocketService from "app/services/socket";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

export const AdminTowerAppealsOverallStats = (props) => {
  //const filters = useFilters();
  const stats = useQuery([`tower-appeals-overall-stats`], async () => {
    const response = await ApiService.get("tower/appeals-stats", {      
      timestamp: moment.utc().valueOf(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  });

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Tower Appeals All-time</h4>
          <div className="row">
            {stats.data &&
              stats.data.map((data, index) => {
                return (
                  <div className="col-xl-3 col-md-3" key={index}>
                    <div className="widget-simple text-center card">
                      <div className="card-body">
                        <h3 className="text-success mt-0">
                          <span>{data.value || 0}</span>
                        </h3>
                        <p className="text-muted mb-0">Total {data.key}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminTowerAppealsOverallStats;
