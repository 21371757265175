import CardMenu from "admin/components/card-menu";

import { useHelpTexts } from "app/hooks/useSession";
import TotalActiveLands from "./total-active-lands";
import TotalLandowners from "./total-landowners";
import TotalSalesStats from "./total-sales-stats";

export const LandownerStats = (props) => {
  const componentProps = {
    component: "components/landowner/landowner-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Landowner stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Landowner stats tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i className="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          {/* <div class="col-xl-4 col-md-6">
          <TotalLands />
        </div> */}
          <div class="col-xl-4 col-md-6">
            <TotalLandowners />
          </div>
          <div class="col-xl-4 col-md-6">
            <TotalActiveLands />
          </div>
          <TotalSalesStats />
        </div>
      </div>
    </div>
  );
};

export default LandownerStats;
