import { Link } from "react-router-dom";
import moment from "moment-timezone";
import config from "app/config";

export const AdminNftsGridCard = ({ asset, sort, badge = null }) => {
  return (
    <div className="card product-box ribbon-box">
      <div className="product-img" style={{ overflow: "inherit" }}>
        {badge ? (
          <div
            class="ribbon ribbon-primary float-end"
            style={{ marginRight: "-5px", marginBottom: "0px" }}
          >
            {badge}
          </div>
        ) : null}

        <div className="p-3">
          <Link to={`/admin/nfts/${asset.asset_id}`}>
            <img
              src={`${config.ipfs.url}/ipfs/${
                asset.data && asset.data.img
                  ? asset.data.img
                  : "QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP"
              }`}
              alt="nfts-pic"
              className="img-fluid  mx-auto d-block"
              style={{ height: "300px" }}
            />
          </Link>
        </div>
      </div>

      <div className="product-info border-top p-3">
        <div>
          <h5 className="font-16 mt-0 mb-0">
            <Link to={`/admin/nfts/${asset.asset_id}`} className="text-dark">
              {asset.name ? asset.name : "N/A"}
            </Link>
          </h5>
          <p className="card-text m-0">
            {sort === "updated_at_time" ? (
              <small
                className="text-muted"
                data-timestamp={asset.updated_at_time}
                title={moment(parseInt(asset.updated_at_time)).fromNow()}
              >
                Updated:&nbsp;
                {moment
                  .tz(parseInt(asset.updated_at_time), "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}
              </small>
            ) : null}
            {sort === "minted_at_time" ? (
              <small
                className="text-muted"
                data-timestamp={asset.minted_at_time}
                title={moment(parseInt(asset.minted_at_time)).fromNow()}
              >
                Minted:&nbsp;
                {asset.minted_at_time
                  ? moment
                      .tz(parseInt(asset.minted_at_time), "UTC")
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "N/A"}
              </small>
            ) : null}
            {sort === "transferred_at_time" ? (
              <small
                className="text-muted"
                data-timestamp={asset.transferred_at_time}
                title={moment(parseInt(asset.transferred_at_time)).fromNow()}
              >
                Transferred:&nbsp;
                {asset.transferred_at_time
                  ? moment
                      .tz(parseInt(asset.transferred_at_time), "UTC")
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "N/A"}
              </small>
            ) : null}
            {sort === "burned_at_time" ? (
              <small
                className="text-muted"
                data-timestamp={asset.burned_at_time}
                title={moment(parseInt(asset.burned_at_time)).fromNow()}
              >
                Burned:&nbsp;
                {asset.burned_at_time
                  ? moment
                      .tz(parseInt(asset.burned_at_time), "UTC")
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "N/A"}
              </small>
            ) : null}
          </p>
          <h5 className="m-0">
            <span className="text-muted">
              {asset.burned_by_account ? (
                <>
                  Burned by&nbsp;
                  <Link
                    to={`/admin/account-scanner/${asset.burned_by_account}`}
                  >
                    {asset.burned_by_account}
                  </Link>
                </>
              ) : (
                <Link to={`/admin/account-scanner/${asset.owner}`}>
                  {asset.owner}
                </Link>
              )}
            </span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default AdminNftsGridCard;
