import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const MissionsChart = (props) => {
  const componentProps = {
    component: "components/missions/missions-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [types, setTypes] = useState({
    common: true,
    rare: true,
    epic: true,
    legendary: true,
  });

  const missions = useQuery(["mission_chart", filters, types], async () => {
    const response = await ApiService.get("missions/mission-chart", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      filters: JSON.stringify(types),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  const setFilters = (type) => {
    types[type] = types[type] ? false : true;
    setTypes({ ...types });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!missions.data) return;

    setData(missions.data);

    chartComponent.current.chart.hideLoading();
  }, [missions.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div class="float-sm-end">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-chart-common"
              onChange={() => setFilters("common")}
              defaultChecked={types.common ? true : false}
            />
            <label class="form-check-label" for="mission-chart-common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-chart-rare"
              onChange={() => setFilters("rare")}
              defaultChecked={types.rare ? true : false}
            />
            <label class="form-check-label" for="mission-chart-rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-chart-epic"
              onChange={() => setFilters("epic")}
              defaultChecked={types.epic ? true : false}
            />
            <label class="form-check-label" for="mission-chart-epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-chart-legendary"
              onChange={() => setFilters("legendary")}
              defaultChecked={types.legendary ? true : false}
            />
            <label class="form-check-label" for="mission-chart-legendary">
              Legendary
            </label>
          </div>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows boarding, active and completed missions on each day in the selected time-frame.. We can filter missions by rarity."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {missions.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    // type: 'column',
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  plotOptions: {
                    column: {
                      stacking: "normal",
                    },
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionsChart;
