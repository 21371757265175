import FirstMine from "./first-mine";
import LastMine from "./last-mine";
import TotalMine from "./total-mine";
import TotalBounty from "./total-bounty";
import TotalCommission from "./total-commission";
import TotalNft from "./total-nft";
import NftValue from "./nft-value";
import TlmBalance from "./tlm-balance";
import TlmTransfer from "./tlm-transfer";

export const AccountStats = (props) => {

  return (
      <div className="row mt-2">
        {props.isMiner == true ? (
        <>
        <div class="col-xl-4 col-md-6">
          <FirstMine account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <LastMine account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <TotalMine account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <TotalBounty account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <TotalCommission account={props.account} />
        </div>
        </>
        ) : null }
        <div class="col-xl-4 col-md-6">
          <TotalNft account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <NftValue account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <TlmBalance account={props.account} />
        </div>
        <div class="col-xl-4 col-md-6">
          <TlmTransfer account={props.account} />
        </div>
      </div>
  );
};

export default AccountStats;
