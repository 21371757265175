import { useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const PlanetChart = (props) => {
  const componentProps = {
    component: "components/charts/planet-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };

  const filters = useFilters();
  const helpTexts = useHelpTexts();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState([]);

  const planet = useQuery(["planet", filters], async () => {
    const response = await ApiService.get("tlm/planet_chart", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let data = await response.json();
    setData(data);
  });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Planet Chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the mining allocation per planet. The total allocation adds up to 100% of the allocation to all the planets shown."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {planet.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {planet.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "area",
                        backgroundColor: "transparent",
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      xAxis: {
                        categories: data.categories,
                        tickmarkPlacement: "on",
                        title: {
                          enabled: false,
                        },
                      },
                      yAxis: {
                        labels: {
                          format: "{value}%",
                        },
                        title: {
                          enabled: false,
                        },
                      },
                      tooltip: {
                        pointFormat:
                          '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f})<br/>',
                        split: true,
                      },
                      plotOptions: {
                        area: {
                          stacking: "percent",
                          lineColor: "#ffffff",
                          lineWidth: 1,
                          marker: {
                            lineWidth: 1,
                            lineColor: "#ffffff",
                          },
                          accessibility: {
                            pointDescriptionFormatter: function (point) {
                              function round(x) {
                                return Math.round(x * 100) / 100;
                              }
                              return (
                                point.index +
                                1 +
                                ", " +
                                point.category +
                                ", " +
                                point.y +
                                " millions, " +
                                round(point.percentage) +
                                "%, " +
                                point.series.name
                              );
                            },
                          },
                        },
                      },
                      series: data.serieses,
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlanetChart;
