import LatestTags from "admin/components/tag-lookup/latest-tags";
import TagLookupResults from "admin/components/tag-lookup/tag-lookup-results";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import ApiService from "../../../app/services/api";

const TagLookup = () => {  
  const history = useHistory();
  const params = useParams();

  const [keyword, setKeyword] = useState(params.keyword || "");

  const searchKeyword = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/admin/tag-lookup/" + keyword,
    });
  };

  useEffect(()=>{
    if(!params.keyword) return;
   
    setKeyword(params.keyword);
  },[params.keyword])

  // const latestTagReasons = useQuery([`latest_tag_reasons`], async () => {
  //   const response = await ApiService.get("bots/tag-reasons/latest", {});
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  // const lookupResult = useQuery([`tag_lookup`, keyword], async () => {
  //   if (keyword === "") {
  //     return null;
  //   }
  //   const response = await ApiService.get("bots/lookup", { keyword: keyword });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  // useEffect(() => {
  //   if (lookupResult.data && lookupResult.data.account.length === 1) {
  //     setTimeout(() => {
  //       setKeyword(lookupResult.data.account[0].tag);
  //       history.push({
  //         pathname: "/admin/tag-lookup/" + lookupResult.data.account[0].tag,
  //       });
  //     }, 1000);
  //   }
  // }, [lookupResult.data, history]);

  return (
    <>
      <BreadcrumbsItem to="/admin/tag-lookup">Tag Lookup</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <div className="dropdown">
            <form
              className="d-flex align-items-center"
              onSubmit={searchKeyword}
            >
              <input
                type="text"
                name="keyword"
                className="form-control dropdown-toggle"
                id="autocomplateSearch"
                placeholder="Enter the account name or a tag to lookup"
                aria-label="Search"
                autoComplete={"off"}
                value={keyword}
                onChange={(event) => {
                  setKeyword(event.target.value);
                }}
              />
              <button type="submit" className="btn btn-success mb-2 mb-sm-0">
                Search
              </button>
            </form>
          </div>
        </div>
      </div>

      {keyword === "" ? (
        <>
        <LatestTags />
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="card  mt-2">
                <div className="card-body">
                  <h4 class="header-title">Latest Tags</h4>
                  <table className="table  nowrap w-100">
                    <thead>
                      <tr>
                        <th>Tag</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestTagReasons &&
                        latestTagReasons.data &&
                        latestTagReasons.data.map((row) => (
                          <tr>
                            <td>
                              <Link
                                to={`/admin/tag-lookup/${row.tag}`}
                                onClick={() => setKeyword(row.tag)}
                              >
                                {row.tag}
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : null}

      {keyword !== "" ? (
        <>
        <TagLookupResults keyword={keyword}/>
          {/* {lookupResult.status === "loading" ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <>
              {lookupResult.data && lookupResult.data.account.length ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card  mt-2">
                        <div className="card-body">
                          <h4 class="header-title">Account: {keyword}</h4>
                          <table className="table  nowrap w-100">
                            <thead>
                              <tr>
                                <th>Tag</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Updated</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lookupResult.data.account.map((row) => (
                                <tr>
                                  <td>
                                    <Link
                                      to={`/admin/tag-lookup/${row.tag}`}
                                      onClick={() => setKeyword(row.tag)}
                                    >
                                      {row.tag}
                                    </Link>
                                  </td>
                                  <td>{row.status}</td>
                                  <td>
                                    {moment
                                      .tz(row.created_at, "UTC")
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                  <td>
                                    {moment
                                      .tz(row.updated_at, "UTC")
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {lookupResult.data && lookupResult.data.tag.length ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card  mt-2">
                        <div className="card-body">
                          <h4 class="header-title">Tag: {keyword}</h4>
                          <table className="table  nowrap w-100">
                            <thead>
                              <tr>
                                <th>Account</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Updated</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lookupResult.data.tag.map((row) => (
                                <tr>
                                  <td>
                                    <Link
                                      to={`/admin/account-scanner/${row.wallet}`}
                                    >
                                      {row.wallet}
                                    </Link>
                                  </td>
                                  <td>{row.status}</td>
                                  <td>
                                    {moment
                                      .tz(row.created_at, "UTC")
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                  <td>
                                    {moment
                                      .tz(row.updated_at, "UTC")
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card  mt-2">
                        <div className="card-body">
                          <h4 class="header-title">Questions before unblock</h4>
                          <ul>
                            {lookupResult.data &&
                            lookupResult.data.tagReason &&
                            lookupResult.data.tagReason.flag_type ===
                              "rule-yeomenwarder-false-flag-type1" ? (
                              <>
                                <li>
                                  What interface do you use to play the game. It
                                  does not look like you are using the AW UI
                                </li>
                              </>
                            ) : null}
                            {lookupResult.data &&
                            lookupResult.data.tagReason &&
                            lookupResult.data.tagReason.flag_type ===
                              "intel-holistic-type-1" ? (
                              <>
                                <li>What browser or device are you on?</li>
                                <li>
                                  Do you use any VPNs or software to connect to
                                  Alien Worlds?
                                </li>
                                <li>
                                  Any extensions or tools on your browser which
                                  could make the system appear as a bot?
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {lookupResult.data &&
              !lookupResult.data.account.length &&
              !lookupResult.data.tag.length ? (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card  mt-2">
                        <div className="card-body">
                          <h4 class="header-title">Not Found</h4>
                          <p>
                            This tag or account name was not found on the list
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )} */}
        </>
      ) : null}
    </>
  );
};

export default TagLookup;
