import CardMenu from "admin/components/card-menu";
import ActiveMiners from "./active-miners";
import ActiveLandowners from "./active-landowners";
import ActiveLands from "./active-lands";
import { useHelpTexts } from "app/hooks/useSession";

export const ActiveStats = (props) => {
  const componentProps = {
    component: "components/mining/active-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Active miners, landowners and lands`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Total active miners - Number of accounts that have mined at least once in the selected time-frame. <br>
                            Total active landowners - Number of landowners with at least one mine on any of the land they own in the selected time-frame. <br>
                            Total active lands - Number of lands with at least one mine in the selected time-frame. Total 3343 lands.                         
                            `
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <ActiveMiners />
          </div>
          <div class="col-xl-4 col-md-6">
            <ActiveLandowners />
          </div>
          <div class="col-xl-4 col-md-6">
            <ActiveLands />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveStats;
