import { useEffect } from "react";
import GuardStats from "../components/guard/guard-stats";
import GuardRamUsage from "../components/guard/guard-ram-usage";
import GuardSignedTransactions from "../components/guard/guard-signed-transactions";
import GuardSignedTransactionsChart from "../components/guard/guard-signed-transactions-chart";
import GuardRamCountChart from "../components/guard/guard-ram-count-chart";
import GuardRamSumChart from "../components/guard/guard-ram-sum-chart";
import GuardCreatedAccounts from "../components/guard/guard-created-accounts";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Highcharts from "highcharts";
import GuardCreatedAccountsChart from "admin/components/guard/guard-created-accounts-chart";

function AdminDashboardGuard() {

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$('.button-menu-mobile').click(function(){ 
      setTimeout(() => {
        window.$(Highcharts.charts).each(function(i,chart){
          chart.reflow();
        });
      }, 1000);
    });
  })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-guard">Guard</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <GuardStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardRamUsage /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardCreatedAccounts /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardCreatedAccountsChart /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardSignedTransactions /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardSignedTransactionsChart /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardRamCountChart /> 
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
           <GuardRamSumChart /> 
        </div>
      </div>
    </>
  );
}

export default AdminDashboardGuard;
