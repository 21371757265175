import ApiService from "app/services/api";
import { useQuery } from "react-query";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import moment from "moment";
import { useState } from "react";
import { useHelpTexts } from "app/hooks/useSession";

export const TlmDistributionTable = (props) => {
  const componentProps = {
    component: "components/tlm/tlm-distribution-table",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };

  const helpTexts = useHelpTexts();
  const [hideSystemAccounts, setHideSystemAccounts] = useState(false);
  const [hideLesserRange, setHideLesserRange] = useState(false);

  const tlmStats = useQuery(["tlm_stats"], async () => {
    const response = await ApiService.get("tlm/stats", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  var total_address =
    tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .reduce((total_address, stat) => total_address + stat.total_address, 0);
  var total_balance =
    tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .reduce((total_balance, stat) => total_balance + stat.total_balance, 0);

  tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .map((stat) => {
        stat.percentage_address = parseFloat(
          (stat.total_address / total_address) * 100 || 0
        ).toFixed(2);
        stat.percentage_balance = parseFloat(
          (stat.total_balance / total_balance) * 100 || 0
        ).toFixed(2);
      });

  ///console.log(tlmStats);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lesserrange-check"
                  defaultChecked={hideLesserRange}
                  onChange={(el) => setHideLesserRange(!hideLesserRange)}
                />
                <label className="form-check-label" htmlFor="lesserrange-check">
                  Hide &#60;100
                </label>
              </div>
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideSystemAccounts}
                  onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide system accounts
                </label>
              </div>
            </form>
          </div>
          <h4
            className="header-title"
            data-ht-title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `TLM Distibution`
            }
          >
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM Distibution`}{" "}
            (
            {tlmStats.data
              ? moment(tlmStats.data.date).format("Do MMM YYYY")
              : ""}
            )
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "TLM Distribution"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            id="tlm_distribution_table"
          >
            <thead>
              <tr>
                <th>Balance</th>
                <th>Number of Addresses</th>
                <th>Percentage of Wealth</th>
                <th>Percentage of Address</th>
              </tr>
            </thead>
            <tbody>
              {tlmStats.data &&
                tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
                  .filter((stats) =>
                    hideLesserRange ? stats.rangeEnd > 100 : true
                  )
                  .map((stats, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            {(() => {
                              if (stats.rangeEnd === 1) {
                                return "< 1";
                              } else {
                                return `${new Intl.NumberFormat(
                                  "en",
                                  {}
                                ).format(
                                  stats.rangeStart
                                )} to ${new Intl.NumberFormat("en", {}).format(
                                  stats.rangeEnd
                                )}`;
                              }
                              /*    switch (index) {
                                case 0:
                                  return "< 1";
                                case tlmStats.data.length - 1:
                                  return `>= ${new Intl.NumberFormat(
                                    "en",
                                    {}
                                  ).format(stats.rangeStart)}`;
                                default:
                                  return `${new Intl.NumberFormat(
                                    "en",
                                    {}
                                  ).format(
                                    stats.rangeStart
                                  )} to ${new Intl.NumberFormat(
                                    "en",
                                    {}
                                  ).format(stats.rangeEnd)}`;
                              } */
                            })()}
                          </td>
                          <td>
                            {new Intl.NumberFormat("en", {}).format(
                              stats.total_address
                            )}
                          </td>
                          <td>{stats.percentage_balance}%</td>
                          <td>{stats.percentage_address}%</td>
                        </tr>
                      </>
                    );
                  })}
            </tbody>
          </table>
          {tlmStats.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default TlmDistributionTable;
