import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/home";
import TowerAppeals from "./views/tower/appeals";

function DefaultApp() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/tower/appeals" exact>
          <TowerAppeals />
        </Route>
      </Switch>
    </Router>
  );
}

export default DefaultApp;
