import Emitter from "app/services/emitter";
import { useEffect } from "react";
import { useRef } from "react";
import { useQuery } from "react-query";
import moment from "moment-timezone";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";

export const AdminListNotificationLists = () => {
  const tableRef = useRef();
  const dataTableRef = useRef();

  useEffect(() => {
    Emitter.on("CREATE_NOTIFICATION_LIST_SUCCESS", (newValue) => {
      notificationLists.refetch();
    });

    Emitter.on("DELETE_NOTIFICATION_LIST_SUCCESS", (newValue) => {
      notificationLists.refetch();
    });

    return () => {
      Emitter.off("CREATE_NOTIFICATION_LIST_SUCCESS");
      Emitter.off("DELETE_NOTIFICATION_LIST_SUCCESS");
    };
  });

  const notificationLists = useQuery(
    "list_notification_lists",
    async () => {
      const response = await ApiService.get("alerts/notification/lists", {
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    { refetchInterval: 30 * 1000, cacheTime: 1000 }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!notificationLists.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [notificationLists.data]);

  const onClickNew = async () => {
    Emitter.emit("NEW_NOTIFICATION_LIST");
  };

  const onClickEdit = async (notificationList) => {
    Emitter.emit("EDIT_NOTIFICATION_LIST", notificationList);
  };

  const onClickDelete = async (notificationList) => {
    try {
      const response = await ApiService.delete(
        `alerts/notification/lists/${notificationList.id}`,
        {}
      );

      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("DELETE_NOTIFICATION_LIST_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "List deleted",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "List delete Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
          <div className="float-sm-end">
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={(e) => onClickNew()}
                >
                  Create New
                </button>
              </div>
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="list_notification_lists"
            >
              <thead>
                <tr>
                  <th>List Type</th>
                  <th>List Name</th>
                  <th>Created Date</th>
                  <th>Updated Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {notificationLists.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {notificationLists.data &&
                      notificationLists.data
                        .filter((notificationList) => notificationList.id > 0)
                        .map((notificationList, index) => {
                          return (
                            <tr key={index}>
                              <td>{notificationList.type}</td>
                              <td>{notificationList.name}</td>
                              <td
                                title={moment(
                                  notificationList.created_datetime
                                ).fromNow()}
                              >
                                {moment
                                  .tz(notificationList.created_datetime, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td
                                title={moment(
                                  notificationList.updated_datetime
                                ).fromNow()}
                              >
                                {moment
                                  .tz(notificationList.updated_datetime, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td>
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) => onClickEdit(notificationList)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) =>
                                    onClickDelete(notificationList)
                                  }
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                  </>
                )}
              </tbody>
            </table>
            {notificationLists.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminListNotificationLists;
