import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AccountService from "../../services/account";

export const OracleNfts = () => {
    
  
    return (
      <>
        <BreadcrumbsItem to="/admin/oracle-nfts">Oracle NFTs</BreadcrumbsItem>
  
        <div className="row">
          <div class="col-lg-12">
            
          </div>
        </div>
      </>
    );
};

export default OracleNfts;
