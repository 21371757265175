import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import usePrevious from "app/hooks/usePrevious";

export const GuardSignedTransactions = (props) => {
  const componentProps = {
    component: "components/guard/guard-signed-transactions",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const transactions = useQuery(
    [`guard_signed_transactions`, filters],
    async () => {
      const response = await ApiService.get("guard/signed-transactions", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transactions.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transactions.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Recent transactions signed`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "Signed transactions tooltip"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table class="table dt-responsive nowrap w-100" ref={tableRef}>
            <thead>
              <tr>
                <th>Time</th>
                <th>Transaction ID</th>
                <th>CPU usage</th>
                <th>Net usage</th>
              </tr>
            </thead>
            <tbody>
              {transactions.data &&
                transactions.data.map((item, index) => {
                  item = item._source;
                  return (
                    <tr key={index}>
                      <td title={moment(item["@timestamp"]).fromNow()}>
                        {moment
                          .tz(item["@timestamp"], "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{item.trx_id}</td>
                      <td>{item.cpu_usage_us}</td>
                      <td>{item.net_usage_words}</td>
                    </tr>
                  );
                })}

              {transactions.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {transactions.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default GuardSignedTransactions;
