import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";

export const TopMiners = (props) => {

  const tableRef = useRef();
  const dataTableRef = useRef();

  const topMiners = useQuery(
    [`mining_explorer_top_miners`, props.query],
    async () => {
      const response = await ApiService.get("mining/top-miners", {
        query: props.query
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      initialData: new Array(10).fill(0),
    }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topMiners.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current || window.$(tableRef.current).DataTable({
            "language": {
                "paginate": {
                    "previous": "<i class='mdi mdi-chevron-left'>",
                    "next": "<i class='mdi mdi-chevron-right'>"
                }
            },
            "order": [],
            "lengthMenu": [ 10, 100, 500],
            "dom": 'Blfrtip',
            "columnDefs": [{ targets: 'no-sort', orderable: false }],
            "buttons": ['copy', {extend:"csv", title:`Top Miners`},
                {
                    extend: 'copy',
                    title: null,
                    header: false,
                    exportOptions: {
                      columns: ':contains("Miner")',
                      rows: ':visible',
                      customizeData: function (dataTable) {
                        dataTable.body = [
                          ...new Map(
                            dataTable.body.map((item) => [item[0], item])
                          ).values(),
                        ];
                      }
                    }
                }
            ]
        });
      window.$('.buttons-copy').hide();  
      window.$('.buttons-csv').hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    setTimeout(() => {
        window.$.Components.init();
    });
  }, [topMiners.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_miners_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard('top_miners', 1);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h4 className="header-title">
            Top Miners
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This shows the top 10 miners based on TLM earned in the selected filter"
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
                  <table className="table dt-responsive nowrap w-100" ref={tableRef} id="top_miners">
                    <thead>
                      <tr>
                        <th className="no-sort">
                          Miner
                          &nbsp;
                          <Link to="" onClick={copyWalletsToClipboard}>
                              <i className="fas fa-copy" title="Copy miners to clipboard"></i>
                          </Link>
                        </th>
                        <th>Total(TLM)</th>
                        <th>Min(TLM)</th>
                        <th>Max(TLM)</th>
                        <th>Avg(TLM)</th>
                        <th>Days Mined</th>
                        <th>Total commission</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!topMiners.isError &&
                        topMiners.data.map((topMiner, index) => (
                          <>
                            {!topMiner.key ? (
                              <tr key={index}>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                              </tr>
                            ) : (
                              <tr key={index}>
                                <td>
                                  <Link to={`/admin/account-scanner/${topMiner.key}`}>
                                    {topMiner.key}
                                  </Link>
                                </td>
                                <td>
                                  {parseFloat(
                                    topMiner.total_bounty.value
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 4,
                                  })}
                                </td>
                                <td>
                                  {parseFloat(topMiner.min_bounty.value).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 4,
                                    }
                                  )}
                                </td>
                                <td>
                                  {parseFloat(topMiner.max_bounty.value).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 4,
                                    }
                                  )}
                                </td>
                                <td>
                                  {parseFloat(
                                    topMiner.average_bounty.value
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 4,
                                  })}
                                </td>
                                <td>
                                  {parseFloat(topMiner.doc_count).toLocaleString()}
                                </td>
                                <td>
                                  {parseFloat(topMiner.total_commission.value).toLocaleString()}
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                    </tbody>
                  </table>
          {topMiners.isError ? <Error500 /> : null}          
        </div>
      </div>
    </>
  );
};

export default TopMiners;
