import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const MissionsRaritySpaceshipLeasingChart = (props) => {
  const componentProps = {
    component: "components/missions/missions-rarity-spaceship-leasing-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);

  const missions = useQuery(
    ["mission_rarity_spaceship_leasing_chart", filters],
    async () => {
      const response = await ApiService.get(
        "missions/mission-rarity-spaceship-leasing-chart",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!missions.data) return;

    setData(missions.data);

    chartComponent.current.chart.hideLoading();
  }, [missions.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission rarity spaceship leasing chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the spaceship leasing of each rarity based on boarding time. The boarding time is the time between boarding start and the launch time. This boarding time is split into 4 quarters. Eg: If boarding time was one day, each quarter is 6 hours long. This chart will indicate if users engage will mission as soon as it is released or towards the launch time of the mission. Only missions that have the boarding period in the selected time-frame are shown."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {missions.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    categories: ["Common", "Rare", "Epic", "Legendary"],
                    title: {
                      text: "Rarity",
                    },
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "percentage of spaceship",
                    },
                  },
                  plotOptions: {
                    column: {
                      stacking: "percent",
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        '<span style="color:' +
                        this.series.color +
                        '">ID</span>: <b>' +
                        this.x +
                        '</b><br/><span style="color:' +
                        this.series.color +
                        '">Spaceship count</span>: <b>' +
                        this.y +
                        '</b><br/><span style="color:' +
                        this.series.color +
                        '">Percentage</span>: <b>' +
                        this.point.percentage.toFixed(2) +
                        "</b><br/>"
                      );
                    },
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionsRaritySpaceshipLeasingChart;
