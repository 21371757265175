import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const MineChart2 = (props) => {
  const componentProps = {
    component: "components/mining/mine-chart2",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState({ dates: [], data: [] });
  const [type, setType] = useState("day");

  const mining = useQuery(["mining_chart2", filters, type], async () => {
    const response = await ApiService.get("logmines/mine-chart2", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      type: type,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!mining.data) return;

    setData({ dates: mining.data.dates, data: mining.data.data });

    chartComponent.current.chart.hideLoading();
  }, [mining.data]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={handleChange}
              >
                <option value="day">Per Day</option>
                <option value="hour">Per Hour</option>
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mining chart`}{" "}
          {type == "day" ? "per day of the week" : "per hour of the day"}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows mining actions based on day of the week or hour of the day. It indicates what day of the week is busier than other. In the case of hour of the day, it shows what hour of the day is busier."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {mining.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: false,
                  },
                  xAxis: {
                    categories: data.dates,
                    title: {
                      text: type == "day" ? "Day" : "Hour",
                    },
                  },
                  yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "Count",
                    },
                  },
                  series: [
                    {
                      name: "Total Mines",
                      data: data.data,
                    },
                  ],
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MineChart2;
