import TransferFlow from "../components/graphs/TransferFlow";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function AdminTlmForensics() {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">TLM Forensics</BreadcrumbsItem>
      {/* <div>TLM Detective</div> */}

      <div className="row">
        <div className="col-lg-12">
          <TransferFlow />
        </div>
      </div>
    </>
  );
}

export default AdminTlmForensics;
