import AdminDefaultLayout from "./layouts/default";
import AdminAuthLayout from "./layouts/auth";
import { Redirect, Route, Switch } from "react-router";
import moment from "moment";

import { useLoggedIn } from "../app/hooks/useSession";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import AuthService from "../app/services/auth";
import { useDispatch } from "react-redux";
import { setHelpTexts, setUser } from "../app/state/session";
import ApiService from "app/services/api";
import { Helmet } from "react-helmet";

function AdminApp() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const location = useLocation();
  const sessionLoggedIn = useLoggedIn();
  const dispatch = useDispatch();

  useEffect(async () => {
    setCurrentLocation(location);
    if (sessionLoggedIn) {
      const data = await AuthService.me();
      if (data && data.user) {
        dispatch(setUser(data.user));
        setTimeout(() => {
          window.$.Components.init();
        }, 500);
      }
    }
    const helpTextsResponse = await ApiService.get("help-texts", {
      timestamp: moment().utc().valueOf(),
    });
    const helpTexts = await helpTextsResponse.json();
    if (helpTexts && helpTexts.length) {
      dispatch(setHelpTexts(helpTexts));
      window.$.fn.tooltip &&
        window.$('[data-bs-toggle="tooltip"]').tooltip("dispose").tooltip();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>AW Yeomen Warder - Admin</title>
        <meta name="description" content="Yeomen - Admin." />
      </Helmet>
      <Switch>
        <Route path="/admin">
          {sessionLoggedIn ? (
            // <Redirect to="/admin/dashboard" />
            <AdminDefaultLayout />
          ) : (
            // <Redirect to="/admin/login" />
            <Redirect
              to={{
                pathname: "/admin/login",
                search: location.search,
                state: {
                  from: currentLocation ? currentLocation.pathname : null,
                },
              }}
            />
          )}
        </Route>
      </Switch>
      {!sessionLoggedIn ? <AdminAuthLayout /> : null}
    </>
  );
}

export default AdminApp;
