import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const UniqueMinersPerToolsChart = (props) => {
  const componentProps = {
    component: "components/mining/unique_miners_per_tools_chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [rarity, setRarity] = useState({
    common: true,
    rare: true,
    epic: true,
    legendary: true,
    mythical: true,
    abundant: true,
  });
  const [shine, setShine] = useState({
    stone: true,
    gold: true,
    stardust: true,
    antimatter: true,
    xDimension: true,
  });
  const [showLinear, setShowLinear] = useState(true);
  const [showAllSeries, setShowAllSeries] = useState(true);

  const miners = useQuery(
    ["unique_miners_per_tools_chart", filters, rarity, shine],
    async () => {
      const response = await ApiService.get(
        "logmines/unique-miners-per-tools-chart",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
          rarity: JSON.stringify(rarity),
          shine: JSON.stringify(shine),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  const setRarityFilters = (type) => {
    rarity[type] = rarity[type] ? false : true;
    setRarity({ ...rarity });
  };
  const setShineFilters = (type) => {
    shine[type] = shine[type] ? false : true;
    setShine({ ...shine });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!miners.data) return;

    setData(miners.data);

    chartComponent.current.chart.hideLoading();
  }, [miners.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="series-check"
                defaultChecked={showAllSeries}
                onChange={(el) => setShowAllSeries(!showAllSeries)}
              />
              <label className="form-check-label" htmlFor="series-check">
                Show all/none
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Unique miners per tools chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the unique miners per tools on each day in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Rarity:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="common"
              onChange={() => setRarityFilters("common")}
              defaultChecked={rarity.common ? true : false}
            />
            <label class="form-check-label" for="common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="rare"
              onChange={() => setRarityFilters("rare")}
              defaultChecked={rarity.rare ? true : false}
            />
            <label class="form-check-label" for="rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="epic"
              onChange={() => setRarityFilters("epic")}
              defaultChecked={rarity.epic ? true : false}
            />
            <label class="form-check-label" for="epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="legendary"
              onChange={() => setRarityFilters("legendary")}
              defaultChecked={rarity.legendary ? true : false}
            />
            <label class="form-check-label" for="legendary">
              Legendary
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mythical"
              onChange={() => setRarityFilters("mythical")}
              defaultChecked={rarity.mythical ? true : false}
            />
            <label class="form-check-label" for="mythical">
              Mythical
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="abundant"
              onChange={() => setRarityFilters("abundant")}
              defaultChecked={rarity.abundant ? true : false}
            />
            <label class="form-check-label" for="abundant">
              Abundant
            </label>
          </div>
        </div>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Shine:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="stone"
              onChange={() => setShineFilters("stone")}
              defaultChecked={shine.stone ? true : false}
            />
            <label class="form-check-label" for="stone">
              Stone
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="gold"
              onChange={() => setShineFilters("gold")}
              defaultChecked={shine.gold ? true : false}
            />
            <label class="form-check-label" for="gold">
              Gold
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="stardust"
              onChange={() => setShineFilters("stardust")}
              defaultChecked={shine.stardust ? true : false}
            />
            <label class="form-check-label" for="stardust">
              Stardust
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="antimatter"
              onChange={() => setShineFilters("antimatter")}
              defaultChecked={shine.antimatter ? true : false}
            />
            <label class="form-check-label" for="antimatter">
              Antimatter
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="xDimension"
              onChange={() => setShineFilters("xDimension")}
              defaultChecked={shine.xDimension ? true : false}
            />
            <label class="form-check-label" for="xDimension">
              XDimension
            </label>
          </div>
        </div>
        {miners.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "top",
                    y: 25,
                    labelFormatter: function () {
                      return this.userOptions.legend_name;
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    allowDecimals: false,
                    // min: 0,
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    outside: true,
                    xDateFormat: "%Y-%m-%d",
                    // shared: true
                  },
                  plotOptions: {
                    series: {
                      visible: showAllSeries,
                    },
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UniqueMinersPerToolsChart;
