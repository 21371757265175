import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts3d from "highcharts/highcharts-3d";
import { Link } from "react-router-dom";

Highcharts3d(Highcharts);

export const AdminTokensTopHolders = (props) => {
  const chartComponent = useRef(null);
  const [seriesData, setSeriesData] = useState([]);
  const { code, name } = props;
  const [hideSystemAccounts, setHideSystemAccounts] = useState(false);

  const token = useQuery([`token_${code}_${name}`, code, name], () => {
    return ApiService.get(`token/${code}/${name}`, {}).then((res) =>
      res.json()
    );
  });

  const topHolders = useQuery(
    [`token_${code}_${name}_top_holders`, code, name, hideSystemAccounts],
    () => {
      return ApiService.get(`token/topHolders/${code}/${name}`, {
        hideSystemAccounts: hideSystemAccounts? 1 : 0
      }).then(
        (res) => res.json()
      );
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!token.data || !topHolders.data) return;

    var seriesData = [];
    topHolders.data.map((holder, key) => {
      if (key <= 100) {
        var percentage = parseFloat(
          ((holder[1] / token.data.supply) * 100).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        );
        if (key === 0) {
          seriesData.push({
            name: holder[0],
            y: percentage,
            selected: true,
            sliced: true,
          });
        } else {
          seriesData.push({ name: holder[0], y: percentage });
        }
      }
    });

    setSeriesData(seriesData);
    chartComponent.current.chart.hideLoading();
  }, [token.data, topHolders.data]);

  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
      },
      styledMode: false,
      allowPointSelect: true,
      height: 350,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    exporting: false,
    credits: {
      enabled: false,
      href: "https://yeomen.ai/",
      text: "yeomen.ai",
    },
    tooltip: {
      hideDelay: 3000,
      useHTML: true,
      followPointer: false,
      style: {
        padding: 0,
        pointerEvents: "auto",
      },
      formatter: function () {
        var ret =
          '<a href="/account/' +
          this.point.name +
          '" style="color:inherit"><b>' +
          this.point.name +
          "</b></a><br>";
        ret +=
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": ";
        ret += "<b>" + Highcharts.numberFormat(this.point.y, 2) + "</b>";
        return ret;
      },
    },
    plotOptions: {
      pie: {
        innerSize: 110,
        depth: 50,
        allowPointSelect: true,
        cursor: "pointer",
        size: "100%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y:,.2f} %",
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        name: "Tokens",
        data: seriesData,
      },
    ],
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
        <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
                <div className="form-check me-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="systemaccounts-check"
                        defaultChecked={hideSystemAccounts}
                        onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
                    />
                    <label className="form-check-label" htmlFor="systemaccounts-check">
                        Hide system accounts
                    </label>
                </div>
            </form>
        </div>
          <h5>Top 100 {name} Holders</h5>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <table className="table mb-0" id="basic-datatable">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Account</th>
                <th>{name} Held</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {topHolders.isLoading ? (
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ) : (
                <>
                  {topHolders.data.map((holder, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={`/admin/account-scanner/${holder[0]}`}>
                          {holder[0]}
                        </Link>
                      </td>
                      <td>
                        {parseFloat(holder[1]).toLocaleString(undefined, {
                          minimumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {token.data?((holder[1] / token.data.supply) * 100).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        ):0}
                        %
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminTokensTopHolders;
