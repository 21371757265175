import { useQuery } from "react-query";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";

export const UserTopLevel = (props) => {

  const stats = useQuery([`user_point_stats_${props.account}`], async () => {
    return AccountService.getUserpointStats(props.account);
  });

  return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{(stats.data && stats.data.top_level)? stats.data.top_level.toLocaleString() : 0}</span>
                )}
                </h3>
                <p className="text-muted mb-0">Top level</p>
            </div>
        </div>
  );
};

export default UserTopLevel;
