import { useRef, useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ReportsService from "../../../services/reports";
import Emitter from "app/services/emitter";
import { Link } from "react-router-dom";
import { useHelpTexts } from "app/hooks/useSession";
import { useQueryParams } from "app/hooks/useQueryParams";
import config from "app/config";
import ApiService from "app/services/api";

export const AdminRequestSheetsManager = () => {
  //const [spreadsheetUrl, setSpreadsheetUrl] = useState();
  const [filters, setFilters] = useState({});

  const requestSheetsManager = async (e) => {
    e.preventDefault();
    try {
      if (!filters["spreadsheetUrl"]) {
        window.$.toast({
          heading: "Error",
          text: "Required field: " + "Spreadsheet Url",
          position: "top-right",
          icon: "error",
        });
        return;
      } else if (!filters["start_date"]) {
        window.$.toast({
          heading: "Error",
          text: "Required field: " + "Start Date",
          position: "top-right",
          icon: "error",
        });
        return;
      } else if (!filters["end_date"]) {
        window.$.toast({
          heading: "Error",
          text: "Required field: " + "End Date",
          position: "top-right",
          icon: "error",
        });
        return;
      }

      const response = await ApiService.put("reports/sheets-manager/request", {
        //spreadsheetUrl: spreadsheetUrl,
        filters: filters,
      });

      //const data = await response.json();
      if (!response.ok) {
        const data = await response.json();
        throw Error(data.message || "Unable to process");
      }

      Emitter.emit("REQUEST_SHEETS_MANAGER_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Your request is being updated to the date range selected.",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <p>Sheets Manager</p>
                  <div className="row ">
                    <div
                      className="col-lg-6 col-md-10"
                      key={`spreadsheet_link`}
                    >
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            e.preventDefault();
                            //setSpreadsheetUrl(e.target.value);
                            setFilters((prevState) => ({
                              ...prevState,
                              ["spreadsheetUrl"]: e.target.value,
                            }));
                          }}
                        />
                        <label for={`spreadsheet_link`}>
                          {"Spreadsheet Url *"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-3 col-md-6" key={`start_date`}>
                      <div class="form-floating mb-3">
                        <DateRangePicker
                          id={`daterangepicker_start_date`}
                          initialSettings={{
                            autoUpdateInput: false,
                            singleDatePicker: true,
                            timePicker: false,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onCallback={(start, end, label) => {
                            setFilters((prevState) => ({
                              ...prevState,
                              ["start_date"]: start.format("YYYY-MM-DD"),
                            }));
                          }}
                          onApply={(event, picker) => {
                            setFilters((prevState) => ({
                              ...prevState,
                              ["start_date"]:
                                picker.startDate.format("YYYY-MM-DD"),
                            }));
                            window
                              .$(event.currentTarget)
                              .val(picker.startDate.format("YYYY-MM-DD"));
                          }}
                        >
                          <input type="text" className="form-control" />
                        </DateRangePicker>
                        <label for={`daterangepicker_start_date`}>
                          {"Start Date *"}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6" key={`end_date`}>
                      <div class="form-floating mb-3">
                        <DateRangePicker
                          id={`daterangepicker_end_date`}
                          initialSettings={{
                            autoUpdateInput: false,
                            singleDatePicker: true,
                            timePicker: false,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onCallback={(start, end, label) => {
                            setFilters((prevState) => ({
                              ...prevState,
                              ["end_date"]: start.format("YYYY-MM-DD"),
                            }));
                          }}
                          onApply={(event, picker) => {
                            setFilters((prevState) => ({
                              ...prevState,
                              ["end_date"]:
                                picker.startDate.format("YYYY-MM-DD"),
                            }));
                            window
                              .$(event.currentTarget)
                              .val(picker.startDate.format("YYYY-MM-DD"));
                          }}
                        >
                          <input type="text" className="form-control" />
                        </DateRangePicker>
                        <label for={`daterangepicker_end_date`}>
                          {"End Date *"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-light"
                    onClick={requestSheetsManager}
                  >
                    Populate Data 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRequestSheetsManager;
