import ApiService, { apiHeaders } from "app/services/api";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useRef } from "react";
import CardMenu from "../card-menu";
import { copyToClipboard } from "app/utils/datatables";
import { setReport } from "app/state/session";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import usePrevious from "app/hooks/usePrevious";
import config from "app/config";
import ReportsService from "admin/services/reports";
import Emitter from "app/services/emitter";
import { useDispatch } from "react-redux";

export const StakesLookupResults = (props) => {
  const componentProps = {
    component: "components/stakes-lookup/stakes-lookup-results",
    options: { props: { query: props.query } },
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "stakes-lookup-results" },
  };
  const dispatch = useDispatch();
  const helpTexts = useHelpTexts();
  const history = useHistory();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevQuery = usePrevious(props.query);

  if (dataTableRef.current && prevQuery !== props.query) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500, 1000],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/stakes`,
            type: "POST",
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.query = props.query;
              /* data.from = props.lookupFilters.from;
              data.to = props.lookupFilters.to;
              data.memo = props.lookupFilters.memo;
              data.startDate = props.lookupFilters.start_date;
              data.endDate = props.lookupFilters.end_date; */
            },
          },
          columns: [
            { data: "timestamp", name: "@timestamp" },
            { data: "from", name: "@delegatebw.from" },
            { data: "receiver", name: "@delegatebw.receiver" }, 
            { data: "stake_cpu_quantity", name: "@delegatebw.stake_cpu_quantity" },
            { data: "stake_net_quantity", name: "@delegatebw.stake_net_quantity" },
            { data: "amount", name: "@delegatebw.amount" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.timestamp).fromNow());
              },
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.from}" target="_blank">${rowData.from}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 2,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.receiver}" target="_blank">${rowData.receiver}</a>`;

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Stakes` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Receiver")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            }
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();

      // dataTableRef.current.on("preDraw", function (e) {
      //   const info = window.$(tableRef.current).DataTable().page.info();
      //   if (info.start >= 10000) {
      //     dispatch(
      //       setReport({
      //         type: "reportExportStakes",
      //         format: "csv",
      //         filters: props.query != "" ? JSON.parse(props.query) : {},
      //       })
      //     );
      //     window.$("#large-result-window-elasticsearch-modal").modal("show");
      //     return false;
      //   }

      //   return true;
      // });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.query]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("stakes_lookup", 1);
  };

  const copyReceiverAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("stakes_lookup", 2);
  };


  const exportCSV = async () => {
    try {
      const response = await ReportsService.requestReport({
        type: "reportExportTlmTransfers",
        format: "csv",
        filters: props.query != "" ? JSON.parse(props.query) : {},
      });
      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("REQUEST_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Request created Successfully. <a href='/admin/reports/reports'><button class='btn btn-xs btn-primary'>View reports</button></a>",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Export failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="card mt-2">
        <div className="card-body">
          {/* <CardMenu {...componentProps} {...props} /> */}
          {/* {props.query != "" ? (
            <div className="float-end me-2">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn btn-sm btn-success mb-2 mb-sm-0"
                  onClick={exportCSV}
                >
                  Export as csv
                </button>
              </form>
            </div>
          ) : null} */}
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Stakes`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || ``
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="stakes_lookup"
          >
            <thead>
              <tr>
                <th>Date</th>
                <th className="no-sort">
                  From &nbsp;
                  <Link to="" onClick={copyFromAddressToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy from address to clipboard"
                    ></i>
                  </Link>
                </th>
                <th className="no-sort">
                  Receiver &nbsp;
                  <Link to="" onClick={copyReceiverAddressToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy to address to clipboard"
                    ></i>
                  </Link>
                </th>    
                <th>Stake CPU</th>
                <th>Stake NET</th>
                <th>Total Stake</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default StakesLookupResults;
