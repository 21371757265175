import ApiService from "../../app/services/api";

export const LogminesService = {
    getLogmines: async (filters) => {        
        const response = await ApiService.get("logmines", filters);
          if (!response.ok) {      
            throw new Error("Network response was not ok");
          }
          return response.json();
    }
}

export default LogminesService;