import { useEffect } from "react";
import MissionsStats from "../components/missions/mission-stats";
import MissionsTlm from "../components/missions/mission-tlm";
import MissionsRarityNumMissions from "../components/missions/mission-rarity-num-missions";
import MissionsRarityNftMinted from "../components/missions/mission-rarity-nft-minted";
import MissionsRarityHighestSpaceship from "../components/missions/mission-rarity-highest-spaceship";
import MissionsRarityLowestSpaceship from "../components/missions/mission-rarity-lowest-spaceship";
import MissionsRarityHighestTlmPot from "../components/missions/mission-rarity-highest-tlm-pot";
import MissionsRarityLowestTlmPot from "../components/missions/mission-rarity-lowest-tlm-pot";
import MissionsRarityTlmStaked from "../components/missions/mission-rarity-tlm-staked";
import MissionsRarityMostCrafts from "../components/missions/mission-rarity-most-crafts";
import MissionsRarityMostTlm from "../components/missions/mission-rarity-most-tlm";
import MissionsTable from "../components/missions/missions-table";
import MissionsTopPlayers from "../components/missions/missions-top-players";
import MissionsChart from "../components/missions/missions-chart";
import MissionsChartTlm from "../components/missions/missions-chart-tlm";
import MissionsRaritySpaceshipChart from "../components/missions/missions-rarity-spaceship-chart";
import MissionsUnclaimedChart from "../components/missions/missions-unclaimed-chart";
import MissionsAprChart from "../components/missions/missions-apr-chart";
import MissionsNftInterestChart from "../components/missions/missions-nft-interest-chart";
import MissionsSpaceshipLeasingChart from "../components/missions/missions-spaceship-leasing-chart";
import MissionsRaritySpaceshipLeasingChart from "../components/missions/missions-rarity-spaceship-leasing-chart";
import MissionsAdditionalSpaceshipsChart from "../components/missions/missions-additional-spaceships-chart";
import MissionsRewardPerSpaceshipChart from "../components/missions/missions-reward-per-spaceship-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Highcharts from "highcharts";
import MissionsChartPerMonth from "admin/components/missions/missions-chart-per-month";

function AdminDashboardMissions() {

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$('.button-menu-mobile').click(function(){ 
      setTimeout(() => {
        window.$(Highcharts.charts).each(function(i,chart){
          chart.reflow();
        });
      }, 1000);
    });
  })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-missions">Missions</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <MissionsStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsTlm />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityNumMissions />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityNftMinted />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityHighestSpaceship />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityLowestSpaceship />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityHighestTlmPot />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityLowestTlmPot />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityTlmStaked />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityMostCrafts />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRarityMostTlm />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsTable />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsTopPlayers />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsChartTlm />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRaritySpaceshipChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsUnclaimedChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsAprChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsNftInterestChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsSpaceshipLeasingChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRaritySpaceshipLeasingChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsAdditionalSpaceshipsChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsRewardPerSpaceshipChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MissionsChartPerMonth />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardMissions;
