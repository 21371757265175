export function formatBytes(bytes, precision) {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
  if (typeof precision === "undefined") precision = 1;
  var units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
    number = Math.floor(Math.log(bytes) / Math.log(1024));
  if (number === "-Infinity") {
    number = 0;
  }
  return (
    (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
    " " +
    units[number]
  );
}

export const shortNumberFormat = (number, precision) => {
  if (typeof precision === "undefined") precision = 0;
  let abs = Math.abs(number);
  if (abs >= Math.pow(10, 12)) {
    // trillion
    number = (number / Math.pow(10, 12)).toFixed(precision) + "T";
  } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
    // billion
    number = (number / Math.pow(10, 9)).toFixed(precision) + "B";
  } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
    // million
    number = (number / Math.pow(10, 6)).toFixed(precision) + "M";
  } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
    // thousand
    number = (number / Math.pow(10, 3)).toFixed(precision) + "K";
  } else {
    number = number.toFixed(precision);
  }

  return number;
};
