import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";
import AdminTemplatesGridCard from "../../nfts/templates-grid-card";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeAtomicassetsMiningTemplates = (props) => {
  const componentProps = {
    component: "components/realtime/atomicassets/mining-templates",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const [isPaused, setPaused] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    var socket = SocketService.getSocket("/atomicassets");

    if (socket.connected) {
      socket.emit("join", "latestMiningTemplates");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "latestMiningTemplates");
      });
    }

    if (isPaused) {
      socket.off("latestMiningTemplates");
    } else {
      socket.on("latestMiningTemplates", function (data) {
        setTemplates(data);
      });
    }

    return () => {
      socket.off("latestMiningTemplates");
      socket.emit("leave", "latestMiningTemplates");
    };
  }, [isPaused]);

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-end me-2">
            <form className="d-flex align-items-center flex-wrap">
              <button
                type="button"
                className="btn btn-sm btn-success mb-2 mb-sm-0"
                onClick={() => setPaused(!isPaused)}
              >
                {isPaused ? "Unpause" : "Pause"}
              </button>
            </form>
          </div>
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Top 8 mining tools (last 1K actions)`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div className="row">
            {templates.map((template, index) => (
              <div className="col-xl-3 col-md-6" key={index}>
                <AdminTemplatesGridCard
                  template={template}
                  sort={"created_at_time"}
                  badge={template.total_mines}
                />
              </div>
            ))}
            {templates.length === 0 ? (
              <div className="col-12">
                <p>No records</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeAtomicassetsMiningTemplates;
