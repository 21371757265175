//import { useEffect, useState } from "react";
import { setLoaded, setFailed } from "app/state/ipfsImage";
import { useDispatch } from "react-redux";

export const IpfsImage = (props) => {
    const dispatch = useDispatch();

  const onLoad = (e) => {
    dispatch(setLoaded(e.target.src));
  };

  const onError = (e) => {    
    dispatch(setFailed(e.target.src));
  };

  return <img alt="ipfsimage" {...props} onLoad={onLoad} onError={onError} />;
};

export default IpfsImage;
