import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import { useState } from "react";
import { useUser } from "app/hooks/useSession";
import moment from "moment";
import { useCallback } from "react";

export const AdminUsers = () => {
  const tableRef = useRef();
  const dataTableRef = useRef();
  const [user, setUser] = useState({ level: "level1", name: "", email: "" });
  const sessionUser = useUser();

  const users = useQuery(["admin_users"], async () => {
    const response = await ApiService.get("auth/admin-users", {
      time: moment().unix(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
      dataTableRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!users.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[0, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [users.data]);

  const handleAddClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.put("auth/admin-users", {
        level: user.level,
        name: user.name,
        email: user.email,
      });
      //if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      users.refetch();
      window.$.toast({
        heading: "Success",
        text: "Added Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Add Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const statusToggle = async (confirmation = false) => {
    try {
      if (!confirmation) {
        return window.$("#status-toggle-modal").modal("show");
      }

      let response = await ApiService.put("auth/admin-users/status", {
        id: user.id,
        status: user.status === "active" ? "inactive" : "active",
      });

      response = await response.json();

      if (!response || !response.status || response.status !== "ok") {
        throw Error(response.message ?? "Unable to process");
      }

      setUser({});
      users.refetch();
      window.$.toast({
        heading: "Success",
        text: `Status updated`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Status Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          {sessionUser.level == "level2" ||
          sessionUser.role == "super_admin" ? (
            <div className="float-sm-end">
              <button
                class="btn btn-sm btn-success me-1"
                type="button"
                onClick={() => {
                  window.$("#add-user-modal").modal("show");
                }}
              >
                Add
              </button>
            </div>
          ) : null}
          <h4 className="header-title">
            Admin users
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This shows the details of admin users."
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="admin_users"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Has loggedin</th>
                <th>Status</th>
                <th>Level</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.data &&
                users.data.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.last_loggedin_at ? "Yes" : "No"}</td>
                      <td>{user.status}</td>
                      <td>{user.level}</td>
                      <td>{user.role}</td>
                      <td>
                        {(sessionUser.level === "level2" &&
                          ["general", "level0", "level1"].includes(
                            user.level
                          )) ||
                        sessionUser.role === "super_admin" ? (
                          <button
                            className={`btn btn-sm ${
                              user.status === "active"
                                ? "btn-danger"
                                : "btn-success"
                            }`}
                            onClick={() => {
                              setUser(user);
                              statusToggle(false);
                            }}
                          >
                            {user.status === "active"
                              ? "Deactivate"
                              : "Activate"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}

              {users.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {users.isError ? <Error500 /> : null}
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h4 className="header-title">
            Permissions (Table last updated: 31st Aug 2022)
          </h4>
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>General</th>
                <th>Level 0</th>
                <th>Level 1</th>
                <th>Level 2</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>My Dashboard</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>TLM</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Planet</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Mining</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Landowner</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>NFTs</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Guard</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>System Accounts</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Missions</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Users</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Teleport</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Zendesk</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Energy</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Sinks</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>DAO</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Packs</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>User Points</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Forensics</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Mining Explorer</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Account Scanner</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Flag/Unflag users</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Realtime</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Token Holders</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Tool Calculator</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Reports</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Check Bots</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Self Test</th>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Bulk Input</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
              <tr>
                <th>Tag Lookup</th>
                <td>
                  <i className="ri-close-circle-line ri-2x text-danger"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
                <td>
                  <i className="ri-checkbox-circle-line ri-2x text-success"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        id="add-user-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add</h4>
            </div>
            <div className="modal-body">
              <div class="mb-3">
                <label for="name" class="form-label">
                  Level
                </label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      level: e.target.value,
                    }))
                  }
                >
                  <option value={"general"}>General</option>
                  <option value={"level0"}>Level 0</option>
                  <option value={"level1"}>Level 1</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="name" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Name"
                  value={user.name}
                  required
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                />
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
              <div class="mb-3">
                <label for="emailAddress" class="form-label">
                  Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="emailAddress"
                  placeholder="Email"
                  value={user.email}
                  required
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleAddClick}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#add-user-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="status-toggle-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div>
            <div className="modal-body">
              Are you sure you want to{" "}
              {user.status === "active" ? "deactivate" : "activate"} this user?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.$("#status-toggle-modal").modal("hide");
                  statusToggle(true);
                }}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  setUser({});
                  window.$("#status-toggle-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
