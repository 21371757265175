import { useQuery } from "react-query";
import { useFilters } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import StatBox from "./stat-box";

export const EthBscOutboundTlm = (props) => {
  const filters = useFilters();

  const stats = useQuery([`${props.type}_stats_${props.account}`, props.account, filters], () => {
    return AccountService.getEthBscStats(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        type: props.type
    });
  });

  return (
          <div className="widget-simple text-center card">
              <div className="card-body">
                {stats.status === "loading" ? (
                    <div className="text-end">
                        <h3 className="mb-1 mt-0">
                        <Skeleton />
                        </h3>
                        <p className="text-muted mb-0">Outbound TLM in {props.type == "bsc" ? "BSC" : "ETH"}</p>
                    </div>
                ) : (
                    <StatBox
                    data={stats.data && stats.data.outbound_tlm  ? stats.data.outbound_tlm : 0}
                    title={"Outbound TLM in "+(props.type == "bsc" ? "BSC" : "ETH")}
                    />
                )}
              </div>
          </div>
  );
};

export default EthBscOutboundTlm;
