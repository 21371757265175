import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import indicators from "highcharts/indicators/indicators";
import accelerationbands from "highcharts/indicators/acceleration-bands";
import bollingerbands from "highcharts/indicators/bollinger-bands";
import ema from "highcharts/indicators/ema";
import dema from "highcharts/indicators/dema";
import regressions from "highcharts/indicators/regressions";
import tema from "highcharts/indicators/tema";
import wma from "highcharts/indicators/wma";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import moment from "moment";
import { removeEmpty } from "app/utils/object";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
indicators(Highcharts);
accelerationbands(Highcharts);
bollingerbands(Highcharts);
ema(Highcharts);
dema(Highcharts);
regressions(Highcharts);
tema(Highcharts);
wma(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const TlmPriceChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/tlm/tlm-price-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };

  const filters = useFilters();
  const helpTexts = useHelpTexts();
  const [data, setData] = useState([]);
  const [type, setType] = useState("selected");

  const price = useQuery(["tlm_price_chart", filters, type], async () => {
    const response = await ApiService.get(
      "tlm/tlm-price-chart",
      removeEmpty({
        startDate: type === "selected" ? filters.dates.startDate : null,
        endDate: type === "selected" ? filters.dates.endDate : null,
        type: type,
      })
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!price.data) return;

    setData(price.data);

    chartComponent.current.chart.hideLoading();
  }, [price.data]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={handleChange}
                value={type}
              >
                <option value="selected">Selected time</option>
                <option value="all">All time</option>
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `TLM price chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the TLM price per day during the period selected."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {price.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "line",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      showInLegend: true,
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    title: {
                      text: "Date",
                    },
                    //range: moment(filters.dates.endDate).diff(moment(filters.dates.startDate), 'seconds') * 1000
                  },
                  yAxis: {
                    title: {
                      text: "USD",
                    },
                  },
                  series: [
                    {
                      id: "usd-price",
                      name: "USD price",
                      data: data,
                    },
                  ],
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TlmPriceChart;
