import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import ApiService, { apiHeaders } from "app/services/api";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import { useState } from "react";
import { useUser } from "app/hooks/useSession";
import moment from "moment";
import { useCallback } from "react";
import config from "app/config";
import CardMenu from "admin/components/card-menu";

export const AdminTowerSignalsEntities = (props) => {
  const componentProps = {
    component: "views/tower/manage/components/tower-signals-entities",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "tower_signals_entities" },
  };
  const tableRef = useRef();
  const dataTableRef = useRef();

  const [filters, setFilters] = useState({ signal: "ALL" });
  const [signalsEntity, setSignalsEntity] = useState({
    id: null,
    signal: "EXCHANGE",
    entity: "",
    entities: "",
    notes: "",
  });
  const MAX_LENGTH = 500000; //500000
  const sessionUser = useUser();

  // const signalsEntities = useQuery(["tower_signals_entities"], async () => {
  //   const response = await ApiService.get("tower/signals-entities", {
  //     time: moment().unix(),
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   const result = await response.json();

  //   return result;
  // });

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
      dataTableRef.current = null;
    }

    window
      .$(document)
      .on("click", "table#tower_signals_entities .btn-edit", function () {
        var record = JSON.parse(window.$(this).attr("data-row"));
        setSignalsEntity({
          ...record,
          entities: "",
        });
        window.$("#add-tower_signals_entity-modal").modal("show");
      });

    window
      .$(document)
      .on("click", "table#tower_signals_entities .btn-delete", function () {
        var record = JSON.parse(window.$(this).attr("data-row"));
        setSignalsEntity({
          ...record,
          entities: "",
        });
        window.$("#delete-tower-signals-entity-modal").modal("show");
      });
  }, []);

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [[0, "desc"]],
          lengthMenu: [10, 100, 500, 1000],
          pageLength: 100,
          searching: true,
          processing: true,
          serverSide: true,          
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/tower/signals-entities`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.filters = {
                signal: window.$("select[name=filter_signal]").val(),
              };
              // data.query = props.query;
            },
          },
          columns: [
            { data: "id", name: "id" },
            { data: "signal", name: "signal" },
            { data: "entity", name: "entity" },
            { data: "notes", name: "notes" },
            { data: "created_at", name: "created_at" },
            {
              data: "id",
              name: "actions",
              render: function (data, type, row) {
                return `<button
                class="btn btn-sm btn-success btn-edit"
                data-row='${JSON.stringify(row)}'               
              >
              <i class="fa fa-edit"></i>
                
              </button>
              &nbsp;
              <button
                class="btn btn-sm btn-danger btn-delete"
                data-row='${JSON.stringify(row)}'     
              >
              <i class="fa fa-times"></i>
                
              </button>
              `;
              },
            },
          ],
          columnDefs: [
            {
              targets: 4,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.created_at, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.created_at).fromNow());
              },
            },
            // {
            //   targets: 4,
            //   createdCell: function (
            //     cell,
            //     cellData,
            //     rowData,
            //     rowIndex,
            //     colIndex
            //   ) {
            //     window.$(cell).empty();
            //     var data = `${moment
            //       .tz(rowData.created_at, "UTC")
            //       .format("YYYY-MM-DD HH:mm:ss")}`;

            //     window.$(cell).prepend(data);
            //   },
            // },
          ],
          dom: "Blfrtip",
          buttons: [{ extend: "csv", title: `Tower Entities` }],
        });
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, []);

  const handleAddClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.put("tower/signals-entities", {
        id: signalsEntity.id,
        signal: signalsEntity.signal,
        entity: signalsEntity.entity,
        entities: csvToArray(signalsEntity.entities),
        notes: signalsEntity.notes,
      });
      //if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      window.$("#add-tower_signals_entity-modal").modal("hide");
      dataTableRef.current.ajax.reload(null, false);
      window.$.toast({
        heading: "Success",
        text: "Process Successfully completed",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const deleteSignalsEntity = async (confirmation = false) => {
    try {
      if (!confirmation) {
        return window.$("#status-toggle-modal").modal("show");
      }

      let response = await ApiService.delete("tower/signals-entities", {
        id: signalsEntity.id,
      });

      response = await response.json();

      if (!response || !response.status || response.status !== "ok") {
        throw Error(response.message ?? "Unable to process");
      }

      // setSignalsEntity({
      //   id:null,
      //   signal:null,
      //   entity:"",
      //   entities:null
      // });
      dataTableRef.current.ajax.reload(null, false);
      window.$.toast({
        heading: "Success",
        text: `Deleted`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Delete Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    //const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    //const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const rows = str.split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows
      .filter(function (row) {
        if (row === "") return false;

        return true;
      })
      .map(function (row) {
        const values = row.split(delimiter);
        return values;
        //   const el = headers.reduce(function (object, header, index) {
        //     object[header] = values[index];
        //     return object;
        //   }, {});
        //   return el;
      });

    // return the array
    return arr;
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
        <CardMenu {...componentProps} {...props} />
          {sessionUser.level == "level2" ||
          sessionUser.role == "super_admin" ? (
            <div className="float-sm-end">
              <button
                class="btn btn-sm btn-success me-1"
                type="button"
                onClick={() => {
                  setSignalsEntity((prevState) => ({
                    ...prevState,
                    id: null,
                    signal: window.$("select[name=filter_signal]").val(),
                    entity: "",
                    notes: "",
                  }));
                  window.$("#add-tower_signals_entity-modal").modal("show");
                }}
              >
                Add
              </button>
            </div>
          ) : null}
          
          <h4 className="header-title">
            Tower Entities
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This shows the details Tower  Entities."
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div class="form-floating mb-3">
                <select
                  className="form-select form-select-sm"
                  name="filter_signal"
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      signal: e.target.value,
                    }));
                    dataTableRef.current.ajax.reload(null, false);
                  }}
                  defaultValue={filters.signal}
                >
                  <option value="ALL">ALL</option>
                  <option value={"EXCHANGE"}>EXCHANGE</option>
                  <option value={"SYSTEM_ACCOUNT"}>SYSTEM_ACCOUNT</option>
                  <option value={"BOT_NETWORK_HUB"}>BOT_NETWORK_HUB</option>
                  <option value={"HIGH_RISK_TRANSFER_HUB_AUTO"}>
                    HIGH_RISK_TRANSFER_HUB_AUTO
                  </option>
                  <option value={"HIGH_RISK_STAKER_AUTO"}>
                    HIGH_RISK_STAKER_AUTO
                  </option>
                  {/* <option value="SENDSINK">SENDSINK</option>
                  <option value="WAXSEND">WAXSEND</option>
                  <option value="NFTRCV">NFTRCV</option>
                  <option value="CREATED">CREATED</option> */}
                </select>
                <label for="floatingSignal">Type</label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className={`alert`}>
                  {filters.signal}:&nbsp;
                  {
                    {
                      ALL: "This lists all",
                      // SENDSINK:
                      //   "This list of accounts will be used as sink addresses. Transfers to sink will result in a bad score.",
                      // WAXSEND:
                      //   "This list of accounts will be used as waxsend addresses. Transfers to waxsend will result in a bad score.",
                      // NFTRCV:
                      //   "List of accounts from which NFT transfers are not considered as direct transfers. ",
                      // CREATED:
                      //   "List of accounts from which created accounts are considered as valid. ",
                      EXCHANGE: "List of exchange accounts whitelisted",
                      SYSTEM_ACCOUNT: "List of system accounts whitelisted",
                      BOT_NETWORK_HUB:
                        "List of bot network hub accounts blacklisted",
                      HIGH_RISK_TRANSFER_HUB_AUTO:
                        "List of auto generated transfer hub accounts blacklisted",
                      HIGH_RISK_STAKER_AUTO:
                        "List of auto generated staked from accounts blacklisted",
                    }[filters.signal]
                  }
                </div>
              </div>
            </div>

            {/* <div className="float-sm-end">
                <form className="d-flex align-items-center flex-wrap">
                  <div className="form-check me-2">
                    <select
                      className="form-select form-select-sm"
                      name="filter_signal"
                      onChange={(e) => {
                        setFilters((prevState) => ({
                          ...prevState,
                          signal: e.target.value,
                        }));
                        dataTableRef.current.ajax.reload(null, false);
                      }}
                      defaultValue={filters.signal}
                    >
                      <option value="all">All</option>
                      <option value="SENDSINK">SENDSINK</option>
                      <option value="WAXSEND">WAXSEND</option>
                    </select>
                  </div>
                </form>
              </div> */}
          </div>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="tower_signals_entities"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Entity</th>
                <th>Notes</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* {signalsEntities.data &&
                signalsEntities.data.map((signalsEntity, index) => {
                  return (
                    <tr key={index}>
                      <td>{signalsEntity.id}</td>
                      <td>{signalsEntity.signal}</td>
                      <td>{signalsEntity.entity}</td>
                      <td>{signalsEntity.created_at}</td>
                      <td>
                        <button
                          className={`btn btn-sm btn-success`}
                          onClick={() => {
                            setSignalsEntity(signalsEntity);
                            window
                              .$("#add-tower_signals_entity-modal")
                              .modal("show");
                          }}
                        >
                          Edit
                        </button>
                        &nbsp;
                        <button
                          className={`btn btn-sm btn-danger`}
                          onClick={() => {
                            setSignalsEntity(signalsEntity);
                            window
                              .$("#delete-tower-signals-entity-modal")
                              .modal("show");
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })} */}

              {/* {signalsEntities.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null} */}
            </tbody>
          </table>
          {/* {signalsEntities.isError ? <Error500 /> : null} */}
        </div>
      </div>

      <div
        id="add-tower_signals_entity-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                {" "}
                {signalsEntity.id ? `Edit ID: ${signalsEntity.id}` : "Add"}
              </h4>
            </div>
            <div className="modal-body">
              <div class="mb-3">
                <label for="signal" class="form-label">
                  Type
                </label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    setSignalsEntity((prevState) => ({
                      ...prevState,
                      signal: e.target.value,
                    }))
                  }
                  value={signalsEntity.signal}
                >
                  <option value={""}>--Select--</option>
                  <option value={"EXCHANGE"}>EXCHANGE</option>
                  <option value={"SYSTEM_ACCOUNT"}>SYSTEM_ACCOUNT</option>
                  <option value={"BOT_NETWORK_HUB"}>BOT_NETWORK_HUB</option>
                  <option value={"HIGH_RISK_TRANSFER_HUB_AUTO"}>
                    HIGH_RISK_TRANSFER_HUB_AUTO
                  </option>
                  <option value={"HIGH_RISK_STAKER_AUTO"}>
                    HIGH_RISK_STAKER_AUTO
                  </option>
                  {/* <option value={"SENDSINK"}>SENDSINK</option>
                  <option value={"WAXSEND"}>WAXSEND</option>
                  <option value={"NFTRCV"}>NFTRCV</option>
                  <option value={"CREATED"}>CREATED</option> */}
                </select>
              </div>
              {signalsEntity.id ? (
                <div class="mb-3">
                  <label for="entity" class="form-label">
                    Entity
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="entity"
                    placeholder="entity"
                    value={signalsEntity.entity}
                    required
                    onChange={(e) =>
                      setSignalsEntity((prevState) => ({
                        ...prevState,
                        entity: e.target.value,
                      }))
                    }
                  />
                </div>
              ) : null}
              {!signalsEntity.id ? (
                <div class="mb-3">
                  <label for="entities" class="form-label">
                    Entities
                  </label>
                  <textarea
                    className="form-control"
                    style={{ height: "100px" }}
                    value={signalsEntity.entities}
                    onChange={(e) => {
                      const text = e.target.value;
                      if (text.length >= MAX_LENGTH) {
                        window.$.toast({
                          heading: "Error",
                          text: "Entities limit reached",
                          position: "top-right",
                          icon: "error",
                        });
                      }

                      setSignalsEntity((prevState) => ({
                        ...prevState,
                        entities: text.substring(0, MAX_LENGTH),
                      }));
                    }}
                  />
                  <span class="form-text m-b-none">
                    {Math.max(0, MAX_LENGTH - signalsEntity.entities.length)}{" "}
                    characters left
                  </span>
                </div>
              ) : null}

              <div class="mb-3">
                <label for="notes" class="form-label">
                  Notes
                </label>
                <textarea
                  className="form-control"
                  style={{ height: "100px" }}
                  value={signalsEntity.notes || ""}
                  onChange={(e) => {
                    setSignalsEntity((prevState) => ({
                      ...prevState,
                      notes: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleAddClick}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#add-tower_signals_entity-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="delete-tower-signals-entity-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div>
            <div className="modal-body">Are you sure you want to delete</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.$("#delete-tower-signals-entity-modal").modal("hide");
                  deleteSignalsEntity(true);
                }}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#delete-tower-signals-entity-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTowerSignalsEntities;
