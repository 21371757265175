import ApiService from "../../app/services/api";

export const NftsService = {
  getStats: async (type, filters) => {
    const response = await ApiService.get("nfts/stats/" + type, filters);
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  getNft: async (assetId) => {
    const response = await ApiService.get("nfts/" + assetId);
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  getNftLogs: async (assetId) => {
    const response = await ApiService.get("nfts/" + assetId + "/logs");
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  getNftTransfers: async (assetId) => {
    const response = await ApiService.get("nfts/" + assetId + "/transfers");
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  getNftMining: async (assetId, type) => {
    const response = await ApiService.get("logmines/nft-mining", {
      asset_id: assetId,
      type: type
    });
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  checkTainted: async (assetId) => {
    const response = await ApiService.get("nfts/" + assetId + "/check-tainted");
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return;
    }
    return response.json();
  },
  markTainted: async (assetId, data) => {
    const response = await ApiService.post("nfts/" + assetId + "/mark-tainted", data);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  },
  removeTainted: async (assetId) => {
    const response = await ApiService.get("nfts/" + assetId + "/remove-tainted");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  },
};

export default NftsService;
