import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";
import { setReport } from "app/state/session";

export const NftsBoughtSold = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/nfts-bought-sold",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "nfts_bought_sold" },
  };
  const helpTexts = useHelpTexts();
  const dispatch = useDispatch();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  // const transfers = useQuery([`account_transfers_${props.account}`, props.account, filters], async () => {
  //   const response = await AccountService.getTransfers(props.account, {
  //       startDate: filters.dates.startDate,
  //       endDate: filters.dates.endDate,
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100],
          searching: false,
          processing: true,
          serverSide: true,
          pagingType: "simple",
          info: false,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/account/nfts-bought-sold/${props.account}`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = filters.dates.startDate;
              data.endDate = filters.dates.endDate;
            },
          },
          columns: [
            { data: "updated", name: "updated" },
            { data: "nft_id", name: "nft_id" },
            { data: "buyer", name: "buyer" },
            { data: "seller", name: "seller" },           
            { data: "price", name: "price" },
          ],
          columnDefs: [
            {
              targets: 0,
              orderable: true,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(parseInt(rowData.updated), "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr(
                    "title",
                    moment(parseInt(rowData.updated)).fromNow()
                  );
              },
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/nfts/${rowData.nft_id}" target="_blank">${rowData.nft_id}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 2,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.buyer}" target="_blank">${rowData.buyer}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 3,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.seller}" target="_blank">${rowData.seller}</a>`;

                window.$(cell).prepend(data);
              },
            },            
            {
              targets: 4,
              orderable: true,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${(rowData.price / Math.pow(10, 8)).toFixed(
                  2
                )} WAX`;

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `NFTs Bought/Sold by ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Buyer")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Seller")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();

      // dataTableRef.current.on("preDraw", function (e) {
      //   const info = window.$(tableRef.current).DataTable().page.info();
      //   if (info.start >= 10000) {
      //     dispatch(
      //       setReport({
      //         type: "reportTlmTransfers",
      //         format: "csv",
      //         filters: {
      //           start_datetime: moment(filters.dates.startDate)
      //             .startOf("day")
      //             .format("YYYY-MM-DD HH:mm:ss"),
      //           end_datetime: moment(filters.dates.endDate)
      //             .endOf("day")
      //             .format("YYYY-MM-DD HH:mm:ss"),
      //           account: props.account,
      //         },
      //       })
      //     );
      //     window.$("#large-result-window-elasticsearch-modal").modal("show");
      //     return false;
      //   }

      //   return true;
      // });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.account, filters]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("nfts_bought_sold", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("nfts_bought_sold", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `NFTs Bought/Sold`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `NFTs Bought/Sold`}{" "}
          by {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the latest NFTs Bought/Sold by this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="nfts_bought_sold"
        >
          <thead>
            <tr>
              <th className="no-sort">Date</th>
              <th className="no-sort">NFT</th>
              <th className="no-sort">
                Buyer &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                Seller &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>             
              <th className="no-sort">Price</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        {/* {transfers.isError ? <Error500 /> : null} */}

        {/* <button
          type="buttom"
          className="btn btn-success mb-2 mb-sm-0 mx-auto d-block mt-2"
          onClick={() => {
            dataTableRef.current.page("next").draw("page");
          }}
        >
          Load Next
        </button> */}
      </div>
    </div>
  );
};

export default NftsBoughtSold;
