import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Emitter from "app/services/emitter";
import ApiService from "app/services/api";
import moment from "moment";
import { useDispatch } from "react-redux";
//import { setDashboardComponentsCount } from "app/state/session";
import { useBetween } from 'use-between';

export const useLoggedIn = () => {
  const loggedIn = useSelector((state) => state.session.loggedIn);
  return loggedIn;
};

export const useUser = () => {
  const user = useSelector((state) => state.session.user);
  return user;
};

export const useFilters = () => {
  const filters = useSelector((state) => state.session.filters);
  return filters;
};

export const useReport = () => {
  const report = useSelector((state) => state.session.report);
  return report;
};

export const useHelpTexts = () => {
  const helpTexts = useSelector((state) => state.session.helpTexts);
  return helpTexts;
};

// export const useDashboardComponentsCount = () => {
//   const [count, setCount] = useState(0);
   
//   useEffect(() => {
//     const fetchDashboardComponentsCount = async () => {
//       const response = await ApiService.get("me/dashboard/components/count", {
//         timestamp: moment.utc().valueOf(),
//       })
//         .then((response) => response.json())
//         .catch();

//       if (response) setCount(response.dashboardComponentsCount);
//     };

//     Emitter.on("ADD_TO_DASHBOARD_SUCCESS", (newValue) => {
//       fetchDashboardComponentsCount();
//     });

//     Emitter.on("REMOVE_FROM_DASHBOARD_SUCCESS", (newValue) => {
//       fetchDashboardComponentsCount();
//     });

//     fetchDashboardComponentsCount();

//     return () => {
//       Emitter.off("ADD_TO_DASHBOARD_SUCCESS");
//       Emitter.off("REMOVE_FROM_DASHBOARD_SUCCESS");
//     };
//   }, []);

//   return count;
// };

// export const useSharedDashboardComponentsCount = ()  => useBetween(useDashboardComponentsCount);