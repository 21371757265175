import { Link } from "react-router-dom";

function Error500() {

  return (
      <div class="account-pages my-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="card">

                            <div class="card-body p-4">
                            
                                <div class="text-center w-75 m-auto">
                                    <div class="auth-logo">
                                        <Link to="/admin/dashboard" className="logo logo-dark text-center">
                                            <span className="logo-sm">
                                                <img src="/assets/images/logo-sm-dark.png" alt="" height="24" />
                                            </span>
                                            <span className="logo-lg">
                                                <img src="/assets/images/logo-dark.png" alt="" height="20" />
                                            </span>
                                        </Link>

                                        <Link to="/admin/dashboard" className="logo logo-light text-center">
                                            <span className="logo-sm">
                                                <img src="/assets/images/logo-sm.png" alt="" height="24" />
                                            </span>
                                            <span className="logo-lg">
                                                <img src="/assets/images/logo-light.png" alt="" height="20" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                                <div class="text-center mt-4">
                                    <h1 class="text-error">500</h1>
                                    <h3 class="mt-3 mb-2">Internal Server Error</h3>
                                    <p class="text-muted mb-3">Why not try refreshing your page? or you can contact <a href="" class="text-dark"><b>Support</b></a></p>

                                    <Link to="/admin/dashboard" class="btn btn-success waves-effect waves-light">Back to Home</Link>
                                </div>

                            </div>        
                        </div>

                    </div>
                </div>
            </div>
        </div>
  );
}

export default Error500;
