import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "admin/components/card-menu";
import moment from "moment";
import { Graph3d, DataSet } from "vis-graph3d/standalone";
import { Skeleton } from "@material-ui/lab";

export const AdminTowerMinersGraph3d = ({ filters }) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "views/tower/debug/components/tower_miners_scatter3d",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const visJsGraphRef = useRef();
  var visJsGraph3dRef = useRef();

  const [pointSignal, setPointSignal] = useState("DEFAULT");
  const [showLinear, setShowLinear] = useState(false);

  const signalsResponse = useQuery(["tower_signals"], async () => {
    const response = await ApiService.get("tower/tower-signals", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  const results = useQuery(
    ["tower_miners_graph3d", filters, pointSignal],
    async () => {
      const response = await ApiService.get("tower/tower-miners-graph3d", {
        ...filters,
        pointSignal: pointSignal,
        timestamp: moment().utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  const [records, setRecords] = useState([]);

  useEffect(() => {
    //chartComponent.current.chart.showLoading();
    if (!results.data) return;

    //if (visJsGraph3dRef.current) {
    //visJsGraph3dRef.current.destroy();
    // }

    // Create the data and options for the 3D graph
    const data = new DataSet([]);

    for (var record of results.data) {
      //var style = record.tower_bot==1?"#FF0000" : "#058DC7";
      data.add({
        x: record.total_tlm,
        y: record.total_mines,
        z: record.bot_score,
        record: record,
        style: record.tower_bot === 1 ? "#FF0000" : "#058DC7",
      });
    }

    if (results.data.length === 0) {
      data.add({
        x: 0,
        y: 0,
        z: 0,
      });
    }

    // specify options
    var options = {
      width: "100%",
      height: "100%",
      style: "dot-color",
      xLabel: "TLM",
      yLabel: "Mines",
      zLabel: "Bot Score",
      showLegend: true,
      //showPerspective: true,
      //showGrid: true,
      // keepAspectRatio: true,
      // verticalRatio: 1.0,
      tooltip: function (point) {
        // parameter point contains properties x, y, z
        return `<b>Data</b><br>
                      TLM: ${point.x}<br>
                      Mines: ${point.y}<br>					            
                      Miner: ${point.data.record.miner}<br>
                      Score: ${point.data.record.score}<br>
                      Bot Score: ${point.data.record.bot_score}<br>
                      Yeomen Score: ${point.data.record.yeomen_score || "N/A"}`;
      },
      //legendLabel: 'distance',
      cameraPosition: {
        horizontal: -5.41,
        vertical: 0,
        //distance: 0.1,
      },
      gridColor: "#94A0AD",
      axisColor: "#94A0AD",
      dotSizeRatio: 0.004,
      valueMin: 0,
      valueMax: 10,
    };

    visJsGraph3dRef.current = new Graph3d(visJsGraphRef.current, data, options);

    visJsGraph3dRef.current.on("click", (point) => {
      setRecords((prevRecords) => [...prevRecords, point.record]);
    });

    //chartComponent.current.chart.hideLoading();
  }, [results.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            {/* <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div> */}
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={(e) => {
                  setPointSignal(e.target.value);
                }}
                value={pointSignal}
              >
                <option value="DEFAULT">--DEFAULT--</option>
                {signalsResponse.data &&
                  signalsResponse.data.map((signal, index) => (
                    <option key={index} value={signal.signal}>
                      {signal.signal}
                    </option>
                  ))}
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Tower Miners Graph3d`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Tower Miners Graph3d"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {results.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3">
            {results.isLoading ? (
              <>
                <div className="text-center">
                  <div className="spinner-border text-primary m-" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <div
                ref={visJsGraphRef}
                style={{ width: "100%", height: "600px" }}
              ></div>
            )}
          </div>
        )}

        {records.length > 0 ? (
          <table class="table dt-responsive  w-100">
            <thead>
              <tr>
                <th>Miner</th>
                <th>TLM</th>
                <th>Mines</th>
                <th>Score</th>
                <th>Bot Score</th>
                <th>Yeomen Score</th>
              </tr>
            </thead>
            <tbody>
              {records.reverse().map((record, index) => (
                <tr key={index}>
                  <td>{record.miner}</td>
                  <td>{record.total_tlm}</td>
                  <td>{record.total_mines}</td>
                  <td>{record.score}</td>
                  <td>{record.bot_score}</td>
                  <td>{record.yeomen_score || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default AdminTowerMinersGraph3d;
