import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const SharedNewUserWAX = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/shared-new-users-wax",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const accounts = useQuery(
    [`shared_new_users_wax${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getSharedNewUsers(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!accounts.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500, 1000],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            {
              extend: "csv",
              title: `Shared new users WAX for ${props.account}`,
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Wallet")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [accounts.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#shared_new_users_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("shared_new_users", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Shared new users WAX`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Shared new users WAX`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Shared new users WAX tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {accounts.data ? (
          <>
            {accounts.data.creator != "" ? (
              <>
                <p>
                  Creator:{" "}
                  <Link
                    to={`/admin/account-scanner/${accounts.data.creator}`}
                    target="_blank"
                  >
                    {accounts.data.creator}
                  </Link>
                  &nbsp;
                  <a
                    href={`${config.explorer.url}/account/${accounts.data.creator}`}
                    target="_blank"
                  >
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                </p>
                <p>
                  Created On: {accounts.data.created_at}&nbsp;
                  <a
                    href={`${config.explorer.url}/transaction/${accounts.data.trx_id}`}
                    target="_blank"
                  >
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                </p>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
          </>
        )}
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="shared_new_users"
        >
          <thead>
            <tr>
              <th className="no-sort">
                Wallet &nbsp;
                <Link to="" onClick={copyWalletsToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy accounts to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Creator</th>
              <th>Created On</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {accounts.data &&
              accounts.data.accounts.map((account, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${account.name}`}
                        target="_blank"
                      >
                        {account.name}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${account.creator}`}
                        target="_blank"
                      >
                        {account.creator}
                      </Link>
                    </td>
                    <td>
                      {account.time}&nbsp;
                      <a
                        href={`${config.explorer.url}/transaction/${account.trx_id}`}
                        target="_blank"
                      >
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </td>
                    <td className="text-end">{account.cost} WAX</td>
                  </tr>
                );
              })}

            {accounts.isFetching ? (
              <>
                {new Array(5).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {accounts.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default SharedNewUserWAX;
