import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import ApiService, { apiHeaders } from "app/services/api";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import { useState } from "react";
import { useUser } from "app/hooks/useSession";
import moment from "moment";
import { useCallback } from "react";
import config from "app/config";
import CardMenu from "admin/components/card-menu";

export const AdminTowerSignalsEntitiesMultiple = (props) => {
  const componentProps = {
    component: "views/tower/manage/components/tower-signals-entities-multiple",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "tower_signals_entities_multiple" },
  };
  const tableRef = useRef();
  const dataTableRef = useRef();

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
      dataTableRef.current = null;
    }

  }, []);

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500, 1000],
          pageLength: 100,
          searching: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/tower/signals-entities-multiple`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              
            },
          },
          columns: [           
            
            { data: "entity", name: "entity" },
            { data: "signals_count", name: "signals_count" },
            { data: "whitelist_signals_count", name: "whitelist_signals_count" },
            { data: "blacklist_signals_count", name: "blacklist_signals_count" },
            { data: "signals", name: "signals" }
          ],
          columnDefs: [           
          ],
          dom: "Blfrtip",
          buttons: [{ extend: "csv", title: `Tower Entities` }],
        });
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, []);


  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            Tower Entities in multiple categories
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This shows the details Tower  Entities in multiple categories."
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="tower_signals_entities"
          >
            <thead>
              <tr>
                <th>Entity</th>
                <th>Count</th>
                <th>Whitelist Count</th>
                <th>Blacklist Count</th>
                <th>Types</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminTowerSignalsEntitiesMultiple;
