import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import config from "app/config";
import Error500 from "admin/components/errors/error-500";
import { useFilters, useHelpTexts } from "app/hooks/useSession";

export const PendingTransfers = (props) => {
  const componentProps = {
    component: "components/teleport/pending-transfers",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const transfers = useQuery(["pending_transfers2"], async () => {
    const response = await ApiService.get("teleport/pending-transfers2", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[0, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Pending transfers`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the pending WAX to BSC or BSC to WAX transfers."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="wax_to_bsc_transfers"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Age</th>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
              <th>Oracles signed</th>
              <th>Direction</th>
              <th>Teleport ID</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transfers.data &&
              transfers.data.map((transfer, index) => {
                return (
                  <tr key={index}>
                    <td title={moment(transfer.date).fromNow()}>
                      {moment
                        .tz(transfer.date, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>{moment(transfer.date).fromNow()}</td>
                    {transfer.direction === "WAX to BSC" ? (
                      <>
                        <td>
                          <Link to={`/admin/account-scanner/${transfer.from}`}>
                            {transfer.from}
                          </Link>
                        </td>
                        <td>
                          <a
                            href={`https://bscscan.com/address/0x${transfer.to}`}
                            target="_blank"
                          >
                            0x{transfer.to.substring(0, 5)}..
                          </a>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <a
                            href={`https://bscscan.com/address/0x${transfer.from}`}
                            target="_blank"
                          >
                            0x{(transfer.from||'').substring(0, 5)}..
                          </a>
                        </td>
                        <td>
                          <Link to={`/admin/account-scanner/${transfer.to}`}>
                            {transfer.to}
                          </Link>
                        </td>
                      </>
                    )}
                    <td className="text-end">{transfer.amount}</td>
                    <td className="text-end">
                      {transfer.number_of_oracle_signatures_signed}
                    </td>
                    <td>{transfer.direction}</td>
                    {transfer.direction === "WAX to BSC" ? (
                      <>
                        <td>
                          {transfer.source_trx ? (
                            <a
                              href={`${config.explorer.url}/transaction/${transfer.source_trx}`}
                              target="_blank"
                            >
                              {transfer.teleport_id}
                            </a>
                          ) : (
                            transfer.teleport_id
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {transfer.source_trx ? (
                            <a
                              href={`https://bscscan.com/tx/${transfer.source_trx}`}
                              target="_blank"
                            >
                              {transfer.teleport_id}
                            </a>
                          ) : (
                            transfer.teleport_id
                          )}
                        </td>
                      </>
                    )}
                    <td>{transfer.status}</td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default PendingTransfers;
