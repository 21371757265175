import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import DailyActiveUsersChart from "../components/users/daily-active-users-chart";
import MonthlyActiveUsersChart from "../components/users/monthly-active-users-chart";
import Highcharts from "highcharts";
import TlmPriceChart from "admin/components/tlm/tlm-price-chart";
import BotsChart from "./reports/bots-chart";
import AvgMinePerDayPerPlanet from "admin/components/mining/avg-mine-per-day-per-planet";

function AdminDashboardBotReport() {
  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$(".button-menu-mobile").click(function () {
      setTimeout(() => {
        window.$(Highcharts.charts).each(function (i, chart) {
          chart.reflow();
        });
      }, 1000);
    });
  });

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-bot-report">
        Bot Report
      </BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <BotsChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailyActiveUsersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AvgMinePerDayPerPlanet />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmPriceChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <MonthlyActiveUsersChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardBotReport;
