import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const NodeEdges = (props) => {
  const componentProps = {
    component: "components/forensics/node-edges",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "node_edges" }
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const account = props.account;
  const nodeEdges = props.data;

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!nodeEdges) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `NFT transfers` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [nodeEdges]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#latest_teleports_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("node_edges", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#latest_teleports_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("node_edges", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          NFT Transfers for {account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Help Text"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="node_edges"
        >
          <thead>
            <tr>
              <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>
                To&nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {nodeEdges &&
              nodeEdges.map((nodeEdge, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/admin/account-scanner/${nodeEdge.from}`}>
                        {nodeEdge.from}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admin/account-scanner/${nodeEdge.to}`}>
                        {nodeEdge.to}
                      </Link>
                    </td>
                    <td>{nodeEdge.value}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NodeEdges;
