import { useEffect, useState } from "react";
import SocketService from "../../../app/services/socket";

export const AdminSelfTestNumFailedJobs = () => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    var socket = SocketService.getSocket("/selfTest");

    if (socket.connected) {
      socket.emit("join", "numFailedJobs");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "numFailedJobs");
      });
    }

    socket.on("numFailedJobs", function (data) {
      setValue(data);
    });

    return () => {
      socket.off("numFailedJobs");
      socket.emit("leave", "numFailedJobs");
    };
  }, []);
  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          <span data-plugin="counterup">
            {parseInt(value).toLocaleString()}
          </span>
        </h3>
        <p className="text-muted mb-0">Failed Jobs</p>
      </div>
    </div>
  );
};

export default AdminSelfTestNumFailedJobs;
