import { useQuery } from "react-query";
import { useFilters } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import StatBox from "./stat-box";
import moment from "moment";

export const Staked = (props) => {
  const filters = useFilters();

  const stats = useQuery([`account_chain_${props.account}`, props.account], () => {
    return AccountService.getFromChain(props.account);
  });

  return (
          <div className="widget-simple text-center card">
              <div className="card-body">
                {stats.status === "loading" ? (
                    <div className="text-end">
                        <h3 className="mb-1 mt-0">
                        <Skeleton />
                        </h3>
                        <p className="text-muted mb-0">Staked</p>
                    </div>
                ) : (
                    <StatBox
                    data={stats.data.staked + " WAX"}
                    title={"Staked"}
                    />
                )}
              </div>
          </div>
  );
};

export default Staked;
