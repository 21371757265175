import { useBetween } from 'use-between';
import ApiService from 'app/services/api';
import { useEffect,useState } from 'react';
import moment from 'moment';
import Emitter from "app/services/emitter";

const useCount = () => {
  const [count, setCount] = useState(0);
   
  useEffect(() => {
    const fetchDashboardComponentsCount = async () => {
      const response = await ApiService.get("me/dashboard/components/count", {
        timestamp: moment.utc().valueOf(),
      })
        .then((response) => response && response.json())
        .catch();

      if (response) setCount(response.dashboardComponentsCount);
    };

    Emitter.on("ADD_TO_DASHBOARD_SUCCESS", (newValue) => {
      fetchDashboardComponentsCount();
    });

    Emitter.on("REMOVE_FROM_DASHBOARD_SUCCESS", (newValue) => {
      fetchDashboardComponentsCount();
    });

    fetchDashboardComponentsCount();

    return () => {
      Emitter.off("ADD_TO_DASHBOARD_SUCCESS");
      Emitter.off("REMOVE_FROM_DASHBOARD_SUCCESS");
    };
  }, []);

  return count;
};

export const useDashboardComponentsCount = ()  => useBetween(useCount);