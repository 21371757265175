import { useQuery } from "react-query";
import { useFilters } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import StatBox from "./stat-box";

export const TlmBalance = (props) => {
  const filters = useFilters();

  const stats = useQuery([`account_stats_${props.account}`, props.account, filters], () => {
    return AccountService.getStats(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
    });
  });

  return (
          <div className="widget-simple text-center card">
              <div className="card-body">
                {stats.status === "loading" ? (
                    <div className="text-end">
                        <h3 className="mb-1 mt-0">
                        <Skeleton />
                        </h3>
                        <p className="text-muted mb-0">TLM Balance</p>
                    </div>
                ) : (
                    <StatBox
                    data={stats.data?stats.data.tlm_balance:0}
                    title={"TLM Balance"}
                    />
                )}
              </div>
          </div>
  );
};

export default TlmBalance;
