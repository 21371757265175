import ApiService from "app/services/api";
import Emitter from "app/services/emitter";
import moment from "moment";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";

export const TowerBotControlSettings = () => {
  const [settings, setSettings] = useState({
    TOWER_BOTS_PER_DAY_PERCENTAGE: 0,
    next_scheduled_run: null,
    active_miners: 0,
    active_miners_date: null,
  });
  useEffect(() => {
    window.$('[data-toggle="touchspin"]').each(function (idx, obj) {
      var objOptions = window.$.extend({}, {}, window.$(obj).data());
      window
        .$(obj)
        .TouchSpin(objOptions)
        .on("touchspin.on.startspin", function (e) {
          setSettings((prevState) => ({
            ...prevState,
            TOWER_BOTS_PER_DAY_PERCENTAGE: e.target.value,
          }));
        });
    });
  }, []);

  useEffect(() => {
    ApiService.get("tower/bot-control-settings", {
      timestamp: moment().utc().valueOf(),
    }).then(async (response) => {
      const data = await response.json();
      setSettings({
        ...data,
      });
    });
  }, []);

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post("tower/bot-control-settings", {
        TOWER_BOTS_PER_DAY_PERCENTAGE: settings.TOWER_BOTS_PER_DAY_PERCENTAGE,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      Emitter.emit("BOT_CONTROL_SETTINGS_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Updated Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">
          Tower bot control - Limit on number of bots daily
        </h4>

        <div class="row">
          <div class="col-lg-10">
            <div className="mb-2 row">
              <label className="col-md-2 col-form-label" htmlFor="form-range">
                {/* Limit on number of bots daily */}
                Bots Percentage
              </label>
              <div className="col-md-10 align-self-center">
                <input
                  className="form-range"
                  type="range"
                  name="range"
                  value={settings.TOWER_BOTS_PER_DAY_PERCENTAGE}
                  min={0}
                  max={100}
                  step="0.1"
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_BOTS_PER_DAY_PERCENTAGE: e.target.value,
                    }))
                  }
                />
                <p className="text-muted m-0">
                  Max number of miners to block per day based on the percentage
                  of miners. eg: If this is 0, it will skip blocking miners.
                  Expected values range between 0 and 100
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <input
              data-toggle="touchspin"
              value={settings.TOWER_BOTS_PER_DAY_PERCENTAGE}
              //defaultValue="4"
              type="text"
              data-step="0.1"
              data-decimals={2}
              data-bts-postfix="%"
              onChange={(e) =>
                setSettings((prevState) => ({
                  ...prevState,
                  TOWER_BOTS_PER_DAY_PERCENTAGE: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="text-center">
          <h4 class="header-title text-muted m-0">
            Time to next bot run:{" "}
            {settings.next_scheduled_run ? (
              <Countdown
                date={settings.next_scheduled_run}
                renderer={({ hours, minutes, seconds, completed }) => {
                  if (completed) {
                    // Render a completed state
                    return "Running";
                  } else {
                    // Render a countdown
                    return (
                      <span>
                        {hours} hours {minutes} minutes {seconds} seconds
                      </span>
                    );
                  }
                }}
              />
            ) : (
              "N/A"
            )}
          </h4>
          <p class="text-muted m-0">
            Bot cap set to maximum of{" "}
            {new Intl.NumberFormat().format(
              parseInt(
                (settings.active_miners *
                  settings.TOWER_BOTS_PER_DAY_PERCENTAGE) /
                  100
              )
            )}
          </p>
          <p class="text-muted m-0">
            {" "}
            (based on {settings.TOWER_BOTS_PER_DAY_PERCENTAGE}% of{" "}
            {new Intl.NumberFormat().format(settings.active_miners)} miners on{" "}
            {moment(settings.active_miners_date).format("MMM Do")})
          </p>
          <div className="mt-2">
            <button
              class="btn btn-primary waves-effect waves-light me-1"
              type="submit"
              onClick={handleUpdateClick}
            >
              Update settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TowerBotControlSettings;
