import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import { Link } from "react-router-dom";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";

export const MissionsNft = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/missions-nft",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const missions = useQuery(
    [`account_missions_nft_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getMissionsNft(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!missions.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    setTimeout(() => {
      window.$.Components.init();
    });
  }, [missions.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Missions NFT`}{" "}
            for {props.account}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "This shows the NFTs gained by this account"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="missions-nft"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>NFT</th>
                <th>No. of NFTs</th>
                <th>Rarity</th>
                <th>Player spaceships</th>
                <th>Join time</th>
                <th>Claim time</th>
              </tr>
            </thead>
            <tbody>
              {missions.data &&
                missions.data.map((mission, index) => {
                  return (
                    <tr key={index}>
                      <td>{mission.mid}</td>
                      <td>{mission.name}</td>
                      <td>
                        <a
                          href={`https://bscscan.com/address/${mission.nft_address}`}
                          target="_blank"
                        >
                          <img
                            src={mission.nft_image}
                            alt="nfts-pic"
                            style={{ width: "50px" }}
                          />
                        </a>
                      </td>
                      <td className="text-end">{mission.nft_count}</td>
                      <td>{mission.rarity}</td>
                      <td className="text-end">{mission.spaceship_count}</td>
                      <td title={moment(mission.join_time).fromNow()}>
                        {moment
                          .tz(mission.join_time, "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      {mission.status == "claimed" ? (
                        <td title={moment(mission.claim_time).fromNow()}>
                          {moment
                            .tz(mission.claim_time, "UTC")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                      ) : (
                        <td>Not claimed</td>
                      )}
                    </tr>
                  );
                })}

              {missions.isFetching ? (
                <>
                  {new Array(5).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {missions.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default MissionsNft;
