import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import AdminRealtimeLogminesMiningStats from "../../components/realtime/logmines/mining_stats";
import AdminRealtimeLogminesTlmStats from "../../components/realtime/logmines/tlm_stats";
import AdminRealtimeLogminesAccountStats from "../../components/realtime/logmines/account_stats";

import AdminRealtimeAtomicassetsNfts from "../../components/realtime/atomicassets/nfts";
import AdminRealtimeAtomicassetsMiningTemplates from "../../components/realtime/atomicassets/mining-templates";
import AdminRealtimeAtomicassetsMaxMintedTemplates from "../../components/realtime/atomicassets/max-minted-templates";
import AdminRealtimeLogminesPlanetsNumMines from "../../components/realtime/logmines/planets_num_mines";
// import AdminRealtimeLogminesMiningActionsChart from "../../components/realtime/logmines/mining_actions_chart";
import AdminRealtimeLogminesMiningActionsHistogramChart from "../../components/realtime/logmines/mining_actions_histogram_chart";
import AdminRealtimeGuardStats from "../../components/realtime/guard/guard-stats";
import AdminRealtimeTeleportStats from "../../components/realtime/teleport/teleport-stats";
import AdminRealtimeAtomicassetsNftTransfers from "../../components/realtime/atomicassets/nft-transfers";
import AdminRealtimeLogminesMiningActions24hHistogramChart from "admin/components/realtime/logmines/mining_actions_24h_histogram_chart";

export const AdminRealtime = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/realtime">Realtime</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesMiningStats/>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesTlmStats/>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesAccountStats/>
        </div>
      </div>      
     
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesPlanetsNumMines />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeAtomicassetsNfts />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeAtomicassetsMiningTemplates />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeAtomicassetsMaxMintedTemplates />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesMiningActionsChart />
        </div>
      </div> */}
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesMiningActionsHistogramChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeLogminesMiningActions24hHistogramChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeGuardStats/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeTeleportStats/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminRealtimeAtomicassetsNftTransfers/>
        </div>
      </div>
    </>
  );
};

export default AdminRealtime;
