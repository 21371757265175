import AdminNftsGridCard from "./nfts-grid-card";
import { useInfiniteQuery } from "react-query";
import ApiService from "../../../app/services/api";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { useFilters } from "../../../app/hooks/useSession";
import moment from "moment-timezone";

export const AdminNftsGrid = (props) => {
  const [type, setType] = useState("all");
  const [hideNfts, setHideNfts] = useState(
    props.hideNfts === false ? false : true
  );
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("updated_at_time");
  const filters = useFilters();

  const scrollLoad = props.scrollLoad === false ? false : true;
  const templateId = props.templateId || null;
  const limit = 12;

  const assets = useInfiniteQuery(
    ["nfts", query, hideNfts, type, filters],
    async ({ pageParam = 1 }) => {
      let sort;
      switch (type) {
        case "minted":
          sort = "minted_at_time";
          break;
        case "transferred":
          sort = "transferred_at_time";
          break;
        case "burned":
          sort = "burned_at_time";
          break;
        case "shined":
          sort = "minted_at_time";
          break;
        case "backed":
          sort = "updated_at_time";
          break;
        case "all":
        default:
          sort = "updated_at_time";
          break;
      }
      setSort(sort);

      const response = await ApiService.get("nfts", {
        query: query,
        sort: sort,
        order: "DESC",
        page: pageParam,
        limit: limit,
        filter: JSON.stringify({
          hideNfts: hideNfts,
          type: type,
          templateId: templateId,
          startAtTime: moment
            .tz(filters.dates.startDate, "UTC")
            .startOf("day")
            .valueOf(),
          endAtTime: moment
            .tz(filters.dates.endDate, "UTC")
            .endOf("day")
            .valueOf(),
        }),
      });
      const result = await response.json();

      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => lastPage.nextPage,
      refetchOnWindowFocus: false,
    }
  );

  const loadMoreButtonRef = React.useRef();
 
  /* Scroll load more */
  useEffect(() => {
    if (!scrollLoad || !assets.hasNextPage) {
      return;
    }
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          assets.fetchNextPage();
        }
      })
    );
    const el = loadMoreButtonRef && loadMoreButtonRef.current;
    if (!el) {
      return;
    }
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [loadMoreButtonRef.current, assets.hasNextPage]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-2">
          <div className="col-sm-4">
            <h4>NFTs</h4>
          </div>
          <div className="col-sm-8">
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                <div className="btn-group me-2">
                  <button
                    className="btn btn-success btn-sm dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {(() => {
                      switch (type) {
                        case "minted":
                          return "Minted";
                          break;
                        case "burned":
                          return "Burned";
                          break;
                        case "shined":
                          return "Shined";
                          break;
                        case "transferred":
                          return "Transferred";
                          break;
                        case "backed":
                          return "Backed";
                          break;
                        case "all":
                        default:
                          return "All NFTs";
                          break;
                      }
                    })()}
                    <i className="mdi mdi-chevron-down"></i>
                  </button>
                  <div className="dropdown-menu">
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("all");
                      }}
                    >
                      All NFTs
                    </a>
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("minted");
                      }}
                    >
                      Minted
                    </a>
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("burned");
                      }}
                    >
                      Burned
                    </a>
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("shined");
                      }}
                    >
                      Shined
                    </a>
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("transferred");
                      }}
                    >
                      Transferred
                    </a>
                    <a
                      className="dropdown-item"
                      href="true"
                      onClick={(el) => {
                        el.preventDefault();
                        setType("backed");
                      }}
                    >
                      Backed
                    </a>
                  </div>
                </div>
                <div className="form-check me-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="nftshide-check"
                    defaultChecked={hideNfts}
                    onChange={(el) => setHideNfts(!hideNfts)}
                  />
                  <label className="form-check-label" htmlFor="nftshide-check">
                    Hide initial account NFTs
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="This hides the NFTs issued for new accounts which are primarily a shovel or the male and female human avatar. It is issued for every new account and it is preferable to avoid displaying it when the page loads."
                  style={{ boxShadow: "none" }}
                >
                <i class="fas fa-info-circle"></i>
                </button>
                <div className="me-2">
                  <label htmlFor="nftssearch-input" className="visually-hidden">
                    Search
                  </label>
                  <input
                    type="search"
                    className="form-control border-light"
                    id="nftssearch-input"
                    placeholder="Search..."
                    onChange={(el) => setQuery(el.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          {assets.data ? (
            <>
              {assets.data.pages.map((group, index) => (
                <React.Fragment key={index}>
                  {group.data && group.data.map((asset, index) => (
                    <div className="col-xl-3 col-md-6" key={index}>
                      <AdminNftsGridCard asset={asset} sort={sort} />
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </>
          ) : null}

          {assets.isFetching ? (
            // <div class="col-lg-12">
            //   <p>Loading...</p>
            // </div>
            <>
              {new Array(limit).fill(0).map((group, index) => (
                <div className="col-xl-3 col-md-6" key={index}>
                  <div className="card product-box">
                    <div className="product-img">
                      <div className="p-3">
                        <Skeleton height={330} />
                      </div>
                    </div>
                    <div className="product-info border-top p-3">
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {assets.data ? (
            <>
              {(assets.data.pages &&
                assets.data.pages[0] &&
                assets.data.pages[0].data.length) === 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    <p>No NFTs found</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <div className="text-center my-3">
                        <button
                          href="true"
                          className="btn btn-sm btn-success"
                          ref={loadMoreButtonRef}
                          onClick={() => assets.fetchNextPage()}
                          disabled={
                            !assets.hasNextPage || assets.isFetchingNextPage
                          }
                        >
                          {assets.isFetchingNextPage
                            ? "Loading more..."
                            : assets.hasNextPage
                            ? "Load More"
                            : "Nothing more to load"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdminNftsGrid;
