import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import { Link } from "react-router-dom";
import { copyToClipboard } from "app/utils/datatables";

export const OutgoingTeleports = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/outgoing-teleports",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const transfers = useQuery(
    [`outgoing_teleports_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getOutgoingTeleports(
        props.account,
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Outgoing teleports for ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#outgoing_teleports_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("outgoing_teleports", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Outgoing teleports`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Outgoing teleports`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the outgoing teleports for this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="outgoing_teleports"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>From</th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transfers.data &&
              transfers.data.map((transfer, index) => {
                transfer = transfer._source;
                return (
                  <tr key={index}>
                    <td title={moment(transfer["@timestamp"]).fromNow()}>
                      {moment
                        .tz(transfer["@timestamp"], "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${transfer.act.data.from}`}
                      >
                        {transfer.act.data.from}
                      </Link>
                    </td>
                    <td>
                      <a
                        href={`https://bscscan.com/address/${transfer.act.data.eth_address.substring(
                          0,
                          transfer.act.data.eth_address.length - 24
                        )}`}
                        target="_blank"
                      >
                        {transfer.act.data.eth_address.substring(
                          0,
                          transfer.act.data.eth_address.length - 24
                        )}
                      </a>
                    </td>
                    <td className="text-end">
                      {transfer.act.data.quantity.substring(
                        0,
                        transfer.act.data.quantity.length - 4
                      )}
                    </td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default OutgoingTeleports;
