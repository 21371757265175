import { useQuery } from "react-query";
import moment from "moment-timezone";
import { filter } from "minimatch";

import { createRef, useEffect, useMemo, useRef, useState } from "react";

import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService, { apiHeaders } from "app/services/api";
import config from "app/config";
import usePrevious from "app/hooks/usePrevious";

export const AdminAccountsCreators = ({}) => {
  const componentProps = {
    component: "views/tower/report/components/accounts-creators",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const filters = useFilters();
  const prevFilters = usePrevious(filters);

  if (dataTableRef.current && prevFilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!results.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500, 1000],
          searching: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/tower/accounts-creators`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
            data: {
              startDate: filters.dates.startDate,
              endDate: filters.dates.endDate,
            },
          },
          columns: [
            {
              data: "creator",
              name: "creator",
              render: function (data, type, row) {
                return `<a href="/admin/account-scanner/${data}" target="_blank">${data}</a>`;
              },
            },
            {
              data: "total_accounts",
              name: "total_accounts",
              render: function (data, type, row) {
                return parseInt(data).toLocaleString(undefined, {});
              },
            },
            { data: "flag_whitelist", name: "flag_whitelist" },
          ],
          columnDefs: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [filters]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title">Accounts Creators</h4>
            <p>This table shows the top account creators on WAX. Tower requires you to ensure that any Known exchanges are marked.</p>
            <table
              class="table dt-responsive  w-100"
              ref={tableRef}
              id="accounts_creators"
            >
              <thead>
                <tr>
                  <th>Creator</th>
                  <th>Total Accounts</th>
                  <th>Known Exchange / System</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAccountsCreators;
