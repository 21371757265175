import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import moment from "moment";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
indicators(Highcharts);
regressions(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const AvgMinePerDayPerPlanet = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/mining/avg-mine-per-day-per-planet",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState(filters.dates);

  const planets = useQuery(
    ["avg_mine_per_day_per_planet", filters],
    async () => {
      const response = await ApiService.get(
        "planets/avg-mine-per-day-per-planet",
        {
          startDate: moment(filters.dates.startDate)
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!planets.data) return;

    setData(planets.data);
    setDates(filters.dates);

    chartComponent.current.chart.hideLoading();
  }, [planets.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Average mine per day per planet`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the average mine per day per planet on each day in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {planets.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      showInLegend: true,
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                    min: moment(dates.startDate).unix() * 1000,
                    max: moment(dates.endDate).unix() * 1000,
                    //range: moment(filters.dates.endDate).diff(moment(filters.dates.startDate), 'seconds') * 1000
                  },
                  yAxis: {
                    tickInterval: 0.1,
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "Average bounty",
                    },
                  },
                  tooltip: {
                    valueDecimals: 3,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvgMinePerDayPerPlanet;
