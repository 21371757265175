import CardMenu from "admin/components/card-menu";
import TotalMissions from "./total-missions";
import ActiveMissions from "./active-missions";
import MissionsBoarding from "./missions-boarding";
import CompletedUnclaimedMissions from "./completed-unclaimed-missions";
import CompletedClaimedMissions from "./completed-claimed-missions";
import TotalSpaceships from "./total-spaceships";
import { useHelpTexts } from "app/hooks/useSession";

export const MissionStats = (props) => {
  const componentProps = {
    component: "components/missions/mission-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Total missions - Number of missions in the selected time-frame. <br>
                            Active missions - Number of active missions in the selected time-frame. <br>
                            Missions boarding - Number of missions in the boarding state in the selected time-frame. <br>
                            Completed unclaimed missions - Number of missions that are completed but not fully claimed in the selected time-frame. <br>
                            Completed claimed missions - Number of missions that are completed and claimed in the selected time-frame. <br>
                            Total spaceships - Total spaceships leased across all missions in the selected time-frame.
                            `
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <TotalMissions />
          </div>
          <div class="col-xl-4 col-md-6">
            <ActiveMissions />
          </div>
          <div class="col-xl-4 col-md-6">
            <MissionsBoarding />
          </div>
          <div class="col-xl-4 col-md-6">
            <CompletedUnclaimedMissions />
          </div>
          <div class="col-xl-4 col-md-6">
            <CompletedClaimedMissions />
          </div>
          <div class="col-xl-4 col-md-6">
            <TotalSpaceships />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionStats;
