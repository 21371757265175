import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";

export const TopMines = (props) => {
  const componentProps = {
    component: "components/mining/top_mines",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "top_mines" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const topMines = useQuery([`top_mines`, filters], async () => {
    const response = await ApiService.get("logmines/top-mines", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topMines.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          columnDefs: [{ targets: "no-sort", orderable: false }],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Top Mines` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Miner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Land Owner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [topMines.data]);

  const copyMinersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("top_mines", 1);
  };

  const copyLandownersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("top_mines", 2);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Top Mines`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows the largest 50 mine actions by bounty in the selected time-frame. This report is helpful in identifying large mine actions which is a potential indicator of downtime of botting activity resulting in increased bounty pay out."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            class="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="top_mines"
          >
            <thead>
              <tr>
                <th>Time</th>
                <th className="no-sort">
                  Miner &nbsp;
                  <Link to="" onClick={copyMinersToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy miners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Planet</th>
                <th>Tool 1</th>
                <th>Tool 2</th>
                <th>Tool 3</th>
                <th>Bounty</th>
                <th>Land</th>
                <th className="no-sort">
                  Land Owner &nbsp;
                  <Link to="" onClick={copyLandownersToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy landowners to clipboard"
                    ></i>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {topMines.data &&
                topMines.data.map((topMine, index) => {
                  topMine = topMine._source;
                  return (
                    <tr key={index}>
                      <td title={moment(topMine.timestamp).fromNow()}>
                        {moment
                          .tz(topMine.timestamp, "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        <Link
                          to={`/admin/account-scanner/${topMine.act_data_miner}`}
                        >
                          {topMine.act_data_miner}
                        </Link>
                      </td>
                      <td>{topMine.act_data_planet_name}</td>
                      <td>
                        {topMine.act_data_bag_items_0 ? (
                          <Link
                            to={`/admin/nfts/${topMine.act_data_bag_items_0}`}
                          >
                            {topMine.act_data_bag_items_0}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {topMine.act_data_bag_items_0 ? (
                          <Link
                            to={`/admin/nfts/${topMine.act_data_bag_items_1}`}
                          >
                            {topMine.act_data_bag_items_1}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {topMine.act_data_bag_items_0 ? (
                          <Link
                            to={`/admin/nfts/${topMine.act_data_bag_items_2}`}
                          >
                            {topMine.act_data_bag_items_2}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{topMine.act_data_bounty}</td>
                      <td>
                        <Link to={`/admin/nfts/${topMine.act_data_land_id}`}>
                          {topMine.act_data_land_id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/admin/account-scanner/${topMine.act_data_landowner}`}
                        >
                          {topMine.act_data_landowner}
                        </Link>
                      </td>
                    </tr>
                  );
                })}

              {topMines.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {topMines.isError ? <Error500 /> : null}
          <Link
            to={`/admin/mining-explorer?tree={"id":"88a898ba-cdef-4012-b456-717e2a8cbc76","type":"group","children1":{"aaa8ab88-4567-489a-bcde-f17e2a8ccaf5":{"type":"rule","properties":{"field":"act_data_bounty","operator":"greater_or_equal","value":[${
              topMines.data && topMines.data[topMines.data.length - 1]
                ? topMines.data[topMines.data.length - 1]._source
                    .act_data_bounty
                : 0
            }],"valueSrc":["value"],"valueType":["number"]}}}}`}
          >
            <button
              type="buttom"
              className="btn btn-success mb-2 mb-sm-0 mx-auto d-block mt-2"
            >
              View more
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopMines;
