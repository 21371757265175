import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import moment from "moment-timezone";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const SystemAccountsTransactionsChart = (props) => {
  const componentProps = {
    component: "components/energy/system-accounts-transactions-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);

  const transactions = useQuery(
    ["system_accounts_transactions_chart", filters],
    async () => {
      const response = await ApiService.get(
        "energy/system-accounts-transactions-chart",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!transactions.data) return;

    setData(transactions.data);

    chartComponent.current.chart.hideLoading();
  }, [transactions.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `System accounts transactions chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the number of transactions by the system accounts on each day in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {transactions.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    xDateFormat: "%Y-%m-%d",
                    formatter: function () {
                      var points = this.points;
                      points.sort(function (a, b) {
                        return b.y > a.y;
                      });
                      var text = `${moment(points[0].x).format(
                        "YYYY-MM-DD"
                      )}</br>`;
                      points.forEach((point) => {
                        text += `<span style="color:${point.series.color}">${
                          point.series.name
                        }</span>: <b>${point.y.toLocaleString()}</b><br/>`;
                      });
                      return text;
                    },
                    // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemAccountsTransactionsChart;
