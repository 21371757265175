import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DefaultApp from "./default/App";
import AdminApp from "./admin/App";
import ScrollToTop from "./app/components/scroll-to-top";
import ApiService from "app/services/api";
import { useDispatch } from "react-redux";
import { useLoggedIn } from "app/hooks/useSession";

import "./App.css";

function App() {
  const dispatch = useDispatch();
  const sessionLoggedIn = useLoggedIn();

  ApiService.setDispatch(dispatch);
  ApiService.setSessionLoggedIn(sessionLoggedIn);
  
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/admin">
          <AdminApp />
        </Route>
        <Route path="/">
          <DefaultApp />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
