import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import usePrevious from "app/hooks/usePrevious";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const GuardRamUsage = (props) => {
  const componentProps = {
    component: "components/guard/guard-ram-usage",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "guard_ram_usage" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const ramUsage = useQuery([`guard_ram_usage`, filters], async () => {
    const response = await ApiService.get("guard/ram-usage", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!ramUsage.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i className='mdi mdi-chevron-left'>",
              next: "<i className='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Latest ram usage` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Receiver")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [ramUsage.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#guard_ram_usage_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("guard_ram_usage", 1);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Latest ram usage`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "Ram usage tooltip"
              }
              style={{ boxShadow: "none" }}
            >
              <i className="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="guard_ram_usage"
          >
            <thead>
              <tr>
                <th>Time</th>
                <th>Bytes</th>
                <th>Payer</th>
                <th className="no-sort">
                  Receiver &nbsp;
                  <Link to="" onClick={copyWalletsToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy receivers to clipboard"
                    ></i>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {ramUsage.data &&
                ramUsage.data.map((item, index) => {
                  item = item._source;
                  let ram = item["@buyrambytes"];
                  return (
                    <tr key={index}>
                      <td title={moment(item["@timestamp"]).fromNow()}>
                        {moment
                          .tz(item["@timestamp"], "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{ram.bytes}</td>
                      <td>
                        <Link to={`/admin/account-scanner/${ram.payer}`}>
                          {ram.payer}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/account-scanner/${ram.receiver}`}>
                          {ram.receiver}
                        </Link>
                        &nbsp;
                        <a
                          href={`${config.explorer.url}/account/${ram.receiver}`}
                          target="_blank"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}

              {ramUsage.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {ramUsage.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default GuardRamUsage;
