import ApiService from "app/services/api";
import { useEffect } from "react";
import { useState } from "react";
import Emitter from "app/services/emitter";

export const AdminCreateNotificationList = () => {
  const [formData, setFormData] = useState({
    confirmation: false,
    id: "",
    type: "",
    name: "",
    items: "",
  });
  const MAX_LENGTH = 500000; //500000

  useEffect(() => {
    Emitter.on("NEW_NOTIFICATION_LIST", () => {
      resetNotificationListForm();
    });

    Emitter.on("EDIT_NOTIFICATION_LIST", (notificationList) => {
      setFormData({
        ...notificationList,
        id: notificationList.id,
        type: notificationList.type,
        name: notificationList.name,
        items: notificationList.items,
      });
    });

    return () => {
      Emitter.off("NEW_NOTIFICATION_LIST");
      Emitter.off("EDIT_NOTIFICATION_LIST");
    };
  }, []);

  const resetNotificationListForm = () => {
    setFormData((notificationList) => ({
      ...notificationList,
      id: "",
      type: "",
      name: "",
      items: "",
    }));
  };

  const createNotificationList = async (confirmation = false) => {
    try {
      if (formData.type === "") {
        throw Error("Type required");
      } else if (formData.name === "") {
        throw Error("Name required");
      } else if (formData.items === "") {
        throw Error("Items required");
      }

      if (!confirmation) {
        return window.$("#create-notification-list-modal").modal("show");
      }

      let response = await ApiService.post("alerts/notification/lists", {
        id: formData.id,
        type: formData.type,
        name: formData.name,
        items: csvToArray(formData.items),
      });

      response = await response.json();

      if (!response || !response.status || response.status !== "ok") {
        throw Error(response.message ?? "Unable to process");
      }

      resetNotificationListForm();
      Emitter.emit("CREATE_NOTIFICATION_LIST_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Notification List created Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Notification List create Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }

    setFormData({ ...formData, confirmation: false });
  };

  const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    //const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    //const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const rows = str.split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows
      .filter(function (row) {
        if (row === "") return false;

        return true;
      })
      .map(function (row) {
        const values = row.split(delimiter);
        return values;
        //   const el = headers.reduce(function (object, header, index) {
        //     object[header] = values[index];
        //     return object;
        //   }, {});
        //   return el;
      });

    // return the array
    return arr;
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3"> Notification List</h4>

              <div className="row">
                <div className="col-6">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Type</label>
                      <select
                        class="form-select"
                        onChange={(e) => {
                          e.preventDefault();
                          setFormData({
                            ...formData,
                            type: e.target.value,
                          });
                        }}
                        value={formData.type}
                        disabled={formData.id > 0 ? true : false}
                      >
                        <option value={""}>--Select--</option>
                        <option value={"email"}>Email</option>
                        <option value={"telegram"}>Telegram</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Items</label>
                      <textarea
                        className="form-control"
                        style={{ height: "100px" }}
                        value={formData.items}
                        maxLength={MAX_LENGTH}
                        onChange={(e) => {
                          const text = e.target.value;
                          if (text.length >= MAX_LENGTH) {
                            window.$.toast({
                              heading: "Error",
                              text: "Items limit reached",
                              position: "top-right",
                              icon: "error",
                            });
                          }

                          setFormData({
                            ...formData,
                            items: text.substring(0, MAX_LENGTH),
                          });
                        }}
                      />
                      <span class="form-text m-b-none">
                        {Math.max(0, MAX_LENGTH - formData.items.length)}{" "}
                        characters left
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Import Items</label>&nbsp;
                      <input
                        type="file"
                        name="fileItems"
                        id="fileItems"
                        accept=".csv,.txt"
                        onChange={(e) => {
                          const input = e.target.files[0];
                          if (
                            !["text/csv", "text/plain"].includes(input.type)
                          ) {
                            window.$.toast({
                              heading: "Error",
                              text: "Invalid File selected. Supports txt and csv",
                              position: "top-right",
                              icon: "error",
                            });
                            return;
                          }

                          const reader = new FileReader();
                          reader.onload = function (event) {
                            const text = event.target.result;
                            if (text.length > MAX_LENGTH) {
                              window.$.toast({
                                heading: "Error",
                                text: "Items limit reached",
                                position: "top-right",
                                icon: "error",
                              });
                            }

                            setFormData({
                              ...formData,
                              items: text.substring(0, MAX_LENGTH),
                            });
                            e.target.value = "";
                          };
                          reader.readAsText(input);
                        }}
                      />
                    </div>

                    <div>
                      <button
                        className="btn btn-primary waves-effect waves-light me-1"
                        type="button"
                        onClick={() => {
                          createNotificationList(false);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="create-notification-list-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div>
            <div className="modal-body">
              Are you sure you want to import these items?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.$("#create-notification-list-modal").modal("hide");
                  createNotificationList(true);
                }}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#create-notification-list-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreateNotificationList;
