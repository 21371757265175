import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import LogminesService from "../../services/logmines";
import CardMenu from "../card-menu";

export const SampleTable = (props) => {
  const componentProps = {
    component: "components/tables/sample",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };

  //  const filters = useSelector((state) => state.session.filters);
  const filters = useFilters();

  const logmines = useQuery(["logmines", filters], () => {
    return LogminesService.getLogmines({
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
  });

  // const logmines = useQuery(["logmines", filters], async () => {
  //   const response = await ApiService.get("logmines", {
  //     startDate: filters.dates.startDate,
  //     endDate: filters.dates.endDate,
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title mt-0">Logmines</h4>
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Miner</th>
                <th>Bounty</th>
                <th>Lanowner</th>
              </tr>
            </thead>
            <tbody>
              {logmines.status === "success" &&
                logmines.data.map((logmine, index) => {
                  return (
                    <tr key={index}>
                      <td>...{logmine.trx_id.substring(50)}</td>
                      <td>{logmine.act_data_miner}</td>
                      <td>{logmine.act_data_bounty}</td>
                      <td>{logmine.act_data_landowner}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {logmines.status === "loading" ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SampleTable;
