import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const BotTasks = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/bot-tasks",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const filters = useFilters();

  const botTasks = useQuery(
    [`bot-tasks_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getBotTasks(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      return result;
    }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!botTasks.data) {
      return;
    }

    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [[3, "desc"]],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Bot Tasks for ${props.account}` },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [botTasks.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4
            className="header-title"
            data-ht-title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `Bot Tasks`
            }
          >
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Bot Tasks`}{" "}
            for {props.account}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "List of bot tasks done by account"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="bot-tasks"
          >
            <thead>
              <tr>
                <th>Action</th>
                <th>Status</th>
                <th>Created On</th>
                <th>Updated On</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {botTasks &&
                botTasks.data &&
                Array.isArray(botTasks.data) &&
                botTasks.data.map((botTask, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          {
                            1: "Block",
                            2: "Unblock",
                            default: botTask.action,
                          }[botTask.action]
                        }
                      </td>
                      <td>
                        {
                          {
                            1: "PENDING ",
                            2: "SUPERCEDED",
                            3: "COMPLETED",
                            4: "FAILED",
                            5: "BLOCKED_BY_ACCOUNT_STATUS",
                            6: "NO_PROCESSING_REQUIRED",
                            default: botTask.processingStatus,
                          }[botTask.processingStatus]
                        }
                      </td>
                      <td>{botTask.createdAt}</td>
                      <td>{botTask.updatedAt}</td>
                      <td>{botTask.reason}</td>
                    </tr>
                  );
                })}

              {botTasks.isFetching ? (
                <>
                  {new Array(5).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {botTasks.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default BotTasks;
