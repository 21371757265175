import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import AccountService from "../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
//import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const DaoProposals = (props) => {
  const componentProps = {
    component: "components/dao/dao-proposals",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "dao-proposals" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          order: [[0, "desc"]],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/dao/dao-proposals`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = filters.dates.startDate;
              data.endDate = filters.dates.endDate;
            },
          },
          columns: [
            { data: "modified_date", name: "modified_date" },
            { data: "proposal_name", name: "proposal_name" },
            { data: "proposer", name: "proposer" },
            { data: "title", name: "title" },
            { data: "dac_id", name: "dac_id" },
            //{ data: "actions", name: "actions" },
            { data: "amount", name: "amount" },
            { data: "state", name: "state" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.modified_date, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.modified_date).fromNow());
              },
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${rowData.proposal_name}`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 2,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.proposer}" target="_blank">${rowData.proposer}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 3,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${rowData.title}`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 6,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = ``;
                switch (rowData.state) {
                  case 0:
                    data = "Active";
                    break;
                  case 1:
                    data = "Executed";
                    break;
                  case 2:
                    data = "Cancelled";
                    break;
                }

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `DAO Proposals` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Proposer")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [filters]);

  const copyProposerAddressToClipboard = async (e) => {
    e.preventDefault();

    copyToClipboard("dao-proposals", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `DAO Proposals`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `DAO Proposals`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "DAO Proposals"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="dao-proposals"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">Proposal Name &nbsp;</th>
              <th className="no-sort">
                Proposer &nbsp;
                <Link to="" onClick={copyProposerAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy proposer address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Title </th>
              <th>Planet </th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default DaoProposals;
