import { useState, useEffect, useRef } from "react";
import ReportsService from "../../services/reports";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link } from "react-router-dom";
import BotsChart from "./bots-chart";
import Highcharts from "highcharts";
import { copyToClipboard } from "app/utils/datatables";

function AdminCheckBots() {
  const tableRef1 = useRef();
  const dataTableRef1 = useRef();
  const tableRef2 = useRef();
  const dataTableRef2 = useRef();
  const [accounts, setAccounts] = useState("");
  const [bots, setBots] = useState([]);
  const [nonBots, setNonBots] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    ReportsService.getBotsAndNonBots(accounts).then((response) => {
      setLoading(false);
      if (response) {
        setBots(response.bots);
        setNonBots(response.non_bots);
      }
    });
  };

  if (dataTableRef1.current) {
    dataTableRef1.current.destroy();
    dataTableRef1.current = null;
  }

  useEffect(() => {
    if (bots.length === 0) {
      return;
    }
    setTimeout(() => {
      dataTableRef1.current =
        dataTableRef1.current ||
        window.$(tableRef1.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copy bots to clipboard",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Bots")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
    }, 500);

    if (dataTableRef1.current) {
      dataTableRef1.current.draw(true);
    }
  }, [bots]);

  if (dataTableRef2.current) {
    dataTableRef2.current.destroy();
    dataTableRef2.current = null;
  }

  useEffect(() => {
    if (nonBots.length == 0) {
      return;
    }
    setTimeout(() => {
      dataTableRef2.current =
        dataTableRef2.current ||
        window.$(tableRef2.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copy non bots to clipboard",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Non Bots")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
    }, 500);

    if (dataTableRef2.current) {
      dataTableRef2.current.draw(true);
    }
  }, [nonBots]);

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$(".button-menu-mobile").click(function () {
      setTimeout(() => {
        window.$(Highcharts.charts).each(function (i, chart) {
          chart.reflow();
        });
      }, 1000);
    });
  });

  const copyBotsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#bots_wrapper .btn-group .buttons-copy`)[0].click();
    copyToClipboard("bots", 0);
  };

  const copyNonBotsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#non_bots_wrapper .btn-group .buttons-copy`)[0].click();
    copyToClipboard("non_bots", 0);
  };

  return (
    <>
      <BreadcrumbsItem to="/admin/reports/reports">Reports</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/reports/check-bots">
        Check Bots
      </BreadcrumbsItem>
      <div className="row">
        <div className="col-lg-12">
          <div className="p-2">
            <form role="form">
              <div className="mb-2 row">
                <label
                  className="col-md-12 col-form-label"
                  for="example-textarea"
                >
                  Paste accounts separated by comma or new line
                </label>
                <div class="col-md-12">
                  <textarea
                    className="form-control"
                    name="accounts"
                    onChange={(e) => setAccounts(e.target.value)}
                    rows="5"
                  >
                    {accounts}
                  </textarea>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                onClick={handleClick}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ position: "relative", top: "-140px" }}
        >
          <div className="spinner-border" role="status"></div>
        </div>
      ) : null}

      {bots.length != 0 || nonBots.length != 0 ? (
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef1}
              id="bots"
            >
              <thead>
                <tr>
                  <th>
                    Bots &nbsp;
                    <Link to="" onClick={copyBotsToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy bots to clipboard"
                      ></i>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bots.map((bot, index) => {
                  return (
                    <tr>
                      <td>{bot}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col-lg-6 col-md-12">
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef2}
              id="non_bots"
            >
              <thead>
                <tr>
                  <th>
                    Non Bots &nbsp;
                    <Link to="" onClick={copyNonBotsToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy non bots to clipboard"
                      ></i>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nonBots.map((nonBot, index) => {
                  return (
                    <tr>
                      <td>{nonBot}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}

      <div className="row mt-2">
        <div className="col-lg-12">
          <BotsChart />
        </div>
      </div>
    </>
  );
}

export default AdminCheckBots;
