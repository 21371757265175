import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";
import { useFilters, useHelpTexts } from "app/hooks/useSession";

export const NftTransfers = (props) => {
  const componentProps = {
    component: "components/forensics/nft-transfers",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "nft_transfers" },
  };
  const helpTexts = useHelpTexts();
  const formFilters = props.formFilters;
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevFormFilters = usePrevious(formFilters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevFormFilters !== formFilters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/account/nft-transfers/${props.account}`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = formFilters.date;
              data.endDate = formFilters.date;
            },
          },
          columns: [
            { data: "timestamp", name: "timestamp" },
            { data: "from", name: "transfer_from" },
            { data: "to", name: "transfer_to" },
            { data: "memo", name: "transfer_memo" },
            { data: "asset_ids", name: "transfer_asset_ids" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.timestamp).fromNow());
              },
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.from}" target="_blank">${rowData.from}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 2,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.to}" target="_blank">${rowData.to}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 4,
              orderable: false,
              render: function (datum, type, rowData, meta) {
                var data = `<ul style='list-style-type: none;padding: 0px;' id="row-${meta.row}">`;
                rowData.asset_ids.map((asset_id, index) => {
                  data += `<li style="display:${index>1?'none':'block'}"><a href="/admin/nfts/${asset_id}" target="_blank">${asset_id}</a></li>`;
                });
                data += "</ul>";
                data += rowData.asset_ids.length>2?`<a href="#" class="moreLink" data-row="${meta.row}"> + ${rowData.asset_ids.length-2} more</a>`:``;

                return data;
              }
            }
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `NFT Transfers for ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    window.$( document ).on( "click", "a.moreLink", function(e) {
      e.preventDefault();
      var row = window.$(this).attr('data-row');
      window.$(`table#nft_transfers ul#row-${row} li`).css('display','block');
      window.$(`table#nft_transfers a.moreLink[data-row=${row}]`).css('display','none');
    });

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.account]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#wax_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("nft_transfers", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#wax_transfers_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("nft_transfers", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          NFT Transfers for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the latest NFT transfers going into the account or out from this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="nft_transfers"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Memo</th>
              <th>NFTs</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default NftTransfers;
