import ApiService from "app/services/api";
import SocketService from "app/services/socket";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

export const AdminSelfTestTowerStats = (props) => {
  const stats = useQuery([`self-test_tower-stats`], async () => {
    const response = await ApiService.get("self-test/tower-stats", {
      timestamp: moment.utc().valueOf(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  });

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Tower Stats</h4>
          <div className="row">
            {stats.data &&
              stats.data.map((data, index) => {
                return (
                  <div className="col-xl-3 col-md-3" key={index}>
                    <div className="widget-simple text-center card">
                      <div className="card-body">
                        <h3 className="text-success mt-0">
                          <span>{data.value || 0}</span>
                        </h3>
                        <p className="text-muted mb-0">{data.key}</p>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:platform-guard"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">platform-guard</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:paywall-failed"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">paywall-failed</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:paywall-success"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">paywall-success</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-bypass"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-bypass</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-failed"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-failed</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-success"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-success</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSelfTestTowerStats;
