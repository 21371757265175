import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsHistogramBellcurve from "highcharts/modules/histogram-bellcurve";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "admin/components/card-menu";
import moment from "moment";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
HighchartsHistogramBellcurve(Highcharts);
indicators(Highcharts);
regressions(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const AdminTowerMinersScatter = ({ filters }) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "views/tower/debug/components/tower_miners_scatter",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();

  const [data, setData] = useState([]);
  const [pointSignal, setPointSignal] = useState("DEFAULT");
  const [showLinear, setShowLinear] = useState(true);

  const signalsResponse = useQuery(["tower_signals"], async () => {
    const response = await ApiService.get("tower/tower-signals", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  const results = useQuery(
    ["tower_miners_scatter", filters, pointSignal],
    async () => {
      const response = await ApiService.get("tower/tower-miners-scatter", {
        ...filters,
        pointSignal: pointSignal,
        timestamp: moment().utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!results.data) return;

    setData(results.data);

    // setTimeout(()=>{
    //    console.log(chartComponent.current.chart);
    //   chartComponent.current.chart.setTitle({ text: "New Title" });
    // },5000)

    chartComponent.current.chart.hideLoading();
  }, [results.data]);

  const [record, setRecord] = useState(null);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    window
      .$(document)
      .off("click", "#tower_miners_scatter_chart_container .highcharts-point")
      .on(
        "click",
        "#tower_miners_scatter_chart_container .highcharts-point",
        function (e) {
          if (record) {
            setRecords((prevRecords) => [...prevRecords, record]);
          }
        }
      );
  }, [record]);

  // useEffect(() => {
  //   if (!chartComponent || !chartComponent.current) return;
  //   if (!data.records) return;

  //   setTimeout(() => {
  //     for (var point of chartComponent.current.chart.series[0].data) {
  //       var record = data.records[point.series.userOptions.id][point.index];

  //       //console.log(point)

  //       point.update({
  //         color:
  //           pointSignal !== "DEFAULT" && record.signals.includes(pointSignal)
  //             ? "#90EE7E"
  //             : "#FF0000",
  //       });
  //     }

  //     for (var point of chartComponent.current.chart.series[1].data) {
  //       var record = data.records[point.series.userOptions.id][point.index];

  //       // point.update({
  //       //   color:
  //       //     pointSignal !== "DEFAULT" && record.signals.includes(pointSignal)
  //       //       ? "#90EE7E"
  //       //       : "#058DC7",
  //       // });
  //     }
  //   }, 500);
  // }, [pointSignal]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={(e) => {
                  setPointSignal(e.target.value);
                }}
                value={pointSignal}
              >
                <option value="DEFAULT">--DEFAULT--</option>
                {signalsResponse.data &&
                  signalsResponse.data.map((signal, index) => (
                    <option key={index} value={signal.signal}>
                      {signal.signal}
                    </option>
                  ))}
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Tower Miners Scatter Plots`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Tower Miners Scatter Plots"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {results.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr" id="tower_miners_scatter_chart_container">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    height: 700,
                    type: "scatter",
                    // zoomType: "xy",
                  },
                  boost: {
                    useGPUTranslations: true,
                    usePreAllocated: true,
                  },
                  title: {
                    text: "Tower Miners Scatter Plots",
                  },
                  subtitle: {
                    text: results.data
                      ? `Total: ${parseInt(
                          results.data.records.tower_bots.length +
                            results.data.records.others.length
                        ).toLocaleString(undefined, {})} Bots: ${parseInt(
                          results.data.records.tower_bots.length
                        ).toLocaleString(undefined, {})} Non Bots: ${parseInt(
                          results.data.records.others.length
                        ).toLocaleString(undefined, {})}`
                      : null,
                  },
                  credits: {
                    enabled: false,
                  },
                  xAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    title: { text: "TLM" },

                    // alignTicks: false,
                    // labels: {
                    //   format: "{value} TLM",
                    // },
                  },

                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    title: { text: "Mines" },

                    // alignTicks: false,
                    // opposite: true,
                    // labels: {
                    //   format: "{value}",
                    // },
                  },
                  // plotOptions: {
                  //   series: {
                  //     cursor: "pointer",
                  //     // point: {
                  //     //   events: {
                  //     //     click: function () {
                  //     //       var record = data.records[this.index];
                  //     //       window.open(
                  //     //         `/admin/account-scanner/${record.miner}`
                  //     //       );
                  //     //     },
                  //     //   },
                  //     // },
                  //     // events: {
                  //     //   click: function () {
                  //     //     var record = data.records[this.index];
                  //     //     window.open(`/admin/account-scanner/${record.miner}`);
                  //     //   },
                  //     // },
                  //   },
                  // },
                  tooltip: {
                    formatter: function () {
                      var record =
                        data.records[this.series.userOptions.id][
                          this.point.index
                        ];

                      setRecord(record);

                      return `<b>${this.series.name}</b><br>
                      TLM: ${this.x}<br>
                      Mines: ${this.y}<br>
                      Miner: ${record.miner}<br>
                      Score: ${record.score}<br>
                      Bot Score: ${record.bot_score}<br>
                      Yeomen Score: ${record.yeomen_score || "N/A"}`;
                    },
                  },
                  series: data.series,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
        {records.length > 0 ? (
          <table class="table dt-responsive  w-100">
            <thead>
              <tr>
                <th>Miner</th>
                <th>TLM</th>
                <th>Mines</th>
                <th>Score</th>
                <th>Bot Score</th>
                <th>Yeomen Score</th>
              </tr>
            </thead>
            <tbody>
              {records.reverse().map((record, index) => (
                <tr key={index}>
                  <td>{record.miner}</td>
                  <td>{record.total_tlm}</td>
                  <td>{record.total_mines}</td>
                  <td>{record.score}</td>
                  <td>{record.bot_score}</td>
                  <td>{record.yeomen_score || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default AdminTowerMinersScatter;
