import { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import { useQuery } from "react-query";
import ApiService from "../../app/services/api";

HighchartsSankey(Highcharts);
DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  },
});

export const Tokenomics = () => {
  
    const [planetStakes, setPlanetStakes] = useState([
        {
            name: 'eyeke.world',
            stake: 770990,
            nft_multiplier: 110099478
        },
        {
            name: 'kavian.world',
            stake: 1501089,
            nft_multiplier: 203281459
        },
        {
            name: 'magor.world',
            stake: 840909,
            nft_multiplier: 107616008
        },
        {
            name: 'naron.world',
            stake: 684627,
            nft_multiplier: 114009334
        },
        {
            name: 'neri.world',
            stake: 1904662,
            nft_multiplier: 291971391
        },
        {
            name: 'veles.world',
            stake: 829005,
            nft_multiplier: 95601098
        }
    ]);
    const [data, setData] = useState([]);
    const [totalStake, setTotalStake] = useState(0);
    const [planetTotal, setPlanetTotal] = useState(0);
    const [miningTotal, setMiningTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const [tlmPrice, setTlmPrice] = useState(null);

    const tokenomics = useQuery(["tokenomics"], async () => {
        const response = await ApiService.get("tokenomics/tlm-price", {});
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        let data = await response.json()
        setTlmPrice(data.tlm_price);
    });

    const generateData = () => {
        var dtap = 936366.924;
        var nftmod = 0.2;
        var binanceDialy = 4000000/30;
        var start = "DTAP";
        var points = [];
        var apst = planetStakes.reduce(function(sum, planet) {
            return sum + planet.stake
        }, 0);
        setTotalStake(apst);
        var apnfts = planetStakes.reduce(function(sum, planet) {
            return sum + planet.nft_multiplier
        }, 0);
        var totalPlanet = 0
        var totalMining = 0;
        var totalAll = 0;
        for(var planet of planetStakes) {
            var pst = planet.stake;
            var pnfts = planet.nft_multiplier;

            var tlmStaked = dtap*(1-nftmod)*pst/apst;
            var tlmNft = dtap*nftmod*pnfts/apnfts;
            var planetAllocation = tlmStaked + tlmNft;

            var binanceDialyAdjustment = binanceDialy*pst/apst;
            planetAllocation -= binanceDialyAdjustment;
            if(planetAllocation >= 500000)
                planetAllocation = 500000;
            
            points.push([start, planet.name, planetAllocation]);

            var planetMining = planetAllocation*0.8;
            var planetClaim = planetAllocation*0.2;

            points.push([planet.name, "Mining "+planet.name, planetMining]);
            points.push([planet.name, "Planet claim "+planet.name, planetClaim]);

            points.push(["Planet claim "+planet.name, "Planet total", planetClaim]);
            points.push(["Mining "+planet.name, "Mining total", planetMining]);

            totalPlanet += planetClaim;
            totalMining += planetMining;
            totalAll += planetClaim + planetMining;
        };

        setData(points);
        setPlanetTotal(totalPlanet);
        setMiningTotal(totalMining);
        setTotal(totalAll);
    }

    const setPlanetStake = (event) => {
        var name = event.target.name;
        var value = event.target.value;

        var data = planetStakes;
        var index = data.findIndex(planet => planet.name == name);
        data[index].stake = parseInt(value);

        setPlanetStakes([...data]);
    }
    
    useEffect(() => {
        setTimeout(() => {
            window.$.Components.init();
        });

        window.$('.button-menu-mobile').click(function(){ 
          setTimeout(() => {
            window.$(Highcharts.charts).each(function(i,chart){
              chart.reflow();
            });
          }, 1000);
        });
    });
    useEffect(() => {
        generateData();
    }, [planetStakes])

  return (
    <>
    <BreadcrumbsItem to="/admin/tokenomics">Tokenomics</BreadcrumbsItem>

    <div className="card">
      <div className="card-body">
        <h4 className="header-title">
          Tokenomics
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="This section simulates the tokenomics of introducing the new Satellite DAO functionality. It arrives at the TLM/day allocation based on the staking for planets."
            style={{ boxShadow: "none" }}
          >
          <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  tooltip: {
                    outside: true,
                    formatter: function () {
                      if (this.point.isNode)
                        return (
                          this.point.name + ": <b>" + new Intl.NumberFormat().format(this.point.sum) + "</b>"
                        );
                      else
                        return (
                          this.point.from +
                          " -> " +
                          this.point.to +
                          "<br><b>" +
                          new Intl.NumberFormat().format(this.point.weight) +
                          "</b>"
                        );
                    },
                  },
                  accessibility: {
                    point: {
                      valueDescriptionFormat:
                        "{index}. {point.from} to {point.to}, {point.weight}.",
                    },
                  },
                  series: [
                    {
                      keys: ["from", "to", "weight"],
                      data: data,
                      type: "sankey",
                      name: "Tokenomics",
                    },
                  ],
                }}
              />
            </div>
        </div>

        {planetStakes.map(planet => (
        <div className="mb-2 row">
            <label className="col-md-2 col-form-label" for={planet.name}>{planet.name}</label>
            <div className="col-md-8 align-self-center">
                <input className="form-range" type="range" name={planet.name} value={planet.stake} title={planet.stake} onChange={setPlanetStake} min="0" max="10000000" />
            </div>
            <div className="col-md-2 align-self-center">
                <input className="form-control" type="text" name={planet.name} value={planet.stake} onChange={setPlanetStake} min="0" max="10000000" />
            </div>
        </div>
        ))}

        <h4 className="header-title mt-4">
        Input Params
        <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="These are settings for the tokenomics input params."
            style={{ boxShadow: "none" }}
          >
          <i class="fas fa-info-circle"></i>
        </button>
        </h4>
        <div class="responsive-table-plugin">
            <div class="table-rep-plugin">
                <div class="table-responsive" data-pattern="priority-columns">
                    <table className="table table-centered">
                        <thead className="table-light">
                            <tr>
                                <th></th>
                                {planetStakes && planetStakes.map(planet => (
                                    <th>{planet.name}</th>
                                ))}
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Staking
                                    <button
                                        type="button"
                                        className="btn btn-outline-none"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="This is the TLM staked to each of the planets. Planet staking returns planet tokens pegged 1:1 with TLM and can be exchanged in 3 days time."
                                        style={{ boxShadow: "none" }}
                                    >
                                    <i class="fas fa-info-circle"></i>
                                    </button>
                                </td>
                                {planetStakes && planetStakes.map(planet => (
                                    <td>{`${planet.stake.toLocaleString()}(${((planet.stake/totalStake)*100).toFixed(2)}%)`}</td>
                                ))}
                                <td>{totalStake.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <h4 className="header-title mt-4">
        Output TLM/day
        <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="This table shows the TLM distributed based on the set input params."
            style={{ boxShadow: "none" }}
          >
          <i class="fas fa-info-circle"></i>
        </button>
        </h4>
        <div class="responsive-table-plugin">
            <div class="table-rep-plugin">
                <div class="table-responsive" data-pattern="priority-columns">
                    <table className="table table-centered">
                        <thead className="table-light">
                            <tr>
                                <th></th>
                                {planetStakes && planetStakes.map(planet => (
                                    <th>{planet.name}</th>
                                ))}
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Planet
                                    <button
                                        type="button"
                                        className="btn btn-outline-none"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="This is the total TLM held on each of the planets. The current tokenomic model retains the whole TLM within the Planet and does not involve staking."
                                        style={{ boxShadow: "none" }}
                                    >
                                    <i class="fas fa-info-circle"></i>
                                    </button>
                                </td>
                                {planetStakes && data.length > 0 && planetStakes.map(planet => {
                                    var item = data.find(item => item[0] == "Planet claim "+planet.name && item[1] == "Planet total");
                                    return (
                                        <td>{item[2].toLocaleString()}</td>
                                    )
                                })}
                                <td>{planetTotal.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>
                                    Mining
                                    <button
                                        type="button"
                                        className="btn btn-outline-none"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="This is the total TLM directed to the mining activity on each planet. It is proportional to the total staked to the planet."
                                        style={{ boxShadow: "none" }}
                                    >
                                    <i class="fas fa-info-circle"></i>
                                    </button>
                                </td>
                                {planetStakes && data.length > 0 && planetStakes.map(planet => {
                                    var item = data.find(item => item[0] == "Mining "+planet.name && item[1] == "Mining total");
                                    return (
                                        <td>{item[2].toLocaleString()}</td>
                                    )
                                })}
                                <td>{miningTotal.toLocaleString()}</td>
                            </tr>
                        </tbody>
                        <tfoot className="table-light">
                            <tr>
                                <th>
                                    Total
                                    <button
                                        type="button"
                                        className="btn btn-outline-none"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="This shows the total, combined amounts of Satellite, Planet and Mining activities."
                                        style={{ boxShadow: "none" }}
                                    >
                                    <i class="fas fa-info-circle"></i>
                                    </button>
                                </th>
                                {planetStakes && data.length > 0 && planetStakes.map(planet => {
                                    var item1 = data.find(item => item[0] == planet.name && item[1] == "Mining "+planet.name);
                                    var item2 = data.find(item => item[0] == planet.name && item[1] == "Planet claim "+planet.name);
                                    return (
                                        <th>{(item1[2]+item2[2]).toLocaleString()}</th>
                                    )
                                })}
                                <td>{total.toLocaleString()}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        {tlmPrice != null ? (
        <>
        <div className="mb-2 mt-2 row">
            <label className="col-md-2 col-form-label" for="tlm-price">TLM price:</label>
            <div className="col-md-2 align-self-center">
                <input className="form-control" type="number" name="tlm-price" value={tlmPrice} onChange={(event) => setTlmPrice(event.target.value)} min="0" max="100" />
            </div>
        </div>
        <h4 className="header-title">TVL staked to all planets: ${(totalStake*tlmPrice).toLocaleString()}</h4>
        </>
        ) : null}
      </div>
    </div>
    </>
  );
};

export default Tokenomics;
