import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "admin/services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";
import { useHelpTexts } from "app/hooks/useSession";
import ApiService, { apiHeaders } from "app/services/api";
import config from "app/config";
import usePrevious from "app/hooks/usePrevious";
import CardMenu from "admin/components/card-menu";

export const AdminTowerMinersBots = (props) => {
  const {filters} = props;
  const componentProps = {
    component: "views/tower/debug/components/tower-miners-bots",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "tower_miners_bots" },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();
  const prevFilters = usePrevious(filters);

  if (dataTableRef.current && prevFilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!results.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500, 1000, 5000, 10000],
          searching: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/tower/tower-miners-bots`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
            data: { filters: filters },
          },
          columns: [
            {
              data: "miner",
              name: "miner",
              render: function (data, type, row) {
                return `<a href="/admin/account-scanner/${data}" target="_blank">${data}</a>`;
              },
            },
            { data: "score", name: "score" },
            { data: "yeomen_score", name: "yeomen_score" },
            {
              data: "status",
              name: "status",
            },
            {
              data: "stats",
              name: "stats",
              render: function (data, type, row) {
                return Object.keys(data.signals).join(', ');
              }
            },
          ],
          columnDefs: [],
          dom: "Blfrtip",
          buttons: [{ extend: "csv", title: `Tower Miners Bots` }],
        });
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [filters]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <CardMenu {...componentProps} {...props} />
            <h4 className="header-title">Tower Miners Bots</h4>
            <table
              class="table dt-responsive  w-100"
              ref={tableRef}
              id="tower_miners_bots"
            >
              <thead>
                <tr>
                  <th>Miner</th>
                  <th>Score</th>
                  <th>Yeomen Score</th>
                  <th>KnownAccounts Status</th>
                  <th>Signals</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTowerMinersBots;
