import ApiService from "app/services/api";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHelpTexts } from "app/hooks/useSession";
import Emitter from "app/services/emitter";

export const TowerSettings = () => {
  const helpTexts = useHelpTexts();
  const [settings, setSettings] = useState({
    TOWER_BOTS_PER_DAY: 0,
    TOWER_BOTS_SCORE: 0,
    TOWER_ACTIVE_MINERS_TLM_THRESHOLD: 0,
    TOWER_ACTIVE_MINERS_MINES_THRESHOLD: 0,
    TOWER_GAOLER_API_BATCH_SIZE: 0,
    TOWER_BOTS_CREATED_ACCOUNTS: 0,
    TOWER_STDEV_MULTIPLIER: 0,
  });
  useEffect(() => {
    ApiService.get("tower/settings", {
      timestamp: moment().utc().valueOf(),
    }).then(async (response) => {
      const data = await response.json();

      setSettings({
        ...data,
      });
    });
  }, []);

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post("tower/settings", {
        ...settings,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Updated Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    Emitter.on("BOT_CONTROL_SETTINGS_SUCCESS", (newValue) => {
      ApiService.get("tower/settings", {
        timestamp: moment().utc().valueOf(),
      }).then(async (response) => {
        const data = await response.json();

        setSettings({
          ...data,
        });
      });
    });

    return () => {
      Emitter.off("BOT_CONTROL_SETTINGS_SUCCESS");
    };
  });

  return (
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Tower Settings</h4>
        <p class="sub-header">Additional info</p>

        <form class="needs-validation" novalidate>
          {/* <div class="mb-3">
            <label for="name" class="form-label header-title">
              {helpTexts.find(
                (helpText) => helpText.key === "TOWER_BOTS_PER_DAY"
              )?.title || `TOWER_BOTS_PER_DAY`}
              <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-ht-key={"TOWER_BOTS_PER_DAY"}
                title={
                  helpTexts.find(
                    (helpText) => helpText.key === "TOWER_BOTS_PER_DAY"
                  )?.value || "Max number of miners to block per day"
                }
                style={{ boxShadow: "none" }}
              >
                <i class="fas fa-info-circle"></i>
              </button>
            </label>
            <input
              type="text"
              class="form-control"
              id="TOWER_BOTS_PER_DAY"
              placeholder="TOWER_BOTS_PER_DAY"
              value={settings.TOWER_BOTS_PER_DAY}
              required
              onChange={(e) =>
                setSettings((prevState) => ({
                  ...prevState,
                  TOWER_BOTS_PER_DAY: e.target.value,
                }))
              }
            />
          </div> */}

          <div class="card border ">
            <div class="card-header">Import Scripts</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key ===
                      "TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD"
                  )?.title ||
                    `TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={
                      "TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD"
                    }
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key ===
                          "TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD"
                      )?.value ||
                      "Threshold value for marking received account as HIGH_RISK_TRANSFER_HUB_AUTO based on number of unique WAX transferred from accounts. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD"
                  placeholder="TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD"
                  value={
                    settings.TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD
                  }
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_SCRIPTS_HIGH_RISK_WAX_TRANSFER_FROM_COUNT_THRESHOLD:
                        e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key ===
                      "TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD"
                  )?.title ||
                    `TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={
                      "TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD"
                    }
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key ===
                          "TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD"
                      )?.value ||
                      "Threshold value for marking received account as HIGH_RISK_TRANSFER_HUB_AUTO based on number of unique TLM transferred from accounts. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD"
                  placeholder="TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD"
                  value={
                    settings.TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD
                  }
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_SCRIPTS_HIGH_RISK_TLM_TRANSFER_FROM_COUNT_THRESHOLD:
                        e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key ===
                      "TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD"
                  )?.title || `TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={
                      "TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD"
                    }
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key ===
                          "TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD"
                      )?.value ||
                      "Threshold value for marking staker account as HIGH_RISK_STAKER_AUTO based on number of unique receivers. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD"
                  placeholder="TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD"
                  value={
                    settings.TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD
                  }
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_SCRIPTS_HIGH_RISK_STAKER_COUNT_THRESHOLD:
                        e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div class="card border ">
            <div class="card-header">Active Miners</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key === "TOWER_ACTIVE_MINERS_TLM_THRESHOLD"
                  )?.title || `TOWER_ACTIVE_MINERS_TLM_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_ACTIVE_MINERS_TLM_THRESHOLD"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_ACTIVE_MINERS_TLM_THRESHOLD"
                      )?.value ||
                      "Filter active miners based on TLM mined per day. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_ACTIVE_MINERS_TLM_THRESHOLD"
                  placeholder="TOWER_ACTIVE_MINERS_TLM_THRESHOLD"
                  value={settings.TOWER_ACTIVE_MINERS_TLM_THRESHOLD}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_ACTIVE_MINERS_TLM_THRESHOLD: e.target.value,
                    }))
                  }
                />
              </div>

              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key === "TOWER_ACTIVE_MINERS_MINES_THRESHOLD"
                  )?.title || `TOWER_ACTIVE_MINERS_MINES_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_ACTIVE_MINERS_MINES_THRESHOLD"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_ACTIVE_MINERS_MINES_THRESHOLD"
                      )?.value ||
                      "Filter active miners based on number of mines per day. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_ACTIVE_MINERS_MINES_THRESHOLD"
                  placeholder="TOWER_ACTIVE_MINERS_MINES_THRESHOLD"
                  value={settings.TOWER_ACTIVE_MINERS_MINES_THRESHOLD}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_ACTIVE_MINERS_MINES_THRESHOLD: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div class="card border ">
            <div class="card-header">Signals Manager</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) => helpText.key === "TOWER_BOTS_CREATED_ACCOUNTS"
                  )?.title || `TOWER_BOTS_CREATED_ACCOUNTS`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_BOTS_CREATED_ACCOUNTS"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_BOTS_CREATED_ACCOUNTS"
                      )?.value ||
                      "Threshold value for considering creator as bot.  Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_BOTS_CREATED_ACCOUNTS"
                  placeholder="TOWER_BOTS_CREATED_ACCOUNTS"
                  value={settings.TOWER_BOTS_CREATED_ACCOUNTS}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_BOTS_CREATED_ACCOUNTS: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key ===
                      "TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD"
                  )?.title || `TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={
                      "TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD"
                    }
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key ===
                          "TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD"
                      )?.value ||
                      "Threshold value for marking signal on from accounts with same memo sent to exchange.  Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD"
                  placeholder="TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD"
                  value={settings.TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_MEMOSHARE_TRANSFER_FROM_COUNT_THRESHOLD:
                        e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div class="card border ">
            <div class="card-header">Adjudicator</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) => helpText.key === "TOWER_BOTS_SCORE"
                  )?.title || `TOWER_BOTS_SCORE`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_BOTS_SCORE"}
                    title={
                      helpTexts.find(
                        (helpText) => helpText.key === "TOWER_BOTS_SCORE"
                      )?.value ||
                      "Threshold score value for considering account for blocking. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_BOTS_SCORE"
                  placeholder="TOWER_BOTS_SCORE"
                  value={settings.TOWER_BOTS_SCORE}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_BOTS_SCORE: e.target.value,
                    }))
                  }
                />
              </div>

              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) => helpText.key === "TOWER_STDEV_MULTIPLIER"
                  )?.title || `TOWER_STDEV_MULTIPLIER`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_STDEV_MULTIPLIER"}
                    title={
                      helpTexts.find(
                        (helpText) => helpText.key === "TOWER_STDEV_MULTIPLIER"
                      )?.value ||
                      "Standard deviation multiplier for calculating threshold score for blocking accounts. eg: If this is -ve value, it will reduce the threshold score range."
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_STDEV_MULTIPLIER"
                  placeholder="TOWER_STDEV_MULTIPLIER"
                  value={settings.TOWER_STDEV_MULTIPLIER}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_STDEV_MULTIPLIER: e.target.value,
                    }))
                  }
                />
              </div>

              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key === "TOWER_BOTS_PER_DAY_PERCENTAGE"
                  )?.title || `TOWER_BOTS_PER_DAY_PERCENTAGE`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_BOTS_PER_DAY_PERCENTAGE"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_BOTS_PER_DAY_PERCENTAGE"
                      )?.value ||
                      "Max number of miners to block per day based on the percentage of miners.  eg: If this is 0, it will skip blocking miners. Expected values range between 0 and 100"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_BOTS_PER_DAY_PERCENTAGE"
                  placeholder="TOWER_BOTS_PER_DAY_PERCENTAGE"
                  value={settings.TOWER_BOTS_PER_DAY_PERCENTAGE}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_BOTS_PER_DAY_PERCENTAGE: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div class="card border ">
            <div class="card-header">Appeals</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key === "TOWER_APPEALS_REJECT_THRESHOLD"
                  )?.title || `TOWER_APPEALS_REJECT_THRESHOLD`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_APPEALS_REJECT_THRESHOLD"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_APPEALS_REJECT_THRESHOLD"
                      )?.value ||
                      "Reject appeals if yeomen score is above this threshold value. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_APPEALS_REJECT_THRESHOLD"
                  placeholder="TOWER_APPEALS_REJECT_THRESHOLD"
                  value={settings.TOWER_APPEALS_REJECT_THRESHOLD}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_APPEALS_REJECT_THRESHOLD: e.target.value,
                    }))
                  }
                />
              </div>

              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) =>
                      helpText.key === "TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER"
                  )?.title || `TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key ===
                          "TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER"
                      )?.value ||
                      "Multiplier value for finding waiting period days before processing appeals. eg: If this is 0, it will result in zero waiting time. Expected values range between 0 and 1"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER"
                  placeholder="TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER"
                  value={settings.TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_APPEALS_WAITING_PERIOD_MULTIPLIER: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div class="card border ">
            <div class="card-header">Gaoler</div>
            <div class="card-body">
              <div class="mb-3">
                <label for="name" class="form-label header-title">
                  {helpTexts.find(
                    (helpText) => helpText.key === "TOWER_GAOLER_API_BATCH_SIZE"
                  )?.title || `TOWER_GAOLER_API_BATCH_SIZE`}
                  <button
                    type="button"
                    className="btn btn-outline-none"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-ht-key={"TOWER_GAOLER_API_BATCH_SIZE"}
                    title={
                      helpTexts.find(
                        (helpText) =>
                          helpText.key === "TOWER_GAOLER_API_BATCH_SIZE"
                      )?.value ||
                      "Number of records to process in a batch for Gaoler API. eg: If this is 0, it will skip processing records. Expected values greater than 0"
                    }
                    style={{ boxShadow: "none" }}
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TOWER_GAOLER_API_BATCH_SIZE"
                  placeholder="TOWER_GAOLER_API_BATCH_SIZE"
                  value={settings.TOWER_GAOLER_API_BATCH_SIZE}
                  required
                  onChange={(e) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      TOWER_GAOLER_API_BATCH_SIZE: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              class="btn btn-primary waves-effect waves-light me-1"
              type="submit"
              onClick={handleUpdateClick}
            >
              Update settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TowerSettings;
