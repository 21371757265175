import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ReportsService from "../../../../services/reports";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../../../../components/card-menu";
import moment from "moment";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const TowerAppealsStatsChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "views/tower/report/components/bots-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const [data, setData] = useState([]);
  //const filters = useFilters();
  const {filters} = props;

  const accounts = useQuery(
    ["tower_appeals_stats_chart", filters],
    async () => {
      const response = await ApiService.get("tower/appeals-stats-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!accounts.data) return;

    setData(accounts.data);

    chartComponent.current.chart.hideLoading();
  }, [accounts.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Tower Appeals Stats chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This chart shows tower appeals per day"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {accounts.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "line",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      showInLegend: true,
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    // range: moment().diff(moment().subtract(5, 'month'), 'seconds') * 1000
                  },
                  yAxis: {
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    outside: true,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TowerAppealsStatsChart;
