import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import AdminDashboard from "../views/dashboard";
import AdminDashboardOverview from "../views/dashboard-overview";
import AdminDashboardTLM from "../views/dashboard-tlm";
import AdminDashboardPlanet from "../views/dashboard-planet";
import AdminDashboardMining from "../views/dashboard-mining";
import AdminDashboardMiningPools from "../views/dashboard-mining-pools";
import AdminDashboardLandowner from "../views/dashboard-landowner";
import AdminDashboardLandratings from "../views/dashboard-landratings";
import AdminDashboardGuard from "../views/dashboard-guard";
import AdminDashboardSystem from "../views/dashboard-system";
import AdminDashboardMissions from "../views/dashboard-missions";
import AdminDashboardUsers from "../views/dashboard-users";
import AdminDashboardTeleport from "../views/dashboard-teleport";
import AdminDashboardEnergy from "../views/dashboard-energy";
import AdminDashboardSinks from "../views/dashboard-sinks";
import AdminDashboardDao from "../views/dashboard-dao";
import AdminDashboardPacks from "../views/dashboard-packs";
import AdminDashboardUserPoints from "../views/dashboard-user-points";
import AdminDashboardNfts from "../views/dashboard-nfts";
import AdminMyAccount from "../views/my-account";
import AdminUsers from "../views/admin-users";
import AdminNftDetail from "../views/nft-detail";
import AdminNfts from "../views/nfts";
import AdminRealtime from "../views/realtime";
import AdminDashboardZendesk from "../views/dashboard-zendesk";

import AdminForensics from "../views/tlm-forensics";
import MiningExplorer from "../views/mining-explorer";
import AccountDetails from "../views/account-scanner";
import OracleNfts from "../views/oracle-nfts";
import ToolCalculator from "../views/tool-calculator";

import AdminTopbar from "./components/topbar";
import AdminLeftSidebar from "./components/left-sidebar";
import AdminBreadcrumb from "./components/breadcrumb";
import AdminMainFilter from "../components/main-filter";

import Highcharts from "highcharts";
import DarkUnica from "highcharts/themes/dark-unica";
import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminTokenHolders from "../views/token-holders";
import AdminTemplateDetail from "../views/template-detail";
import AdminReports from "../views/reports";
import AdminCheckBots from "../views/reports/check-bots";
import AdminTest from "admin/views/test";
import AdminSelfTest from "admin/views/self-test";
import AdminTagLookup from "../views/tag-lookup";
import AdminTagBulk from "../views/tag-bulk";
import AdminTransfersLookup from "../views/transfers-lookup";
import AdminStakesLookup from "../views/stakes-lookup";
import AdminVisitorsLookup from "../views/visitors-lookup";

import Error500 from "./errors/error-500";
import Tokenomics from "../views/tokenomics";
import TokenomicsNew from "../views/tokenomics-new";

import ErrorIpfsImage from "admin/components/errors/error-ipfs-image";

import { useUser } from "../../app/hooks/useSession";
import AdminDashboardBotReport from "admin/views/dashboard-bot-report";
import AdminMyAlerts from "admin/views/my-alerts";
import AdminMyNotificationLists from "admin/views/my-notification-lists/my-notification-lists";
import LargeResultWindowElasticSearchModal from "admin/components/large-result-window-elasticsearch-modal";
import AdminHelpTexts from "admin/views/help-texts";
import HelpTextEditModal from "admin/components/help-text-edit-modal";
import AdminForensicsChartNfts from "admin/views/forensics-chart-nfts";
import AdminCompoundReports from "admin/views/reports/compound-reports";
// import AdminCompoundReportsView from "admin/views/reports/compound-reports/compound-reports-view";
// import AdminCompoundReportsPreview from "admin/views/reports/compound-reports/compound-reports-preview";
import AdminDashboardKpis from "admin/views/dashboard-kpis";
import AdminSheetsDemo from "admin/views/sheets-demo";
import AdminSheetsManager from "admin/views/sheets-manager";

import AdminTowerReport from "admin/views/tower/report";
import AdminTowerManage from "admin/views/tower/manage";
import AdminTowerDebug from "admin/views/tower/debug";
import AdminTowerAppeals from "admin/views/tower/appeals";

DarkUnica(Highcharts);
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "Roboto,sans-serif",
    },
  },
});

function AdminDefaultLayout() {
  const history = useHistory();
  const sessionUser = useUser();

  useEffect(() => {
    history.listen((location) => {
      setTimeout(() => {
        //window.$.App.init();
        window.$.Components.init();
      });
    });

    setTimeout(() => {
      //window.$.App.init();
      window.$.Components.init();
    });
  }, [history]);

  return (
    <>
      <BreadcrumbsItem to="/">AW Yeomen {sessionUser.level}</BreadcrumbsItem>

      <div id="wrapper">
        <AdminTopbar />

        <AdminLeftSidebar />

        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <AdminBreadcrumb />

              <AdminMainFilter />

              <Switch>
                <Route path="/admin" exact>
                  <Redirect to="/admin/dashboard" />
                </Route>
                <Route path="/admin/login" exact>
                  <Redirect to="/admin/dashboard" />
                </Route>
                <Route path="/admin/dashboard" exact>
                  <AdminDashboard />
                </Route>
                <Route path="/admin/dashboard-overview" exact>
                  <AdminDashboardOverview />
                </Route>
                <Route path="/admin/my-account" exact>
                  <AdminMyAccount />
                </Route>
                <Route path="/admin/my-alerts" exact>
                  <AdminMyAlerts />
                </Route>
                <Route path="/admin/my-notification-lists" exact>
                  <AdminMyNotificationLists />
                </Route>
                {sessionUser.role === "super_admin" ||
                sessionUser.level === "level2" ? (
                  <Route path="/admin/admin-users" exact>
                    <AdminUsers />
                  </Route>
                ) : null}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-tlm" exact>
                    <AdminDashboardTLM />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-planet" exact>
                    <AdminDashboardPlanet />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-mining" exact>
                    <AdminDashboardMining />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-mining-pools" exact>
                    <AdminDashboardMiningPools />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-landowner" exact>
                    <AdminDashboardLandowner />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-landratings" exact>
                    <AdminDashboardLandratings />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-nfts" exact>
                    <AdminDashboardNfts />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-guard" exact>
                    <AdminDashboardGuard />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-system" exact>
                    <AdminDashboardSystem />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-missions" exact>
                    <AdminDashboardMissions />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-users" exact>
                    <AdminDashboardUsers />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-teleport" exact>
                    <AdminDashboardTeleport />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-zendesk" exact>
                    <AdminDashboardZendesk />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-energy" exact>
                    <AdminDashboardEnergy />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-sinks" exact>
                    <AdminDashboardSinks />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-dao" exact>
                    <AdminDashboardDao />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-packs" exact>
                    <AdminDashboardPacks />
                  </Route>
                )}
                {sessionUser.role !== "reporter" &&
                  (sessionUser.level === "level0" ||
                    sessionUser.level === "level1" ||
                    sessionUser.level === "level2" ||
                    sessionUser.role === "super_admin") && (
                    <Route path="/admin/dashboard-user-points" exact>
                      <AdminDashboardUserPoints />
                    </Route>
                  )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-bot-report" exact>
                    <AdminDashboardBotReport />
                  </Route>
                )}
                {sessionUser.role !== "reporter" &&
                  (sessionUser.level === "level2" ||
                    sessionUser.role === "super_admin") && (
                    <Route path="/admin/forensics-chart/nfts">
                      <AdminForensicsChartNfts />
                    </Route>
                  )}
                {sessionUser.role !== "reporter" &&
                  (sessionUser.level === "level2" ||
                    sessionUser.role === "super_admin") && (
                    <Route path="/admin/forensics/:accounts?">
                      <AdminForensics />
                    </Route>
                  )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/mining-explorer">
                    <MiningExplorer />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/nfts" exact>
                    <AdminNfts />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/nfts/:assetId" exact>
                    <AdminNftDetail />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/realtime" exact>
                    <AdminRealtime />
                  </Route>
                )}
                {sessionUser.level === "level1" ||
                sessionUser.level === "level2" ||
                sessionUser.role === "super_admin" ? (
                  <Route path="/admin/account-scanner/:account?">
                    <AccountDetails />
                  </Route>
                ) : null}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/token/holders/:code/:name" exact>
                    <AdminTokenHolders />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/oracle-nfts">
                    <OracleNfts />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/tool-calculator">
                    <ToolCalculator />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/template/:templateId" exact>
                    <AdminTemplateDetail />
                  </Route>
                )}
                <Route path="/admin/reports/reports" exact>
                  <AdminReports />
                </Route>
                {sessionUser.level !== "level0" ||
                sessionUser.role === "super_admin" ? (
                  <Route path="/admin/reports/check-bots" exact>
                    <AdminCheckBots />
                  </Route>
                ) : null}
                {/* <Route path="/admin/reports/compound-reports/preview">
                  <AdminCompoundReportsPreview />
                </Route>
                <Route path="/admin/reports/compound-reports/:jobId">
                  <AdminCompoundReportsView />
                </Route> */}
                <Route path="/admin/reports/compound-reports">
                  <AdminCompoundReports />
                </Route>
                <Route path="/admin/reports/sheets-manager" exact>
                  <AdminSheetsManager />
                </Route>
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/self-test" exact>
                    <AdminSelfTest />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/test" exact>
                    <AdminTest />
                  </Route>
                )}
                {sessionUser.role !== "reporter" && (
                  <Route path="/admin/dashboard-kpis" exact>
                    <AdminDashboardKpis />
                  </Route>
                )}
                {["level0", "level1", "level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tag-lookup/:keyword?" exact>
                    <AdminTagLookup />
                  </Route>
                ) : null}
                {["level0", "level1", "level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tag-bulk" exact>
                    <AdminTagBulk />
                  </Route>
                ) : null}
                {["level0", "level1", "level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/transfers-lookup">
                    <AdminTransfersLookup />
                  </Route>
                ) : null}
                {["level0", "level1", "level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/stakes-lookup">
                    <AdminStakesLookup />
                  </Route>
                ) : null}
                {["level0", "level1", "level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/visitors-lookup">
                    <AdminVisitorsLookup />
                  </Route>
                ) : null}

                {["level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tower/report">
                    <AdminTowerReport />
                  </Route>
                ) : null}

                {["level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tower/manage">
                    <AdminTowerManage />
                  </Route>
                ) : null}

                {[].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tower/debug">
                    <AdminTowerDebug />
                  </Route>
                ) : null}

                {["level2"].includes(sessionUser.level) ||
                ["super_admin"].includes(sessionUser.role) ? (
                  <Route path="/admin/tower/appeals">
                    <AdminTowerAppeals />
                  </Route>
                ) : null}

                <Route path="/admin/error/500" exact>
                  <Error500 />
                </Route>
                <Route path="/admin/tokenomics" exact>
                  <Tokenomics />
                </Route>
                <Route path="/admin/tokenomics-new" exact>
                  <TokenomicsNew />
                </Route>
                <Route path="/admin/help-texts" exact>
                  <AdminHelpTexts />
                </Route>
                <Route path="/admin/sheets-demo" exact>
                  <AdminSheetsDemo />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Popups components start*/}
      <ErrorIpfsImage />
      <LargeResultWindowElasticSearchModal />
      {["level2"].includes(sessionUser.level) ||
      ["super_admin"].includes(sessionUser.role) ? (
        <HelpTextEditModal />
      ) : null}

      {/* Modal Popups components end*/}
    </>
  );
}

export default AdminDefaultLayout;
