import CardMenu from "admin/components/card-menu";
import TotalMines from "./total-mines";
import TotalTlm from "./total-tlm";
import TotalCommission from "./total-commission";

export const LandStats = (props) => {
    const componentProps = {
        component: "views/nft-detail/components/land-stats",
        options: {},
        grid_options: { x: 0, y: 0, w: 12, h: 5 },
    };

    return (
                <div className="card bg-transparent">
                    <div className="card-body">
                        <CardMenu {...componentProps} {...props} />
                        <h4 className="header-title">
                            Land stats
                            <button
                            type="button"
                            className="btn btn-outline-none"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Land stats tooltip"
                            style={{ boxShadow: "none" }}
                            >
                            <i class="fas fa-info-circle"></i>
                            </button>
                        </h4>
                        <div className="row">
                            <div class="col-xl-4 col-md-6">
                                <TotalMines land_id={props.land_id} />
                            </div>
                            <div class="col-xl-4 col-md-6">
                                <TotalTlm land_id={props.land_id} />
                            </div>
                            <div class="col-xl-4 col-md-6">
                                <TotalCommission land_id={props.land_id} />
                            </div>
                        </div>
                    </div>
                </div>
    );
};

export default LandStats;
