import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";
import usePrevious from "app/hooks/usePrevious";

export const TopMiners = (props) => {
  const componentProps = {
    component: "components/mining/top_miners",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "top_miners" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const topMiners = useQuery(
    [`top_miners`, filters],
    async () => {
      const response = await ApiService.get("logmines/topMiners", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      //initialData: new Array(10).fill(0),
    }
  );

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topMiners.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Top Miners` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Miner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [topMiners.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_miners_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("top_miners", 1);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Top Miners`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows the top 10 miners based on TLM earned in the selected time-frame. In addition to the total, it also shows the minimum, maximum and average bounty. The total mines show the total mine actions for a miner."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="top_miners"
          >
            <thead>
              <tr>
                <th className="no-sort">
                  Miner &nbsp;
                  <Link to="" onClick={copyWalletsToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy miners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Total(TLM)</th>
                <th>Min(TLM)</th>
                <th>Max(TLM)</th>
                <th>Avg(TLM)</th>
                <th>Days Mined</th>
                <th>Total commission</th>
              </tr>
            </thead>
            <tbody>
              {topMiners.data &&
                topMiners.data.map((topMiner, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/admin/account-scanner/${topMiner.key}`}>
                          {topMiner.key}
                        </Link>
                      </td>
                      <td>
                        {parseFloat(topMiner.total_bounty.value).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                      <td>
                        {parseFloat(topMiner.min_bounty.value).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                      <td>
                        {parseFloat(topMiner.max_bounty.value).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          topMiner.average_bounty.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(topMiner.total_days.value).toLocaleString()}
                      </td>
                      <td>
                        {parseFloat(
                          topMiner.total_commission.value
                        ).toLocaleString()}
                      </td>
                    </tr>
                  );
                })}

              {topMiners.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {topMiners.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default TopMiners;
