import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";
import AdminNftsGridCard from "../../nfts/nfts-grid-card";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeAtomicassetsNfts = (props) => {
  const componentProps = {
    component: "components/realtime/atomicassets/nfts",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const [isPaused, setPaused] = useState(false);
  //const [groupTemplates, setGroupTemplates] = useState(false);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    var socket = SocketService.getSocket("/atomicassets");

    if (socket.connected) {
      socket.emit("join", "latestAssets");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "latestAssets");
      });
    }

    if (isPaused) {
      socket.off("latestAssets");
    } else {
      socket.on("latestAssets", function (data) {
        setAssets(data);
      });
    }

    return () => {
      socket.off("latestAssets");
      socket.emit("leave", "latestAssets");
    };
  }, [isPaused]);

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-end me-2">
            <form className="d-flex align-items-center flex-wrap">
              <button
                type="button"
                className="btn btn-sm btn-success mb-2 mb-sm-0"
                onClick={() => setPaused(!isPaused)}
              >
                {isPaused ? "Unpause" : "Pause"}
              </button>
            </form>
          </div>
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Latest NFTs (last 15 mins)`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          {/* <div className="row mb-2">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <div className="float-sm-end">
                <form className="d-flex align-items-center flex-wrap">                  
                  <button
                    type="button"
                    className="btn btn-sm btn-success mb-2 mb-sm-0"
                    onClick={() => setPaused(!isPaused)}
                  >
                    {isPaused ? "Unpause" : "Pause"}
                  </button>
                </form>
              </div>
            </div>
          </div> */}
          <div className="row">
            {assets.map((asset, index) => (
              <div className="col-xl-3 col-md-6" key={index}>
                <AdminNftsGridCard
                  asset={asset}
                  sort={"minted_at_time"}
                  badge={asset.total_assets}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeAtomicassetsNfts;
