import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "admin/services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";
import { useHelpTexts } from "app/hooks/useSession";
import ApiService, { apiHeaders } from "app/services/api";
import config from "app/config";

export const AdminSelfTestTowerScores = () => {
  const componentProps = {
    component: "components/self-test/tower-scores",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();

  // const results = useQuery(
  //   "tower_scores",
  //   async () => {
  //     const response = await ApiService.get(`self-test/tower-scores`, {
  //       timestamp: moment.utc().valueOf(),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     return response.json();
  //   },
  //   {}
  // );

  // if (dataTableRef.current) {
  //   dataTableRef.current.destroy();
  //   dataTableRef.current = null;
  // }

  useEffect(() => {
    // if (!results.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
          lengthMenu: [10, 100, 500, 1000],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/self-test/tower-scores`,
            type: "GET",
            headers: {
              ...apiHeaders(),
            },
          },
          columns: [
            {
              data: "miner",
              name: "miner",
              render: function (data, type, row) {
                return `<a href="/admin/account-scanner/${data}" target="_blank">${data}</a>`;
              },
            },
            { data: "score", name: "score" },
          ],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title">Tower Scores</h4>
            <table
              class="table dt-responsive  w-100"
              ref={tableRef}
              id="tower_scores"
            >
              <thead>
                <tr>
                  <th>Miner</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {/* {results.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {results.data &&
                      results.data.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td>{row.miner}</td>
                            <td>{row.score}</td>
                          </tr>
                        );
                      })}
                  </>
                )} */}
              </tbody>
            </table>
            {/* {results.isError ? <Error500 /> : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSelfTestTowerScores;
