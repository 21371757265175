import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const TopLandownersActivity = (props) => {
  const componentProps = {
    component: "components/landowner/top-landowners-activity",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "activity" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  const topLandowners = useQuery(
    [`top_landowners_activity`, filters],
    async () => {
      const response = await ApiService.get(
        "landowner/top-landowners-activity",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topLandowners.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          columnDefs: [{ targets: "no-sort", orderable: false }],
          // "processing": true,
          // "serverSide": true,
          // "ajax": {
          //   "url": `${config.api.url}/api/${config.api.version}/landowner/top-landowners-activity`,
          //   "headers": {
          //     ...apiHeaders(),
          //   },
          //   "data": function ( data ) {
          //     data.startDate = filters.dates.startDate;
          //     data.endDate = filters.dates.endDate;
          //   }
          // },
          // "columns": [
          //   {"data": "landowner", "name": "landowner"},
          //   {"data": "land_count", "name": "land_count"},
          //   {"data": "total_bounty", "name": "total_bounty"},
          //   {"data": "min_bounty", "name": "min_bounty"},
          //   {"data": "max_bounty", "name": "max_bounty"},
          //   {"data": "avg_bounty", "name": "average_bounty"},
          //   {"data": "total_commission", "name": "total_commission"},
          //   {"data": "average_commission_percentage", "name": "average_commission_percentage"}
          // ],
          // "columnDefs": [
          //   {
          //       targets: 0,
          //       orderable: false,
          //       createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
          //           window.$(cell).empty();

          //           var data = `<a href="/admin/account-scanner/${rowData.landowner}">${rowData.landowner}</a>`;

          //           window.$(cell).prepend(data);
          //       }
          //   }
          // ],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Landowner activity` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Landowner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [topLandowners.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#activity_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("activity", 1);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Landowner activity`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows the land owners. A single account could have multiple land allocated on different planets."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            class="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="activity"
          >
            <thead>
              <tr>
                <th className="no-sort">
                  Landowner &nbsp;
                  <Link to="" onClick={copyWalletsToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy landowners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Num of Lands</th>
                <th>Total</th>
                <th>Min</th>
                <th>Max</th>
                <th>Avg</th>
                <th>Commission</th>
                <th>Avg Commission %</th>
              </tr>
            </thead>
            <tbody>
              {topLandowners.data &&
                topLandowners.data.map((topLandowner, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/admin/account-scanner/${topLandowner.key}`}>
                          {topLandowner.key}
                        </Link>
                      </td>
                      <td>{topLandowner.land_count.value}</td>
                      <td>
                        {parseFloat(
                          topLandowner.total_bounty.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(
                          topLandowner.min_bounty.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(
                          topLandowner.max_bounty.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(
                          topLandowner.average_bounty.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(
                          topLandowner.total_commission.value
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                        })}
                      </td>
                      <td>
                        {parseFloat(
                          topLandowner.average_commission_percentage.value / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  );
                })}

              {topLandowners.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {topLandowners.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default TopLandownersActivity;
