import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import TopMiners from "./top-miners";
import FirstFiveUsersWith200Mines from "./first-five-users-with-200-mines";
import ReportsService from "../../../services/reports";
import Emitter from "app/services/emitter";
import { copyToClipboard } from "app/utils/datatables";

export const LatestMines = (props) => {

  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevquery = usePrevious(props.query);
  const time = moment().format("YYYY-MM-DD hh:mm:ss");
  const [ showMore, setShowMore ] = useState(false);

  console.log(props.query);

  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }
  
    return headers;
  };

  const latestMines = useQuery(
    [`mining_explorer`, props.query],
    async () => {
      const response = await ApiService.get("mining/latest-mines-aggregation", {
        query: props.query
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevquery !== props.query) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!latestMines.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current || window.$(tableRef.current).DataTable({
          "scrollX": true,
          "language": {
            "paginate": {
                "previous": "<i class='mdi mdi-chevron-left'>",
                "next": "<i class='mdi mdi-chevron-right'>"
            }
          },
          "order": [],
          "lengthMenu": [ 10, 25, 50, 100, 500 ],
          "searching": false,
          "processing": true,
          "serverSide": true,
          "ajax": {
            "url": `${config.api.url}/api/${config.api.version}/mining/latest-mines`,
            "headers": {
              ...apiHeaders(),
            },
            "data": function ( data ) {
              data.query = props.query;
              data.time = time;
            }
          },
          "columns": [
            {"data": "timestamp", "name": "timestamp"},
            {"data": "miner", "name": "act_data_miner"},
            {"data": "tool1", "name": "act_data_bag_items_0"},
            {"data": "tool2", "name": "act_data_bag_items_1"},
            {"data": "tool3", "name": "act_data_bag_items_2"},
            {"data": "bounty", "name": "act_data_bounty"},
            {"data": "planet", "name": "act_data_planet_name"},
            {"data": "land", "name": "act_data_land_id"},
            {"data": "landowner", "name": "act_data_landowner"},
            {"data": "commission", "name": "act_data_params_commission"},
            {"data": "total_points", "name": "total_points"},
            {"data": "daily_points", "name": "daily_points"}
          ],
          "columnDefs": [
            {
                targets: 0,
                createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                    window.$(cell).empty();

                    var data = `${moment
                      .tz(rowData.timestamp, "UTC")
                      .format("YYYY-MM-DD HH:mm:ss")}`;

                    window.$(cell).prepend(data);
                    window.$(cell).attr('title', moment(rowData.timestamp).fromNow());
                }
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = `<a href="/admin/account-scanner/${rowData.miner}">${rowData.miner}</a>`;

                  window.$(cell).prepend(data);
              }
            },
            {
              targets: 2,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.tool1 ? `<a href="/admin/nfts/${rowData.tool1}">${rowData.tool1_short_name}</a>` : "";

                  window.$(cell).prepend(data);
                  window.$(cell).attr('title', rowData.tool1 ? rowData.tool1_name : '');
              }
            },
            {
              targets: 3,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.tool2 ? `<a href="/admin/nfts/${rowData.tool2}">${rowData.tool2_short_name}</a>` : "";

                  window.$(cell).prepend(data);
                  window.$(cell).attr('title', rowData.tool2 ? rowData.tool2_name : '');
              }
            },
            {
              targets: 4,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.tool3 ? `<a href="/admin/nfts/${rowData.tool3}">${rowData.tool3_short_name}</a>` : "";

                  window.$(cell).prepend(data);
                  window.$(cell).attr('title', rowData.tool3 ? rowData.tool3_name : '');
              }
            },
            {
              targets: 7,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.land ? `<a href="/admin/nfts/${rowData.land}">${rowData.land_short_name}</a>` : "";

                  window.$(cell).prepend(data);
                  window.$(cell).attr('title', rowData.land ? rowData.land_name : '');
              }
            },
            {
              targets: 8,
              orderable: false,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = `<a href="/admin/account-scanner/${rowData.landowner}">${rowData.landowner}</a>`;

                  window.$(cell).prepend(data);
              }
            },
            {
              targets: 10,
              orderable: false,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.total_points / 10;

                  window.$(cell).prepend(data);
              }
            },
            {
              targets: 11,
              orderable: false,
              createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                  window.$(cell).empty();

                  var data = rowData.daily_points / 10;

                  window.$(cell).prepend(data);
              }
            }
          ],
          "dom": 'Blfrtip',
          "buttons": ['copy', {extend:"csv", title:`Latest Mines`},
            {
              extend: 'copy',
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Miner")',
                rows: ':visible',
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                }
              }
            },
            {
              extend: 'copy',
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Land Owner")',
                rows: ':visible',
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                }
              }
            }
          ]
        });
      window.$('.buttons-copy').hide();  
      window.$('.buttons-csv').hide(); 
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    setShowMore(false);
  }, [props.query]);

  const copyMinersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard('top_mines', 1);
  };
  
  const copyLandownersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard('top_mines', 2);
  };

  const exportCSV = async () => {
    try {
      const response = await ReportsService.requestReport({
        type: "reportExportMining",
        format: "csv",
        filters: props.query != '' ? JSON.parse(props.query) : {}
      });
      if (!response) {
          throw Error("Unable to process");
      }

      Emitter.emit("REQUEST_REPORT_SUCCESS");
      
      window.$.toast({
        heading: "Success",
        text: "Request created Successfully. <a href='/admin/reports/reports'><button class='btn btn-xs btn-primary'>View reports</button></a>",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Export failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          {props.query != '' ? (
          <div className="float-end me-2">
            <form className="d-flex align-items-center flex-wrap">
              <button
                type="button"
                className="btn btn-sm btn-success mb-2 mb-sm-0"
                onClick={exportCSV}
              >
                Export as csv
              </button>
            </form>
          </div>
          ) : null}
          <h6 className="header-title" id="top_mines_title">
            {latestMines.data ? latestMines.data.total_text :  ''}
          </h6>
          <table class="table nowrap w-100" ref={tableRef} id="top_mines">
            <thead>
              <tr>
                <th>Time</th>
                <th>
                  Miner
                  &nbsp;
                  <Link to="" onClick={copyMinersToClipboard}>
                      <i className="fas fa-copy" title="Copy miners to clipboard"></i>
                  </Link>
                </th>
                <th>Tool 1</th>
                <th>Tool 2</th>
                <th>Tool 3</th>
                <th>Bounty</th>
                <th>Planet</th>
                <th>Land</th>
                <th>
                  Land Owner
                  &nbsp;
                  <Link to="" onClick={copyLandownersToClipboard}>
                      <i className="fas fa-copy" title="Copy landowners to clipboard"></i>
                  </Link>
                </th>
                <th>C%</th>
                <th>Total UP</th>
                <th>Daily UP</th>
              </tr>
            </thead>
            <tbody>
              {/* {latestMines.data && latestMines.data.mines.map((latestMine, index) => {
              return (
                <tr key={index}>
                  <td title={moment(latestMine.timestamp).fromNow()}>
                      {moment
                        .tz(latestMine.timestamp, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td><Link to={`/admin/account-scanner/${latestMine.miner}`}>{latestMine.miner}</Link></td>
                  <td title={latestMine.tool1 ? latestMine.tool1_name : ''}>{latestMine.tool1 ? (<Link to={`/admin/nfts/${latestMine.tool1}`}>{latestMine.tool1_short_name}</Link>) : ""}</td>
                  <td title={latestMine.tool2 ? latestMine.tool2_name : ''}>{latestMine.tool2 ? (<Link to={`/admin/nfts/${latestMine.tool2}`}>{latestMine.tool2_short_name}</Link>) : ""}</td>
                  <td title={latestMine.tool3 ? latestMine.tool3_name : ''}>{latestMine.tool3 ? (<Link to={`/admin/nfts/${latestMine.tool3}`}>{latestMine.tool3_short_name}</Link>) : ""}</td>
                  <td>{latestMine.bounty}</td>
                  <td>{latestMine.planet}</td>
                  <td title={latestMine.land ? latestMine.land_name : ''}>{latestMine.land ? (<Link to={`/admin/nfts/${latestMine.land}`}>{latestMine.land_short_name}</Link>) : ""}</td>
                  <td><Link to={`/admin/account-scanner/${latestMine.landowner}`}>{latestMine.landowner}</Link></td>
                  <td>{latestMine.commission}</td>
                </tr>
              );
              })}

              {latestMines.isFetching ? (
              <>
                  {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                      <td><Skeleton/></td>
                  </tr>
                  ))}
              </>
              ) : null} */}
            </tbody>
            <tfoot>
              <tr>
                <th>{latestMines.data ? latestMines.data.time_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Time'}</th>
                <th>{latestMines.data ? latestMines.data.miner_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Miner'}</th>
                <th>Tool 1</th>
                <th>Tool 2</th>
                <th>Tool 3</th>
                <th>{latestMines.data ? latestMines.data.bounty_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Bounty'}</th>
                <th>{latestMines.data ? latestMines.data.planet_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Planet'}</th>
                <th>{latestMines.data ? latestMines.data.land_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Land'}</th>
                <th>{latestMines.data ? latestMines.data.landowner_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'Land Owner'}</th>
                <th>{latestMines.data ? latestMines.data.commission_text.split('<br>').map(str => <p className="mb-0">{str}</p>) :  'C%'}</th>
                <th>Total UP</th>
                <th>Daily UP</th>
              </tr>
              {/* <tr>
                <th colSpan="10">
                  {latestMines.data && latestMines.data.time_text != 'Time' ? (
                  <button
                    type="buttom"
                    className="btn btn-success mb-sm-0 mx-auto d-block"
                    onClick={() => setShowMore(true)}
                  >
                  View more stats
                  </button>
                  ) : null}
                </th>
              </tr> */}
            </tfoot>
          </table>
          {latestMines.data && latestMines.data.time_text != 'Time' ? (
            <button
              type="buttom"
              className="btn btn-success mb-sm-0 mx-auto d-block"
              onClick={() => setShowMore(true)}
            >
            View more stats
            </button>
          ) : null}
          {/* {latestMines.isError ? <Error500 /> : null} */}
          
        </div>
      </div>

      {showMore ? (
      <>
      <TopMiners query={props.query} />
      <FirstFiveUsersWith200Mines query={props.query} />
      </>
      ) : null}
    </>
  );
};

export default LatestMines;
