import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DaoChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/dao/dao-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const [data, setData] = useState({ categories: [], data: [] });
  const [showLinear, setShowLinear] = useState(true);

  const planets = useQuery([`dao_chart`, filters], async () => {
    const response = await ApiService.get(`dao/dao-chart`, {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!planets.data) return;

    setData({ categories: planets.data.categories, data: planets.data.data });

    chartComponent.current.chart.hideLoading();
  }, [planets.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `DAO activity chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This chart shows the DAO stats"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {planets.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  xAxis: {
                    categories: data.categories,
                    title: {
                      text: "Planets",
                    },
                  },
                  yAxis: [
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#2b908f",
                      title: {
                        text: "Active wallets",
                        style: {
                          color: "#2b908f",
                        },
                      },
                      labels: {
                        style: {
                          color: "#2b908f",
                        },
                      },
                    },
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#90ee7e",
                      title: {
                        text: "+Supply",
                        style: {
                          color: "#90ee7e",
                        },
                      },
                      labels: {
                        style: {
                          color: "#90ee7e",
                        },
                      },
                      opposite: true,
                    },
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#f45b5b",
                      title: {
                        text: "-Supply",
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      labels: {
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      opposite: true,
                    },
                  ],
                  series: data.data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaoChart;
