import CardMenu from "admin/components/card-menu";
import PendingOutsideWaxTransactions from "./pending-outside-wax-transactions";
import PendingTowardsWaxTransactions from "./pending-towards-wax-transactions";
import PendingOutsideWaxTlm from "./pending-outside-wax-tlm";
import PendingTowardsWaxTlm from "./pending-towards-wax-tlm";
import StuckTeleports from "./stuck-teleports";
import UnclaimedTeleports from "./unclaimed-teleports";
import NumOracles from "./num-oracles";
import WaxTeleports from "./wax-teleports";
import ClaimedTeleports from "./claimed-teleports";
import { useHelpTexts } from "app/hooks/useSession";

export const TeleportStats = (props) => {
  const componentProps = {
    component: "components/teleport/teleport-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Teleport stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Pending outside WAX transactions - Number of pending outside WAX transactions. <br>
                            Pending towards WAX transactions - Number of pending towards WAX transactions. <br>
                            Pending outside WAX TLM - Amount of pending outside WAX TLM. <br>
                            Pending towards WAX TLM - Amount of pending towards WAX TLM. <br>
                            Stuck teleports - Number of teleports without 3 oracle signatures waiting for more than 5 minutes. <br>
                            Unclaimed teleports - Number of unclaimed teleports. <br>
                            Number of oracles - Number of oracles available for signature signing. <br>
                            Total WAX teleports - Number of teleports in WAX chain in selected time frame. <br>
                            Total claimed teleports - Number of claimed teleports in WAX chain in selected time frame. <br>
                            `
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <PendingOutsideWaxTransactions />
          </div>
          <div class="col-xl-4 col-md-6">
            <PendingTowardsWaxTransactions />
          </div>
          <div class="col-xl-4 col-md-6">
            <PendingOutsideWaxTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <PendingTowardsWaxTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <StuckTeleports />
          </div>
          <div class="col-xl-4 col-md-6">
            <UnclaimedTeleports />
          </div>
          <div class="col-xl-4 col-md-6">
            <NumOracles />
          </div>
          <div class="col-xl-4 col-md-6">
            <WaxTeleports />
          </div>
          <div class="col-xl-4 col-md-6">
            <ClaimedTeleports />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeleportStats;
