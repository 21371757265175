import ApiService from "../../app/services/api";

export const AccountService = {
  getStats: async (account, filters) => {
    const response = await ApiService.get("account/stats/" + account, filters);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getUserpointStats: async (account) => {
    const response = await ApiService.get("account/user-point-stats/" + account, {});
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getMines: async (account, filters) => {
    const response = await ApiService.get("account/mines/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getMiningChart: async (account, filters) => {
    const response = await ApiService.get("account/mining-chart/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getNfts: async (account, filters) => {
    const response = await ApiService.get("account/nfts/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getTransfers: async (account, filters) => {
    const response = await ApiService.get("account/transfers/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getTlmSwapped: async (account, filters) => {
    const response = await ApiService.get("account/tlm-swapped/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getIncommingTeleports: async (account, filters) => {
    const response = await ApiService.get("account/incomming-teleports/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getOutgoingTeleports: async (account, filters) => {
    const response = await ApiService.get("account/outgoing-teleports/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getFromChain: async (account) => {
    const response = await ApiService.get("account/chain/" + account, {});
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getTools: async (account, filters) => {
    const response = await ApiService.get("account/tools/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getProfile: async (account, filters) => {
    const response = await ApiService.get("account/profile/" + account, filters);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  checkAccount: async (account) => {
    const response = await ApiService.get("account/check-account/" + account, {});
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getAssetsTransfers: async (account, filters) => {
    const response = await ApiService.get("account/atomicassets/transfers/" + account, filters);
    // if (!response.ok) {
    //     return;
    // }
    return response;
  },
  getConnectedAssetsTransfers: async (account, filters) => {
    const response = await ApiService.get("account/atomicassets/connected-transfers/" + account, filters);
    // if (!response.ok) {
    //     return;
    // }
    return response;
  },
  getChainAccount: async (account, params) => {
    const response = await ApiService.get("chain/account/" + account, params);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getChainUserres: async (account, params) => {
    const response = await ApiService.get("chain/userres/" + account, params);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getChainDelband: async (account, params) => {
    const response = await ApiService.get("chain/delband/" + account, params);
    if (!response.ok) {
      return;
    }
    return response.json();
  },    
  getMinesPerHour: async (account, filters) => {
    const response = await ApiService.get("account/mining-hours/" + account, filters);
    // if (!response.ok) {
    //     return;
    // }
    return response;
  },
  getUnclaimedNfts: async (account) => {
    const response = await ApiService.get("account/unclaimed-nfts/" + account, {});
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getExchangeMemo: async (account, filters) => {
    const response = await ApiService.get("transfers/memos_by_account/" + account, filters);

    return response;
  },
  getSharedNewUsers: async (account, filters) => {
    const response = await ApiService.get("account/shared-new-users-wax/" + account, filters);

    return response;
  },
  getOneHopAccounts: async (account, filters) => {
    const response = await ApiService.get("account/one-hop-accounts/" + account, filters);

    return response;
  },
  getEthBscStats: async (account, filters) => {
    const response = await ApiService.get("account/eth-bsc-stats/" + account, filters);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getMissions: async (account, filters) => {
    const response = await ApiService.get("account/missions/" + account, filters);

    return response;
  },
  getMissionsNft: async (account, filters) => {
    const response = await ApiService.get("account/missions-nft/" + account, filters);

    return response;
  },
  getInboundTransfers: async (account, filters) => {
    const response = await ApiService.get("account/eth-bsc-inbound-transfers/" + account, filters);

    return response;
  },
  getOutboundTransfers: async (account, filters) => {
    const response = await ApiService.get("account/eth-bsc-outbound-transfers/" + account, filters);

    return response;
  },
  getTlmSwaps: async (account, filters) => {
    const response = await ApiService.get("account/eth-bsc-tlm-swaps/" + account, filters);

    return response;
  },
  getStakedInfo: async (account) => {
    const response = await ApiService.get("account/staked-info/" + account);

    return response;
  },
  getConnectedMalformedTxs: async (account) => {
    const response = await ApiService.get("account/connected-malformed-txs/" + account);

    return response;
  },
  getCreatedAccounts: async (account, params) => {
    const response = await ApiService.get("account/created-accounts/" + account, params);

    return response;
  },
  getAppeals: async (account, params) => {
    const response = await ApiService.get("account/appeals/" + account, params);

    return response;
  },
  getBotTasks: async (account, params) => {
    const response = await ApiService.get("account/bot-tasks/" + account, params);

    return response;
  },
};

export default AccountService;
