import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";

export const TotalLanOwners = () => {
  const filters = useFilters();

  const stats = useQuery([`mining_stats_totalLandOwners`, filters], async () => {
    const response = await ApiService.get("logmines/stats/totalLandOwners", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
    });
    if (!response.ok) {      
        throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
          <div className="widget-simple text-center card">
              <div className="card-body">
                {stats.status === "loading" ? (
                    <>
                        <h3 className="text-success mt-0">
                            <Skeleton />
                        </h3>
                        <p className="text-muted mb-0">Total active landowners</p>
                    </>
                ) : (
                    <>
                        <h3 class="text-success mt-0">
                            <span data-plugin="counterup">{stats.data.total_landowners}</span>
                        </h3>
                        <p class="text-muted mb-0">Total active landowners</p>
                    </>
                )}
              </div>
          </div>
  );
};

export default TotalLanOwners;
