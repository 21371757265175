import ApiService from "app/services/api";
import FastApiService from "app/services/fast_api";
import moment from "moment";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

function AdminSearch() {
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [autocompleteItems, setAutocompleteItems] = useState({});
  const [showAutocompleteItems, setShowAutocompleteItems] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Method 1 - from scratch
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const autocompleteSearch = async (el) => {
    try {
      setIsLoading(true);
      setKeyword(el.target.value);
      const keyword = el.target.value.toLowerCase();

      const response = await FastApiService.get("autocomplete/search", {
        keyword: keyword,
        timestamp: moment.utc().valueOf(),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setAutocompleteItems(data);

      // window.$(".autocompletesearch-slimscroll").slimScroll({
      //   height: "auto",
      //   position: "right",
      //   size: "8px",
      //   touchScrollStep: 20,
      //   color: "#9ea5ab",
      // });

      setIsLoading(false);
    } catch (err) {}
  };

  const optimizedSearch = useCallback(debounce(autocompleteSearch), {});

  const selectItem = (item, key) => {
    setShowAutocompleteItems(false);

    switch (key) {
      case "accounts":
        history.push(`/admin/account-scanner/${item.scope}`);
        break;
      case "helpTexts":
        history.push(item["page_url"]);
        break;
      default:
        break;
    }

    setKeyword("");
  };

  const handleOnFocus = () => {
    setShowAutocompleteItems(true);
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setShowAutocompleteItems(false);
    }, 1000);
  };

  return (
    <>
      {/* <li className="dropdown d-none d-lg-inline-block">
        <a
          className="nav-link dropdown-toggle arrow-none waves-effect waves-light"         
          href="#"
        >
          <i className="fe-search fa-lg"></i>
        </a>
      </li> */}

      <li className="d-none d-lg-block">
        <form className="app-search">
          <div className="app-search-box dropdown notification-list show">
            <div className="input-group">
              <input
                type="search"
                className="form-control dropdown-toggle"
                placeholder="Search..."
                //id="top-search"
                //value={keyword}
                //onChange={autocompleteSearch}
                onChange={optimizedSearch}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
              />

              <button className="btn" type="submit">
                <i className="fe-search"></i>
              </button>
            </div>

            <div
              className={`dropdown-menu dropdown-menu-right dropdown-lg ${
                keyword.length > 0 && showAutocompleteItems ? "show" : "d-none"
              }`}
            >
              <div className="dropdown-item noti-title">
                <h5 className="m-0">Search results</h5>
              </div>

              <div
                className={`d-flex justify-content-center ${
                  isLoading ? "show" : "d-none"
                }`}
              >
                <div className="spinner-border" role="status" />
              </div>

              <div
                class="slimscroll noti-scroll autocompletesearch-slimscroll "
                style={{ maxHeight: "270px", overflow: "hidden" }}
              >
                {Object.keys(autocompleteItems).map(
                  (autocompleteItemKey, index) => (
                    <>
                      {autocompleteItems[autocompleteItemKey].map(
                        (autocompleteItem, index) => (
                          <Link
                            className="dropdown-item notify-item"
                            key={index}
                            onClick={() =>
                              selectItem(autocompleteItem, autocompleteItemKey)
                            }
                          >
                            <p
                              className="notify-details"
                              style={{ marginLeft: "0px" }}
                            >
                              {
                                {
                                  accounts: (
                                    <>
                                      Account: {autocompleteItem["scope"]}
                                      <small className="text-muted">
                                        /admin/account-scanner/
                                        {autocompleteItem["scope"]}
                                      </small>
                                    </>
                                  ),
                                  helpTexts: (
                                    <>
                                      {autocompleteItem["title"]}
                                      <small className="text-muted">
                                        {autocompleteItem["page_url"]}
                                      </small>
                                    </>
                                  ),
                                }[autocompleteItemKey]
                              }
                            </p>
                          </Link>
                        )
                      )}
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </form>
      </li>

      {/* <li className="dropdown d-inline-block d-lg-none">
        <a
          className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
          data-bs-toggle="dropdown"
          href="true"
          role="button"
          aria-haspopup="false"
          aria-expanded="false"
        >
          <i className="fe-search noti-icon"></i>
        </a>
        <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0 notification-list show">
          <form className="p-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search ..."
              aria-label="Search"
            />
          </form>

          
        </div>
      </li> */}
    </>
  );
}

export default AdminSearch;
