import LandownerAllocation from "../components/landowner/landowner-allocation";
import TopLandownerAllocation from "../components/landowner/top-landowner-allocation";
import TopLandownersActivity from "../components/landowner/top-landowners-activity";
import TopLandowners from "../components/landowner/top-landowners";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import TotalActiveLands from "admin/components/landowner/total-active-lands";
import TotalLandowners from "admin/components/landowner/total-landowners";
import TotalLands from "admin/components/landowner/total-lands";
import LandsalesChart from "admin/components/landowner/landsales-chart";
import TotalSalesStats from "admin/components/landowner/total-sales-stats";
import LandownerStats from "admin/components/landowner/landowner-stats";
import KpisSuperBoostNftAuctions from "admin/components/landowner/kpis-super-boost-nft-auctions";
import KpisMegaBoostNftAuctions from "admin/components/landowner/kpis-mega-boost-nft-auctions";

function AdminDashboardTLM() {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-landowner">
        Landowner
      </BreadcrumbsItem>
      <div className="row">
        {/* <div class="col-xl-4 col-md-6">
          <TotalLands />
        </div> */}
        {/*  <div class="col-xl-4 col-md-6">
          <TotalLandowners />
        </div>
        <div class="col-xl-4 col-md-6">
          <TotalActiveLands />
        </div>
        <TotalSalesStats /> */}
      </div>
      <div className="row">
        <div class="col-12">
          <LandownerStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <LandownerAllocation />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TopLandownersActivity />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TopLandowners />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TopLandownerAllocation />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <LandsalesChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <KpisSuperBoostNftAuctions />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <KpisMegaBoostNftAuctions />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardTLM;
