import "gridstack/dist/gridstack.min.css";
import { GridStack } from "gridstack";
import "gridstack/dist/h5/gridstack-dd-native";

import { useEffect, Suspense, lazy, useState, createRef, useRef } from "react";
import { useQuery } from "react-query";
import ApiService from "app/services/api";
import moment from "moment";
import Emitter from "app/services/emitter";
import { useDashboardComponentsCount } from "admin/hooks/useDashboardComponentsCount";
import { Prompt } from "react-router";
import Highcharts from "highcharts";

export const AdminDashboard = () => {
  const [dashboardChanged, setDashboardChanged] = useState(false);
  const dashboardComponents = useQuery(["dashboard_components"], () => {
    return ApiService.get("me/dashboard/components", {
      timestamp: moment.utc().valueOf(),
    })
      .then((response) => response.json())
      .catch();
  });

  const dashboardComponentsCount = useDashboardComponentsCount();

  const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

  var gridStackOptions = {
    width: 12,
    height: 12,
    float: false,
    animate: true,
    disableResize: isMobile()? true : false,
    disableDrag: isMobile()? true : false,
    cellHeight: 60,
    cellHeightUnit: "px",
    verticalMargin: 20,
    acceptWidgets: ".grid-stack-item",
    resizable: { handles: "e, se, s, sw, w" },
    alwaysShowResizeHandle:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
  };
  const gridCmptsRefs = useRef({});
  const gridRef = useRef();

  if (dashboardComponents.data) {
    // console.log(Object.keys(gridCmptsRefs.current).length,' ',  dashboardComponents.data.length)
    // if (
    //   Object.keys(gridCmptsRefs.current).length !==
    //   dashboardComponents.data.length
    // ) {
    dashboardComponents.data.forEach((dashboardComponent) => {
      gridCmptsRefs.current[dashboardComponent.id] =
        gridCmptsRefs.current[dashboardComponent.id] || createRef();
    });
    // }
  }

  if (gridRef.current) {
    const grid = gridRef.current;
    grid.on("resizestop", function (event, items) {
      window.dispatchEvent(new Event("resize"));
      setTimeout(() => {
        setDashboardChanged(true);
      });
    });
    grid.on("dragstop", function (event, items) {
      setTimeout(() => {
        setDashboardChanged(true);
      });
    });
  }

  useEffect(() => {
    gridRef.current = gridRef.current || GridStack.init(gridStackOptions);
    const grid = gridRef.current;

    grid.batchUpdate();
    grid.removeAll(false);
    if (dashboardComponents.data) {
      dashboardComponents.data.forEach((dashboardComponent) => {
        if (gridCmptsRefs.current[dashboardComponent.id]) {
          grid.addWidget(gridCmptsRefs.current[dashboardComponent.id].current);
        }
      });
    }
    grid.commit();

    setTimeout(() => {
      //window.$.App.init();
      window.$.Components.init();
    });

    window.$('.button-menu-mobile').click(function(){ 
      setTimeout(() => {
        window.$(Highcharts.charts).each(function(i,chart){
          chart.reflow();
        });
      }, 1000);
    });

    // grid.on("resizestop", function (event, el) {
    //   console.log("resizestop", el.gridstackNode);
    //   //let width = parseInt(el.getAttribute('gs-w')) || 0;
    //   // or all values...
    //   //let node = el.gridstackNode; // {x, y, width, height, id, ....}
    // });
  }, [dashboardComponents]);

  const removeFromDashboard = (dashboardComponent) => {
    try {
      const response = ApiService.delete("me/dashboard/components", {
        id: dashboardComponent.id,
        component: dashboardComponent.component,
      })
        .then((response) => response.json())
        .catch();

      if (response.status === "error") {
        throw new Error(response.message);
      }

      const grid = gridRef.current;

      grid.removeWidget(gridCmptsRefs.current[dashboardComponent.id].current);
      delete gridCmptsRefs.current[dashboardComponent.id];
      gridCmptsRefs.current[dashboardComponent.id] = null;
      //Object.keys(gridCmptsRefs.current).forEach((k) => gridCmptsRefs.current[k] == null && delete gridCmptsRefs.current[k]);
      // dashboardComponents.data = dashboardComponents.data.filter(function( data ) {
      //   return data.id !== dashboardComponent.id;
      // });
      // console.log("gridCmptsRefremoveFromDashboard", gridCmptsRefs);
      // console.log('dashboardComponents',dashboardComponents.data)
      dashboardComponents.refetch();

      Emitter.emit("REMOVE_FROM_DASHBOARD_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: `Removed from My Dashboard successfully`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Remove from My dashboard failed" + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  // if(dashboardComponents.data){
  //   if (Object.keys(refs.current).length !== dashboardComponents.data.length) {
  //     dashboardComponents.data.forEach(({ id }) => {
  //       refs.current[id] = refs.current[id] || createRef()
  //     })
  //   }
  // }

  // useEffect(() => {
  //   gridRef.current =
  //     gridRef.current ||
  //     GridStack.init(
  //       {
  //         float: true,
  //       },
  //       '.controlled'
  //     )
  //   const grid = gridRef.current
  //   grid.batchUpdate()
  //   grid.removeAll(false)
  //   if(dashboardComponents.data){
  //     dashboardComponents.data.forEach(({ id }) => grid.makeWidget(refs.current[id].current))
  //   }

  //   grid.commit()
  // }, [dashboardComponents.data])

  //const grid = GridStack.init();

  // useEffect(() => {
  //   //window.dispatchEvent(new Event("resize"));
  //   // const serializedData = [
  //   //   { x: 0, y: 0, w: 2, h: 2, content: "item 1" },
  //   //   { x: 2, y: 3, w: 3, content: "item 2" },
  //   //   { x: 1, y: 3, content: "item 3" },
  //   // ];

  //   // grid.load(serializedData);
  // }, []);

  const handleSaveDashboard = () => {
    const grid = gridRef.current;
    const serializedData = grid.save();

    try {
      const response = ApiService.put("me/dashboard/components", {
        serializedData: serializedData,
      })
        .then((response) => response.json())
        .catch();

      if (response.status === "error") {
        throw new Error(response.message);
      }

      setDashboardChanged(false);

      window.$.toast({
        heading: "Success",
        text: "Save dashboard success",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      window.$.toast({
        heading: "Error",
        text: "Save dashboard failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h4>Dashboard</h4>
        </div>
        {!isMobile()? (
        <div className="col-lg-6">
          <div class="float-sm-end">
            <form class="d-flex align-items-center flex-wrap">
              <button
                onClick={handleSaveDashboard}
                type="button"
                class={`btn mb-2 mb-sm-0 ${
                  dashboardChanged ? "btn-success" : "btn-light disabled"
                }`}
              >
                Save Dashboard
              </button>
            </form>
          </div>
        </div>
        ) : null}
      </div>

      {dashboardComponentsCount === 0 ? (
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-xl-4">
            <div className="text-center">
              <h4>Welcome to Yeomen</h4>
              <p>
                Use the menu on the left to navigate. You can add items to your
                dashboard from most of the sections on the left.
              </p>
            </div>
          </div>
        </div>
      ) : null}

      <div class="grid-stack">
        <Suspense fallback="Loading...">
          {dashboardComponents.data &&
            dashboardComponents.data.map((dashboardComponent, index) => {
              const DynamicLazyComponent = lazy(() =>
                import(`admin/${dashboardComponent.component}`)
                  .then((comp) => comp)
                  .catch(() => console.log("Error in importing"))
              );

              const id = `dashboard_component_${dashboardComponent.id}`;
              const options = dashboardComponent.options;
              const props = (options && options.props) || {};
              const gridOptions = Object.assign(
                { x: 0, y: 78, w: 4, h: 6 },
                Object(dashboardComponent.grid_options)
              );

              //const ref = createRef();
              // const ref = refs.current[id] = refs.current[id] || createRef()

              // const timerId = setTimeout(() => {
              //   if (ref.current) {
              //     grid.addWidget(ref.current);
              //     clearTimeout(timerId);
              //   }
              // });

              return (
                <div
                  key={index}
                  id={id}
                  //ref={ref}
                  ref={gridCmptsRefs.current[dashboardComponent.id]}
                  class="grid-stack-item"
                  gs-w={gridOptions.w}
                  gs-h={gridOptions.h}
                  gs-x={gridOptions.x}
                  gs-y={gridOptions.y}
                  gs-id={dashboardComponent.id}
                >
                  <div className="grid-stack-item-content">
                    <DynamicLazyComponent
                      key={index}
                      {...props}
                      dashboardComponent={dashboardComponent}
                      removeFromDashboard={removeFromDashboard}
                    />
                  </div>
                </div>
              );
            })}
        </Suspense>
        <Prompt when={dashboardChanged} message="Click on Save Dashboard to keep changes to the dashboard" />        
      </div>
    </>
  );
};

export default AdminDashboard;
