import { useEffect } from "react";
import MiningHours from "../../../components/charts/mining-hours";
import BotScoreTiming from "./bot-score-timing";
import AvgMineDelay from "./avg-mine-delay";
import AvgMineTime from "./avg-mine-time";
import CardMenu from "../../../components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const BotScore = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/bot-score",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });
  });

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Bot score`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Bot score`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "Bot score by timing looks at the delay imposed after each mine and how quickly this account mined subsequently."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div className="col-lg-6">
            <div className="card bg-transparent">
              <div className="card-body">
                <div className="row">
                  <div class="col-xl-4 col-md-6">
                    <BotScoreTiming account={props.account} />
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <AvgMineDelay account={props.account} />
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <AvgMineTime account={props.account} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <MiningHours account={props.account} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotScore;
