import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";
import { setReport } from "app/state/session";

export const TlmTransfers = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/tlm-transfers",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "tlm_transfers" },
  };
  const helpTexts = useHelpTexts();
  const dispatch = useDispatch();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  // const transfers = useQuery([`account_transfers_${props.account}`, props.account, filters], async () => {
  //   const response = await AccountService.getTransfers(props.account, {
  //       startDate: filters.dates.startDate,
  //       endDate: filters.dates.endDate,
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500, 1000],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/account/transfers/${props.account}`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = filters.dates.startDate;
              data.endDate = filters.dates.endDate;
            },
          },
          columns: [
            { data: "timestamp", name: "timestamp" },
            { data: "from", name: "transfer_from" },
            { data: "to", name: "transfer_to" },
            { data: "memo", name: "transfer_memo" },
            { data: "amount", name: "transfer_amount" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.timestamp).fromNow());
              },
            },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.from}" target="_blank">${rowData.from}</a>`;

                window.$(cell).prepend(data);
              },
            },
            {
              targets: 2,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.to}" target="_blank">${rowData.to}</a>`;

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Tlm Transfers for ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();

      dataTableRef.current.on("preDraw", function (e) {
        const info = window.$(tableRef.current).DataTable().page.info();
        if (info.start >= 10000) {
          dispatch(
            setReport({
              type: "reportTlmTransfers",
              format: "csv",
              filters: {
                start_datetime: moment(filters.dates.startDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss"),
                end_datetime: moment(filters.dates.endDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss"),
                account: props.account,
              },
            })
          );
          window.$("#large-result-window-elasticsearch-modal").modal("show");
          return false;
        }

        return true;
      });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.account, filters]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("tlm_transfers", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#tlm_transfers_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("tlm_transfers", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM Transfers`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `TLM Transfers`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the latest TLM transfers going into the account or out from this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="tlm_transfers"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Memo</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {/* {transfers.data && transfers.data.map((transfer, index) => {
            transfer = transfer._source;
            return (
                <tr key={index}>
                <td title={moment(transfer.timestamp).fromNow()}>{moment.tz(transfer.timestamp,"UTC").format("YYYY-MM-DD HH:mm:ss")}</td>
                <td><Link to={`/admin/account-scanner/${transfer.transfer_from}`} target="_blank">{transfer.transfer_from}</Link></td>
                <td><Link to={`/admin/account-scanner/${transfer.transfer_to}`} target="_blank">{transfer.transfer_to}</Link></td>
                <td>{transfer.transfer_memo}</td>
                <td>{transfer.transfer_amount}</td>
                </tr>
            );
            })}

            {transfers.isFetching ? (
            <>
                {new Array(5).fill(0).map((log, index) => (
                <tr key={index}>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                </tr>
                ))}
            </>
            ) : null} */}
          </tbody>
        </table>
        {/* {transfers.isError ? <Error500 /> : null} */}
        <Link to={"/admin/forensics/" + props.account}>
          <button
            type="buttom"
            className="btn btn-success mb-2 mb-sm-0 mx-auto d-block mt-2"
          >
            View on TLM Forensics
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TlmTransfers;
