import ApiService from "../../app/services/api";

export const TemplatesService = {
    getTemplate: async (templateId) => {
        const response = await ApiService.get("templates/" + templateId);
        if (!response.ok) {
            //throw new Error("Network response was not ok");
            return;
        }
        return response.json();
    },
    getTemplatePrice: async (templateId) => {
        const response = await ApiService.get("templates/" + templateId + "/price");
        if (!response.ok) {
            //throw new Error("Network response was not ok");
            return;
        }
        return response.json();
    },
}

export default TemplatesService;