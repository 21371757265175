import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";

export const PendingOutsideWaxTlm = () => {

  const stats = useQuery(['teleport_stats'], async () => {
    const response = await ApiService.get("teleport/stats");
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{(stats.data && stats.data.pending_outside_wax_tlm)? stats.data.pending_outside_wax_tlm.toLocaleString() : 0}</span>
                )}
                </h3>
                <p className="text-muted mb-0">Pending outside WAX TLM</p>
            </div>
        </div>
  );
};

export default PendingOutsideWaxTlm;
