import { useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import { setHelpTexts } from "app/state/session";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const HelpTextEditModal = () => {
  const helpTexts = useHelpTexts();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    key: "",
    value: "",
    title: "",
    keywords: "",
    page_url: "",
  });

  useEffect(() => {
    window
      .$("body")
      .off("dblclick", "button[data-ht-key]")
      .on("dblclick", "button[data-ht-key]", function () {
        var key = window.$(this).attr("data-ht-key");
        var value = window.$(this).attr("data-ht-value");
        if (value === undefined || value === "") {
          value = window.$(this).attr("data-bs-original-title");
        }
        var title = window
          .$(this)
          .closest(".header-title")
          .attr("data-ht-title");
        if (title === undefined || title === "") {
          title = window.$(this).closest(".header-title").text();
        }
        var page_url = window.location.pathname;

        var helpText = helpTexts.find((helpText) => helpText.key === key);

        var keywords = helpText ? helpText.keywords : "";

        setFormData((prevState) => ({
          ...prevState,
          key: key,
          value: value,
          title: title,
          keywords: keywords,
          page_url: page_url,
        }));
        window.$("#help-text-edit-modal").modal("show");
      });
  }, [helpTexts]);

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.put("help-texts", {
        key: formData.key,
        value: formData.value,
        title: formData.title,
        keywords: formData.keywords,
        page_url: formData.page_url,
      });
      //if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      var helpTextsCloned = helpTexts.map((helpText) => {
        return { ...helpText };
      });

      var helpText = helpTextsCloned.find(
        (helpText) => helpText.key === data.data.key
      );
      if (!helpText) {
        helpTextsCloned.push(data.data);
      } else {
        helpText.value = data.data.value;
        helpText.title = data.data.title;
        helpText.keywords = data.data.keywords;
        helpText.page_url = data.data.page_url;
      }

      // helpTextsCloned = helpTextsCloned
      //   .filter((obj) => {
      //     return data.data.key === obj.key;
      //   })
      //   .map((obj) => {
      //     obj.value = data.data.value;
      //     return obj;
      //   });

      dispatch(setHelpTexts(helpTextsCloned));
      window.$.fn.tooltip &&
        window.$('[data-bs-toggle="tooltip"]').tooltip("dispose").tooltip();

      window.$("#help-text-edit-modal").modal("hide");

      window.$.toast({
        heading: "Success",
        text: "Updated Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div
        id="help-text-edit-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Help Text</h4>
            </div>
            <div className="modal-body">
              <div class="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }))
                  }
                />
              </div>
              <div class="mb-3">
                <label className="form-label">Help Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  cols={5}
                  value={formData.value}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      value: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label className="form-label">Keywords</label>
                <textarea
                  className="form-control"
                  rows={2}
                  cols={5}
                  value={formData.keywords}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      keywords: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleUpdateClick}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#help-text-edit-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpTextEditModal;
