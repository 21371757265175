import ApiService from "../../app/services/api";

export const ReportsService = {
  getTypeTitle: (type) => {
    var title;
    switch (type) {
      // case "reportSample1":
      //   title = "Report sample1";
      //   break;
      // case "reportSample2":
      //   title = "Report sample2";
      //   break;
      // case "reportSample3":
      //   title = "Report sample3";
      //   break;
      case "reportMiningActions":
        title = "Report Mining Actions";
        break;
      case "reportTlmTransfers":
        title = "Report TLM Transfers";
        break;
      case "reportTlmMiners":
        title = "Top 100 TLM miners (non-bot)";
        break;
      case "reportBotScoreUsers":
        title = "Report bot score users";
        break;
      case "reportNfts":
        title = "Report NFTs with Template ID";
        break;
      case "reportAccountsMiningFrequency":
        title = "Accounts mining frequency";
        break;
      case "reportMostExchangeMemos":
        title = "Most used exchange memos";
        break;
      case "reportExportNftMovementsSummary":
        title = "Export NFT movements (Summary)";
        break;
      case "reportExportNftMovementsInterval":
        title = "Export NFT movements (Interval)";
        break;
      case "reportMinesWithTemplate":
        title = "Peoples mine with tool (Template ID)";
        break;
      case "reportExportMining":
        title = "Export csv from mining explorer";
        break;
      case "reportMultipleAuthorizationsMine":
        title = "Multiple Authorizations Mine";
        break;
      case "reportContractLandowners":
        title = "Landowners with Contract";
        break;
      case "reportExportTlmTransfers":
        title = "Export csv from Tlm transfers lookup";
        break;
      case "reportExportWaxTransfers":
        title = "Export csv from Wax transfers lookup";
        break;
      case "reportExportNftTransfers":
        title = "Export csv from NFT transfers lookup";
        break;
      case "reportExportNftsTransfersDeduped":
        title = "Export csv from NFTs Transfers Deduped";
        break;
      case "reportCreatedAccountsUsingNewuser":
        title = "Accounts created using newuser.wax";
        break;
      case "reportNftsForensics":
        title = "NFTs Forensics";
        break;
      case "reportMiningActiveUsersMonthly":
        title = "Mining Active Users Monthly";
        break;
      case "reportTlmEarnedPerMiner":
        title = "Monthly Avg TLM amount for a user by month";
        break;
      case "reportTlmEarnedPerMiners":
        title = "Monthly Avg. TLM amount earned per user";
        break;
      case "reportActiveMinersUniqueAnalysis":
        title = "Active miners unique analysis";
        break;
      case "reportActiveMinersCohortAnalysis":
        title = "Active miners cohort analysis";
        break;
      case "reportEarnSpentRatio":
        title = "Earn Vs Spent, per user, monthly";
        break;
      case "reportEarnOverThreshold":
        title = "% of active users earning more than X TLM per month";
        break;
      case "reportSecondaryMarketPurchase":
        title = "*Secondary Market* Average spending in WAX per user per month";
        break;
      case "reportSecondaryMarketSale":
        title = "*Secondary Market* Average earning in WAX per user per month";
        break;
      case "reportTopTlmMinersPerPlanet":
        title = "Top 100 TLM miners per planet";
        break;
      case "reportTopNftPointsMinersPerPlanet":
        title = "Top 100 NFT Points miners per planet";
        break;
      case "reportTopTlmMinersPerLand":
        title = "Top 100 TLM miners per land";
        break;
      case "reportTopNftPointsMinersPerLand":
        title = "Top 100 NFT Points miners per land";
        break;
      case "reportMiningActiveUsersPerInterval":
        title = "New miners based on first mine";
        break;
      case "reportActiveMinersAverageAnalysis":
        title = "Active Users Retention";
        break;
      case "reportNftsHeldByActiveMiners":
        title = "% NFTs held by active miners";
        break;
      case "reportActiveMinersSpendCluster":
        title = "Segment Active User in 4 Cluster for TLM Spending";
        break;
      default:
        break;
    }

    return title || type;
  },
  requestReport: async (data) => {
    const response = await ApiService.put("reports/request", data);
    // if (!response.ok) {
    //   return;
    // }
    // return response.json();
    return response;
  },
  getReports: async (params) => {
    const response = await ApiService.get("reports", params);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  getLatestReports: async (params) => {
    const response = await ApiService.get("reports-latest", params);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  downloadReport: async (jobId) => {
    const response = await ApiService.get(`reports/${jobId}/download`);
    if (!response.ok) {
      return;
    }
    return response.blob();
  },
  clearReport: async (jobId) => {
    const response = await ApiService.get(`reports/${jobId}/clear`);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  retryReport: async (jobId) => {
    const response = await ApiService.get(`reports/${jobId}/retry`);
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getBotsAndNonBots: async (accounts) => {
    const response = await ApiService.post(`reports/check-bots`, {
      accounts: accounts,
    });
    if (!response.ok) {
      return;
    }
    return response.json();
  },
  getBotsChartData: async (type, startDate, endDate) => {
    const response = await ApiService.get("reports/bots-chart", {
      type: type,
      startDate: startDate || null,
      endDate: endDate || null,
    });

    return response;
  },
  getCompoundReports: async (params) => {
    const response = await ApiService.get("reports/compound-reports", params);
    // if (!response.ok) {
    //   return;
    // }
    return response;
  },
  requestCompoundReport: async (data) => {
    const response = await ApiService.put(
      "reports/compound-reports/request",
      data
    );
    // if (!response.ok) {
    //   return;
    // }
    // return response.json();
    return response;
  },
};

export default ReportsService;
