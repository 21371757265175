import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import AccountService from "../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
//import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const WaxTransfers = (props) => {
  const componentProps = {
    component: "components/guard/guard-created-accounts",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "guard-created-accounts" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          order: [[ 0, "desc" ]],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/guard/created-accounts`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.startDate = filters.dates.startDate;
              data.endDate = filters.dates.endDate;
            },
          },
          columns: [
            { data: "timestamp", name: "timestamp" },
            // { data: "from", name: "transfer_from" },
            // { data: "to", name: "transfer_to" },
            { data: "memo", name: "transfer_memo" },
            { data: "amount", name: "transfer_amount" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.timestamp).fromNow());
              },
            },
            // {
            //   targets: 1,
            //   orderable: false,
            //   createdCell: function (
            //     cell,
            //     cellData,
            //     rowData,
            //     rowIndex,
            //     colIndex
            //   ) {
            //     window.$(cell).empty();

            //     var data = `<a href="/admin/account-scanner/${rowData.from}" target="_blank">${rowData.from}</a>`;

            //     window.$(cell).prepend(data);
            //   },
            // },
            // {
            //   targets: 2,
            //   orderable: false,
            //   createdCell: function (
            //     cell,
            //     cellData,
            //     rowData,
            //     rowIndex,
            //     colIndex
            //   ) {
            //     window.$(cell).empty();

            //     var data = `<a href="/admin/account-scanner/${rowData.to}" target="_blank">${rowData.to}</a>`;

            //     window.$(cell).prepend(data);
            //   },
            // },
            {
              targets: 1,
              orderable: false,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `<a href="/admin/account-scanner/${rowData.memo}" target="_blank">${rowData.memo}</a>`;

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Guard Created Accounts` },
            // {
            //   extend: "copy",
            //   title: null,
            //   header: false,
            //   exportOptions: {
            //     columns: ':contains("From")',
            //     rows: ":visible",
            //     customizeData: function (dataTable) {
            //       dataTable.body = [
            //         ...new Map(
            //           dataTable.body.map((item) => [item[0], item])
            //         ).values(),
            //       ];
            //     },
            //   },
            // },
            // {
            //   extend: "copy",
            //   title: null,
            //   header: false,
            //   exportOptions: {
            //     columns: ':contains("To")',
            //     rows: ":visible",
            //     customizeData: function (dataTable) {
            //       dataTable.body = [
            //         ...new Map(
            //           dataTable.body.map((item) => [item[0], item])
            //         ).values(),
            //       ];
            //     },
            //   },
            // },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Account")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [filters]);

  // const copyFromAddressToClipboard = async (e) => {
  //   e.preventDefault();

  //   //window.$(`#wax_transfers_wrapper .btn-group .buttons-copy`)[1].click();
  //   copyToClipboard("guard-created-accounts", 1);
  // };

  // const copyToAddressToClipboard = async (e) => {
  //   e.preventDefault();

  //   //window.$(`#wax_transfers_wrapper .btn-group .buttons-copy`)[2].click();
  //   copyToClipboard("guard-created-accounts", 2);
  // };

  const copyAccountAddressToClipboard = async (e) => {
    e.preventDefault();

    copyToClipboard("guard-created-accounts", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Guard Created Accounts`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Guard Created Accounts`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the guard created accounts."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="guard-created-accounts"
        >
          <thead>
            <tr>
              <th>Date</th>
              {/* <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th> */}
              <th>
                Account{" "}
                <Link to="" onClick={copyAccountAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default WaxTransfers;
