
const Error500 = () => {
  return (
    <div className="card text-center">
      <div class="card-body text-danger react-loading-skeleton">
        <h3 class="card-title">Oops</h3>
        <p class="card-text">Looks like something went wrong.</p>
      </div>
    </div>
  );
};

export default Error500;
