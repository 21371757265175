import { Link } from "react-router-dom";


export const AdminNftsTemplateOwnersGridCard = ({ account }) => {
  return (
    <div className="card product-box">
      <div className="product-img">
        <div className="p-3">
          <Link to={`/admin/account-scanner/${account.owner}`}>
            <img
              src={`https://wax.api.atomichub.io/v1/preview/avatar/${account.owner}`}
              alt={`${account.owner}`}
              className="img-fluid  mx-auto d-block"
              style={{ height: "200px" }}
            />
          </Link>
        </div>
      </div>

      <div className="product-info border-top p-3">
        <div>
          <h5 className="font-16 mt-0 mb-0">
            <Link to={`/admin/account-scanner/${account.owner}`} className="text-dark">
              {account.owner}
            </Link>
          </h5>
          <h5 className="mt-2">
            <span className="text-muted">{account.total_assets} Assets</span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default AdminNftsTemplateOwnersGridCard;
