import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminNftsGrid from "../../components/nfts/nfts-grid";

export const AdminNfts = () => {

  
  return (
    <>     
      <BreadcrumbsItem to="/admin/nfts">NFTs Explorer</BreadcrumbsItem>      
     
       <AdminNftsGrid /> 
    </>
  );
};

export default AdminNfts;
