import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";

export const UniqueMinersPerTools = () => {
  const componentProps = {
    component: "components/mining/unique_miners_per_tools",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  //const [planets, setPlanets] = useState(new Array(6).fill(0));

  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const stats = useQuery(
    [`unique_miners_per_tools`, filters],
    async () => {
      const response = await ApiService.get(
        "logmines/unique-miners-per-tools",
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        return;
      }

      return response.json();
    },
    {
      initialData: new Array(6).fill(0),
    }
  );

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Number of unique miners per tool`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows how many miners used each tool. Abundant tools include shovels, etc. This report can take some time to generate as it is pretty data intensive."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
        </div>
      </div>
      <div className="row">
        {stats.data &&
          stats.data.map((tool, index) => (
            <div className="col-xl-3 col-md-6" key={index}>
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {tool.miners_count === undefined ? (
                      <Skeleton width={50} />
                    ) : (
                      <span>{tool.miners_count.toLocaleString()}</span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">
                    {!tool.template_name ? (
                      <Skeleton width={200} />
                    ) : (
                      tool.template_name
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default UniqueMinersPerTools;
