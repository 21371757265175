import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const CumulativeUsersChart = (props) => {
  const componentProps = {
    component: "components/users/cumulative-users-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [hasMined, setHasMined] = useState(false);

  const users = useQuery(
    ["cumulative_users_chart", filters, hasMined],
    async () => {
      const response = await ApiService.get("users/cumulative-users-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        hasMined: hasMined ? 1 : 0,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!users.data) return;

    setData(users.data);

    chartComponent.current.chart.hideLoading();
  }, [users.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="has-mined-check"
                  defaultChecked={hasMined}
                  onChange={(el) => setHasMined(!hasMined)}
                />
                <label
                  className="form-check-label"
                  htmlFor="has-mined-check"
                >
                  Has Mined
                </label>
              </div>
            </form>
          </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Cumulative users (shovel data)`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Cumulative users tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {users.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "line",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: false,
                  },
                  xAxis: {
                    type: "category",
                    title: {
                      text: "Date",
                    },
                  },
                  yAxis: {
                    title: {
                      text: "Count",
                    },
                  },
                  series: [
                    {
                      name: "New users",
                      data: data,
                    },
                  ],
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CumulativeUsersChart;
