import { useEffect } from "react";
import SinkStats from "../components/shining/sink-stats";
import DailyShiningChart from "../components/shining/daily-shining-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function AdminDashboardSinks() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-sinks">Sinks</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <SinkStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailyShiningChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardSinks;
