import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "../../../../services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";

export const AdminListCompoundReports = () => {
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();

  useEffect(() => {
    Emitter.on("REQUEST_COMPOUND_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    Emitter.on("CLEAR_COMPOUND_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    Emitter.on("RETRY_COMPOUND_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    return () => {
      Emitter.off("REQUEST_COMPOUND_REPORT_SUCCESS");
      Emitter.off("CLEAR_COMPOUND_REPORT_SUCCESS");
      Emitter.off("RETRY_COMPOUND_REPORT_SUCCESS");
    };
  });

  const reports = useQuery(
    "list_compound_reports",
    async () => {
      const response = await ReportsService.getCompoundReports({
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    { refetchInterval: 30 * 1000, cacheTime: 1000 }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!reports.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [reports.data]);

  const humanize = (str) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const displayStatus = (report) => {
    if (report.status === "new") {
      return <span className="badge badge-outline-primary">New</span>;
    } else if (report.status === "pending") {
      return <span className="badge badge-outline-warning">Pending</span>;
    } else if (report.status === "processing") {
      return <span className="badge badge-outline-warning">Processing</span>;
    } else if (report.status === "completed") {
      return <span className="badge badge-outline-success">Completed</span>;
    } else if (report.status === "failed") {
      return <span className="badge badge-outline-danger">Failed</span>;
    }
  };

  const onClickView = (report, mode) => {
    if (typeof report.generate_file === "string") {
      report.generate_file = JSON.parse(report.generate_file);
    }

    switch (mode) {
      case "presentation":
        window.open(
          `https://docs.google.com/presentation/d/${report.generate_file.presentationId}/view`,
          "_blank"
        );
        break;
      case "document":
      default:
        window.open(
          `https://docs.google.com/document/d/${report.generate_file.documentId}/view`,
          "_blank"
        );
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="list_compound_reports"
            >
              <thead>
                <tr>
                  <th>Request Date</th>
                  <th>Parameters</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {reports.data &&
                      reports.data.map((report, index) => {
                        var filters = [];
                        if (report.filters) {
                          Object.entries(JSON.parse(report.filters)).forEach(
                            function (entry) {
                              const [key, value] = entry;
                              filters.push(humanize(key) + ": " + value);
                            }
                          );
                        }
                        return (
                          <tr key={index}>
                            <td
                              title={moment(report.request_datetime).fromNow()}
                            >
                              {moment
                                .tz(report.request_datetime, "UTC")
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              {filters.map((filter, index) => (
                                <p className="lh-1" key={index}>
                                  {filter}
                                </p>
                              ))}
                            </td>
                            <td>{displayStatus(report)}</td>
                            <td>
                              {report.status === "new" ? "In progress" : null}
                              {report.status === "processing"
                                ? `In progress ${
                                    report.job_progress > 0
                                      ? `(${report.job_progress}%)`
                                      : ""
                                  }`
                                : null}
                              {report.status === "completed" ? (
                                <>
                                  <button
                                    className="btn btn-link p-0 d-block"
                                    onClick={(e) =>
                                      onClickView(report, "document")
                                    }
                                  >
                                    View Doc
                                  </button>
                                  {/* <button
                                    className="btn btn-link p-0 d-block"
                                    onClick={(e) =>
                                      onClickView(report, "presentation")
                                    }
                                  >
                                    View Slide
                                  </button> */}
                                </>
                              ) : null}
                              {/* {(report.status === "completed" &&
                                moment
                                  .tz(report.generate_datetime, "UTC")
                                  .add(1, "days")
                                  .format("YYYY-MM-DD HH:mm:ss") <=
                                  moment
                                    .tz("UTC")
                                    .format("YYYY-MM-DD HH:mm:ss")) ||
                              report.status === "failed" ? (
                                <button
                                  className="btn btn-link text-danger p-0 d-block"
                                  onClick={(e) => onClickClear(report)}
                                >
                                  {report.clear_attempts}
                                  Clear
                                </button>
                              ) : null} */}
                              {/* {report.status === "completed" ||
                              report.status === "failed" ? (
                                <button
                                  className="btn btn-link p-0 d-block"
                                  //onClick={(e) => onClickRetry(report)}
                                >
                                  Retry
                                </button>
                              ) : null} */}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {reports.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminListCompoundReports;
