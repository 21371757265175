import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import ToolCalculatorService from "../services/tool-calculator";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";

function ToolCalculator() {
  const [land, setLand] = useState(null);
  const [tool1, setTool1] = useState(null);
  const [tool2, setTool2] = useState(null);
  const [tool3, setTool3] = useState(null);
  const [chargeTime, setChargeTime] = useState("");
  const [miningPower, setMiningPower] = useState("");
  const [miningMin, setMiningMin] = useState(" ");
  const [chanceNft, setChanceNft] = useState("");
  const [nftMin, setNftMin] = useState("");
  const [powReduction, setPowReduction] = useState("");
  const [wastingPower, setWastingPower] = useState(0);
  const [isCompare, setIsCompare] = useState(false);
  const [nftPoints, setNftPoints] = useState(null);

  const tableRef = useRef();
  const dataTableRef = useRef();

  const params = new URLSearchParams(useLocation().search);
  let landId = params.get("land") ? params.get("land") : null;
  let tool1Id = params.get("tool1") ? params.get("tool1") : null;
  let tool2Id = params.get("tool2") ? params.get("tool2") : null;
  let tool3Id = params.get("tool3") ? params.get("tool3") : null;

  const templates = useQuery(["lands_and_tools"], async () => {
    const response = await ToolCalculatorService.getLandsAndTools();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  const setTemplate = async (event) => {
    setIsCompare(false);

    var type = event.target.name;
    var templateId = event.target.value;
    const template = await ToolCalculatorService.getTemplate(templateId);

    if (type == "land") {
      setLand(template);
      landId = templateId;
    } else if (type == "tool1") {
      setTool1(template);
      tool1Id = templateId;
    } else if (type == "tool2") {
      setTool2(template);
      tool2Id = templateId;
    } else {
      setTool3(template);
      tool3Id = templateId;
    }

    var url =
      "/admin/tool-calculator/?" +
      (landId ? "land=" + landId : "") +
      (tool1Id ? "&tool1=" + tool1Id : "") +
      (tool2Id ? "&tool2=" + tool2Id : "") +
      (tool3Id ? "&tool3=" + tool3Id : "");
    window.history.pushState({}, null, url);
  };

  useEffect(async () => {
    if (landId) {
      var land = await ToolCalculatorService.getTemplate(landId);
      setLand(land);
    }
    if (tool1Id) {
      var tool1 = await ToolCalculatorService.getTemplate(tool1Id);
      setTool1(tool1);
    }
    if (tool2Id) {
      var tool2 = await ToolCalculatorService.getTemplate(tool2Id);
      setTool2(tool2);
    }
    if (tool3Id) {
      var tool3 = await ToolCalculatorService.getTemplate(tool3Id);
      setTool3(tool3);
    }
    window.$(document).ready(function () {
      window.$(".select2").select2();
      window.$(".select2").on("change", setTemplate);
    });
  }, [templates.data]);

  useEffect(() => {
    if (tool1 || tool2 || tool3) {
      //mining power
      var landTlmMultiplier = land ? land.ease / 10 : 0;
      var tool1MiningPower = tool1 ? tool1.ease / 10 : 0;
      var too12MiningPower = tool2 ? tool2.ease / 10 : 0;
      var tool3MiningPower = tool3 ? tool3.ease / 10 : 0;
      var miningPower =
        landTlmMultiplier *
        (tool1MiningPower + too12MiningPower + tool3MiningPower);

      //charge time
      var landChargeMultiplier = land ? land.delay / 10 : 0;
      var tool1ChargeTime = tool1 ? parseInt(tool1.delay) : 0;
      var tool2ChargeTime = tool2 ? parseInt(tool2.delay) : 0;
      var tool3ChargeTime = tool3 ? parseInt(tool3.delay) : 0;
      // var arr = [tool1ChargeTime, tool2ChargeTime, tool3ChargeTime];
      // var max1 = Math.max.apply(null, arr);
      // arr.splice(arr.indexOf(max1), 1);
      // var max2 = Math.max.apply(null, arr);
      // var chargeTime = landChargeMultiplier * (max1 + max2);
      //scenarios for cooldown:
      var cooldowns = [];
      if (tool1 && tool2 && tool3) {
        cooldowns = [tool1ChargeTime, tool2ChargeTime, tool3ChargeTime].sort();
        cooldowns = cooldowns.slice(1);
      } else if (tool1 && tool2) {
        cooldowns = [tool1ChargeTime, tool2ChargeTime].sort();
        cooldowns[0] = cooldowns[0] / 2;
      } else {
        cooldowns = [tool1ChargeTime].sort();
      }
      const chargeTime =
        cooldowns.reduce((partialSum, a) => partialSum + a, 0) *
        landChargeMultiplier;

      //chance of NFT
      var landNftMultiplier = land ? land.luck / 10 : 0;
      var tool1NftLuck = tool1 ? tool1.luck / 10 : 0;
      var tool2NftLuck = tool2 ? tool2.luck / 10 : 0;
      var tool3NftLuck = tool3 ? tool3.luck / 10 : 0;
      var chanceNft =
        landNftMultiplier * (tool1NftLuck + tool2NftLuck + tool3NftLuck);

      //pow reduction
      var tool1Difficulty = tool1 ? parseInt(tool1.difficulty) : 0;
      var tool2Difficulty = tool2 ? parseInt(tool2.difficulty) : 0;
      var tool3Difficulty = tool3 ? parseInt(tool3.difficulty) : 0;
      var powReduction = tool1Difficulty + tool2Difficulty + tool3Difficulty;

      if (miningPower > 80) {
        var wastingPower = miningPower - 80;
        miningPower = 80;
      } else var wastingPower = 0;
      setWastingPower(Math.round(wastingPower));
      var miningMin = chargeTime ? (miningPower / chargeTime) * 60 : null;
      var nftMin = chargeTime ? (chanceNft / chargeTime) * 60 : null;
      setMiningPower(
        miningPower.toFixed(2) + "%" + (wastingPower !== 0 ? "*" : "")
      );
      if (miningMin) setMiningMin(miningMin.toFixed(2) + "%/m");
      else setMiningMin("");
      if (chargeTime) {
        var minutes = Math.floor(chargeTime / 60);
        var seconds = chargeTime - minutes * 60;
        if (seconds) var text = minutes + "m " + seconds.toFixed(0) + "s";
        else var text = minutes + "m";

        setChargeTime(text);
      } else setChargeTime(chargeTime.toFixed(2) + " seconds");
      setChanceNft(chanceNft.toFixed(2) + "%");
      if (nftMin) setNftMin(nftMin.toFixed(2) + "%/m");
      else setNftMin("");
      setPowReduction(powReduction);

      const nftPoints = calculateNftPoints(land, tool1, tool2, tool3);
      console.log(nftPoints);
      setNftPoints(nftPoints);
    }
  }, [land, tool1, tool2, tool3]);

  const getBuild = (land) => {
    //mining power
    var landTlmMultiplier = land ? land.ease / 10 : 0;
    var tool1MiningPower = tool1 ? tool1.ease / 10 : 0;
    var too12MiningPower = tool2 ? tool2.ease / 10 : 0;
    var tool3MiningPower = tool3 ? tool3.ease / 10 : 0;
    var miningPower =
      landTlmMultiplier *
      (tool1MiningPower + too12MiningPower + tool3MiningPower);

    //charge time
    var landChargeMultiplier = land ? land.delay / 10 : 0;
    var tool1ChargeTime = tool1 ? parseInt(tool1.delay) : 0;
    var tool2ChargeTime = tool2 ? parseInt(tool2.delay) : 0;
    var tool3ChargeTime = tool3 ? parseInt(tool3.delay) : 0;
    var arr = [tool1ChargeTime, tool2ChargeTime, tool3ChargeTime];
    var max1 = Math.max.apply(null, arr);
    arr.splice(arr.indexOf(max1), 1);
    var max2 = Math.max.apply(null, arr);
    var chargeTime = landChargeMultiplier * (max1 + max2);

    //chance of NFT
    var landNftMultiplier = land ? land.luck / 10 : 0;
    var tool1NftLuck = tool1 ? tool1.luck / 10 : 0;
    var tool2NftLuck = tool2 ? tool2.luck / 10 : 0;
    var tool3NftLuck = tool3 ? tool3.luck / 10 : 0;
    var chanceNft =
      landNftMultiplier * (tool1NftLuck + tool2NftLuck + tool3NftLuck);

    //pow reduction
    var tool1Difficulty = tool1 ? parseInt(tool1.difficulty) : 0;
    var tool2Difficulty = tool2 ? parseInt(tool2.difficulty) : 0;
    var tool3Difficulty = tool3 ? parseInt(tool3.difficulty) : 0;
    var powReduction = tool1Difficulty + tool2Difficulty + tool3Difficulty;

    if (miningPower > 80) {
      var wastingPower = miningPower - 80;
      miningPower = 80;
    } else var wastingPower = 0;
    var miningMin = chargeTime ? (miningPower / chargeTime) * 60 : null;
    var nftMin = chargeTime ? (chanceNft / chargeTime) * 60 : null;
    miningPower =
      miningPower.toFixed(2) + "%" + (wastingPower !== 0 ? "*" : "");
    if (miningMin) miningMin = miningMin.toFixed(2) + "%/m";
    else miningMin = "";
    if (chargeTime) {
      var minutes = Math.floor(chargeTime / 60);
      var seconds = chargeTime - minutes * 60;
      if (seconds) var text = minutes + "m " + seconds.toFixed(0) + "s";
      else var text = minutes + "m";

      chargeTime = text;
    } else chargeTime = chargeTime.toFixed(2) + " seconds";
    chanceNft = chanceNft.toFixed(2) + "%";
    if (nftMin) nftMin = nftMin.toFixed(2) + "%/m";
    else nftMin = "";

    const nftPoints = calculateNftPoints(land, tool1, tool2, tool3);

    var build = {
      charge_time: chargeTime,
      mining_power: miningPower,
      mining_min: miningMin,
      chance_nft: chanceNft,
      nft_min: nftMin,
      nftPoints: nftPoints,
      pow_reduction: powReduction,
    };
    return build;
  };

  const calculateNftPoints = (land, tool1, tool2, tool3) => {
    const land_nft_multiplier = land ? land.luck / 10 : 0;
    const tool1_nft_power =
      tool1 && tool1.rarity !== "Abundant" ? tool1.luck / 10 : 0;
    const tool2_nft_power =
      tool2 && tool2.rarity !== "Abundant" ? tool2.luck / 10 : 0;
    const tool3_nft_power =
      tool3 && tool3.rarity !== "Abundant" ? tool3.luck / 10 : 0;

    const land_cooldown_multiplier = land ? land.delay / 10 : 0;
    const tool1_cooldown =
      tool1 && tool1.rarity !== "Abundant" ? tool1.delay : 0;
    const tool2_cooldown =
      tool2 && tool2.rarity !== "Abundant" ? tool2.delay : 0;
    const tool3_cooldown =
      tool3 && tool3.rarity !== "Abundant" ? tool3.delay : 0;

    const nft_points_per_mine =
      (tool1_nft_power + tool2_nft_power + tool3_nft_power) *
      land_nft_multiplier;

    //scenarios for cooldown:
    var cooldowns = [];
    if (tool1 && tool2 && tool3) {
      cooldowns = [tool1_cooldown, tool2_cooldown, tool3_cooldown].sort();
      cooldowns = cooldowns.slice(1);
    } else if (tool1 && tool2) {
      cooldowns = [tool1_cooldown, tool2_cooldown].sort();
      cooldowns[0] = cooldowns[0] / 2;
    } else {
      cooldowns = [tool1_cooldown].sort();
    }
    const cooldown_per_mine =
      cooldowns.reduce((partialSum, a) => partialSum + a, 0) *
      land_cooldown_multiplier;
    const points_per_minute =
      (nft_points_per_mine / cooldown_per_mine) * 60 || 0;

    return {
      nft_points_per_mine: nft_points_per_mine,
      cooldown_per_mine: cooldown_per_mine,
      points_per_minute: points_per_minute,
    };
  };

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!isCompare) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [isCompare]);

  return (
    <>
      <BreadcrumbsItem to="/admin/tool-calculator">
        Tool Calculator
      </BreadcrumbsItem>

      <div className="row">
        <div class="col-lg-3">
          {templates.isFetching ? (
            <Skeleton />
          ) : (
            <select name="land" className="form-control select2">
              <option value="">Select a land</option>
              {templates.data &&
                templates.data.lands.map((land, index) => {
                  var selected = land.template_id == landId ? "selected" : "";
                  return (
                    <option value={land.template_id} selected={selected}>
                      {land.name}
                    </option>
                  );
                })}
            </select>
          )}
          {land ? (
            <Link to={`/admin/template/${land.template_id}`}>
              <img
                src={`${config.ipfs.url}/ipfs/${land.image}`}
                alt="land-pic"
                className="img-fluid mt-2 mx-auto d-block"
                style={{ height: "300px" }}
              />
            </Link>
          ) : (
            <img
              src={`${config.ipfs.url}/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
              alt="land-pic"
              className="img-fluid mt-2 mx-auto d-block"
              style={{ height: "300px", opacity: 0.4 }}
            />
          )}
        </div>
        <div class="col-lg-3">
          {templates.isFetching ? (
            <Skeleton />
          ) : (
            <select name="tool1" className="form-control select2">
              <option value="" selected>
                Select a tool
              </option>
              {templates.data &&
                templates.data.tools.map((tool, index) => {
                  var selected = tool.template_id == tool1Id ? "selected" : "";
                  return (
                    <option value={tool.template_id} selected={selected}>
                      {tool.name}
                    </option>
                  );
                })}
            </select>
          )}
          {tool1 ? (
            <Link to={`/admin/template/${tool1.template_id}`}>
              <img
                src={`${config.ipfs.url}/ipfs/${tool1.image}`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px" }}
              />
            </Link>
          ) : (
            <img
              src={`${config.ipfs.url}/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
              alt="land-pic"
              className="img-fluid mt-2 mt-2 mx-auto d-block"
              style={{ height: "300px", opacity: 0.4 }}
            />
          )}
        </div>
        <div class="col-lg-3">
          {templates.isFetching ? (
            <Skeleton />
          ) : (
            <select name="tool2" className="form-control select2">
              <option value="" selected>
                Select a tool
              </option>
              {templates.data &&
                templates.data.tools.map((tool, index) => {
                  var selected = tool.template_id == tool2Id ? "selected" : "";
                  return (
                    <option value={tool.template_id} selected={selected}>
                      {tool.name}
                    </option>
                  );
                })}
            </select>
          )}
          {tool2 ? (
            <Link to={`/admin/template/${tool2.template_id}`}>
              <img
                src={`${config.ipfs.url}/ipfs/${tool2.image}`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px" }}
              />
            </Link>
          ) : (
            <img
              src={`${config.ipfs.url}/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
              alt="land-pic"
              className="img-fluid mt-2 mt-2 mx-auto d-block"
              style={{ height: "300px", opacity: 0.4 }}
            />
          )}
        </div>
        <div class="col-lg-3">
          {templates.isFetching ? (
            <Skeleton />
          ) : (
            <select name="tool3" className="form-control select2">
              <option value="" selected>
                Select a tool
              </option>
              {templates.data &&
                templates.data.tools.map((tool, index) => {
                  var selected = tool.template_id == tool3Id ? "selected" : "";
                  return (
                    <option value={tool.template_id} selected={selected}>
                      {tool.name}
                    </option>
                  );
                })}
            </select>
          )}
          {tool3 ? (
            <Link to={`/admin/template/${tool3.template_id}`}>
              <img
                src={`${config.ipfs.url}/ipfs/${tool3.image}`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px" }}
              />
            </Link>
          ) : (
            <img
              src={`${config.ipfs.url}/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
              alt="land-pic"
              className="img-fluid mt-2 mt-2 mx-auto d-block"
              style={{ height: "300px", opacity: 0.4 }}
            />
          )}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-2">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {chargeTime ? <span>{chargeTime}</span> : <span>0</span>}
              </h3>
              <p class="text-muted mb-0">Charge Time</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {miningPower ? (
                  <>
                    <span>{miningPower}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span>{miningMin}</span>
                  </>
                ) : (
                  <span>0</span>
                )}
              </h3>
              <p class="text-muted mb-0">Mining Power</p>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {chanceNft ? (
                  <>
                    <span>{chanceNft}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span>{nftMin}</span>
                  </>
                ) : (
                  <span>0</span>
                )}
              </h3>
              <p class="text-muted mb-0">NFT Luck (Stage 1)</p>
            </div>
          </div>
        </div> */}
        <div className="col-lg-3">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {nftPoints ? (
                  <>
                    <span>
                      {parseFloat(nftPoints.points_per_minute).toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}
                      /m
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span>
                      {parseFloat(
                        nftPoints.points_per_minute * 60
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      /h
                    </span>
                  </>
                ) : (
                  <span>0</span>
                )}
              </h3>
              <p class="text-muted mb-0">NFT points</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {nftPoints ? (
                  <span>{nftPoints.nft_points_per_mine}</span>
                ) : (
                  <span>0</span>
                )}
              </h3>
              <p class="text-muted mb-0">NFT Power</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="widget-simple text-center card">
            <div className="card-body">
              <h3 class="text-success mt-0">
                {powReduction ? <span>{powReduction}</span> : <span>0</span>}
              </h3>
              <p class="text-muted mb-0">POW Reduction</p>
            </div>
          </div>
        </div>
      </div>

      {wastingPower !== 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <p class="text-muted mb-0">
                  The maximum mining power is 80%, this build is not using
                  approx {wastingPower + "%"} mining power
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {templates.isError ? <Error500 /> : null}

      <div className="row">
        <div className="col-lg-12">
          <button
            type="buttom"
            className="btn btn-success mb-2 mb-sm-0 mx-auto d-block"
            onClick={() => {
              setIsCompare(true);
            }}
          >
            Compare build in all lands
          </button>
          {isCompare ? (
            <div className="card mt-2">
              <div className="card-body">
                <h4 className="header-title">Compare current build by Land</h4>
                <table
                  className="table dt-responsive nowrap w-100"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>Land</th>
                      <th>Charge</th>
                      <th>TLM</th>
                      <th>NFT</th>
                      <th>TLM/Min</th>
                      {/* <th>NFT/Min</th> */}
                      <th>NFT Points/Min</th>
                      <th>NFT Power</th>
                      <th>POW</th>
                    </tr>
                  </thead>
                  <tbody>
                    {templates.data &&
                      templates.data.lands.map((land, index) => {
                        var build = getBuild(land);
                        return (
                          <tr key={index}>
                            <td>{land.name}</td>
                            <td>{build.charge_time}</td>
                            <td>{build.mining_power}</td>
                            <td>{build.chance_nft}</td>
                            <td>{build.mining_min}</td>
                            {/* <td>{build.nft_min}</td> */}
                            <td>
                              {parseFloat(
                                build.nftPoints.points_per_minute
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              /m
                            </td>
                            <td>{build.nftPoints.nft_points_per_mine}</td>
                            <td>{build.pow_reduction}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ToolCalculator;
