import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import NftsService from "../../../services/nfts";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { useRef } from "react";
import { useEffect } from "react";
import { copyToClipboard } from "app/utils/datatables";

export const NftHistoryMining = (props) => {
  let { assetId } = useParams();

  const tableRef = useRef();
  const dataTableRef = useRef();

  const mining = useQuery([`nft_${assetId}_history_mining`, assetId], () => {
    return NftsService.getNftMining(assetId, props.type);
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!mining.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 50, 500],
          order: [[0, "desc"]],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `History Mining` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Miner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Land Owner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [mining.data]);

  const copyMinersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("nft_history_mining", 1);
  };

  const copyLandownersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_mines_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("nft_history_mining", 2);
  };

  return (
    <div class="responsive-table-plugin">
      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">
          <table className="table mb-0" ref={tableRef} id="nft_history_mining">
            <thead>
              <tr>
                <th>Time</th>
                <th>
                  Miner &nbsp;
                  <Link to="" onClick={copyMinersToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy miners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Tool 1</th>
                <th>Tool 2</th>
                <th>Tool 3</th>
                <th>Bounty</th>
                <th>Planet</th>
                <th>Land</th>
                <th>
                  Land Owner &nbsp;
                  <Link to="" onClick={copyLandownersToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy landowners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Comm%</th>
              </tr>
            </thead>
            <tbody>
              {mining.data &&
                mining.data.map((mine, index) => {
                  return (
                    <tr key={index}>
                      <td title={moment(mine.timestamp).fromNow()}>
                        {moment
                          .tz(mine.timestamp, "UTC")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        <Link to={`/admin/account-scanner/${mine.miner}`}>
                          {mine.miner}
                        </Link>
                      </td>
                      <td title={mine.tool1 ? mine.tool1_name : ""}>
                        {mine.tool1 ? (
                          <Link to={`/admin/nfts/${mine.tool1}`}>
                            {mine.tool1_short_name}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td title={mine.tool2 ? mine.tool2_name : ""}>
                        {mine.tool2 ? (
                          <Link to={`/admin/nfts/${mine.tool2}`}>
                            {mine.tool2_short_name}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td title={mine.tool3 ? mine.tool3_name : ""}>
                        {mine.tool3 ? (
                          <Link to={`/admin/nfts/${mine.tool3}`}>
                            {mine.tool3_short_name}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{mine.bounty}</td>
                      <td>{mine.planet}</td>
                      <td title={mine.land ? mine.land_name : ""}>
                        {mine.land ? (
                          <Link to={`/admin/nfts/${mine.land}`}>
                            {mine.land_short_name}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <Link to={`/admin/account-scanner/${mine.landowner}`}>
                          {mine.landowner}
                        </Link>
                      </td>
                      <td>{mine.commission}</td>
                    </tr>
                  );
                })}

              {mining.isFetching ? (
                <>
                  {new Array(5).fill(0).map((transfer, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          <Link
            to={
              props.type == "land"
                ? `/admin/mining-explorer?tree={"id":"ab899ba8-cdef-4012-b456-717d9eb6a223","type":"group","children1":{"bbaab989-89ab-4cde-b012-317d9eb6b160":{"type":"rule","properties":{"field":"act_data_land_id","operator":"equal","value":["${assetId}"],"valueSrc":["value"],"valueType":["number"]}}}}`
                : `/admin/mining-explorer?tree={"id":"98998a98-cdef-4012-b456-717d9eb9ca92","type":"group","children1":{"aaaaa8ab-cdef-4012-b456-717d9eba3303":{"type":"rule","properties":{"field":"act_data_bag_items_0","operator":"equal","value":["${assetId}"],"valueSrc":["value"],"valueType":["number"]}},"ab9aaa8b-89ab-4cde-b012-317d9eba640f":{"type":"rule","properties":{"field":"act_data_bag_items_1","operator":"equal","value":["${assetId}"],"valueSrc":["value"],"valueType":["number"]}},"abb8abaa-4567-489a-bcde-f17d9eba9fe7":{"type":"rule","properties":{"field":"act_data_bag_items_2","operator":"equal","value":["${assetId}"],"valueSrc":["value"],"valueType":["number"]}}},"properties":{"conjunction":"OR"}}`
            }
          >
            <button
              type="buttom"
              className="btn btn-success mb-2 mb-sm-0 mx-auto d-block mt-2"
            >
              View more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NftHistoryMining;
