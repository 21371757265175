import TlmTransfersLookupResults from "admin/components/transfers-lookup/tlm-transfers-lookup-results";
import WaxTransfersLookupResults from "admin/components/transfers-lookup/wax-transfers-lookup-results";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQuery } from "react-query";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  use,
} from "react-router-dom";
import ApiService from "../../../app/services/api";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQueryParams } from "app/hooks/useQueryParams";
import {
  Query,
  Builder,
  Utils as QbUtils,
  BasicConfig,
} from "react-awesome-query-builder";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import Skeleton from "react-loading-skeleton";
import NftTransfersLookupResults from "admin/components/transfers-lookup/nft-transfers-lookup-results";

const TransfersLookup = () => {
  const history = useHistory();
  //const params = useParams();

  //const [keyword, setKeyword] = useState(params.keyword || "");
  //const queryParams = useQueryParams();

  /*   const [lookupFilters, setLookupFilters] = useState({
    from: queryParams.get("from") || "",
    to: queryParams.get("to") || "",
    memo: queryParams.get("memo") || "",
    start_date: queryParams.get("start_date") || "",
    end_date: queryParams.get("end_date") || "",
  }); */

  /*   const searchKeyword = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/admin/transfers-lookup/" + keyword,
    });
  };

  useEffect(() => {
    if (!params.keyword) return;

    setKeyword(params.keyword);
  }, [params.keyword]); */

  /*   const search = (event) => {
     event.preventDefault();
    history.push({
      search: new URLSearchParams(lookupFilters).toString(),
    });
  }; */

  const InitialConfig = MaterialConfig;
  InitialConfig.operators.like.label = "Contains";
  InitialConfig.operators.not_like.label = "Not contains";
  const config = {
    ...InitialConfig,
    fields: {
      transfer_from: {
        label: "Transfer From",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
      transfer_to: {
        label: "Transfer To",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
      transfer_amount: {
        label: "Amount",
        type: "number",
        valueSources: ["value"],
        excludeOperators: [],
      },
      transfer_memo: {
        label: "Memo",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "starts_with",
          "ends_with",
          "proximity",
          // "like",
          //"not_like",
        ],
      },
      timestamp: {
        label: "Timestamp",
        type: "datetime",
        valueSources: ["value"],
        fieldSettings: {
          dateFormat: "YYYY-MM-DD",
          valueFormat: "YYYY-MM-DDTHH:mm:ss.000",
        },
      },
      transfer_asset_ids: {
        label: "NFT",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "starts_with",
          "ends_with",
          "proximity",
          // "like",
          //"not_like",
        ],
      },
      transfer_templates: {
        label: "Tool type (Template ID)",
        type: "select",
        valueSources: ["value"],
        excludeOperators: [
          "starts_with",
          "ends_with",
          "proximity",
          // "like",
          //"not_like",
        ],
        fieldSettings: {
          listValues: [],
          showSearch: true,
        },
        // widgets: {
        //   select: {
        //     operators: ["equal", "not_equal"],
        //     defaultOperator: "equal",
        //     opProps: {
        //       equal: {
        //         label: "With",
        //       },
        //       not_equal: {
        //         label: "Without",
        //       },
        //     },
        //   },
        // },
      },
      transfer_asset_ids_count: {
        label: "NFTs Count",
        type: "number",
        valueSources: ["value"],
        operators: [
          "equal",
          //"greater",
          "greater_or_equal",
          //"less",
          "less_or_equal",
        ],
      },
    },
  };
  //config.settings.showNot = false;

  const templates = useQuery([`transfers_lookup_templates`], async () => {
    const response = await ApiService.get("mining/templates");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  const queryValue = { id: QbUtils.uuid(), type: "group" };
  const params = new URLSearchParams(useLocation().search);
  const tree = params.get("tree")
    ? JSON.parse(params.get("tree"))
    : {
        id: "98aaaaab-cdef-4012-b456-717ebe3af3a9",
        type: "group",
        children1: {
          "9988a88b-4567-489a-bcde-f17ebe3bbb44": {
            type: "rule",
            properties: {
              field: "timestamp",
              operator: "between",
              value: [
                moment().subtract(6, "month").format("YYYY-MM-DDTHH:mm:ss.000"),
                moment().format("YYYY-MM-DDTHH:mm:ss.000"),
              ],
              valueSrc: ["value", null],
              valueType: ["datetime", "datetime"],
            },
          },
        },
      };
  const [state, setState] = useState({});
  // const [elasticQuery, setElasticQuery] = useState(
  //   params.get("tree")
  //     ? JSON.stringify(
  //         QbUtils.elasticSearchFormat(
  //           QbUtils.checkTree(QbUtils.loadTree(tree, config), config),
  //           config
  //         )
  //       )
  //     : ""
  // );
  const [elasticQuery, setElasticQuery] = useState("");

  useEffect(() => {
    let query = params.get("tree")
      ? JSON.stringify(
          QbUtils.elasticSearchFormat(
            QbUtils.checkTree(QbUtils.loadTree(tree, config), config),
            config
          )
        )
      : "";

    //Modify query for proper timestamp
    query = query.replace(
      /"(\w*)":"(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})"/g,
      function (match, p1, p2, p3, p4, p5, p6, p7, offset, string) {
        console.log(p2);
        return `"${p1}":"${p2}-${p3}-${p4}T${p5}:${p6}:${p7}"`;
      }
    );
    setElasticQuery(query);
  }, []);

  const onChange = (immutableTree, config) => {
    setState({ tree: immutableTree, config: config });
  };

  const applyFilter = () => {
    let query = JSON.stringify(
      QbUtils.elasticSearchFormat(state.tree, state.config)
    );

    //Modify query for proper timestamp
    query = query.replace(
      /"(\w*)":"(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})"/g,
      function (match, p1, p2, p3, p4, p5, p6, p7, offset, string) {
        console.log(p2);
        return `"${p1}":"${p2}-${p3}-${p4}T${p5}:${p6}:${p7}"`;
      }
    );

    if (query) {
      setElasticQuery(query);
      let tree = JSON.stringify(QbUtils.getTree(state.tree));
      let url = "/admin/transfers-lookup?tree=" + tree;
      window.history.pushState({}, null, url);
    }
  };

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );

  useEffect(() => {
    if (!templates.data) return;

    config.fields.transfer_templates.listValues = templates.data;

    setState({
      tree: tree
        ? QbUtils.checkTree(QbUtils.loadTree(tree, config), config)
        : QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config: config,
    });
  }, [templates.data]);

  const [modalFormData, setModalFormData] = useState({
    format: "json",
    query: "",
  });

  return (
    <>
      <BreadcrumbsItem to="/admin/transfers-lookup">
        Transfers Lookup
      </BreadcrumbsItem>

      {/*       <div className="row">
        <div className="col-lg-12">
          <div className="dropdown">
            <form
              className="d-flex align-items-center"
              onSubmit={searchKeyword}
            >
              <input
                type="text"
                name="keyword"
                className="form-control dropdown-toggle"
                id="autocomplateSearch"
                placeholder="Enter the account name or a tag to lookup"
                aria-label="Search"
                autoComplete={"off"}
                value={keyword}
                onChange={(event) => {
                  setKeyword(event.target.value);
                }}
              />
              <button type="submit" className="btn btn-success mb-2 mb-sm-0">
                Search
              </button>
            </form>
          </div>
        </div>
      </div> */}

      {/*       <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={`Enter From`}
                      value={lookupFilters.from}
                      onChange={(e) => {
                        e.preventDefault();
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          from: e.target.value,
                        }));
                      }}
                    />
                    <label>From</label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={`Enter To`}
                      value={lookupFilters.to}
                      onChange={(e) => {
                        e.preventDefault();
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          to: e.target.value,
                        }));
                      }}
                    />
                    <label>To</label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={`Enter Memo`}
                      value={lookupFilters.memo}
                      onChange={(e) => {
                        e.preventDefault();
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          memo: e.target.value,
                        }));
                      }}
                    />
                    <label>Memo</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <DateRangePicker
                      //id={`daterangepicker_${type.type}_${field.name}`}
                      initialSettings={{
                        autoUpdateInput: false,
                        singleDatePicker: true,
                        timePicker: false,
                        locale: {
                          format: "YYYY-MM-DD",
                        },
                      }}
                      onCallback={(start, end, label) => {
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          start_date: start.format("YYYY-MM-DD"),
                        }));
                      }}
                      onApply={(event, picker) => {
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          start_date: picker.startDate.format("YYYY-MM-DD"),
                        }));
                        window
                          .$(event.currentTarget)
                          .val(picker.startDate.format("YYYY-MM-DD"));
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={lookupFilters.start_date}
                      />
                    </DateRangePicker>
                    <label>Start Date</label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <DateRangePicker
                      //id={`daterangepicker_${type.type}_${field.name}`}
                      initialSettings={{
                        autoUpdateInput: false,
                        singleDatePicker: true,
                        timePicker: false,
                        locale: {
                          format: "YYYY-MM-DD",
                        },
                      }}
                      onCallback={(start, end, label) => {
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          end_date: start.format("YYYY-MM-DD"),
                        }));
                      }}
                      onApply={(event, picker) => {
                        setLookupFilters((prevState) => ({
                          ...prevState,
                          end_date: picker.startDate.format("YYYY-MM-DD"),
                        }));
                        window
                          .$(event.currentTarget)
                          .val(picker.startDate.format("YYYY-MM-DD"));
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={lookupFilters.end_date}
                      />
                    </DateRangePicker>
                    <label>End Date</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-light"
                    onClick={search}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <p className="text-warning mt-2">
        <i class="fas fa-exclamation-triangle me-2"></i>
        This section is under active development. Please use only for feedback.
      </p> */}
      {state.tree && state.config ? (
        <>
          <Query
            {...state.config}
            value={state.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
          <button
            type="button"
            className="btn btn-primary mt-1 mb-1"
            onClick={applyFilter}
          >
            Apply filter
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-secondary mt-1 mb-1"
            onClick={() => {
              let query =
                modalFormData.format === "json"
                  ? JSON.stringify(
                      QbUtils.jsonLogicFormat(state.tree, state.config).logic
                    )
                  : QbUtils.spelFormat(state.tree, state.config);
              setModalFormData((prevState) => ({
                ...prevState,
                query: query,
              }));
              window.$("#query-format-edit-modal").modal("show");
            }}
          >
            Query Format
          </button>
        </>
      ) : (
        <Skeleton />
      )}

      {elasticQuery ? (
        <>
          <TlmTransfersLookupResults query={elasticQuery} />
          <WaxTransfersLookupResults query={elasticQuery} />
          <NftTransfersLookupResults query={elasticQuery} />
        </>
      ) : null}

      <div
        id="query-format-edit-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Query Format</h4>
            </div>
            <div className="modal-body">
              {/* <div className="form-check me-2"> */}
              {/* <label className="checkbox-inline" style={{ width: "100px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={modalFormData.format === "spel" ? true : false}
                    id="inlineCheckbox1"
                    onChange={(el) => {
                      let query = QbUtils.spelFormat(state.tree, state.config);
                      setModalFormData({
                        ...modalFormData,
                        format: "spel",
                        query: query,
                      });
                    }}
                  />{" "}
                  SPEL
                </label> */}
              {/* <label className="checkbox-inline" style={{ width: "100px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={modalFormData.format === "json" ? true : false}
                    id="inlineCheckbox2"
                    onChange={(el) => {
                      let query = JSON.stringify(
                        QbUtils.jsonLogicFormat(state.tree, state.config).logic
                      );
                      setModalFormData({
                        ...modalFormData,
                        format: "json",
                        query: query,
                      });
                    }}
                  />{" "}
                  JSON
                </label> */}
              {/* <label className="checkbox-inline" style={{ width: "150px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={modalFormData.format === "string" ? true : false}
                    id="inlineCheckbox3"
                    onChange={(el) => {
                      let query = QbUtils.queryString(state.tree, state.config);
                      setModalFormData({
                        ...modalFormData,
                        format: "string",
                        query: query,
                      });
                    }}
                  />{" "}
                  Human readable
                </label> */}
              {/* </div> */}
              <label className="form-label">JSON</label>
              <textarea
                className="form-control"
                rows={8}
                cols={10}
                value={modalFormData.query}
                readOnly={modalFormData.format === "string" ? true : false}
                onChange={(e) =>
                  setModalFormData((prevState) => ({
                    ...prevState,
                    query: e.target.value,
                  }))
                }
              ></textarea>
              <br />
              <label className="form-label">Human Readable</label>
              <div>{QbUtils.queryString(state.tree, state.config)}</div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                disabled={modalFormData.format === "string" ? true : false}
                onClick={() => {
                  let immutableTree;
                  if (modalFormData.format === "json") {
                    immutableTree = QbUtils.loadFromJsonLogic(
                      JSON.parse(modalFormData.query),
                      config
                    );
                    console.log(
                      QbUtils.checkTree(
                        QbUtils.loadTree(immutableTree, config),
                        config
                      )
                    );
                  } else if (modalFormData.format === "spel") {
                    let immutable = QbUtils.loadFromSpel(
                      modalFormData.query,
                      config
                    );
                    immutableTree = immutable[0];
                  } else if (modalFormData.format === "string") {
                    return;
                  }

                  setState({
                    tree: QbUtils.checkTree(
                      QbUtils.loadTree(immutableTree, config),
                      config
                    ),
                    config: config,
                  });

                  window.$("#query-format-edit-modal").modal("hide");
                }}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#query-format-edit-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransfersLookup;
