import EthBscTlm from "./eth-bsc-tlm";
import EthBscInboundTlm from "./eth-bsc-inbound-tlm";
import EthBscOutboundTlm from "./eth-bsc-outbound-tlm";
import EthBscNft from "./eth-bsc-nft";

export const EthBscStats = (props) => {

  return (
      <div className="row mt-2">
        {props.isBsc ? (
        <>
        <div class="col-xl-4 col-md-6">
          <EthBscTlm account={props.account} type="bsc" />
        </div>
        <div class="col-xl-4 col-md-6">
          <EthBscInboundTlm account={props.account} type="bsc" />
        </div>
        <div class="col-xl-4 col-md-6">
          <EthBscOutboundTlm account={props.account} type="bsc" />
        </div>
        <div class="col-xl-4 col-md-6">
          <EthBscNft account={props.account} type="bsc" />
        </div>
        </>
        ) : null}
        {props.isEth ? (
        <>
        <div class="col-xl-4 col-md-6">
          <EthBscTlm account={props.account} type="eth" />
        </div>
        <div class="col-xl-4 col-md-6">
          <EthBscInboundTlm account={props.account} type="eth" />
        </div>
        <div class="col-xl-4 col-md-6">
          <EthBscOutboundTlm account={props.account} type="eth" />
        </div>
        </>
        ) : null}
      </div>
  );
};

export default EthBscStats;
