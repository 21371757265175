import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import TotalRedeemablePoints from "./total-redeemable-points";

export const UserPointStats = (props) => {
  const componentProps = {
    component: "components/user-points/user-point-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const points = useQuery(
    [`user_point_stats`, filters],
    async () => {
      const response = await ApiService.get("user-points/stats", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      initialData: new Array(8).fill(0),
    }
  );

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `User point stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the user point stats."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          {points.data &&
            points.data.map((point, index) => (
              <div class="col-xl-4 col-md-6" key={index}>
                <div className="widget-simple text-center card">
                  <div className="card-body">
                    <h3 className="text-success mt-0">
                      {!point.value ? (
                        <Skeleton width={50} />
                      ) : (
                        <span>{point.value.toLocaleString()}</span>
                      )}
                    </h3>
                    <p className="text-muted mb-0">
                      {!point.key ? <Skeleton width={200} /> : point.key}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          <div class="col-xl-4 col-md-6">
            <TotalRedeemablePoints />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPointStats;
