import AuthService from "../../../../app/services/auth";
import ApiService from "../../../../app/services/api";
import { useEffect, useState } from "react";

export const UpdateDetails = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    AuthService.me().then((data) => {
      setUser(data.user);
    });
  }, []);

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.put("me", {
        name: user.name,
        email: user.email,
        telegram_chat_id: user.telegram_chat_id
      });
      //if (!response.ok) {
     //   throw new Error("Network response was not ok");
     // }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      window.$.toast({ heading: "Success", text: "Updated Successfully", position:"top-right",icon:"success"});
    } catch (err) {
      //console.log(err.message);
      window.$.toast({ heading: "Error", text: "Update Failed: " + err.message, position:"top-right",icon:"error" });
    }
  };

  return (
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Basic Details</h4>
        <p class="sub-header">Additional info</p>

        <form class="needs-validation" novalidate>
          <div class="mb-3">
            <label for="name" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Name"
              value={user.name}
              required
              onChange={(e) =>
                setUser((prevState) => ({ ...prevState, name: e.target.value }))
              }
            />
            <div class="invalid-feedback">Please choose a username.</div>
          </div>
          <div class="mb-3">
            <label for="emailAddress" class="form-label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="emailAddress"
              placeholder="Email"
              value={user.email}
              required
              onChange={(e) =>
                setUser((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
            />
          </div>
          <div class="mb-3">
            <label for="emailAddress" class="form-label">
              Telegram Chat Id
            </label>
            <input
              type="text"
              class="form-control"
              id="telegramChatId"
              placeholder="Telegram Chat Id"
              value={user.telegram_chat_id}
              required
              onChange={(e) =>
                setUser((prevState) => ({
                  ...prevState,
                  telegram_chat_id: e.target.value,
                }))
              }
            />
            <p><a href="https://www.alphr.com/find-chat-id-telegram/" target="_blank">How to get Chat ID</a>
            </p>
          </div>
          <div className="text-end">
            <button
              class="btn btn-primary waves-effect waves-light me-1"
              type="submit"
              onClick={handleUpdateClick}
            >
              Update details
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateDetails;
