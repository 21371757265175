import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";

export const TotalSalesStats = () => {
  const filters = useFilters();

  const stats = useQuery([`landowner_stats_sales`, filters], async () => {
    const response = await ApiService.get("landowner/stats/sales", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
    <>
      <div class="col-xl-4 col-md-6">
        <div className="widget-simple text-center card">
          <div className="card-body">
            <h3 className="text-success mt-0">
              {stats.status === "loading" ? (
                <Skeleton />
              ) : (
                <span data-plugin="counterup">
                  {parseInt(stats.data.total_buyers).toLocaleString()}
                </span>
              )}
            </h3>
            <p className="text-muted mb-0">Total buyers</p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div className="widget-simple text-center card">
          <div className="card-body">
            <h3 className="text-success mt-0">
              {stats.status === "loading" ? (
                <Skeleton />
              ) : (
                <span data-plugin="counterup">
                  {parseInt(stats.data.total_sellers).toLocaleString()}
                </span>
              )}
            </h3>
            <p className="text-muted mb-0">Total sellers</p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div className="widget-simple text-center card">
          <div className="card-body">
            <h3 className="text-success mt-0">
              {stats.status === "loading" ? (
                <Skeleton />
              ) : (
                <span data-plugin="counterup">
                  {parseInt(stats.data.total_owners).toLocaleString()}
                </span>
              )}
            </h3>
            <p className="text-muted mb-0">New landowners</p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div className="widget-simple text-center card">
          <div className="card-body">
            <h3 className="text-success mt-0">
              {stats.status === "loading" ? (
                <Skeleton />
              ) : (
                <span data-plugin="counterup">
                  {parseInt(stats.data.total_transfers).toLocaleString()}
                </span>
              )}
            </h3>
            <p className="text-muted mb-0">Total transfers</p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div className="widget-simple text-center card">
          <div className="card-body">
            <h3 className="text-success mt-0">
              {stats.status === "loading" ? (
                <Skeleton />
              ) : (
                <span>
                  {parseInt(stats.data.total_volumes).toLocaleString()} USD
                </span>
              )}
            </h3>
            <p className="text-muted mb-0">Total volume</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalSalesStats;
