import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";

HighchartsSankey(Highcharts);
DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const Sankey = (props) => {
  const componentProps = {
    component: "components/charts/sankey",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };

  const filters = useFilters();
  const helpTexts = useHelpTexts();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState([]);

  const sankey = useQuery(["sankey", filters], async () => {
    const response = await ApiService.get("tlm/sankey", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let data = await response.json();
    setData(data);
  });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `TLM Flow`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the flow of TLM (the in-game currency of Alien Worlds) through the eco system in general."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {sankey.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {sankey.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        backgroundColor: "transparent",
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      tooltip: {
                        formatter: function () {
                          if (this.point.isNode)
                            return (
                              this.point.name +
                              ": <b>" +
                              new Intl.NumberFormat().format(this.point.sum) +
                              "</b>"
                            );
                          else
                            return (
                              this.point.from +
                              " -> " +
                              this.point.to +
                              "<br><b>" +
                              new Intl.NumberFormat().format(
                                this.point.weight
                              ) +
                              "</b>"
                            );
                        },
                      },
                      accessibility: {
                        point: {
                          valueDescriptionFormat:
                            "{index}. {point.from} to {point.to}, {point.weight}.",
                        },
                      },
                      series: [
                        {
                          keys: ["from", "to", "weight"],
                          data: data,
                          type: "sankey",
                          name: "TLM flow",
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Sankey;
