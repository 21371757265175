import { useEffect } from "react";
// import DaoTokenStats from "../components/dao/dao-token-stats";
import DaoChart from "../components/dao/dao-chart";
import DaoChartPerPlanet from "../components/dao/dao-chart-per-planet";
import DaoStakedChart from "../components/dao/dao-staked-chart";
import DaoPlanetsStats from "../components/dao/dao-planets-stats";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import DaoPlanetStatsChart from "../components/dao/dao-planet-stats-chart";
import DaoPlanetTreasuryChart from "../components/dao/dao-planet-treasury-chart";
import DaoPlanetDistributionChart from "../components/dao/dao-planet-distribution-chart";
import DaoEngagementChart from "admin/components/dao/dao-engagement-chart";
import DaoProposals from "admin/components/dao/dao-proposals";
import DaoTlmTransfers from "admin/components/dao/dao-tlm-transfers";

function AdminDashboardDao() {
  const planets = [
    "eyeke.world",
    "kavian.world",
    "magor.world",
    "naron.world",
    "neri.world",
    "veles.world",
  ];

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });
  });

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-dao">DAO</BreadcrumbsItem>

      {/* <div className="row">
        <div className="col-lg-12">
          <DaoTokenStats />
        </div>
      </div> */}      

      <DaoPlanetsStats />
      
      <div className="row">
        <div className="col-lg-12">
          <DaoPlanetStatsChart />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <DaoChart />
        </div>
      </div>     

      <div className="row">
        {planets.map((planet, index) => {
          return (
            <div className="col-lg-12">
              <DaoChartPerPlanet planet={planet} />
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DaoStakedChart />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <DaoPlanetTreasuryChart />
        </div>
      </div>

      {/* <div className="row">
        <div className="col-lg-12">
          <DaoPlanetDistributionChart />
        </div>
      </div> */}

      <div className="row">
        <div className="col-lg-12">
          <DaoEngagementChart />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <DaoProposals />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <DaoTlmTransfers />
        </div>
      </div>


    </>
  );
}

export default AdminDashboardDao;
