import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import config from "app/config";

export const AdminSelfTestNodeStatus = () => {
    const stats = useQuery(['api_status'], async () => {
        const response = await fetch(`${config.api.url}/api_status`);
        if (!response.ok) {
            return 'Inactive';
        }
        return 'Active';
    });

    return (
        <div className="widget-simple text-center card">
        <div className="card-body">
            <h3 className={(stats.data && stats.data == 'Active' ? 'text-success' : 'text-danger') + ' mt-0'}>
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{stats.data}</span>
                )}
            </h3>
            <p className="text-muted mb-0">Node status</p>
        </div>
        </div>
    );
};

export default AdminSelfTestNodeStatus;
