import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeLogminesPlanetsNumMines = (props) => {
  const componentProps = {
    component: "components/realtime/logmines/planets_num_mines",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  const [planets, setPlanets] = useState(new Array(6).fill(0));
  useEffect(() => {
    var socket = SocketService.getSocket("/logmines");

    if (socket.connected) {
      socket.emit("join", "planetsNumMines");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "planetsNumMines");
      });
    }

    socket.on("planetsNumMines", function (data) {
      setPlanets(data);
    });

    return () => {
      socket.off("planetsNumMines");
      socket.emit("leave", "planetsNumMines");
    };
  }, []);
  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Number of mining actions per planet (last 15 mins)`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>

          <div className="row">
            {planets.map((planet, index) => (
              <div className="col-xl-3 col-md-6" key={index}>
                <div className="widget-simple text-center card">
                  <div className="card-body">
                    <h3 className="text-success mt-0">
                      {!planet.mines_count ? (
                        <Skeleton width={50} />
                      ) : (
                        <span data-plugin="counterup">
                          {parseInt(planet.mines_count).toLocaleString()}
                        </span>
                      )}
                    </h3>
                    <p className="text-muted mb-0">
                      {!planet.name ? <Skeleton width={200} /> : planet.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeLogminesPlanetsNumMines;
