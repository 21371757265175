import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";

export const AdminRealtimeLogminesNumTransferToaccounts = () => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    var socket = SocketService.getSocket("/logmines");

    if (socket.connected) {
      socket.emit("join", "numTransferToAccounts");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "numTransferToAccounts");
      });
    }

    socket.on("numTransferToAccounts", function (data) {
      setValue(data);
    });

    return () => {
      socket.off("numTransferToAccounts");
      socket.emit("leave", "numTransferToAccounts");
    };
  }, []);
  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          <span data-plugin="counterup">
            {parseFloat(value).toLocaleString()}
          </span>
        </h3>
        <p className="text-muted mb-0">Number of accounts</p>
      </div>
    </div>
  );
};

export default AdminRealtimeLogminesNumTransferToaccounts;
