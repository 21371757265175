import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminCreateNotificationList from "./components/create-notification-list";
import AdminListNotificationLists from "./components/list-notification-lists";

export const AdminMyNotificationLists = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/my-notification-lists">
        Notification Lists
      </BreadcrumbsItem>
      <AdminCreateNotificationList /> 
      <AdminListNotificationLists />
    </>
  );
};

export default AdminMyNotificationLists;
