import { useEffect, useState } from "react";
import SocketService from "../../../app/services/socket";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";

export const AdminSelfTestTimeLatestNftDatabase = () => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    var socket = SocketService.getSocket("/selfTest");

    if (socket.connected) {
      socket.emit("join", "timeLatestNftDatabase");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "timeLatestNftDatabase");
      });
    }

    socket.on("timeLatestNftDatabase", function (data) {
      setValue(data);
    });

    return () => {
      socket.off("timeLatestNftDatabase");
      socket.emit("leave", "timeLatestNftDatabase");
    };
  }, []);
  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {!value ? (
            <Skeleton />
          ) : (
            <span>
              {moment.tz(parseInt(value), "UTC").fromNow()}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">Latest NFT Database</p>
      </div>
    </div>
  );
};

export default AdminSelfTestTimeLatestNftDatabase;
