import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useFilters } from "../../app/hooks/useSession";

import DateRangePicker from "react-bootstrap-daterangepicker";
import { setFilters } from "../../app/state/session";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
//import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
//import "bootstrap-daterangepicker/daterangepicker.css";

export const AdminMainFilter = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialFilters = useFilters();
  
  useEffect(() => {
    setTimeout(() => {
        window.$.Components.init();
    });
  })

  const [dates, setDates] = useState({
    startDate:
      initialFilters && initialFilters.dates.startDate
        ? moment(initialFilters.dates.startDate)
        : moment().subtract(3, "months"),
    endDate:
      initialFilters && initialFilters.dates.endDate
        ? moment(initialFilters.dates.endDate)
        : moment(),
  });

  const getFilters = () => {
    const filters = {
      dates: {
        startDate: dates.startDate.format("YYYY-MM-DD"),
        endDate: dates.endDate.format("YYYY-MM-DD"),
      },
    };

    return filters;
  };

  const handleDateRangeCallback = (start, end, label) => {
    setDates({
      startDate: start,
      endDate: end,
    });
  };

  const handleBtnClick = () => {
    // const filters = {
    //   dates: {
    //     startDate: dates.startDate.format("YYYY-MM-DD"),
    //     endDate: dates.endDate.format("YYYY-MM-DD"),
    //   },
    // };
    const filters = getFilters();
    dispatch(setFilters(filters));
  };

  useEffect(() => {
    handleBtnClick();
  }, [dates])

  //const filters = getFilters();
  // dispatch(setFilters(filters));

  const excludePaths = [
    "/admin/my-account",
    // /\/admin\/nfts\/(.*)$/,
    "/admin/realtime",
    /\/admin\/token\/(.*)$/,
    "/admin/tool-calculator",
    "/admin/mining-explorer",
    "/admin/reports",
    "/admin/self-test",
    "/admin/error/500",
    "/admin/tokenomics",
    "/admin/tokenomics-new",
    "/admin/tag-lookup",
    "/admin/tag-bulk",
    "/admin/transfers-lookup",
    "/admin/forensics-chart/nfts",
    "/admin/tower/debug",
    "/admin/tower/appeals"
  ];
  //if (["/admin/my-account"].includes(location.pathname)) {
  if (
    excludePaths
      .map((excludePath) => new RegExp(excludePath).test(location.pathname))
      .includes(true)
  ) {
    return <></>;
  }

  return (
    <div className="row mb-2">
      <div className="col-lg-6"></div>
      <div className="col-lg-6">
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            {moment(dates.endDate).diff(dates.startDate, 'months', true) > 3 ? (
            <button
                type="button"
                className="btn btn-outline-none text-warning"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Date range longer than 3 months will cause pages to be a lot slower to load owing to the extra processing requirements."
                style={{ boxShadow: "none" }}
            >
            <i className="fas fa-exclamation-triangle"></i>
            </button>
            ) : null}
            {Math.round(moment(dates.endDate).diff(dates.startDate, 'hours', true)) == 48 ? (
            <button
                type="button"
                className="btn btn-outline-none text-warning"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="24 hour time frames could result in some inconsistent looking figures."
                style={{ boxShadow: "none" }}
            >
            <i className="fas fa-exclamation-triangle"></i>
            </button>
            ) : null}
            <div className="me-2">
              <label className="visually-hidden">Date Filter</label>
              <DateRangePicker
                initialSettings={{
                  format: "DD/MM/YYYY",
                  startDate: dates.startDate,
                  endDate: dates.endDate,
                  showDropdowns: true,
                  showWeekNumbers: true,
                  timePicker: false,
                  timePickerIncrement: 1,
                  timePicker12Hour: true,
                  ranges: {
                    Today: [moment(), moment()],
                    "Last 24 Hours": [
                      moment().subtract(24, "hours"),
                      moment()
                    ],
                    Yesterday: [
                      moment().subtract(1, "days"),
                      moment().subtract(1, "days"),
                    ],
                    "Last 7 Days": [moment().subtract(7, "days"), moment().subtract(1, "days")],
                    "Last 30 Days": [moment().subtract(30, "days"), moment().subtract(1, "days")],
                    "This Month": [
                      moment().startOf("month"),
                      moment().subtract(1, "days"),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    "Last 3 Months": [
                      moment().subtract(3, "month"),
                      moment().subtract(1, "days"),
                    ],
                    "Last 6 Months": [
                      moment().subtract(6, "month"),
                      moment().subtract(1, "days"),
                    ],
                    "All time": [
                      moment("2020-07-01"),
                      moment().subtract(1, "days"),
                    ],
                  },
                  opens: "left",
                  drops: "down",
                  buttonClasses: ["btn", "btn-sm"],
                  applyClass: "btn-success",
                  cancelClass: "btn-secondary",
                  separator: " to ",
                  locale: {
                    format: "YYYY/MM/DD",
                    applyLabel: "Submit",
                    cancelLabel: "Cancel",
                    fromLabel: "From",
                    toLabel: "To",
                    customRangeLabel: "Custom",
                    daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                    monthNames: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ],
                    firstDay: 1,
                  },
                }}
                onCallback={handleDateRangeCallback}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
            <button
              type="button"
              className="btn btn-success mb-sm-0"
              onClick={handleBtnClick}
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminMainFilter;
