import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import { copyToClipboard } from "app/utils/datatables";

export const LearderboardPointsByFrequency = (props) => {
  const componentProps = {
    component: "components/user-points/leaderboard-points-by-frequency",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "leaderboard_points_by_frequency" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const [frequency, setFrequency] = useState("daily");

  const [hideFlaggedAccounts, setHideFlaggedAccounts] = useState(false);

  const points = useQuery(
    [
      "leaderboard_points_by_frequency",
      frequency,
      filters,
      hideFlaggedAccounts,
    ],
    async () => {
      const response = await ApiService.get(
        "user-points/leaderboard-points-by-frequency",
        {
          frequency: frequency,
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
          hideFlaggedAccounts: hideFlaggedAccounts ? 1 : 0,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      return result;
    }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!points.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Leaderboard Points` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("User")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [points.data]);

  const copyusersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#leaderboard_points_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("leaderboard_points", 1);
  };

  const handleChange = (event) => {
    setFrequency(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="systemaccounts-check"
                defaultChecked={hideFlaggedAccounts}
                onChange={(el) => setHideFlaggedAccounts(!hideFlaggedAccounts)}
              />
              <label
                className="form-check-label"
                htmlFor="systemaccounts-check"
              >
                Hide flagged accounts
              </label>
            </div>
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="frequency"
                onChange={handleChange}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Leaderboard Points`}{" "}
          {frequency === "weekly" ? "Weekly" : "Daily"}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the leaderboard points by frquency."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="leaderboard_points_by_frequency"
        >
          <thead>
            <tr>
              <th>Rank</th>
              <th className="no-sort">
                User &nbsp;
                <Link to="" onClick={copyusersToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy users to clipboard"
                  ></i>
                </Link>
              </th>
              <th>
                {frequency === "weekly" ? "Weekly" : "Daily"} points{" "}
                <span title="shown as x10 on the contract">*</span>
              </th>
              <th>
                Total points <span title="shown as x10 on the contract">*</span>
              </th>
              <th>
                Redeemable points{" "}
                <span title="shown as x10 on the contract">*</span>
              </th>
              <th>Top level claimed</th>
              <th>Last action</th>
            </tr>
          </thead>
          <tbody>
            {points.data &&
              points.data.map((point, index) => {
                return (
                  <tr key={index}>
                    <td className="text-end">{index + 1}</td>
                    <td>
                      <Link to={`/admin/account-scanner/${point.user}`}>
                        {point.user}
                      </Link>
                    </td>
                    <td className="text-end">
                      {point[`${frequency}_points`] / 10}
                    </td>
                    <td className="text-end">{point.total_points / 10}</td>
                    <td className="text-end">{point.redeemable_points / 10}</td>
                    <td className="text-end">{point.top_level_claimed}</td>
                    <td title={moment(point.last_action_timestamp).fromNow()}>
                      {moment
                        .tz(point.last_action_timestamp, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                  </tr>
                );
              })}

            {points.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {points.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default LearderboardPointsByFrequency;
