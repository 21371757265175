import { useQuery } from "react-query";
import AccountService from "../../../services/account";
import React, { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";

export const NftTransfers = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/nft-transfers",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const limit = 1000;
  const assets = useQuery(
    [`account_nfts_${props.account}`, filters, props.account],
    async ({ pageParam = 1 }) => {
      const response = await AccountService.getNfts(props.account, {
        query: "",
        sort: "asset_id",
        order: "DESC",
        page: pageParam,
        limit: limit,
        filter: JSON.stringify({
          startAtTime: moment
            .tz(filters.dates.startDate, "UTC")
            .startOf("day")
            .valueOf(),
          endAtTime: moment
            .tz(filters.dates.endDate, "UTC")
            .endOf("day")
            .valueOf(),
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!assets.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [assets.data]);

  return (
    <div className="row">
      <div class="col-lg-12">
        <div className="card">
          <div className="card-body">
            <CardMenu {...componentProps} {...props} />
            <h4
              className="header-title"
              data-ht-title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || `NFTs`
              }
            >
              {helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `NFTs`}{" "}
              with {props.account}
              <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-ht-key={componentProps.component}
                title={
                  helpTexts.find(
                    (helpText) => helpText.key === componentProps.component
                  )?.value ||
                  "This shows the NFTs with this account. The NFTs are grouped by type to avoid flooding the page with related NFTs. The valuation is based on recent sale and is the total value of all the NFTs of this type held by the account."
                }
                style={{ boxShadow: "none" }}
              >
                <i class="fas fa-info-circle"></i>
              </button>
            </h4>
            <table
              className="table table-centered dt-responsive w-100 table-nowrap"
              ref={tableRef}
              id="nfts"
            >
              <thead className="table-light">
                <tr>
                  {/* <th>Image</th> */}
                  <th>Name</th>
                  <th>Template ID</th>
                  <th>Number of Items</th>
                  <th>Valuation $</th>
                  <th>Shine</th>
                  <th>Rarity</th>
                </tr>
              </thead>
              <tbody>
                {assets.data &&
                  Array.isArray(assets.data) &&
                  assets.data.map((asset, index) => {
                    return (
                      <tr>
                        {/* <td>
                                <Link to={`/admin/template/${asset.template_id}`}>
                                    <img
                                    src={`${config.ipfs.url}/ipfs/${asset.image}`}
                                    alt="nfts-pic"
                                    style={{width:"50px"}}
                                    />
                                </Link>
                            </td> */}
                        <td>
                          <Link to={`/admin/template/${asset.template_id}`}>
                            <h5 className="mt-0 mb-1 text-truncate">
                              {asset.name}
                            </h5>
                          </Link>
                        </td>
                        <td>{asset.template_id}</td>
                        <td>{asset.asset_count}</td>
                        <td>
                          {new Intl.NumberFormat("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(asset.asset_count * asset.usd_prize)}
                        </td>
                        <td>{asset.shine}</td>
                        <td>{asset.rarity}</td>
                      </tr>
                    );
                  })}

                {assets.isFetching ? (
                  <>
                    {new Array(5).fill(0).map((log, index) => (
                      <tr key={index}>
                        {/* <td><Skeleton/></td> */}
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : null}
              </tbody>
            </table>
            {assets.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftTransfers;
