import ApiService, { apiHeaders } from "app/services/api";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useRef } from "react";
import CardMenu from "../card-menu";
import { copyToClipboard } from "app/utils/datatables";
import { setReport } from "app/state/session";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import usePrevious from "app/hooks/usePrevious";
import config from "app/config";
import ReportsService from "admin/services/reports";
import Emitter from "app/services/emitter";
import { useDispatch } from "react-redux";

export const VisitorsLookupResults = (props) => {
  const componentProps = {
    component: "components/visitors-lookup/visitors-lookup-results",
    options: { props: { query: props.query } },
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "visitors-lookup-results" },
  };
  const dispatch = useDispatch();
  const helpTexts = useHelpTexts();
  const history = useHistory();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevQuery = usePrevious(props.query);

  if (dataTableRef.current && prevQuery !== props.query) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!transfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500, 1000],
          searching: false,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/visitors`,
            type: "POST",
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              data.query = props.query;
              /* data.from = props.lookupFilters.from;
              data.to = props.lookupFilters.to;
              data.memo = props.lookupFilters.memo;
              data.startDate = props.lookupFilters.start_date;
              data.endDate = props.lookupFilters.end_date; */
            },
          },
          columns: [
            { data: "event_timestamp", name: "event_timestamp" },
            { data: "event_name", name: "event_name" },
            { data: "country", name: "country" },
            { data: "region", name: "region" },
            { data: "device_brand", name: "device_brand" },
            { data: "device_model", name: "device_model" },
            { data: "device_os", name: "device_os" },
          ],
          columnDefs: [
            {
              targets: 0,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.event_timestamp / 1000, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr(
                    "title",
                    moment(rowData.event_timestamp / 1000).fromNow()
                  );
              },
            },
          ],
          dom: "Blfrtip",
          buttons: ["copy", { extend: "csv", title: `Vistors` }],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();

      // dataTableRef.current.on("preDraw", function (e) {
      //   const info = window.$(tableRef.current).DataTable().page.info();
      //   if (info.start >= 10000) {
      //     dispatch(
      //       setReport({
      //         type: "reportExportStakes",
      //         format: "csv",
      //         filters: props.query != "" ? JSON.parse(props.query) : {},
      //       })
      //     );
      //     window.$("#large-result-window-elasticsearch-modal").modal("show");
      //     return false;
      //   }

      //   return true;
      // });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.query]);

  return (
    <>
      <div className="card mt-2">
        <div className="card-body">
          {/* <CardMenu {...componentProps} {...props} /> */}
          {/* {props.query != "" ? (
            <div className="float-end me-2">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn btn-sm btn-success mb-2 mb-sm-0"
                  onClick={exportCSV}
                >
                  Export as csv
                </button>
              </form>
            </div>
          ) : null} */}
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Visitors`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || ``
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            className="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="visitors_lookup"
          >
            <thead>
              <tr>
                <th>Event Date</th>
                <th>Event Name</th>
                <th>Country</th>
                <th>Region</th>
                <th>Device Brand</th>
                <th>Device Model</th>
                <th>Device OS</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default VisitorsLookupResults;
