import { Switch, Route } from "react-router-dom";
import AdminLogin from "../views/login";
import { useEffect } from "react"; 

function AdminAuthLayout() {

  useEffect(()=>{  
    setTimeout(()=>{
      //window.$.App.init();
      window.$.Components.init();
    });    
},[]);

  return (
    <>
      <div className="account-pages mt-5 mb-5">
        <div className="container">
          <Switch>
            <Route path="/admin/login">
              <AdminLogin />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

export default AdminAuthLayout;
