import CardMenu from "admin/components/card-menu";
import GuardRamCount from "./guard-ram-count";
import GuardNumActions from "./guard-num-actions";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeGuardStats = (props) => {
  const componentProps = {
    component: "components/realtime/guard/guard-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Guard stats (last 15 mins)`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Guard stats tooltip"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <GuardRamCount />
          </div>
          <div class="col-xl-4 col-md-6">
            <GuardNumActions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRealtimeGuardStats;
