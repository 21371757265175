import VisitorsLookupResults from "admin/components/visitors-lookup/visitors-lookup-results";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQuery } from "react-query";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  use,
} from "react-router-dom";
import ApiService from "../../../app/services/api";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQueryParams } from "app/hooks/useQueryParams";
import {
  Query,
  Builder,
  Utils as QbUtils,
  BasicConfig,
} from "react-awesome-query-builder";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import Skeleton from "react-loading-skeleton";

const VisitorsLookup = () => {
  const history = useHistory();
  //const params = useParams();

  const InitialConfig = MaterialConfig;
  InitialConfig.operators.like.label = "Contains";
  InitialConfig.operators.not_like.label = "Not contains";
  const config = {
    ...InitialConfig,
    fields: {
      event_timestamp: {
        label: "Event Timestamp",
        type: "datetime",
        valueSources: ["value"],
        fieldSettings: {
          dateFormat: "YYYY-MM-DD",
          valueFormat: "YYYY-MM-DDTHH:mm:ss.000",
        },
      },
      event_name: {
        label: "Event Name",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
      // event_params: {
      //   label: "Event Params",
      //   type: "!group",
      //   subfields: {
      //     key: {
      //       type: "select",
      //       fieldSettings: {
      //         listValues: ["page_title","ga_session_id","wallet"],
      //       },
      //       valueSources: ["value"],
      //     },
      //     "value.string_value": {
      //       type: "text",
      //       valueSources: ["value"],
      //     },
      //     "value.int_value": {
      //       type: "number",
      //       valueSources: ["value"],
      //     },
      //   },
      // },   
      'event_params_wallet': {
        label: "Wallet",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
      'geo.country': {
        label: "Country",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
      'geo.region': {
        label: "Region",
        type: "text",
        valueSources: ["value"],
        excludeOperators: [
          "is_empty",
          "is_not_empty",
          "is_null",
          "is_not_null",
          "starts_with",
          "ends_with",
          "proximity",
          //"like",
          "not_like",
        ],
      },
    },
  };
  //config.settings.showNot = false;

  const queryValue = { id: QbUtils.uuid(), type: "group" };
  const params = new URLSearchParams(useLocation().search);
  const tree = params.get("tree")
    ? JSON.parse(params.get("tree"))
    : {
        id: "98aaaaab-cdef-4012-b456-717ebe3af3a9",
        type: "group",
        children1: {
          "9988a88b-4567-489a-bcde-f17ebe3bbb44": {
            type: "rule",
            properties: {
              field: "event_timestamp",
              operator: "between",
              value: [
                moment().subtract(32, "days").startOf("day").format("YYYY-MM-DDTHH:mm:ss.000"),
                moment().subtract(2,"days").endOf("day").format("YYYY-MM-DDTHH:mm:ss.000"),
              ],
              valueSrc: ["value", null],
              valueType: ["datetime", "datetime"],
            },
          },
        },
      };
  const [state, setState] = useState({});
  const [bigQuery, setBigQuery] = useState(
    params.get("tree")
      ? QbUtils.sqlFormat(
          QbUtils.checkTree(QbUtils.loadTree(tree, config), config),
          config
        )
      : ""
  );

  const onChange = (immutableTree, config) => {
    setState({ tree: immutableTree, config: config });
  };

  const applyFilter = () => {
    let query = QbUtils.sqlFormat(state.tree, state.config);

    //Modify query for proper timestamp
    query = query.replace(
      /"(\w*)":"(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})"/g,
      function (match, p1, p2, p3, p4, p5, p6, p7, offset, string) {
        console.log(p2);
        return `"${p1}":"${p2}-${p3}-${p4}T${p5}:${p6}:${p7}"`;
      }
    );

    if (query) {
      setBigQuery(query);
      let tree = JSON.stringify(QbUtils.getTree(state.tree));
      let url = "/admin/visitors-lookup?tree=" + tree;
      window.history.pushState({}, null, url);
    }
  };

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );

  useEffect(() => {
    setState({
      tree: tree
        ? QbUtils.checkTree(QbUtils.loadTree(tree, config), config)
        : QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config: config,
    });
  }, []);

  const [modalFormData, setModalFormData] = useState({
    format: "json",
    query: "",
  });

  return (
    <>
      <BreadcrumbsItem to="/admin/visitors-lookup">
        Visitors Lookup
      </BreadcrumbsItem>

      {/* <p className="text-warning mt-2">
        <i class="fas fa-exclamation-triangle me-2"></i>
        This section is under active development. Please use only for feedback.
      </p> */}
      {state.tree && state.config ? (
        <>
          <Query
            {...state.config}
            value={state.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
          <button
            type="button"
            className="btn btn-primary mt-1 mb-1"
            onClick={applyFilter}
          >
            Apply filter
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-secondary mt-1 mb-1"
            onClick={() => {
              let query =
                modalFormData.format === "json"
                  ? JSON.stringify(
                      QbUtils.jsonLogicFormat(state.tree, state.config).logic
                    )
                  : QbUtils.spelFormat(state.tree, state.config);
              setModalFormData((prevState) => ({
                ...prevState,
                query: query,
              }));
              window.$("#query-format-edit-modal").modal("show");
            }}
          >
            Query Format
          </button>
        </>
      ) : (
        <Skeleton />
      )}

      {bigQuery ? (
        <>
          <VisitorsLookupResults query={bigQuery} />
        </>
      ) : null}

      <div
        id="query-format-edit-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Query Format</h4>
            </div>
            <div className="modal-body">
              {/* <div className="form-check me-2"> */}
              {/* <label className="checkbox-inline" style={{ width: "100px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={modalFormData.format === "spel" ? true : false}
                    id="inlineCheckbox1"
                    onChange={(el) => {
                      let query = QbUtils.spelFormat(state.tree, state.config);
                      setModalFormData({
                        ...modalFormData,
                        format: "spel",
                        query: query,
                      });
                    }}
                  />{" "}
                  SPEL
                </label> */}
              {/* <label className="checkbox-inline" style={{ width: "100px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={modalFormData.format === "json" ? true : false}
                    id="inlineCheckbox2"
                    onChange={(el) => {
                      let query = JSON.stringify(
                        QbUtils.jsonLogicFormat(state.tree, state.config).logic
                      );
                      setModalFormData({
                        ...modalFormData,
                        format: "json",
                        query: query,
                      });
                    }}
                  />{" "}
                  JSON
                </label> */}
              {/* <label className="checkbox-inline" style={{ width: "150px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={modalFormData.format === "string" ? true : false}
                    id="inlineCheckbox3"
                    onChange={(el) => {
                      let query = QbUtils.queryString(state.tree, state.config);
                      setModalFormData({
                        ...modalFormData,
                        format: "string",
                        query: query,
                      });
                    }}
                  />{" "}
                  Human readable
                </label> */}
              {/* </div> */}
              <label className="form-label">JSON</label>
              <textarea
                className="form-control"
                rows={8}
                cols={10}
                value={modalFormData.query}
                readOnly={modalFormData.format === "string" ? true : false}
                onChange={(e) =>
                  setModalFormData((prevState) => ({
                    ...prevState,
                    query: e.target.value,
                  }))
                }
              ></textarea>
              <br />
              <label className="form-label">Human Readable</label>
              <div>{QbUtils.queryString(state.tree, state.config)}</div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                disabled={modalFormData.format === "string" ? true : false}
                onClick={() => {
                  let immutableTree;
                  if (modalFormData.format === "json") {
                    immutableTree = QbUtils.loadFromJsonLogic(
                      JSON.parse(modalFormData.query),
                      config
                    );
                    console.log(
                      QbUtils.checkTree(
                        QbUtils.loadTree(immutableTree, config),
                        config
                      )
                    );
                  } else if (modalFormData.format === "spel") {
                    let immutable = QbUtils.loadFromSpel(
                      modalFormData.query,
                      config
                    );
                    immutableTree = immutable[0];
                  } else if (modalFormData.format === "string") {
                    return;
                  }

                  setState({
                    tree: QbUtils.checkTree(
                      QbUtils.loadTree(immutableTree, config),
                      config
                    ),
                    config: config,
                  });

                  window.$("#query-format-edit-modal").modal("hide");
                }}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#query-format-edit-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitorsLookup;
