import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { useState } from "react";

export const AdminApiLogs = (props) => {
  const componentProps = {
    component: "components/self-test/api_logs",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "api_logs" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const [status, setStatus] = useState({
    completed: true,
    failed: true,
  });
  const setStatusFilters = (type) => {
    status[type] = status[type] ? false : true;
    setStatus((prevStatus) => ({ ...prevStatus, ...status }));
  };

  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  /*  if (
    dataTableRef.current &&
    (prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  } */

  useEffect(() => {
    setTimeout(() => {
      // alert("Outside" + JSON.stringify(status));
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          //lengthMenu: [10, 100, 500],
          order: [[2, "desc"]],
          searching: false,
          processing: true,
          serverSide: true,
          ordering: false,
          bPaginate: false,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/logs/api-logs-response`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              //data.startDate = filters.dates.startDate;
              //data.endDate = filters.dates.endDate;
              data.status = JSON.stringify({
                completed: window
                  .$(".api_logs .form-check-input#completed")
                  .is(":checked"),
                failed: window
                  .$(".api_logs .form-check-input#failed")
                  .is(":checked"),
              });
            },
          },
          columns: [
            { data: "api_url", name: "api_url" },
            { data: "total", name: "total" },
            { data: "sum", name: "sum" },
            { data: "min", name: "min" },
            { data: "max", name: "max" },
            { data: "avg", name: "avg" },
          ],
          columnDefs: [],
          dom: "Blfrtip",
          buttons: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [status]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">API Logs</h4>
        <div className="float-sm-end api_logs">
          <div class="d-flex align-items-center flex-wrap">
            <div class="form-check form-check-inline">
              <label>Status:</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="completed"
                onChange={() => setStatusFilters("completed")}
                defaultChecked={status.completed ? true : false}
              />
              <label class="form-check-label" for="completed">
                Completed
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="failed"
                onChange={() => setStatusFilters("failed")}
                defaultChecked={status.failed ? true : false}
              />
              <label class="form-check-label" for="failed">
                Failed
              </label>
            </div>
          </div>
        </div>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="api_logs"
        >
          <thead>
            <tr>
              <th>API URL</th>
              <th>Calls</th>
              <th>SUM</th>
              <th>MIN</th>
              <th>MAX</th>
              <th>AVG</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminApiLogs;
