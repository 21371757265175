import ApiService from "app/services/api";
import SocketService from "app/services/socket";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

export const AdminSelfTestMetricsCatchup = (props) => {
  const stats = useQuery([`self-test_metrics-catchup_stats`], async () => {
    const response = await ApiService.get(
      "self-test/metrics-catchup/stats",
      {}
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  });
  /*  const [metrics, setMetrics] = useState(null);
    useEffect(() => {
      var socket = SocketService.getSocket("/selfTest");
  
      if (socket.connected) {
        socket.emit("join", "timeLatestMetrics");
      } else {
        socket.on("connect", function () {
          socket.emit("join", "timeLatestMetrics");
        });
      }
  
      socket.on("timeLatestMetrics", function (data) {
        setMetrics(data);
      });
  
      return () => {
        socket.off("timeLatestMetrics");
        socket.emit("leave", "timeLatestMetrics");
      };
    }, []); */

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Metrics Catchup</h4>
          <div className="row">
            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!stats.data ? (
                      <Skeleton />
                    ) : (
                      <span data-plugin="counterup">
                        {moment.tz(stats.data.miners, "UTC").fromNow()}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">Latest Miners</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!stats.data ? (
                      <Skeleton />
                    ) : (
                      <span data-plugin="counterup">
                        {moment.tz(stats.data.dao_info, "UTC").fromNow()}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">Latest DAO info</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!stats.data ? (
                      <Skeleton />
                    ) : (
                      <span data-plugin="counterup">
                        {moment.tz(stats.data.planet_info, "UTC").fromNow()}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">Latest Planet Info</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSelfTestMetricsCatchup;
