import AdminSelfTestTimeLatestTlmTransfers from "./time_latest_tlm_transfers";
import AdminSelfTestTimeLatestMiningActions from "./time_latest_mining_actions";
import AdminSelfTestTimeLatestWaxTransfers from "./time_latest_wax_transfers";
import AdminSelfTestTimeLatestUserPoints from "./time_latest_user_points";
import AdminSelfTestTimeLatestRedeemUserPoints from "./time_latest_redeem_user_points";

export const AdminSelfTestElasticsearchCatchup = (props) => {
  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Index Catchup</h4>
          <div className="row">
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestMiningActions />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestTlmTransfers />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestWaxTransfers />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestUserPoints />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestRedeemUserPoints />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSelfTestElasticsearchCatchup;
