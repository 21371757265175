import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";

export const MissionsRarityNumMissions = () => {
  const componentProps = {
    component: "components/missions/mission-rarity-num-missions",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const stats = useQuery(
    [`missions_rarity_stats`, filters],
    async () => {
      const response = await ApiService.get("missions/rarity-stats", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        return;
      }

      return response.json();
    },
    {
      initialData: new Array(6).fill(0),
    }
  );

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h4>
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Number of missions per rarity`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "This shows the number of missions on each rarity."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
        </div>
      </div>
      <div className="row">
        {stats.data &&
          stats.data.map((rarity, index) => (
            <div className="col-xl-3 col-md-6" key={index}>
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {rarity.missions_count === undefined ? (
                      <Skeleton width={50} />
                    ) : (
                      <span>{rarity.missions_count}</span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">
                    {!rarity.name ? <Skeleton width={200} /> : rarity.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default MissionsRarityNumMissions;
