import ApiService from "app/services/api";
import { useQuery } from "react-query";

import moment from "moment-timezone";
import { useState } from "react";
import { useEffect } from "react";
export const SampleLongApi = () => {
  const [jobId, setJobId] = useState(`${moment.utc().valueOf()}`);
  const longApiData = useQuery(
    ["long_api_data", jobId],
    async () => {
      const response = await ApiService.get("sample-long-api", {
        jobId: jobId,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      return result;
    },
    {
      /*refetchInterval: 30 * 1000,*/
      retry: 15,
      retryDelay: 10000,
    }
  );

  return (
    <>
      {jobId}
      {longApiData.isLoading ? "loading" : longApiData.data}
    </>
  );
};

export default SampleLongApi;
