import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import AccountService from "../../../services/account";
import { Link } from "react-router-dom";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import moment from "moment-timezone";
import IpfsImage from "admin/components/ipfs-image";
import { copyToClipboard } from "app/utils/datatables";
import { useHelpTexts } from "app/hooks/useSession";
import config from "app/config";

export const ConnectedNftTransfer = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/connected-nft-transfer",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "connected_nft_transfers" },
  };
  const helpTexts = useHelpTexts();
  const account = props.account || null;
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);

  const [hideSystemAccounts, setHideSystemAccounts] = useState(true);

  const assetsTransfers = useQuery(
    [`connected_exchange_memo${account}`, account, hideSystemAccounts],
    async () => {
      const response = await AccountService.getConnectedAssetsTransfers(
        account,
        {
          hideSystemAccounts: hideSystemAccounts ? 1 : 0,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevAccount !== props.account) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!assetsTransfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [[3, "desc"]],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            {
              extend: "csv",
              title: `Connected accounts: NFT transfers ${props.account}`,
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Account")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    setTimeout(() => {
      window.$.Components.init();
    });
  }, [assetsTransfers.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#connected_nft_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("connected_nft_transfers", 1);
  };

  return (
    <>
      <div className="row">
        <div class="col-lg-12">
          <div className="card">
            <div className="card-body">
              <CardMenu {...componentProps} {...props} />
              <div className="float-sm-end">
                <form className="d-flex align-items-center flex-wrap">
                  <div className="form-check me-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="systemaccounts-check"
                      defaultChecked={hideSystemAccounts}
                      onChange={(el) =>
                        setHideSystemAccounts(!hideSystemAccounts)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="systemaccounts-check"
                    >
                      Hide system accounts
                    </label>
                  </div>
                </form>
              </div>
              <h4
                className="header-title"
                data-ht-title={
                  helpTexts.find(
                    (helpText) => helpText.key === componentProps.component
                  )?.title || ` Connected accounts: NFT transfers`
                }
              >
                {helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || ` Connected accounts: NFT transfers`}{" "}
                {props.account}
                <button
                  type="button"
                  className="btn btn-outline-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-ht-key={componentProps.component}
                  title={
                    helpTexts.find(
                      (helpText) => helpText.key === componentProps.component
                    )?.value || "Connected accounts: NFT transfers tooltip"
                  }
                  style={{ boxShadow: "none" }}
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </h4>

              <table
                className="table dt-responsive w-100 nowrap"
                ref={tableRef}
                id="connected_nft_transfers"
              >
                <thead>
                  <tr>
                    <th className="no-sort">
                      Account &nbsp;
                      <Link to="" onClick={copyWalletsToClipboard}>
                        <i
                          className="fas fa-copy"
                          title="Copy accounts to clipboard"
                        ></i>
                      </Link>
                    </th>
                    <th>Created date</th>
                    <th className="text-center">Assets</th>
                    <th>Inbound Transfers</th>
                    <th>Outbound Transfers</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {assetsTransfers.isLoading ? (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {assetsTransfers.data &&
                        Array.isArray(assetsTransfers.data) &&
                        assetsTransfers.data.map((transfer, index) => (
                          <tr key={index}>
                            <td>
                              <Link
                                to={`/admin/account-scanner/${transfer.account}`}
                                target="_blank"
                              >
                                {transfer.account}
                              </Link>
                            </td>
                            <td title={moment(transfer.created_date).fromNow()}>
                              {moment
                                .tz(transfer.created_date, "UTC")
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td className="text-center">
                              {transfer.data ? (
                                <>
                                  {transfer.data.length == 1 ? (
                                    <Link
                                      to={`/admin/nfts/${transfer.data[0].asset_id}`}
                                    >
                                      {/* <IpfsImage
                              src={`${config.ipfs.url}/ipfs/${
                                transfer.data[0].asset ? transfer.data[0].asset.data.img : ""
                              }`}
                              alt="nfts-pic"
                              style={{ width: "50px" }}
                            /> */}
                                      <p>
                                        {transfer.data[0].asset
                                          ? transfer.data[0].asset.name
                                          : "N/A"}
                                      </p>
                                    </Link>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-primary"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#view-nfts-${index}`}
                                    >
                                      View NFTs
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </td>
                            <td>{transfer.incomming}</td>
                            <td>{transfer.outgoing}</td>
                            <td>{transfer.incomming + transfer.outgoing}</td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
              {assetsTransfers.isError ? <Error500 /> : null}
            </div>
          </div>
        </div>
      </div>

      {assetsTransfers.data &&
        Array.isArray(assetsTransfers.data) &&
        assetsTransfers.data.map((transfer, index) => (
          <div
            id={`view-nfts-${index}`}
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">NFTs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transfer.data &&
                        transfer.data.map((row, key) => (
                          <tr>
                            <td className="text-center">
                              <Link to={`/admin/nfts/${row.asset_id}`}>
                                <IpfsImage
                                  src={`${config.ipfs.url}/ipfs/${
                                    row.asset ? row.asset.data.img : ""
                                  }`}
                                  alt="nfts-pic"
                                  style={{ width: "50px" }}
                                />
                                <p>{row.asset ? row.asset.name : "N/A"}</p>
                              </Link>
                              {row.direction == "out" ? (
                                <i class="fas fa-arrow-right me-2 text-danger"></i>
                              ) : (
                                <i class="fas fa-arrow-left me-2 text-success"></i>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    class="btn btn-info my-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ConnectedNftTransfer;
