import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const LatestPoints = (props) => {
  const componentProps = {
    component: "components/user-points/latest-points",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "latest_points" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const points = useQuery(["latest_points", filters], async () => {
    const response = await ApiService.get("user-points/latest-points", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!points.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100 /* 500, 1000*/],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Latest 100 Points` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("User")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [points.data]);

  const copyusersToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#latest_points_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("latest_points", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Latest 100 Points`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the latest points."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table  nowrap w-100"
          ref={tableRef}
          id="latest_points"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">
                User &nbsp;
                <Link to="" onClick={copyusersToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy users to clipboard"
                  ></i>
                </Link>
              </th>
              <th>
                points <span title="shown as x10 on the contract">*</span>
              </th>
              <th>Trx id</th>
              <th>Tool 1</th>
              <th>Tool 2</th>
              <th>Tool 3</th>
            </tr>
          </thead>
          <tbody>
            {points.data &&
              points.data.map((point, index) => {
                //point = point._source;
                return (
                  <tr key={index}>
                    <td title={moment(point["timestamp"]).fromNow()}>
                      {moment
                        .tz(point["timestamp"], "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <Link to={`/admin/account-scanner/${point.user}`}>
                        {point.user}
                      </Link>
                    </td>
                    <td className="text-end">{point.points / 10}</td>
                    <td>
                      <a
                        href={`${config.explorer.url}/transaction/${point.trx_id}`}
                        target="_blank"
                      >
                        {point.trx_id.substring(0, 5)}...
                      </a>
                    </td>
                    <td title={point.tool1 ? point.tool1_name : ""}>
                      {point.tool1 ? (
                        <Link to={`/admin/nfts/${point.tool1}`}>
                          {point.tool1_short_name}
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td title={point.tool2 ? point.tool2_name : ""}>
                      {point.tool2 ? (
                        <Link to={`/admin/nfts/${point.tool2}`}>
                          {point.tool2_short_name}
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td title={point.tool3 ? point.tool3_name : ""}>
                      {point.tool3 ? (
                        <Link to={`/admin/nfts/${point.tool3}`}>
                          {point.tool3_short_name}
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
                // return (
                //     <tr key={index}>
                //     <td title={moment(point['@timestamp']).fromNow()}>{moment.tz(point['@timestamp'],"UTC").format("YYYY-MM-DD HH:mm:ss")}</td>
                //     <td><Link to={`/admin/account-scanner/${point.act.data.user}`}>{point.act.data.user}</Link></td>
                //     <td className="text-end">{point.act.data.points/10}</td>
                //     <td><a href={`${config.explorer.url}/transaction/${point.trx_id}`} target="_blank">{point.trx_id}</a></td>
                //     </tr>
                // );
              })}

            {points.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {points.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default LatestPoints;
