import ReportsService from "admin/services/reports";
import { useReport } from "app/hooks/useSession";

export const LargeResultWindowElasticSearchModal = () => {
  const report = useReport();

  const requestReport = async (e) => {
    e.preventDefault();

    try {
      const response = await ReportsService.requestReport({
        type: report.type,
        format: report.format,
        filters: report.filters,
      });

      //const data = await response.json();
      if (!response) {
        throw Error("Unable to process");
      }

      //Emitter.emit("REQUEST_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Request created Successfully. Please wait for the updates.",
        position: "top-right",
        icon: "success",
      });
      window.$("#large-result-window-elasticsearch-modal").modal("hide");
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div
        id="large-result-window-elasticsearch-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Export Data</h4>
            </div>
            <div className="modal-body">
              There is a lot of data to handle here, it is better to export as a
              report. Click OK to create a report.
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={requestReport}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window
                    .$("#large-result-window-elasticsearch-modal")
                    .modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LargeResultWindowElasticSearchModal;
