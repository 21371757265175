import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DailySchemaChart = (props) => {
  const componentProps = {
    component: "components/nfts/daily_schema_chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [showLinear, setShowLinear] = useState(true);
  const [rarity, setRarity] = useState({
    common: true,
    rare: true,
    epic: true,
    legendary: true,
    mythical: true,
    abundant: true,
  });

  const stats = useQuery(["daily_schema_chart", filters, rarity], async () => {
    const response = await ApiService.get("nfts/daily_schema_chart", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      rarity: JSON.stringify(rarity),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  const setRarityFilters = (type) => {
    rarity[type] = rarity[type] ? false : true;
    setRarity({ ...rarity });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!stats.data) return;

    setData(stats.data);

    chartComponent.current.chart.hideLoading();
  }, [stats.data]);

  return (
    <div className="card">
      <div className="card-body">
        {/* <CardMenu {...componentProps} {...props} /> */}
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `NFTs Evaluation by Schemas & Total`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "NFT Schemas chart"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check form-check-inline">
            <label>Rarity:</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="common"
              onChange={() => setRarityFilters("common")}
              defaultChecked={rarity.common ? true : false}
            />
            <label class="form-check-label" for="common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="rare"
              onChange={() => setRarityFilters("rare")}
              defaultChecked={rarity.rare ? true : false}
            />
            <label class="form-check-label" for="rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="epic"
              onChange={() => setRarityFilters("epic")}
              defaultChecked={rarity.epic ? true : false}
            />
            <label class="form-check-label" for="epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="legendary"
              onChange={() => setRarityFilters("legendary")}
              defaultChecked={rarity.legendary ? true : false}
            />
            <label class="form-check-label" for="legendary">
              Legendary
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mythical"
              onChange={() => setRarityFilters("mythical")}
              defaultChecked={rarity.mythical ? true : false}
            />
            <label class="form-check-label" for="mythical">
              Mythical
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="abundant"
              onChange={() => setRarityFilters("abundant")}
              defaultChecked={rarity.abundant ? true : false}
            />
            <label class="form-check-label" for="abundant">
              Abundant
            </label>
          </div>
        </div>
        {stats.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: [
                    {
                      // Primary yAxis
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      title: {
                        text: "USD",
                        style: {
                          color: "#2B908F",
                        },
                      },
                      labels: {
                        style: {
                          color: "#2B908F",
                        },
                        formatter: function () {
                          return `${Intl.NumberFormat("en", {
                            notation: "compact",
                          }).format(this.value)}`;
                        },
                      },
                    },
                  ],
                  tooltip: {
                    valueDecimals: 0,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailySchemaChart;
