import AdminRealtimeLogminesTotalTlmTransfers from "./total_tlm_transfers";
import AdminRealtimeLogminesNumTransferToAccounts from "./num_transfer_to_accounts";

import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeLogminesTlmStats = (props) => {
  const componentProps = {
    component: "components/realtime/logmines/tlm_stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM Stats (last 15 mins)`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>

          <div className="row">
            <div className="col-xl-3 col-md-6">
              <AdminRealtimeLogminesTotalTlmTransfers />
            </div>
            <div className="col-xl-3 col-md-6">
              <AdminRealtimeLogminesNumTransferToAccounts />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeLogminesTlmStats;
