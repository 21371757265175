import AuthService from "../../../app/services/auth";

import UpdateDetails from "./components/update-details";
import ChangePassword from "./components/change-password";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "app/config";

export const AdminMyAccount = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    AuthService.me().then((data) => {
      setUser(data.user);
    });
  }, []);

  const refreshAccessToken = async (e) => {
    e.preventDefault();

    try {
      const data = await AuthService.logoutDevices();      

      if (data.status === "error") {
        throw new Error(data.message);
      }

      setUser(data.user);

      window.$.toast({
        heading: "Success",
        text: "Access token refreshed successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      window.$.toast({
        heading: "Error",
        text: "Refresh access token failed",
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div class="row">
        <div class="col-lg-6">
          <UpdateDetails />
        </div>
        <div class="col-lg-6">
          <ChangePassword />
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <h4 class="header-title">My Access Token</h4>
                  <p class="sub-header">{user.access_token}</p>
                </div>
                <div className="col-lg-6">
                  <div class="float-sm-end">
                    <form class="d-flex align-items-center flex-wrap">
                      <button
                        class="btn btn-primary waves-effect waves-light me-1"
                        type="submit"
                        onClick={refreshAccessToken}
                      >
                        Refresh Access Token
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <a href={`${config.admin.url}/admin/internal_api/v1/docs`} target="_blank">
                View API docs
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMyAccount;
