import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import BotActionProcessingChart from "./components/bot_action_processing_chart";
import BotsActionsChart from "./components/bots-actions-chart";
import BotsTasksChart from "./components/bots-tasks-chart";
import AdminAccountsCreators from "./components/accounts_creators";
import AdminTopTlmAccounts from "./components/top-tlm-accounts";
import AdminTopWaxAccounts from "./components/top-wax-accounts";
import AdminTopMinersTlm from "./components/top-miners-tlm";
import AdminTopMinersCount from "./components/top-miners-count";
import BotsChart from "./components/bots-chart";

export const AdminTowerReport = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/tower">Tower</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/tower/report">Report</BreadcrumbsItem>

      <BotActionProcessingChart />
      <BotsActionsChart />
      <BotsTasksChart />
      {/* <BotsChart /> */}
      <AdminAccountsCreators />
      <AdminTopTlmAccounts mode="transfer_from" />
      <AdminTopTlmAccounts mode="transfer_to" />
      <AdminTopWaxAccounts mode="transfer_from" />
      <AdminTopWaxAccounts mode="transfer_to" />
      <AdminTopMinersTlm />
      <AdminTopMinersCount />
    </>
  );
};

export default AdminTowerReport;
