import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const user = JSON.parse(localStorage.getItem("user"));
const filters = JSON.parse(sessionStorage.getItem("filters"));

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    loggedIn: user ? true : false,
    user: user ? user : null,
    filters: {
      ...{
        dates: {
          startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
      },
      ...filters,
    },
    helpTexts: [],
    dashboardComponentsCount: 0,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: state.user.id,
          email: state.user.email,
          access_token: state.user.access_token,
        })
      );
    },
    unsetUser: (state) => {
      state.user = null;
      state.loggedIn = false;
      localStorage.setItem("user", null);
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      sessionStorage.setItem("filters", JSON.stringify(state.filters));
    },
    clearFilters: (state) => {
      state.filters = {
        dates: {
          startDate: null,
          endDate: null,
        },
      };
      localStorage.setItem("filters", JSON.stringify(state.filters));
    },
    setReport: (state, action) => {
      state.report = action.payload;
    },
    clearReport: (state) => {
      state.report = null;
    },
    setHelpTexts: (state, action) => {
      state.helpTexts = action.payload;
    },
    //  setDashboardComponentsCount: (state, action) => {
    //    state.dashboardComponentsCount = action.payload;
    //  },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  unsetUser,
  setFilters,
  clearFilters,
  setReport,
  clearReport,
  setHelpTexts,
} = sessionSlice.actions;

export default sessionSlice.reducer;
