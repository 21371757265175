import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../app/state/session";
import AuthService from "../../app/services/auth";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const AdminLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const [data, setData] = useState({ email: "", password: "" });
  const [flashMessage, setFlashMessage] = useState(null);

  const handleClick = (e) => {
    e.preventDefault();

    AuthService.login(data.email, data.password).then((response) => {
      if (!response || !response.user) {
        setFlashMessage({ type: "danger", message: "Invalid login" });
        return;
      }

      dispatch(setUser(response.user));
      if (history.location.state && history.location.state.from) {
        history.push(history.location.state.from);
      } else {
        history.push("/admin/dashboard");
      }
    });
  };

  useEffect(() => {
    if (params.get("token")) {
      var token = params.get("token");
      AuthService.getUser(token).then((response) => {
        dispatch(setUser(response.user));
        if (history.location.state && history.location.state.from) {
          history.push(history.location.state.from);
        } else {
          history.push("/admin/dashboard");
        }
      });
    }

    if (params.get("code")) {
      var code = params.get("code");

      if (code === "USER_NOT_FOUND") {
        setFlashMessage({ type: "danger", message: "User not found" });
      }else if (code === "USER_INACTIVE") {
        setFlashMessage({ type: "danger", message: "Inactive user" });
      }
    }
  }, []);

  const socialLogin = (type) => {
    window.open(
      process.env.REACT_APP_API_URL + "/api/v1/login/" + type,
      "_self"
    );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-4">
        <div className="card">
          <div className="card-body p-4">
            <div className="text-center w-75 m-auto">
              <div className="auth-logo mb-4">
                <Link to="/admin" className="logo logo-dark text-center">
                  <span className="logo-lg">
                    <img
                      src="../assets/images/logo-dark.png"
                      alt=""
                      height={22}
                    />
                  </span>
                </Link>
                <Link to="/admin" className="logo logo-light text-center">
                  <span className="logo-lg">
                    <img
                      src="../assets/images/logo-light.png"
                      alt=""
                      height={22}
                    />
                  </span>
                </Link>
              </div>
            </div>
            {flashMessage ? (
              <>
                <div className={`alert alert-${flashMessage.type}`}>
                  {flashMessage.message}
                </div>
              </>
            ) : null}

            <div className="d-grid mb-0 text-center">
              <button
                className="btn btn-lg btn-block btn-danger text-uppercase"
                type="button"
                onClick={() => socialLogin("google")}
              >
                <i class="fab fa-google me-2"></i>
                Sign in with Google
              </button>
            </div>
            <h5 className="mt-3 text-muted text-center">OR</h5>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Sign in with email/password instead
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="text-muted mt-3">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                    <form>
                      <div className="mb-2">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          id="emailaddress"
                          name="email"
                          required
                          placeholder="Enter your email"
                          value={data.email}
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              email: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="Enter your password"
                            value={data.password}
                            onChange={(e) =>
                              setData((prevData) => ({
                                ...prevData,
                                password: e.target.value,
                              }))
                            }
                          />
                          <div
                            className="input-group-text"
                            data-password="false"
                          >
                            <span className="password-eye" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox-signin"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-signin"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="d-grid mb-0 text-center">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={handleClick}
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="text-center">
                 <h5 className="mt-3 text-muted">Sign in with</h5>
                 <ul className="social-list list-inline mt-3 mb-0">
                 <li className="list-inline-item">
                 <Link className="social-list-item border-danger text-danger" onClick={() => socialLogin("google")}><i className="mdi mdi-google"></i></Link>
                 </li>
                 </ul>
                 </div> */}
          </div>
        </div>

        {/* <div className="row mt-3">
            <div className="col-12 text-center">
              <p>
               
                <a href="auth-recoverpw.html" className="text-muted ms-1">
                  Forgot your password?
                </a>
              </p>
             
            </div>
           
          </div> */}
      </div>
    </div>
  );
};
export default AdminLogin;
