import CardMenu from "admin/components/card-menu";
import TotalMissionsTlm from "./total-missions-tlm";
import ActiveMissionsTlm from "./active-missions-tlm";
import MissionsBoardingTlm from "./missions-boarding-tlm";
import CompletedUnclaimedMissionsTlm from "./completed-unclaimed-missions-tlm";
import CompletedClaimedMissionsTlm from "./completed-claimed-missions-tlm";
import TotalRewards from "./total-rewards";
import { useHelpTexts } from "app/hooks/useSession";

export const MissionTlm = (props) => {
  const componentProps = {
    component: "components/missions/mission-tlm",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission TLM`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Total missions TLM - Total TLM staked across all missions in the selected time-frame. <br>
                            Active missions TLM - Total TLM staked across all active missions in the selected time-frame. <br>
                            Missions boarding TLM - Total TLM staked across all missions in the boarding state in the selected time-frame. <br>
                            Completed unclaimed missions TLM - Total TLM staked across all missions that are completed but not fully claimed in the selected time-frame. <br>
                            Completed claimed missions TLM - Total TLM staked across all missions that are completed and claimed in the selected time-frame. <br>
                            Total rewards - Total rewards released across all missions in the selected time-frame.`
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <TotalMissionsTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <ActiveMissionsTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <MissionsBoardingTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <CompletedUnclaimedMissionsTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <CompletedClaimedMissionsTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <TotalRewards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionTlm;
