import CardMenu from "admin/components/card-menu";
import UserTotalPoints from "./user-total-points";
import UserRedeemable from "./user-redeemable";
import UserTopLevel from "./user-top-level";
import UserLastAction from "./user-last-action";
import UserLandcomms from "./user-landcomms";
import UserMinerclaim from "./user-minerclaim";
import { useHelpTexts } from "app/hooks/useSession";

export const UserPointStats = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/user-point-stats",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `User point stats`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `User point stats`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Total points - Total points of the user. <br>
                            Redeemable points - Redeemable points of the user. <br>
                            Top level - Top level of the user. <br>
                            Last action - Last action of the user. <br>
                            `
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <UserTotalPoints account={props.account} />
          </div>
          <div class="col-xl-4 col-md-6">
            <UserRedeemable account={props.account} />
          </div>
          <div class="col-xl-4 col-md-6">
            <UserTopLevel account={props.account} />
          </div>
          <div class="col-xl-4 col-md-6">
            <UserLastAction account={props.account} />
          </div>
          <div class="col-xl-4 col-md-6">
            <UserLandcomms account={props.account} />
          </div>
          <div class="col-xl-4 col-md-6">
            <UserMinerclaim account={props.account} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPointStats;
