import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { useState } from "react";

export const AdminBullLogs = (props) => {
  const componentProps = {
    component: "components/self-test/bull_logs",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "bull_logs" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const [status, setStatus] = useState({
    completed: true,
    failed: true,
  });
  const setStatusFilters = (type) => {
    status[type] = status[type] ? false : true;
    setStatus((prevStatus) => ({ ...prevStatus, ...status }));
  };
  const [hideSmallProcessing, setHideSmallProcessing] = useState(true);

  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };

  /*  if (
    dataTableRef.current &&
    (prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  } */

  useEffect(() => {
    setTimeout(() => {
      // alert("Outside" + JSON.stringify(status));
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          lengthMenu: [10, 100, 500],
          order: [[1, "desc"]],
          searching: true,
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.api.url}/api/${config.api.version}/bull/logs`,
            headers: {
              ...apiHeaders(),
            },
            data: function (data) {
              //data.startDate = filters.dates.startDate;
              //data.endDate = filters.dates.endDate;
              data.status = JSON.stringify({
                completed: window
                  .$(".bull_logs .form-check-input#completed")
                  .is(":checked"),
                failed: window
                  .$(".bull_logs .form-check-input#failed")
                  .is(":checked"),
              });
              data.hideSmallProcessing = window
                .$(".bull_logs .form-check-input#hidesmallprocessing-check")
                .is(":checked")
                ? 1
                : 0;
            },
          },
          columns: [
            { data: "queue", name: "queue" },
            { data: "created_timestamp", name: "created_timestamp" },
            { data: "processed_timestamp", name: "processed_timestamp" },
            { data: "finished_timestamp", name: "finished_timestamp" },
            { data: "status", name: "status" },
            { data: "timestamp_difference", name: "timestamp_difference" },
          ],
          columnDefs: [
            {
              targets: 1,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.created_timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.created_timestamp).fromNow());
              },
            },
            {
              targets: 2,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.processed_timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.processed_timestamp).fromNow());
              },
            },
            {
              targets: 3,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${moment
                  .tz(rowData.finished_timestamp, "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                window.$(cell).prepend(data);
                window
                  .$(cell)
                  .attr("title", moment(rowData.finished_timestamp).fromNow());
              },
            },
            {
              targets: 4,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var data = `${rowData.status}`;

                window.$(cell).prepend(data);
                window.$(cell).attr("title", rowData.failed_reason);
              },
            },
            {
              targets: 5,
              createdCell: function (
                cell,
                cellData,
                rowData,
                rowIndex,
                colIndex
              ) {
                window.$(cell).empty();

                var duration = moment.duration(
                  rowData.timestamp_difference || 0
                );

                var data = "";

                if (duration) {
                  let hours = duration.hours();
                  let minutes = duration.minutes();
                  let seconds = duration.seconds();

                  if (hours > 0) {
                    data += `${hours} hour${hours > 1 ? "s" : ""} `;
                  }

                  if (minutes > 0) {
                    data += `${minutes} min${minutes > 1 ? "s" : ""} `;
                  }

                  if (seconds > 0) {
                    data += `${seconds} sec${seconds > 1 ? "s" : ""} `;
                  }

                  if (duration.asMilliseconds() < 1000) {
                    data = "< 1 sec";
                  }
                }

                /* var data = rowData.timestamp_difference
                  ? `${moment
                      .tz(rowData.timestamp_difference, "UTC")
                      .format("HH:mm:ss.SS")}`
                  : null; */

                window.$(cell).prepend(data);
              },
            },
          ],
          dom: "Blfrtip",
          buttons: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [status, hideSmallProcessing]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">Bull Logs (30 days)</h4>
        <div className="float-sm-end bull_logs">
          <div class="d-flex align-items-center flex-wrap">
            <div class="form-check form-check-inline">
              <label>Status:</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="completed"
                onChange={() => setStatusFilters("completed")}
                defaultChecked={status.completed ? true : false}
              />
              <label class="form-check-label" for="completed">
                Completed
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="failed"
                onChange={() => setStatusFilters("failed")}
                defaultChecked={status.failed ? true : false}
              />
              <label class="form-check-label" for="failed">
                Failed
              </label>
            </div>
          </div>
        </div>
        <div className="float-sm-end bull_logs">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="hidesmallprocessing-check"
                defaultChecked={hideSmallProcessing}
                onChange={(el) => setHideSmallProcessing(!hideSmallProcessing)}
              />
              <label
                className="form-check-label"
                htmlFor="hidesmallprocessing-check"
              >
                Hide Small tasks
              </label>
            </div>
          </form>
        </div>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="bull_logs"
        >
          <thead>
            <tr>
              <th>Queue</th>
              <th>Created Timestamp</th>
              <th>Processed Timestamp</th>
              <th>Finished Timestamp</th>
              <th>Status</th>
              <th>Processing Time</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminBullLogs;
