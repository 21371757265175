import { useQuery } from "react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts3d from "highcharts/highcharts-3d";
import ApiService from "app/services/api";
import CardMenu from "../card-menu";
import { roundNumber } from "app/utils/number";
import { useHelpTexts } from "app/hooks/useSession";

Highcharts3d(Highcharts);

export const TlmLorenzCurve = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/tlm/tlm-lorenz-curve",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };

  const helpTexts = useHelpTexts();
  const [seriesData, setSeriesData] = useState([]);
  const [hideSystemAccounts, setHideSystemAccounts] = useState(true);
  const [hideLesserRange, setHideLesserRange] = useState(true);

  const tlmStats = useQuery(["tlm_stats", hideSystemAccounts], async () => {
    const response = await ApiService.get("tlm/stats", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  var total_address =
    tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .reduce((total_address, stat) => total_address + stat.total_address, 0);
  var total_balance =
    tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .reduce((total_balance, stat) => total_balance + stat.total_balance, 0);

  tlmStats.data &&
    tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
      .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
      .map((stat) => {
        stat.percentage_address = parseFloat(
          (stat.total_address / total_address) * 100 || 0
        ).toFixed(2);
        stat.percentage_balance = parseFloat(
          (stat.total_balance / total_balance) * 100 || 0
        ).toFixed(2);
      });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!tlmStats.data) return;

    var seriesData = [
      {
        type: "spline",
        name: "Line of equality",
        dashStyle: "dot",
        data: [
          [0, 0],
          [100, 100],
        ],
        //  marker: {
        //    enabled: true,
        //  },
        // states: {
        //   hover: {
        //     lineWidth: 0,
        //   },
        // },
        // enableMouseTracking: false,
      },
      {
        type: "spline",
        name: "Lorenz curve",
        data: [
          /*   [0, 0],
          [94.66, 10.56],
          [98.81, 16.12],
          [99.73, 30.93],
          [99.96, 61.58],
          [100, 100], */
        ],
        marker: {
          enabled: true,
        },
      },
    ];

    seriesData[1]["data"].push([0, 0]);
    tlmStats.data &&
      tlmStats.data.stats[hideSystemAccounts ? "excluded" : "all"]
        .filter((stats) => (hideLesserRange ? stats.rangeEnd > 100 : true))
        .reduce(
          (cumulative, stat) => {
            cumulative.percentage_address =
              cumulative.percentage_address +
              parseFloat(stat.percentage_address);
            cumulative.percentage_balance =
              cumulative.percentage_balance +
              parseFloat(stat.percentage_balance);

            seriesData[1]["data"].push([
              cumulative.percentage_address,
              cumulative.percentage_balance,
            ]);
            return cumulative;
          },
          { percentage_address: 0, percentage_balance: 0 }
        );
    seriesData[1]["data"].push([100, 100]);

    setSeriesData(seriesData);
    chartComponent.current.chart.hideLoading();
  }, [tlmStats.data, hideLesserRange]);

  const options = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    exporting: false,
    credits: {
      enabled: false,
      href: "https://yeomen.ai/",
      text: "yeomen.ai",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      valueSuffix: " %",
      formatter: function () {
        return `${roundNumber(this.x)}% of all accounts hold ${
          this.y
        }% of all the TLM`;
      },
    },
    xAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Cumulative Address %",
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Cumulative TLM %",
      },
    },
    plotOptions: {
      series: {
        showInLegend: true,
        // marker: {
        //   enabled: false,
        //   states: {
        //     hover: {
        //       enabled: false,
        //     },
        //   },
        // },
      },
    },
    series: seriesData,
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lesserrange-check"
                  defaultChecked={hideLesserRange}
                  onChange={(el) => setHideLesserRange(!hideLesserRange)}
                />
                <label className="form-check-label" htmlFor="lesserrange-check">
                  Hide &#60;100
                </label>
              </div>
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideSystemAccounts}
                  onChange={(el) => setHideSystemAccounts(!hideSystemAccounts)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide system accounts
                </label>
              </div>
            </form>
          </div>
          <h4 className="header-title">
            {" "}
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM Lorenz curve`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "TLM Lorenz curve"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponent}
          />
        </div>
      </div>
    </>
  );
};

export default TlmLorenzCurve;
