import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "admin/services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";
import { useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";

export const AdminSelfTestApiCachingCalls = () => {
  const componentProps = {
    component: "components/self-test/api-caching-calls",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();

  const cachingResult = useQuery(
    "api_caching_calls",
    async () => {
      const response = await ApiService.get(`self-test/api-caching-calls`, {
        timestamp: moment.utc().valueOf(),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {}
  );

  // if (dataTableRef.current) {
  //   dataTableRef.current.destroy();
  //   dataTableRef.current = null;
  // }

  useEffect(() => {
    if (!cachingResult.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          //bPaginate: false,
         // lengthChange: false,
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [[1, "desc"]],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [cachingResult.data]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title">Api Caching Calls (Last updated: {cachingResult.data?.timestamp})</h4>
            <table
              class="table dt-responsive  w-100"
              ref={tableRef}
              id="api_caching_calls"
            >
              <thead>
                <tr>
                  <th>Api</th>
                  <th>Diff</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cachingResult.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {cachingResult.data &&
                      cachingResult.data.debugCalls.map((debugCall, index) => {
                        return (
                          <tr key={index}>
                            <td >{debugCall.api}</td>
                            <td>{debugCall.diff}</td>
                            <td>{debugCall.status}</td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {cachingResult.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSelfTestApiCachingCalls;
