import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";

export const UnclaimedTeleports = () => {
    const [value, setValue] = useState(0);
    useEffect(() => {
      var socket = SocketService.getSocket("/teleport");
  
      if (socket.connected) {
        socket.emit("join", "outdated");
      } else {
        socket.on("connect", function () {
          socket.emit("join", "outdated");
        });
      }
  
      socket.on("outdated", function (data) {
        setValue(data);
      });
  
      return () => {
        socket.off("outdated");
        socket.emit("leave", "outdated");
      };
    }, []);

  return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                    <span className={value != 0 ? 'text-danger' : ''} data-plugin="counterup">            
                    {parseFloat(value).toLocaleString()}
                    </span>
                </h3>
                <p className="text-muted mb-0">Unclaimed teleports</p>
            </div>
        </div>
  );
};

export default UnclaimedTeleports;
