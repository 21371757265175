import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import { useFilters } from "app/hooks/useSession";

export const TotalTlmBoosted = () => {
  const filters = useFilters();

  const stats = useQuery(["shining_total_tlm_boosted"], async () => {
    const response = await ApiService.get("shining/stats/total_tlm_boosted", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {stats.status === "loading" ? (
            <Skeleton width={50} />
          ) : (
            <span>
              {stats.data && stats.data.total_tlm
                ? stats.data.total_tlm.toLocaleString()
                : 0}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">TLM Boosted</p>
      </div>
    </div>
  );
};

export default TotalTlmBoosted;
