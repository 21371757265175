import { useState, useEffect } from "react";
import moment from "moment";

export const useCurrentTime = () => {
  const [currentMillis, setCurrentMillis] = useState(moment().utc().valueOf());

  useEffect(() => {
    const tick = () => {
      setCurrentMillis(moment().utc().valueOf());
    };

    const timerID = setInterval(() => tick(), 1000);

    return () => clearInterval(timerID);
  }, []);

  return currentMillis;
};

// export const useCurrentTime = () => {
//   const [currentMillis, setCurrentMillis] = useState(new Date().getTime());

//   useEffect(() => {
//     const tick = () => {
//       setCurrentMillis((prevMillis) => prevMillis + 1000);
//     };

//     const timerID = setInterval(() => tick(), 1000);

//     return () => clearInterval(timerID);
//   }, []);

//   return currentMillis;
// };

export default useCurrentTime;
