import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import { helpTextWrapper } from "app/utils/string";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DaoChartPerPlanet = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/dao/dao-chart-per-planet",
    options: { props: { planet: props.planet } },
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();

  const filters = useFilters();

  const [data, setData] = useState([]);
  const [showLinear, setShowLinear] = useState(true);

  const planet = useQuery(
    [`dao_chart_per_planet_${props.planet}`, filters],
    async () => {
      const response = await ApiService.get(
        `dao/dao-chart-per-planet/${props.planet}`,
        {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!planet.data) return;

    setData(planet.data);

    chartComponent.current.chart.hideLoading();
  }, [planet.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `DAO activity chart`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `DAO activity chart`}{" "}
          for {props.planet}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            data-ht-value={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value
            }
            title={helpTextWrapper(
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
                `This chart shows the DAO activities on ${props.planet}`,
              { PLANET: props.planet }
            )}
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {planet.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: [
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#2b908f",
                      title: {
                        text: "Active wallets",
                        style: {
                          color: "#2b908f",
                        },
                      },
                      labels: {
                        style: {
                          color: "#2b908f",
                        },
                      },
                    },
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#90ee7e",
                      title: {
                        text: "+Supply",
                        style: {
                          color: "#90ee7e",
                        },
                      },
                      labels: {
                        style: {
                          color: "#90ee7e",
                        },
                      },
                      opposite: true,
                    },
                    {
                      type: showLinear ? "linear" : "logarithmic",
                      allowDecimals: false,
                      lineColor: "#f45b5b",
                      title: {
                        text: "-Supply",
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      labels: {
                        style: {
                          color: "#f45b5b",
                        },
                      },
                      opposite: true,
                    },
                  ],
                  tooltip: {
                    valueDecimals: 0,
                    shared: true,
                    xDateFormat: "%Y-%m-%d",
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaoChartPerPlanet;
