import { configureStore } from '@reduxjs/toolkit'

import counterReducer from './state/counter'
import sessionReducer from './state/session'
import ipfsImageReducer from './state/ipfsImage'

export default configureStore({
  reducer: {
      counter: counterReducer,
      session: sessionReducer,
      ipfsImage: ipfsImageReducer
  },
})