import { useEffect } from "react";
import UserPointStats from "../components/user-points/user-point-stats";
import LearderboardPoints from "../components/user-points/leaderboard-points";
import LatestPoints from "../components/user-points/latest-points";
import UserPointChart from "../components/user-points/user-point-chart";
import UsersPerLevelChart from "../components/user-points/users-per-level-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LearderboardPointsByFrequency from "../components/user-points/leaderboard-points-by-frequency";
import LearderboardPointsByFrequency2 from "admin/components/user-points/leaderboard-points-by-frequency2";

function AdminDashboardUserPoints() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-user-points">User points</BreadcrumbsItem>      

      <div className="row">
        <div className="col-lg-12">
          <UserPointStats />
        </div>
      </div> 
      <div className="row">
        <div className="col-lg-12">
          <LearderboardPoints />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-12">
          <LearderboardPointsByFrequency />
        </div>
      </div> */}
      <div className="row">
        <div className="col-lg-12">
          <LearderboardPointsByFrequency2 />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <LatestPoints />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <UserPointChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <UsersPerLevelChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardUserPoints;
