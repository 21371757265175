import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";

export const AdminSelfTestNumProcessingBots = () => {
  const stats = useQuery(
    [`num_processing_bots`],
    async () => {
      const response = await ApiService.get("bots/get-processing-count", {});
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {}
  );

  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {stats.status === "loading" ? (
            <Skeleton width={50} />
          ) : (
            <span>
              {parseInt(stats.data.count).toLocaleString()}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">Processing Bots</p>
      </div>
    </div>
  );
};

export default AdminSelfTestNumProcessingBots;
