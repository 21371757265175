import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import CardMenu from "admin/components/card-menu";
import AccountService from "../../../services/account";
import moment from "moment-timezone";

export const ConnectedMalformedTxs = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/connected-malformed-txs",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "connected_malformed_txs" },
  };
  const account = props.account || null;

  const malformedTxs = useQuery(
    [`connected_malformed_txs`, account],
    async () => {
      const response = await AccountService.getConnectedMalformedTxs(
        account,
        {}
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  return (
    <>
      <div className="row">
        <div class="col-lg-12">
          <div className="card  mt-2">
            <div className="card-body">
              <CardMenu {...componentProps} {...props} />
              <h4 class="header-title">
                Connected accounts: Malformed TXs of {account}{" "}
              </h4>
              <table className="table  nowrap w-100">
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {malformedTxs.data &&
                    malformedTxs.data.map((row) => (
                      <tr>
                        <td>
                          <Link to={`/admin/tag-lookup/${row.tag}`}>
                            {row.tag}
                          </Link>
                        </td>
                        <td>{row.status}</td>
                        <td>
                          {moment
                            .tz(row.created_at, "UTC")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          {moment
                            .tz(row.updated_at, "UTC")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                      </tr>
                    ))}
                  {malformedTxs.isLoading ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectedMalformedTxs;
