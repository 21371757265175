import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import { useHistory } from "react-router-dom";

// Load Highcharts modules
require("highcharts/highcharts-more")(Highcharts);

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const BubbleChart = (props) => {
  const componentProps = {
    component: "components/charts/bubble-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const history = useHistory();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState([]);

  const bubble = useQuery(["bubble", filters], async () => {
    const response = await ApiService.get("planets/bubble", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let data = await response.json();
    setData(data);
  });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Most Mined Lands`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This is an aggregate view across all the different lands encapsulated within the planet."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {bubble.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {bubble.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "packedbubble",
                        // height: '100%',
                        backgroundColor: "transparent",
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      tooltip: {
                        useHTML: true,
                        pointFormat:
                          "<b>Land ID:</b> {point.id}<br><b>Bounty:</b> {point.value}<br><b>Type:</b> {point.name}<br><b>Coordinates:</b> {point.coordinates}<br><b>Landowner:</b> {point.landowner}<br><b>Commission:</b> {point.commission}%<br>",
                      },
                      plotOptions: {
                        packedbubble: {
                          minSize: "20%",
                          maxSize: "100%",
                          layoutAlgorithm: {
                            gravitationalConstant: 0.05,
                            splitSeries: true,
                            seriesInteraction: false,
                            dragBetweenSeries: true,
                            parentNodeLimit: true,
                          },
                          dataLabels: {
                            enabled: false,
                          },
                        },
                        series: {
                          events: {
                            click: function (event) {
                              if (event.point.name)
                                history.push(`/admin/nfts/${event.point.name}`);
                            },
                          },
                        },
                      },
                      series: data,
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BubbleChart;
