import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const PlanetFillRate = (props) => {
  const componentProps = {
    component: "components/planets/planet-fillrate",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  const planets = useQuery(
    [`planet_fillrate`],
    async () => {
      const response = await ApiService.get("planets/fillrate", {});
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      initialData: new Array(6).fill(0),
    }
  );

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Planet Fillrate`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the fillrate per planet."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          {planets.data &&
            planets.data.map((planet, index) => (
              <div class="col-xl-4 col-md-6" key={index}>
                <div className="widget-simple text-center card">
                  <div className="card-body">
                    <h3 className="text-success mt-0">
                      {!planet.fill_rate ? (
                        <Skeleton width={50} />
                      ) : (
                        <span>{planet.fill_rate}</span>
                      )}
                    </h3>
                    <p className="text-muted mb-0">
                      {!planet.name ? <Skeleton width={200} /> : planet.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PlanetFillRate;
