import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";

export const LandownerAllocation = (props) => {
  const componentProps = {
    component: "components/landowner/landowner-allocation",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "allocations" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const transfers = useQuery(["landowner_allocation", filters], async () => {
    const response = await ApiService.get("landowner/allocation", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `DTAL - Landowner allocation` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#allocations_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("allocations", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#allocations_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("allocations", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `DTAL - Landowner allocation`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the latest landowner payments made out from the main account terra.worlds."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="allocations"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Memo</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transfers.data &&
              transfers.data.map((transfer, index) => {
                transfer = transfer._source;
                return (
                  <tr key={index}>
                    <td title={moment(transfer.timestamp).fromNow()}>
                      {moment
                        .tz(transfer.timestamp, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${transfer.transfer_from}`}
                      >
                        {transfer.transfer_from}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${transfer.transfer_to}`}
                      >
                        {transfer.transfer_to}
                      </Link>
                    </td>
                    <td>{transfer.transfer_memo}</td>
                    <td className="text-end">
                      {new Intl.NumberFormat("en", {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      }).format(transfer.transfer_amount)}
                    </td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default LandownerAllocation;
