import { useFilters } from "../../../app/hooks/useSession";
import { useQuery } from "react-query";
import NftsService from "../../services/nfts";
import moment from "moment-timezone";
import AdminNftsKnobChart from "./nfts-stats-knob-chart";
import Skeleton from "react-loading-skeleton";

export const AdminNftsStatsMinted = () => {
  const filters = useFilters();

  const stats = useQuery(["nfts_stats_minted", filters], () => {
    return NftsService.getStats("minted", {
      startAtTime: moment
        .tz(filters.dates.startDate, "UTC")
        .startOf("day")
        .valueOf(),
      endAtTime: moment.tz(filters.dates.endDate, "UTC").endOf("day").valueOf(),
    });
  });
  

  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {stats.isLoading ? (
            <Skeleton width={50} />
          ) : (
            <span data-plugin="counterup">
              {parseInt(
                (stats.data && stats.data.minted.filtered_total) || 0
              ).toLocaleString()}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">Minted</p>
      </div>
    </div>
    // <div className="card">
    //   <div className="card-body">
    //     <div className="d-flex justify-content-between align-items-center">
    //       {stats.status === "loading" ? (
    //         // <div className="spinner-grow text-primary m-2" role="status"></div>
    //         <>
    //           <div className="knob-chart" dir="ltr">
    //             <Skeleton circle={true} height={70} width={70} />
    //           </div>
    //           <div className="text-end">
    //             <h3 className="mb-1 mt-0">
    //               <Skeleton />
    //             </h3>
    //             <p className="text-muted mb-0">Total Minted</p>
    //             <p className="text-muted mb-0">
    //               <Skeleton /> <br /> Overall
    //             </p>
    //           </div>
    //         </>
    //       ) : (
    //         <AdminNftsKnobChart
    //           data={stats.data.minted}
    //           title={"Total Minted"}
    //           options={{ color: "#1abc9c", background: "#d1f2eb" }}
    //         />
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};

export default AdminNftsStatsMinted;
