import { useCurrentTime } from "../../../app/hooks/useTimer";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
//import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { useFilters } from "../../../app/hooks/useSession";

function AdminBreadcrumb() {
  const location = useLocation();
  const currentMillis = useCurrentTime();
  const filters = useFilters();

  const renderDatetime = () => {
    //Excluded paths for showing current time
    const excludePaths = ["/admin/realtime"];
    if (
      excludePaths
        .map((excludePath) => new RegExp(excludePath).test(location.pathname))
        .includes(true)
    ) {
      return (
        <>
          {moment.utc(currentMillis).format("MMMM Do YYYY, HH:mm:ss") + " UTC"}
        </>
      );
    }

    if (filters.dates.startDate === filters.dates.endDate) {
      return (
        <>{moment(filters.dates.startDate).format("MMMM Do YYYY") + " UTC"}</>
      );
    }

    return (
      <>
        {moment(filters.dates.startDate).format("YYYY-MM-DD") +
          " to " +
          moment(filters.dates.endDate).format("YYYY-MM-DD") +
          " UTC"}
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">
            {/* {moment.utc(currentMillis).format("MMMM Do YYYY, HH:mm:ss") + " UTC"} */}
            {renderDatetime()}
          </h4>
          <div className="page-title-right">
            <Breadcrumbs
              container={"ol"}
              containerProps={{ className: "breadcrumb m-0" }}
              item={(item) => {
                return (
                  <li className="breadcrumb-item">
                    <Link to={item.to}>{item.children}</Link>
                  </li>
                );
              }}
              finalItem={(item) => {
                return (
                  <li className="breadcrumb-item active">{item.children}</li>
                );
              }}
            />

            {/* <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/admin">Yeomen</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin">Dashboards</Link>
              </li>
              <li className="breadcrumb-item active">Sales</li>
            </ol> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminBreadcrumb;
