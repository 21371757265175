import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const MissionsAprChart = (props) => {
  const componentProps = {
    component: "components/missions/missions-apr-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [types, setTypes] = useState({
    common: true,
    rare: true,
    epic: true,
    legendary: true,
  });

  const [showOutliers, setShowOutliers] = useState(false);

  const missions = useQuery(
    ["mission_apr_chart", filters, types, showOutliers],
    async () => {
      const response = await ApiService.get("missions/mission-apr-chart", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        filters: JSON.stringify(types),
        showOutliers: showOutliers ? 1 : 0,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  const setFilters = (type) => {
    types[type] = types[type] ? false : true;
    setTypes({ ...types });
  };

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!missions.data) return;

    setData(missions.data);

    chartComponent.current.chart.hideLoading();
  }, [missions.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div class="float-sm-end">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-apr-chart-common"
              onChange={() => setFilters("common")}
              defaultChecked={types.common ? true : false}
            />
            <label class="form-check-label" for="mission-apr-chart-common">
              Common
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-apr-chart-rare"
              onChange={() => setFilters("rare")}
              defaultChecked={types.rare ? true : false}
            />
            <label class="form-check-label" for="mission-apr-chart-rare">
              Rare
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-apr-chart-epic"
              onChange={() => setFilters("epic")}
              defaultChecked={types.epic ? true : false}
            />
            <label class="form-check-label" for="mission-apr-chart-epic">
              Epic
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="mission-apr-chart-legendary"
              onChange={() => setFilters("legendary")}
              defaultChecked={types.legendary ? true : false}
            />
            <label class="form-check-label" for="mission-apr-chart-legendary">
              Legendary
            </label>
          </div>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission APR chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows the APR of each mission in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {/* <div className="float-sm-start"> */}
        <form className="d-flex align-items-center flex-wrap">
          <div className="form-check me-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="outliers-check"
              defaultChecked={showOutliers}
              onChange={(el) => setShowOutliers(!showOutliers)}
            />
            <label className="form-check-label" htmlFor="outliers-check">
              Show outliers
            </label>
          </div>
        </form>
        {/* </div> */}
        {missions.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    // type: 'column',
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    title: {
                      text: "Mission",
                    },
                  },
                  yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "APR",
                    },
                  },
                  plotOptions: {
                    series: {
                      turboThreshold: 0,//handle more data points
                      //         lineWidth: 0,
                      //         marker: {
                      //             enabled: true,
                      //             radius: 2
                      //         }
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        '<span style="color:' +
                        this.series.color +
                        '">ID</span>: <b>' +
                        this.x +
                        '</b><br/><span style="color:' +
                        this.series.color +
                        '">APR%</span>: <b>' +
                        this.y +
                        '</b><br/><span style="color:' +
                        this.series.color +
                        '">Rarity</span>: <b>' +
                        this.point.rarity +
                        '</b><br/><span style="color:' +
                        this.series.color +
                        '">Launch date</span>: <b>' +
                        this.point.launch_date +
                        "</b><br/>"
                      );
                    },
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionsAprChart;
