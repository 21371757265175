import { FlashOnOutlined } from "@material-ui/icons";
import ApiService from "app/services/api";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const TagBulk = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    confirmation: false,
    description: "",
    wallets: "",
    flag: true,
  });
  const MAX_LENGTH = 500000; //500000

  const tagBulk = async (confirmation = FlashOnOutlined) => {
    try {
      if (formData.description === "") {
        throw Error("Description required");
      } else if (formData.wallets === "") {
        throw Error("Wallets required");
      }

      if (!confirmation) {
        return window.$("#tag-bulk-modal").modal("show");
      }

      let response = await ApiService.post("bots/tag-bulk", {
        description: formData.description,
        wallets: csvToArray(formData.wallets),
        flag: formData.flag
      });

      response = await response.json();

      if (!response || !response.status || response.status !== "ok") {
        throw Error(response.message ?? "Unable to process");
      }

      window.$.toast({
        heading: "Success",
        //text: `Tag Bulk created Successfully<br/>Processed: ${response.data.processed}<br/>Inserted: ${response.data.executed}<br/>Skipped: ${response.data.skipped}`,
        text: `Tag Bulk created Successfully<br/>${response.data.executed} accounts sent to queue, ${response.data.skipped} skipped`,
        position: "top-right",
        icon: "success",
      });

      history.push(`/admin/tag-lookup/${response.data.tag}`);
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Tag Bulk create Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }

    setFormData({ ...formData, confirmation: false });
  };

  const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    //const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    //const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const rows = str.split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows
      .filter(function (row) {
        if (row === "") return false;

        return true;
      })
      .map(function (row) {
        const values = row.split(delimiter);
        return values;
        //   const el = headers.reduce(function (object, header, index) {
        //     object[header] = values[index];
        //     return object;
        //   }, {});
        //   return el;
      });

    // return the array
    return arr;
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        required
                        value={formData.description}
                        maxLength={MAX_LENGTH}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Wallets</label>
                      <textarea
                        className="form-control"
                        style={{ height: "100px" }}
                        value={formData.wallets}
                        onChange={(e) => {
                          const text = e.target.value;
                          if (text.length >= MAX_LENGTH) {
                            window.$.toast({
                              heading: "Error",
                              text: "Wallets limit reached",
                              position: "top-right",
                              icon: "error",
                            });
                          }

                          setFormData({
                            ...formData,
                            wallets: text.substring(0, MAX_LENGTH),
                          });
                        }}
                      />
                      <span class="form-text m-b-none">
                        {Math.max(0, MAX_LENGTH - formData.wallets.length)}{" "}
                        characters left
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Import Wallets</label>&nbsp;
                      <input
                        type="file"
                        name="fileWallets"
                        id="fileWallets"
                        accept=".csv,.txt"
                        onChange={(e) => {
                          const input = e.target.files[0];
                          if (
                            !["text/csv", "text/plain"].includes(input.type)
                          ) {
                            window.$.toast({
                              heading: "Error",
                              text: "Invalid File selected. Supports txt and csv",
                              position: "top-right",
                              icon: "error",
                            });
                            return;
                          }

                          const reader = new FileReader();
                          reader.onload = function (event) {
                            const text = event.target.result;
                            if (text.length > MAX_LENGTH) {
                              window.$.toast({
                                heading: "Error",
                                text: "Wallets limit reached",
                                position: "top-right",
                                icon: "error",
                              });
                            }

                            setFormData({
                              ...formData,
                              wallets: text.substring(0, MAX_LENGTH),
                            });
                            e.target.value = "";
                          };
                          reader.readAsText(input);
                        }}
                      />
                    </div>
                    <div className="form-check me-2">
                      <label
                        className="checkbox-inline"
                        style={{ width: "100px" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formData.flag ? true : false}
                          id="inlineCheckbox1"
                          onChange={(el) => {
                            setFormData({
                              ...formData,
                              flag: true,
                            });
                          }}
                        />{" "}
                        Flag
                      </label>
                      <label
                        className="checkbox-inline"
                        style={{ width: "100px" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formData.flag ? false : true}
                          id="inlineCheckbox2"                         
                          onChange={(el) => {
                            setFormData({
                              ...formData,
                              flag: false,
                            });
                          }}
                        />{" "}
                        Unflag
                      </label>
                    </div>

                    <div>
                      <button
                        className="btn btn-primary waves-effect waves-light me-1"
                        type="button"
                        onClick={() => {
                          tagBulk(false);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tag-bulk-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div>
            <div className="modal-body">
              Are you sure you want to import these accounts for bot processing?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.$("#tag-bulk-modal").modal("hide");
                  tagBulk(true);
                }}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#tag-bulk-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagBulk;
