import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";

export const MiningActions = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/mining-actions",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "mines" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);
  const location = useLocation();

  const mines = useQuery(
    [`account_mines_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getMines(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!mines.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          bPaginate: false,
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Mining Actions for ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Land Owner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    setTimeout(() => {
      window.$.Components.init();
    });
  }, [mines.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#mines_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("mines", 1);
  };

  return (
    <>
      {(mines.data && mines.data.length > 0) ||
      mines.isError ||
      location.pathname.includes("/admin/dashboard") ? (
        <div className="card">
          <div className="card-body">
            <CardMenu {...componentProps} {...props} />
            <h4
              className="header-title"
              data-ht-title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || `Mining Actions`
              }
            >
              {helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `Mining Actions`}{" "}
              for {props.account}
              <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-ht-key={componentProps.component}
                title={
                  helpTexts.find(
                    (helpText) => helpText.key === componentProps.component
                  )?.value ||
                  "This shows the raw data of the most recent mining actions for this account."
                }
                style={{ boxShadow: "none" }}
              >
                <i class="fas fa-info-circle"></i>
              </button>
            </h4>
            <table
              className="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="mines"
            >
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Land</th>
                  <th className="no-sort">
                    Land Owner &nbsp;
                    <Link to="" onClick={copyWalletsToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy landowners to clipboard"
                      ></i>
                    </Link>
                  </th>
                  <th>Commission</th>
                  <th>Planet</th>
                  <th>Bounty</th>
                </tr>
              </thead>
              <tbody>
                {mines.data &&
                  mines.data.map((mine, index) => {
                    mine = mine._source;
                    return (
                      <tr key={index}>
                        <td title={moment(mine.timestamp).fromNow()}>
                          {moment
                            .tz(mine.timestamp, "UTC")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          <Link to={`/admin/nfts/${mine.act_data_land_id}`}>
                            {mine.act_data_land_id}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/admin/account-scanner/${mine.act_data_landowner}`}
                            target="_blank"
                          >
                            {mine.act_data_landowner}
                          </Link>
                        </td>
                        <td>{mine.act_data_params_commission / 100 + "%"}</td>
                        <td>{mine.act_data_planet_name}</td>
                        <td>{mine.act_data_bounty}</td>
                      </tr>
                    );
                  })}

                {mines.isFetching ? (
                  <>
                    {new Array(5).fill(0).map((log, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : null}
              </tbody>
            </table>
            {mines.isError ? <Error500 /> : null}
            <Link
              to={`/admin/mining-explorer?tree={"id":"89aabbab-cdef-4012-b456-717d9a7e23aa","type":"group","children1":{"bbb98989-89ab-4cde-b012-317d9a7e4214":{"type":"rule","properties":{"field":"act_data_miner","operator":"equal","value":["${props.account}"],"valueSrc":["value"],"valueType":["text"]}}}}`}
            >
              <button
                type="buttom"
                className="btn btn-success mb-2 mb-sm-0 mx-auto d-block mt-2"
              >
                View more
              </button>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MiningActions;
