import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminRequestSheetsManager from "./components/request-sheets-manager";
import AdminListSheetsManager from "./components/list-sheets-manager";

export const AdminSheetsManager = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/reports/sheets-manager">Sheets</BreadcrumbsItem>

      <AdminRequestSheetsManager />
      <AdminListSheetsManager />
    </>
  );
};

export default AdminSheetsManager;
