import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminRequestCompoundReport from "./components/request-compound-report";
import AdminListCompoundReports from "./components/list-compound-reports";

export const AdminCompoundReports = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/reports/compound-reports">
        Compound Reports
      </BreadcrumbsItem>

      <AdminRequestCompoundReport />
      <AdminListCompoundReports />
    </>
  );
};

export default AdminCompoundReports;
