import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { useFilters } from "../../../app/hooks/useSession";
import AccountService from "../../services/account";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    },
});

export const MiningHours = (props) => {
    const filters = useFilters();

    const dummy = Array.from(
        { length: 10 },
        () => Math.floor(Math.random() * 100) + 1
    );

  const [data, setData] = useState([]);

  const xAxis = Array.from({length: 24}, (_, index) => index );

  const location = useLocation();

  const mining = useQuery(["mining_hours", props.account, filters], async () => {
    const response = await AccountService.getMinesPerHour(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
    })
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    let data = await response.json()
    setData(data);
  });

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.$.Components.init();
    //     });
    // }, [mining])

  return (
    <>
    {((data.length > 0 && data.some(item => item !== 0)) || mining.isError || location.pathname.includes("/admin/dashboard")) ? (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title">
            Mining per hour of {props.account}
            <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="This chart shows the hours in UTC that this user was the most active during."
                style={{ boxShadow: "none" }}
            >
            <i class="fas fa-info-circle"></i>
            </button>
        </h4>
        {mining.status === "loading" ? (
          <div style={{height: "400px"}}>
          {dummy.map((value) => {
            return (
              <div className="d-flex flex-column">
                <Skeleton width={`${value}%`} />
              </div>
            );
          })}
          </div>
        ) : (
        <>
        {mining.isError ? <Error500 /> : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'column',
                        height: '20%',
                        backgroundColor: "transparent",
                        style: {
                            fontFamily: "Roboto,sans-serif",
                        },
                    },
                    title: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false,
                    },
                    xAxis: {
                        categories: xAxis,
                        title: {
                            text: 'Hour'
                        }
                    },
                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Count'
                        }
                    },
                    // tooltip: {
                    //     headerFormat: '<table>',
                    //     pointFormat: '<tr><td style="padding:0"><b>{point.y}</b></td></tr>',
                    //     footerFormat: '</table>',
                    //     shared: true,
                    //     useHTML: true
                    // },
                    series: [
                        {
                            name: "Total Mines",
                            data: data,
                        },
                    ]
                }}
              />
            </div>
          </div>
        )}
        </>
        )}
      </div>
    </div>
    ) : null }
    </>
  );
};

export default MiningHours;
