import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "admin/components/card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    },
});

export const TlmEarningsPerDay = (props) => {
    const componentProps = {
        component: "views/nft-detail/components/tlm-earnings-per-day",
        options: {},
        grid_options: { x: 0, y: 0, w: 12, h: 9 },
    };

    const filters = useFilters();

    const chartComponent = useRef(null);

  const [data, setData] = useState({dates: [], data: []});

  const mining = useQuery(["tlm_earnings_per_day", props.land_id, filters], async () => {
    const response = await ApiService.get("logmines/tlm-earnings-per-day", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
        landId: props.land_id
    });
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!mining.data) return;
    
    setData({dates: mining.data.dates, data: mining.data.data});

    chartComponent.current.chart.hideLoading();

    setTimeout(() => {
        window.$.Components.init();
    });
  }, [mining.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
            TLM earnings per day chart
            <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Land owner - TLM earnings per day chart"
                style={{ boxShadow: "none" }}
            >
            <i class="fas fa-info-circle"></i>
            </button>
        </h4>
        {mining.isError ? <Error500 /> : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'column',
                        backgroundColor: "transparent",
                        style: {
                            fontFamily: "Roboto,sans-serif",
                        },
                    },
                    title: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false,
                    },
                    xAxis: {
                        categories: data.dates,
                        title: {
                            text: "Day"
                        }
                    },
                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'TLM'
                        }
                    },
                    series: [
                        {
                            name: "Total TLM",
                            data: data.data,
                        },
                    ],
                    tooltip: {
                        valueDecimals: 4
                    },
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TlmEarningsPerDay;
