import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useRef } from "react";
import SocketService from "../../../../app/services/socket";
import moment from "moment";
import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeLogminesMiningActionsHistogramChart = (props) => {
  const componentProps = {
    component: "components/realtime/logmines/mining_actions_histogram_chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const chartRef = useRef();
  useEffect(() => {
    var socket = SocketService.getSocket("/logmines");

    if (socket.connected) {
      socket.emit("join", "numMinesHistogramChartData");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "numMinesHistogramChartData");
      });
    }

    socket.on("numMinesHistogramChartData", function (data) {
      const chart = chartRef.current.chart;

      if (chart.series[0].data[0]) {
        chart.series[0].data[0].remove(false, false);
      }
      //Spline entry
      if (chart.series[1].data[0]) {
        chart.series[1].data[0].remove(false, false);
      }

      for (var record of data) {
        const seriesPoint = chart.series[0].data.find(
          (point) => record.key === point.options.x
        );
        if (seriesPoint) {
          seriesPoint.update(record.doc_count);
        } else {
          const point = [record.key, record.doc_count];
          chart.series[0].addPoint(point, true, false);
        }

        //Spline entry
        const seriesPoint1 = chart.series[1].data.find(
          (point) => record.key === point.options.x
        );
        if (seriesPoint1) {
          seriesPoint1.update(record.miners.value);
        } else {
          const point1 = [record.key, record.miners.value];
          chart.series[1].addPoint(point1, true, false);
        }
      }
    });

    return () => {
      socket.off("numMinesHistogramChartData");
      socket.emit("leave", "numMinesHistogramChartData");
    };
  }, []);

  const options = {
    chart: {
      //type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: [
      {
        allowDecimals: false,
        min: 0,
        title: {
          text: "Total Mines",
        },
      },
      {
        title: {
          text: "Total Miners",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
      href: "https://yeomen.ai/",
      text: "yeomen.ai",
    },
    series: [
      {
        name: "Total Mines",
        type: "column",
        data: [],
      },
      {
        name: "Total Miners",
        type: "spline",
        data: [],
      },
    ],
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mining chart (last 15 mins)`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default AdminRealtimeLogminesMiningActionsHistogramChart;
