import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminRequestReport from "./components/request-report";
import AdminListReports from "./components/list-reports";

export const AdminReports = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/reports/reports">Reports</BreadcrumbsItem>

      <AdminRequestReport />
      <AdminListReports />
    </>
  );
};

export default AdminReports;
