import ApiService from "app/services/api";
import { useState } from "react";
import { useQuery } from "react-query";
import Emitter from "app/services/emitter";
import moment from "moment-timezone";
import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useUser } from "app/hooks/useSession";

const aggregations = [
  { key: "count", value: "Count" },
  { key: "sum", value: "Sum" },
  { key: "min", value: "Min" },
  { key: "max", value: "Max" },
  { key: "avg", value: "Average" },
  { key: "minute_diff", value: "Minute Difference" },
];

const periods = [
  //{ key: "latest", value: "Latest" },
  { key: "5_minute", value: "5 Minute" },
  //{ key: "10_minute", value: "10 Minute" },
  { key: "1_hour", value: "1 Hour" },
  { key: "12_hour", value: "12 Hour" },
  { key: "1_day", value: "1 Day" },
  { key: "1_month", value: "1 Month" },
];

const types = [
  { key: "alarm", value: "Alarm" },
  { key: "report", value: "Report" },
];

const conditions = [
  { key: "gt", value: "Greater" },
  { key: "lt", value: "Lesser" },
  { key: "gte", value: "Greater/Equal" },
  { key: "lte", value: "Lesser/Equal" },
];

const metrics = [
  {
    property: "mining",
    text: "Mining",
    keys: [
      //{ key: "active_miners", text: "Active miners", value: false },
      {
        key: "mining_actions",
        text: "Mining Actions",
        value: false,
        aggregations: ["count"],
        periods: periods.map((period) => period.key),
        types: ["alarm", "report"],
      },
      {
        key: "mining_actions_planet",
        text: "Mining Actions planet",
        value: true,
        aggregations: ["count"],
        periods: periods.map((period) => period.key),
        types: ["alarm", "report"],
      },
    ],
  },
  {
    property: "index_catchup",
    text: "Index Catchup",
    keys: [
      {
        key: "index_catchup_mining_actions",
        text: "Mining Actions",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "index_catchup_tlm_transfers",
        text: "TLM Transfers",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "index_catchup_wax_transfers",
        text: "WAX Transfers",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "index_catchup_user_points",
        text: "User Points",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "index_catchup_redeem_user_points",
        text: "Redeem User Points",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
    ],
  },
  {
    property: "metrics_catchup",
    text: "Metrics Catchup",
    keys: [
      {
        key: "metrics_catchup_miners",
        text: "Miners",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "metrics_catchup_dao_info",
        text: "DAO Info",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
      {
        key: "metrics_catchup_planet_info",
        text: "Planet Info",
        value: false,
        aggregations: ["minute_diff"],
        periods: periods.map((period) => period.key),
        types: ["alarm"],
      },
    ],
  },
];

// const sampleData = [
//   {
//     name: "sum",
//     data: [
//       [1664536200000, 27874],
//       [1664536500000, 38685],
//       [1664536800000, 38030],
//       [1664537100000, 38565],
//       [1664537400000, 3461],
//     ],
//   },
// ];

export const AdminCreateAlert = () => {
  const [id, setId] = useState("");
  const [metricProperty, setMetricProperty] = useState("");
  const [metricKey, setMetricKey] = useState("");
  const [metricValue, setMetricValue] = useState("");
  const [aggregation, setAggregation] = useState("");
  const [period, setPeriod] = useState("");
  const [type, setType] = useState("alarm");
  const [condition, setCondition] = useState("gt");
  const [threshold, setThreshold] = useState("");
  const [notifications, setNotifications] = useState([
    { type: null, list: null },
  ]);
  //const [notificationType, setNotificationType] = useState("email");
  //const [notificationList, setNotificationList] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const chartComponent = useRef(null);
  const [seriesData, setSeriesData] = useState([]);
  const sessionUser = useUser();

  useEffect(() => {
    Emitter.on("NEW_ALERT", () => {
      resetAlertForm();

      window.$("#cardCollpaseAlert").collapse("show");
      window.$("#basicwizard .nav li:eq(0) a").tab("show");
    });

    Emitter.on("EDIT_ALERT", (alert) => {
      const metricProperty = metrics.find(
        (metricProperty) => metricProperty.property === alert.metric_property
      );
      const metricKey =
        metricProperty &&
        metricProperty.keys.find(
          (metricKey) => metricKey.key === alert.metric_key
        );
      // const notificationList =
      //   notificationLists.data &&
      //   notificationLists.data.find(
      //     (notificationList) =>
      //       notificationList.id === parseInt(alert.notification_list_id)
      //   );
      try {
        alert.notifications = JSON.parse(alert.notifications);
      } catch (err) {
        alert.notifications = [];
      }
      alert.notifications = Array.isArray(alert.notifications)
        ? alert.notifications
        : [];

      setId(alert.id);
      setMetricProperty(metricProperty);
      setMetricKey(metricKey);
      setMetricValue(alert.metric_value);
      setAggregation(alert.aggregation);
      setPeriod(alert.period);
      setType(alert.type);
      setCondition(alert.condition);
      setThreshold(alert.threshold);
      setNotifications(alert.notifications);
      //setNotificationType(alert.notification_type);
      //setNotificationList(notificationList);
      setName(alert.name);
      setDescription(alert.description);

      window.$("#cardCollpaseAlert").collapse("show");
      window.$("#basicwizard .nav li:eq(0) a").tab("show");
    });

    return () => {
      Emitter.off("NEW_ALERT");
      Emitter.off("EDIT_ALERT");
    };
  }, []);

  const resetAlertForm = () => {
    setId(null);
    setMetricProperty("");
    setMetricKey("");
    setMetricValue("");
    setAggregation("");
    setPeriod("");
    setType("alarm");
    setCondition("");
    setThreshold("");
    setNotifications([{ type: null, list: null }]);
    //setNotificationType("email");
    //setNotificationList("");
    setName("");
    setDescription("");
    window.$("#basicwizard .nav li:eq(0) a").tab("show");
  };

  const notificationLists = useQuery(["notification_lists"], async () => {
    const response = await ApiService.get("alerts/notification/lists", {
      timestamp: moment.utc().valueOf(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  const createAlert = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post("alerts", {
        id: id,
        name: name,
        description: description,
        metric_property: metricProperty.property,
        metric_key: metricKey.key,
        metric_value: metricValue,
        aggregation: aggregation,
        period: period,
        type: type,
        condition: condition,
        threshold: threshold,
        notifications: notifications,
        //notification_type: notificationType,
        //notification_list_id: notificationList.id,
      });

      //const data = await response.json();
      if (!response.ok) {
        throw Error("Unable to process");
      }

      resetAlertForm();
      Emitter.emit("CREATE_ALERT_SUCCESS");
      Emitter.emit("LOAD_DASHBOARD_DATA");

      window.$.toast({
        heading: "Success",
        text: "Alert created Successfully. Please wait for the updates.",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(window.$("#basicwizard").bootstrapWizard());
    }, 500);
  }, []);

  useEffect(() => {
    if (!metricProperty || !metricKey || !aggregation || !period) {
      return;
    }

    chartComponent.current.chart.showLoading();
    ApiService.post("alerts/chart/data", {
      metric_property: metricProperty.property,
      metric_key: metricKey.key,
      metric_value: metricValue,
      aggregation: aggregation,
      period: period,
    })
      .then(async (response) => {
        const data = await response.json();
        setSeriesData(data);
        chartComponent.current.chart.hideLoading();
      })
      .catch((err) => {
        chartComponent.current.chart.hideLoading();
      });
  }, [aggregation, period]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="float-sm-end card-widgets">
                <a
                  data-bs-toggle="collapse"
                  href="#cardCollpaseAlert"
                  role="button"
                  aria-expanded="true"
                  aria-controls="cardCollpaseAlert"
                  className="collapsed"
                >
                  <i className="mdi mdi-minus" />
                </a>
              </div>
              <h4 className="header-title mb-3"> Alert</h4>
              <div id="cardCollpaseAlert" className="collapse">
                <form>
                  <div id="basicwizard">
                    <ul className="nav nav-pills nav-justified form-wizard-header mb-4">
                      <li className="nav-item">
                        <a
                          href="#basictab1"
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          <span className="number">1</span>
                          <span className="d-none d-sm-inline">
                            Select Metric
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#basictab2"
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          <span className="number">2</span>
                          <span className="d-none d-sm-inline">
                            Set conditions
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#basictab3"
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          <span className="number">3</span>
                          <span className="d-none d-sm-inline">
                            Choose targets
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content b-0 mb-0">
                      <div className="tab-pane" id="basictab1">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <div class="form-floating mb-3">
                              <select
                                class="form-select"
                                id="floatingMetricProperty"
                                onChange={(e) => {
                                  e.preventDefault();
                                  const metricProperty = metrics.find(
                                    (metricProperty) =>
                                      metricProperty.property === e.target.value
                                  );
                                  setMetricProperty(metricProperty);
                                }}
                                value={
                                  metricProperty && metricProperty.property
                                }
                              >
                                <option value={0}>--Select--</option>
                                {metrics
                                  .filter((metric) => {
                                    return (metric.property ===
                                      "index_catchup" ||
                                      metric.property === "metrics_catchup") &&
                                      sessionUser.role !== "super_admin"
                                      ? false
                                      : true;
                                  })
                                  .map((metricProperty, index) => (
                                    <option
                                      value={metricProperty.property}
                                      key={index}
                                    >
                                      {metricProperty.text}
                                    </option>
                                  ))}
                              </select>
                              <label htmlFor="floatingMetricProperty">
                                Metric Property
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div class="form-floating mb-3">
                              <select
                                class="form-select"
                                id="floatingMetricKey"
                                onChange={(e) => {
                                  e.preventDefault();
                                  const metricKey =
                                    metricProperty &&
                                    metricProperty.keys.find(
                                      (metricKey) =>
                                        metricKey.key === e.target.value
                                    );
                                  setMetricKey(metricKey);
                                }}
                                value={metricKey && metricKey.key}
                              >
                                <option value={0}>--Select--</option>
                                {metricProperty &&
                                  metricProperty.keys.map(
                                    (metricKey, index) => (
                                      <option value={metricKey.key} key={index}>
                                        {metricKey.text}
                                      </option>
                                    )
                                  )}
                              </select>
                              <label htmlFor="floatingMetricKey">
                                Metric Key
                              </label>
                            </div>
                          </div>
                          {metricKey && metricKey.value ? (
                            <div className="col-lg-4 col-md-6">
                              <div class="form-floating mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="floatingMetricValue"
                                  autoComplete={"off"}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setMetricValue(e.target.value);
                                  }}
                                  value={metricValue}
                                />
                                <label htmlFor="floatingMetricValue">
                                  Metric Value
                                </label>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <ul className="pager wizard mb-0 list-inline text-end mt-2">
                          <li className="next list-inline-item">
                            <button type="button" className="btn btn-success">
                              Next
                              <i className="mdi mdi-arrow-right ms-1" />
                            </button>
                          </li>
                        </ul>
                      </div>
                      {/* end tab pane */}
                      <div className="tab-pane" id="basictab2">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div class="form-floating mb-3">
                                  <select
                                    class="form-select"
                                    id="floatingAggregation"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setAggregation(e.target.value);
                                    }}
                                    value={aggregation}
                                  >
                                    <option value={""}>--Select--</option>
                                    {metricKey &&
                                      aggregations
                                        .filter((aggregation) =>
                                          metricKey.aggregations.includes(
                                            aggregation.key
                                          )
                                        )
                                        .map((aggregation) => (
                                          <option value={aggregation.key}>
                                            {aggregation.value}
                                          </option>
                                        ))}
                                    {/* <option value={"sum"}>Sum</option>
                              <option value={"min"}>Min</option>
                              <option value={"max"}>Max</option>
                              <option value={"average"}>Average</option> */}
                                  </select>
                                  <label htmlFor="floatingAggregation">
                                    Aggregation
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div class="form-floating mb-3">
                                  <select
                                    class="form-select"
                                    id="floatingPeriod"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setPeriod(e.target.value);
                                    }}
                                    value={period}
                                  >
                                    <option value={""}>--Select--</option>
                                    {metricKey &&
                                      periods
                                        .filter((period) =>
                                          metricKey.periods.includes(period.key)
                                        )
                                        .map((period) => (
                                          <option value={period.key}>
                                            {period.value}
                                          </option>
                                        ))}

                                    {/* <option value={"latest"}>Latest </option>
                                    <option value={"5_minute"}>5 Minute</option>
                                    <option value={"10_minute"}>10 Minute</option> 
                                    <option value={"1_hour"}>1 Hour</option>
                                    <option value={"12_hour"}>12 Hour</option>
                                    <option value={"1_day"}>1 Day </option>
                                    <option value={"1_month"}>1 Month </option> */}
                                  </select>
                                  <label htmlFor="floatingPeriod">Period</label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div class="form-floating mb-3">
                                  <select
                                    class="form-select"
                                    id="floatingType"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setType(e.target.value);
                                    }}
                                    value={type}
                                  >
                                    {metricKey &&
                                      types
                                        .filter((type) =>
                                          metricKey.types.includes(type.key)
                                        )
                                        .map((type) => (
                                          <option value={type.key}>
                                            {type.value}
                                          </option>
                                        ))}
                                    {/*  <option value={"alarm"}>Alarm</option>
                                    <option value={"report"}>Report</option> */}
                                  </select>
                                  <label htmlFor="floatingType">Type</label>
                                </div>
                              </div>
                            </div>
                            {type === "alarm" ? (
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div class="form-floating mb-3">
                                    <select
                                      class="form-select"
                                      id="floatingCondition"
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setCondition(e.target.value);
                                      }}
                                      value={condition}
                                    >
                                      {conditions.map((condition, index) => (
                                        <option
                                          value={condition.key}
                                          key={index}
                                        >
                                          {condition.value}
                                        </option>
                                      ))}
                                      {/* <option value={"gt"}>Greater</option>
                                      <option value={"lt"}>Lesser</option>
                                      <option value={"gte"}>
                                        Greater/Equal
                                      </option>
                                      <option value={"lte"}>
                                        Lesser/Equal
                                      </option> */}
                                    </select>
                                    <label htmlFor="floatingCondition">
                                      Condition
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div class="form-floating mb-3">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="floatingThreshold"
                                      autoComplete={"off"}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setThreshold(e.target.value);
                                      }}
                                      value={threshold}
                                    />
                                    <label htmlFor="floatingThreshold">
                                      Threshold
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {aggregation && period && condition && threshold ? (
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <h4>
                                    Alert if{" "}
                                    {
                                      {
                                        count: "Count",
                                        minute_diff: "Minute Difference",
                                        default: aggregation,
                                      }[aggregation]
                                    }{" "}
                                    over{" "}
                                    {
                                      {
                                        latest: "Latest",
                                        "5_minute": "5 Minute",
                                        "1_hour": "1 Hour",
                                        "12_hour": "12 Hour",
                                        "1_day": "1 Day",
                                        "1_month": "1 Month",
                                        default: period,
                                      }[period]
                                    }{" "}
                                    Period is{" "}
                                    {
                                      {
                                        gt: "Greater than",
                                        lt: "Less than",
                                        gte: "Greater than or Equal to",
                                        lte: "Less than or Equal to",
                                        default: condition,
                                      }[condition]
                                    }{" "}
                                    {threshold}
                                  </h4>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-lg-4">
                            <HighchartsReact
                              ref={chartComponent}
                              highcharts={Highcharts}
                              options={{
                                title: {
                                  text: "",
                                },
                                credits: {
                                  enabled: false,
                                },
                                legend: {
                                  enabled: false,
                                },
                                exporting: {
                                  enabled: false,
                                },
                                xAxis: {
                                  type: "datetime",
                                },
                                yAxis: {
                                  plotLines: [
                                    {
                                      color: "red", // Color value
                                      dashStyle: "dash", // Style of the plot line. Default to solid
                                      value: threshold, // Value of where the line will appear
                                      width: 2, // Width of the line
                                    },
                                  ],
                                },
                                series: seriesData,
                              }}
                            />
                          </div>
                        </div>

                        <ul className="pager wizard mb-0 list-inline mt-2">
                          <li className="previous list-inline-item">
                            <button type="button" className="btn btn-light">
                              <i className="mdi mdi-arrow-left me-1" /> Back
                            </button>
                          </li>
                          <li className="next list-inline-item float-end">
                            <button type="button" className="btn btn-success">
                              Next
                              <i className="mdi mdi-arrow-right ms-1" />
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="basictab3">
                        <div className="card border-light">
                          <div className="card-body">
                            <div className="float-sm-end">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={(e) => {
                                  notifications.push({
                                    type: null,
                                    list: null,
                                  });
                                  setNotifications([...notifications]);
                                }}
                              >
                                Add Notification
                              </button>

                              <Link
                                to="/admin/my-notification-lists"
                                target={`_blank`}
                                className="btn btn-success btn-sm mx-2"
                              >
                                Create New List
                              </Link>

                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  notificationLists.refetch();
                                }}
                                className="btn btn-success btn-sm"
                              >
                                Refresh Lists
                              </a>
                            </div>
                            <h4 className="header-title mb-3">Notifications</h4>
                            {notifications &&
                              notifications.map((notification, index) => (
                                <div className="row" key={index}>
                                  <div className="col-md-5">
                                    <div class="form-floating mb-3">
                                      <select
                                        class="form-select"
                                        id={`floatingNotificationType${index}`}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          notifications[index].type =
                                            e.target.value;
                                          setNotifications([...notifications]);
                                        }}
                                        value={notification.type}
                                      >
                                        <option value={""}>--Select--</option>
                                        <option value={"email"}>Email</option>
                                        <option value={"telegram"}>
                                          Telegram
                                        </option>
                                      </select>
                                      <label
                                        htmlFor={`floatingNotificationType${index}`}
                                      >
                                        Notification Type
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div class="form-floating mb-3">
                                      <select
                                        class="form-select"
                                        id={`floatingNotificationList${index}`}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const notificationList =
                                            notificationLists.data &&
                                            notificationLists.data.find(
                                              (notificationList) =>
                                                notificationList.id ===
                                                parseInt(e.target.value)
                                            );
                                          notifications[index].list =
                                            notificationList;
                                          setNotifications([...notifications]);
                                        }}
                                        value={
                                          notification.list &&
                                          notification.list.id
                                        }
                                      >
                                        <option value={""}>--Select--</option>
                                        {notificationLists.data &&
                                          notificationLists.data
                                            .filter(
                                              (notificationList) =>
                                                notificationList.type ===
                                                notification.type
                                            )
                                            .map((notificationList, index) => (
                                              <option
                                                value={notificationList.id}
                                                key={index}
                                              >
                                                {notificationList.name}
                                              </option>
                                            ))}
                                      </select>
                                      <label
                                        htmlFor={`floatingNotificationList${index}`}
                                      >
                                        Notification List
                                      </label>
                                      {/* <span class="form-text m-b-none">
                                      <Link
                                        to="/admin/my-notification-lists"
                                        target={`_blank`}
                                      >
                                        Create New List
                                      </Link>
                                    </span> */}
                                      {/* <span className="float-sm-end">
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          notificationLists.refetch();
                                        }}
                                      >
                                        Refresh List
                                      </a>
                                    </span> */}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm mt-2"
                                      onClick={(e) => {
                                        notifications.splice(index, 1);
                                        setNotifications([...notifications]);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <div class="form-floating mb-3">
                              <select
                                class="form-select"
                                id="floatingNotificationType"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setNotificationType(e.target.value);
                                }}
                                value={notificationType}
                              >
                                <option value={"email"}>Email</option>
                              </select>
                              <label htmlFor="floatingNotificationType">
                                Notification Type
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div class="form-floating mb-3">
                              <select
                                class="form-select"
                                id="floatingNotificationList"
                                onChange={(e) => {
                                  e.preventDefault();
                                  const notificationList =
                                    notificationLists.data &&
                                    notificationLists.data.find(
                                      (notificationList) =>
                                        notificationList.id ===
                                        parseInt(e.target.value)
                                    );
                                  setNotificationList(notificationList);
                                }}
                                value={notificationList && notificationList.id}
                              >
                                <option value={""}>--Select--</option>
                                {notificationLists.data &&
                                  notificationLists.data
                                    .filter(
                                      (notificationList) =>
                                        notificationList.type ===
                                        notificationType
                                    )
                                    .map((notificationList, index) => (
                                      <option
                                        value={notificationList.id}
                                        key={index}
                                      >
                                        {notificationList.name}
                                      </option>
                                    ))}
                              </select>
                              <label htmlFor="floatingNotificationList">
                                Notification List
                              </label>
                              <span class="form-text m-b-none">
                                <Link
                                  to="/admin/my-notification-lists"
                                  target={`_blank`}
                                >
                                  Create New List
                                </Link>
                              </span>
                              <span className="float-sm-end">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    notificationLists.refetch();
                                  }}
                                >
                                  Refresh List
                                </a>
                              </span>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <div class="form-floating mb-3">
                              <input
                                type="text"
                                class="form-control"
                                id="floatingName"
                                autoComplete={"off"}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setName(e.target.value);
                                }}
                                value={name}
                              />
                              <label htmlFor="floatingName">Alert Name</label>
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-6">
                            <div class="form-floating mb-3">
                              <textarea
                                className="form-control"
                                name="description"
                                id="description"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setDescription(e.target.value);
                                }}
                                value={description}
                                style={{ height: "100px" }}
                              ></textarea>
                              <label htmlFor="floatingName">
                                Alert Description
                              </label>
                            </div>
                          </div>
                        </div>
                        <ul className="pager wizard mb-0 list-inline mt-2">
                          <li className="previous list-inline-item">
                            <button type="button" className="btn btn-light">
                              <i className="mdi mdi-arrow-left me-1" /> Back
                            </button>
                          </li>
                          <li className="next list-inline-item float-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={createAlert}
                            >
                              Submit
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingMetricProperty"
                      onChange={(e) => {
                        e.preventDefault();
                        const metricProperty = metrics.find(
                          (metricProperty) =>
                            metricProperty.property === e.target.value
                        );
                        setMetricProperty(metricProperty);
                      }}
                      value={metricProperty && metricProperty.property}
                    >
                      <option value={0}>--Select--</option>
                      {metrics.map((metricProperty, index) => (
                        <option value={metricProperty.property} key={index}>
                          {metricProperty.text}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingMetricProperty">
                      Metric Property
                    </label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingMetricKey"
                      onChange={(e) => {
                        e.preventDefault();
                        const metricKey =
                          metricProperty &&
                          metricProperty.keys.find(
                            (metricKey) => metricKey.key === e.target.value
                          );
                        setMetricKey(metricKey);
                      }}
                      value={metricKey && metricKey.key}
                    >
                      <option value={0}>--Select--</option>
                      {metricProperty &&
                        metricProperty.keys.map((metricKey, index) => (
                          <option value={metricKey.key} key={index}>
                            {metricKey.text}
                          </option>
                        ))}
                    </select>
                    <label htmlFor="floatingMetricKey">Metric Key</label>
                  </div>
                </div>
                {metricKey && metricKey.value ? (
                  <div className="col-lg-3 col-md-6">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingMetricValue"
                        autoComplete={"off"}
                        onChange={(e) => {
                          e.preventDefault();
                          setMetricValue(e.target.value);
                        }}
                        value={metricValue}
                      />
                      <label htmlFor="floatingMetricValue">Metric Value</label>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingAggregation"
                      onChange={(e) => {
                        e.preventDefault();
                        setAggregation(e.target.value);
                      }}
                      value={aggregation}
                    >
                      <option value={""}>--Select--</option>
                      <option value={"average"}>Average</option>
                      <option value={"sum"}>Sum</option>
                      <option value={"min"}>Min</option>
                      <option value={"max"}>Max</option>
                    </select>
                    <label htmlFor="floatingAggregation">Aggregation</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingPeriod"
                      onChange={(e) => {
                        e.preventDefault();
                        setPeriod(e.target.value);
                      }}
                      value={period}
                    >
                      <option value={""}>--Select--</option>
                      <option value={"5_minute"}>5 Minute</option>
                      <option value={"10_minute"}>10 Minute</option>
                      <option value={"1_hour"}>1 Hour</option>
                      <option value={"1_day"}>1 Day</option>
                      <option value={"1_month"}>1 Month</option>
                    </select>
                    <label htmlFor="floatingPeriod">Period</label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingType"
                      onChange={(e) => {
                        e.preventDefault();
                        setType(e.target.value);
                      }}
                      value={type}
                    >
                      <option value={"alarm"}>Alarm</option>
                      <option value={"report"}>Report</option>
                    </select>
                    <label htmlFor="floatingType">Type</label>
                  </div>
                </div>
              </div>
              {type === "alarm" ? (
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        id="floatingCondition"
                        onChange={(e) => {
                          e.preventDefault();
                          setCondition(e.target.value);
                        }}
                        value={condition}
                      >
                        <option value={"gt"}>Greater</option>
                        <option value={"lt"}>Lesser</option>
                        <option value={"gte"}>Greater/Equal</option>
                        <option value={"lte"}>Lesser/Equal</option>
                      </select>
                      <label htmlFor="floatingCondition">Condition</label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingThreshold"
                        autoComplete={"off"}
                        onChange={(e) => {
                          e.preventDefault();
                          setThreshold(e.target.value);
                        }}
                        value={threshold}
                      />
                      <label htmlFor="floatingThreshold">Threshold</label>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingNotificationType"
                      onChange={(e) => {
                        e.preventDefault();
                        setNotificationType(e.target.value);
                      }}
                      value={notificationType}
                    >
                      <option value={"email"}>Email</option>
                    </select>
                    <label htmlFor="floatingNotificationType">
                      Notification Type
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingNotificationList"
                      onChange={(e) => {
                        e.preventDefault();
                        const notificationList =
                          notificationLists.data &&
                          notificationLists.data.find(
                            (notificationList) =>
                              notificationList.id === parseInt(e.target.value)
                          );
                        setNotificationList(notificationList);
                      }}
                      value={notificationList && notificationList.id}
                    >
                      <option value={""}>--Select--</option>
                      {notificationLists.data &&
                        notificationLists.data
                          .filter(
                            (notificationList) =>
                              notificationList.type === notificationType
                          )
                          .map((notificationList, index) => (
                            <option value={notificationList.id} key={index}>
                              {notificationList.name}
                            </option>
                          ))}
                    </select>
                    <label htmlFor="floatingNotificationList">
                      Notification List
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingName"
                      autoComplete={"off"}
                      onChange={(e) => {
                        e.preventDefault();
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                    <label htmlFor="floatingName">Alert Name</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-light"
                    onClick={createAlert}
                  >
                    Create Alert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AdminCreateAlert;
