import AdminSelfTestTimeLatestMiningActions from "./time_latest_mining_actions";
import AdminSelfTestTimeLatestNftDatabase from "./time_latest_nft_database";
import AdminSelfTestTimeLatestNftTemplates from "./time_latest_nft_templates";
import AdminSelfTestLatestMission from "./time_latest_mission";

import AdminSelfTestNumFailedJobs from "./num_failed_jobs";
import AdminSelfTestNumPendingJobs from "./num_pending_jobs";
// import AdminSelfTestNumActiveJobs from "./num_active_jobs";
import AdminSelfTestNodeStatus from "./node_status";
import AdminSelfTestWaxChainApiStatus from "./wax_chain_api_status";
import AdminSelfTestMysqlStatus from "./mysql_status";
import AdminSelfTestBotCheckStatus from "./bot_check_status";
import AdminSelfTestLatestBotCheckStatus from "./latest_bot_check_status";
import AdminSelfTestNumProcessingBots from "./num_processing_bots";
import AdminSelfTestTimestampProcessingBots from "./timestamp_processing_bots";
import AdminSelfTestTimeLatestLogminesTemplates from "./time_latest_logmines_templates";
import AdminSelfTestTimeLatestNftTransfersTemplates from "./time_latest_nft_transfers_templates";
import AdminSelfTestLatestProcessedCreatedAccounts from "./latest_processed_created_accounts";

export const AdminSelfTestLiveCatchup = (props) => {
  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Live Catchup</h4>
          <div className="row">
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestNftDatabase />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestMiningActions />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestNftTemplates />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestLatestMission />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestNumFailedJobs />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestNumPendingJobs />
            </div>
            {/* <div className="col-xl-3 col-md-3">
              <AdminSelfTestNumActiveJobs />
            </div> */}
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestNodeStatus />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestWaxChainApiStatus />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestMysqlStatus />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestBotCheckStatus />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestLatestBotCheckStatus />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestNumProcessingBots />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimestampProcessingBots />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestLogminesTemplates />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestTimeLatestNftTransfersTemplates />
            </div>
            <div className="col-xl-3 col-md-3">
              <AdminSelfTestLatestProcessedCreatedAccounts />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSelfTestLiveCatchup;
