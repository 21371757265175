import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsHistogramBellcurve from "highcharts/modules/histogram-bellcurve";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "admin/components/card-menu";
import moment from "moment";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
HighchartsHistogramBellcurve(Highcharts);
indicators(Highcharts);
regressions(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const AdminTowerMinersScoresHistogram = ({ filters }) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "views/tower/debug/components/tower_miners_scores_histogram",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();

  const [data, setData] = useState([]);

  const results = useQuery(
    ["tower_miners_scores_histogram", filters],
    async () => {
      const response = await ApiService.get(
        "tower/tower-miners-scores-histogram",
        {
          ...filters,
          timestamp: moment().utc().valueOf(),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!results.data) return;

    setData(results.data);

    chartComponent.current.chart.hideLoading();
  }, [results.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Tower Miners Scores Histogram`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "Tower Miners Scores Histogram"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {results.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: {
                    text: "Tower Miners Scores Histogram",
                  },
                  credits: {
                    enabled: false,
                  },
                  xAxis: [
                    {
                      title: { text: "Data" },
                      alignTicks: false,
                    },
                    {
                      title: { text: "Histogram" },
                      alignTicks: false,
                      opposite: true,
                      plotLines: data.plotLines,
                    },
                  ],

                  yAxis: [
                    {
                      title: { text: "Data" },
                    },
                    {
                      title: { text: "Histogram" },
                      opposite: true,
                    },
                  ],

                  plotOptions: {
                    histogram: {
                      accessibility: {
                        point: {
                          valueDescriptionFormat:
                            "{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.",
                        },
                      },
                    },
                  },

                  series: data.series,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminTowerMinersScoresHistogram;
