import ApiService from "app/services/api";
import { useEffect } from "react";
import { useRef } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import CardMenu from "../card-menu";
import moment from "moment-timezone";
import { useHelpTexts } from "app/hooks/useSession";

export const LatestTags = (props) => {
  const componentProps = {
    component: "components/tag-lookup/latest-tags",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "latest-tags" },
  };

  const helpTexts = useHelpTexts();
  const history = useHistory();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const latestTagReasons = useQuery([`latest_tag_reasons`], async () => {
    const response = await ApiService.get("bots/tag-reasons/latest", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!latestTagReasons.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Latest Tags` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Miner")',
                rows: ":visible",
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [latestTagReasons.data]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card  mt-2">
            <div className="card-body">
              <CardMenu {...componentProps} {...props} />
              <h4 class="header-title">
                {helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || `Latest Tags`}
                <button
                  type="button"
                  className="btn btn-outline-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-ht-key={componentProps.component}
                  title={
                    helpTexts.find(
                      (helpText) => helpText.key === componentProps.component
                    )?.value || "Latest Tags"
                  }
                  style={{ boxShadow: "none" }}
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </h4>
              <table
                className="table dt-responsive nowrap w-100"
                ref={tableRef}
                id="latest-tags"
              >
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>Created</th>
                    <th>Stats</th>
                  </tr>
                </thead>
                <tbody>
                  {latestTagReasons &&
                    latestTagReasons.data &&
                    latestTagReasons.data.map((row) => (
                      <tr>
                        <td>
                          <Link
                            to={`/admin/tag-lookup/${row.tag}`}
                            onClick={() => {
                              history.push({
                                pathname: "/admin/tag-lookup/" + row.tag,
                              });
                            }}
                          >
                            {row.tag}
                          </Link>
                        </td>
                        <td>
                          {row.created_at
                            ? moment
                                .tz(row.created_at, "UTC")
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "N/A"}
                        </td>
                        <td>
                          {row.total_done} of {row.total} processed
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestTags;
