import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "../card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";

export const MissionsTable = (props) => {
  const componentProps = {
    component: "components/missions/missions-table",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const missions = useQuery(["missions_table", filters], async () => {
    const response = await ApiService.get("missions/mission-table", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!missions.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          scrollX: true,
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [missions.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Mission table`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This table shows the details of the missions."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table className="table nowrap w-100" ref={tableRef}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Launch time</th>
              <th>End time</th>
              <th>Days</th>
              <th>Reward</th>
              <th>Earnings</th>
              <th>Cost</th>
              <th>Rarity</th>
              <th>Players</th>
              <th>Spaceships</th>
              <th>APR</th>
              <th>NFT interest (=5)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {missions.data &&
              missions.data.map((mission, index) => {
                return (
                  <tr key={index}>
                    <td>{mission.id}</td>
                    <td>{mission.name}</td>
                    <td title={moment(mission.launch_time).fromNow()}>
                      {moment
                        .tz(mission.launch_time, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td title={moment(mission.end_time).fromNow()}>
                      {moment
                        .tz(mission.end_time, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td className="text-end">{mission.days}</td>
                    <td className="text-end">
                      {mission.reward.toLocaleString()}
                    </td>
                    <td className="text-end">{mission.earnings}</td>
                    <td className="text-end">
                      {mission.spaceship_cost.toLocaleString()}
                    </td>
                    <td>{mission.rarity}</td>
                    <td className="text-end">
                      {mission.total_players.toLocaleString()}
                    </td>
                    <td className="text-end">
                      {mission.total_spaceship.toLocaleString()}
                    </td>
                    <td className="text-end">{mission.apr.toLocaleString()}</td>
                    <td className="text-end">{mission.nft_interest}</td>
                    <td>{mission.status}</td>
                  </tr>
                );
              })}

            {missions.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {missions.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default MissionsTable;
