import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const DailyTransferChart = (props) => {
  const componentProps = {
    component: "components/teleport/daily-transfer-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const chartComponent = useRef(null);

  const [data, setData] = useState([]);
  const [type, setType] = useState("daily");
  const [showLinear, setShowLinear] = useState(true);

  const transfers = useQuery(["daily_transfer_chart", filters, type], async () => {
    const response = await ApiService.get("teleport/daily-transfer-chart", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      type: type
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let result = await response.json();
    return result;
  });

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!transfers.data) return;

    setData(transfers.data);

    chartComponent.current.chart.hideLoading();
  }, [transfers.data]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const getTitleText = () => {
    let text;
    switch (type) {
      case "monthly":
        text = "Month";
        break;
      case "weekly":
        text = "Week";
        break;
      case "daily":
      default:
        text = "Day";
        break;
      case "hourly":
        text = "Hour";
        break;
    }
    return text;
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={handleChange}
                defaultValue={type}
              >
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>               
              </select>
            </div>
          </form>
        </div>
        <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="linear-check"
                defaultChecked={showLinear}
                onChange={(el) => setShowLinear(!showLinear)}
              />
              <label className="form-check-label" htmlFor="linear-check">
                Show linear
              </label>
            </div>
          </form>
        </div>        
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Transfer chart`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This chart shows WAX to BSC and BSC to WAX transfers in the selected time-frame."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {transfers.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: getTitleText(),
                    },
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    allowDecimals: false,
                    title: {
                      text: "Amount",
                    },
                  },
                  tooltip: {
                    valueDecimals: 3,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyTransferChart;
