import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";

export const TlmSwapped = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/tlm-swapped",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const prevfilters = usePrevious(filters);

  const transfers = useQuery(
    [`account_tlm_swapped_${props.account}`, props.account, filters],
    async () => {
      const response = await AccountService.getTlmSwapped(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (
    dataTableRef.current &&
    (prevAccount !== props.account || prevfilters !== filters)
  ) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [transfers.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `TLM swapped`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `TLM swapped`}{" "}
          by {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the TLM swapped by this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="tlm_swapped"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>TLM sent</th>
              <th>Token received</th>
              <th>Amount received</th>
              <th>Memo</th>
              <th>Sent to</th>
              <th>Delay (Days)</th>
            </tr>
          </thead>
          <tbody>
            {transfers.data &&
              Array.isArray(transfers.data) &&
              transfers.data.map((transfer, index) => {
                return (
                  <tr key={index}>
                    <td title={moment(transfer.timestamp).fromNow()}>
                      {moment
                        .tz(transfer.timestamp, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>{transfer.tlm}</td>
                    <td>{transfer.target_token}</td>
                    <td>{transfer.target_amount}</td>
                    <td>{transfer.memo}</td>
                    <td>{transfer.sent_to}</td>
                    <td>{transfer.delay}</td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(5).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default TlmSwapped;
