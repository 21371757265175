import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";

export const AvgCommission = () => {
  const filters = useFilters();

  const stats = useQuery([`mining_stats_avgCommission`, filters], async () => {
    const response = await ApiService.get("logmines/stats/avgCommission", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {stats.status === "loading" ? (
            <Skeleton />
          ) : (
            <span data-plugin="counterup">{parseInt((stats.data.avg_commission/10000)*100).toLocaleString()}%</span>
          )}
        </h3>
        <p className="text-muted mb-0">Average Commission</p>
      </div>
    </div>
  );
};

export default AvgCommission;
