import { useEffect } from "react";
import PendingTransfers from "../components/teleport/pending-transfers";
import WaxToBscTransfers from "../components/teleport/wax-to-bsc-transfers";
import BscToWaxTransfers from "../components/teleport/bsc-to-wax-transfers";
import CancelledTeleports from "../components/teleport/cancelled-teleports";
import StuckTeleportsTable from "../components/teleport/stuck-teleports-table";
import UnclaimedTeleportsTable from "../components/teleport/unclaimed-teleports-table";
import DailyTransferChart from "../components/teleport/daily-transfer-chart";
import TeleportStats from "../components/teleport/teleport-stats";
import TlmPositions from "../components/teleport/tlm-positions";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LatestTeleports from "admin/components/teleport/latest-teleports";

function AdminDashboardTeleport() {

    useEffect(() => {
        setTimeout(() => {
          window.$.Components.init();
        });
    })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-teleport">Teleport</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <TeleportStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <LatestTeleports />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TlmPositions />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <PendingTransfers />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <WaxToBscTransfers />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <BscToWaxTransfers />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <CancelledTeleports />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <StuckTeleportsTable />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <UnclaimedTeleportsTable />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailyTransferChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardTeleport;
