import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminCreateAlert from "./components/create-alert";
import AdminListAlerts from "./components/list-alerts";

export const AdminMyAlerts = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/my-alerts">Alerts</BreadcrumbsItem>

      <AdminCreateAlert />
      <AdminListAlerts />
    </>
  );
};

export default AdminMyAlerts;
