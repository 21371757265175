import AdminNftsStats  from "../../components/nfts/nfts-stats";
import AdminNftsGrid from "../../components/nfts/nfts-grid";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminNftsList from "../../components/nfts/nfts-list";
import DailySalesChart from "admin/components/nfts/daily_sales_chart";
import DailySchemaChart from "admin/components/nfts/daily_schema_chart";
import NftsSalesChart from "admin/components/nfts/nfts-sales-chart";

export const AdminDashboardNfts = () => {

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-nfts">Nfts</BreadcrumbsItem>

      <AdminNftsStats />
      <AdminNftsList />
      <div className="row">
        <div className="col-lg-12">
          <DailySalesChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <NftsSalesChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <DailySchemaChart />
        </div>
      </div>
      <AdminNftsGrid />
    </>
  );
};

export default AdminDashboardNfts;
