import { useBetween } from 'use-between'; 
import ApiService from 'app/services/api';
import { useEffect,useState } from 'react';
import moment from 'moment';
import Emitter from "app/services/emitter";

const useData = () => {
  const [data, setData] = useState(0);
   
  useEffect(() => {
    const fetchDashboardData = async () => {
      const response = await ApiService.get("me/dashboard/data", {
        timestamp: moment.utc().valueOf(),
      })
        .then((response) => response && response.json())
        .catch();

      if (response) setData(response);
    };

    Emitter.on("LOAD_DASHBOARD_DATA", (newValue) => {
      fetchDashboardData();
    });

    fetchDashboardData();

    return () => {
      Emitter.off("LOAD_DASHBOARD_DATA");    
    };
  }, []);

  return data;
};

export const useDashboardData = ()  => useBetween(useData);