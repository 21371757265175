import { useHistory } from "react-router-dom";
function Home(){
    const history = useHistory();
    //history.push("/admin/dashboard");
   // window.location.href = window.location.href;
    return  (
        <div>
            home page
        </div>
    )
}


export default Home;