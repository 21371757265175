import { useHelpTexts } from "app/hooks/useSession";
import { useEffect } from "react";

export const AdminHelpTexts = () => {
  const helpTexts = useHelpTexts();
  useEffect(() => {
    //window.$.fn.editable.defaults.mode = 'popup';
    // window.$.fn.editableform.buttons =
    //   '<button type="submit" class="btn btn-primary editable-submit btn-sm waves-effect waves-light"><i class="mdi mdi-check"></i></button><button type="button" class="btn btn-danger editable-cancel btn-sm waves-effect"><i class="mdi mdi-close"></i></button>';
    // window.$(".help-texts").editable({
    // showbuttons: "bottom",
    //mode: "popup",
    // inputclass: "form-control-sm",
    //});
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title">
                TLM Distibution
                <button
                  type="button"
                  className="btn btn-outline-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-ht-key="sample-tip"
                  title={
                    helpTexts.find((helpText) => helpText.key === "sample-tip")?.value || 'Testing'
                  }
                  style={{ boxShadow: "none" }}
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </h4>

              <h4 className="header-title">Group 1</h4>
              <a
                href="#"
                class="help-texts"
                id="inline-username"
                data-type="text"
                data-pk="1"
                data-title="Enter username"
              >
                Superuser
              </a>

              <div className="form-group row">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="example-input-small"
                >
                  Help Text 1
                </label>
                <div className="col-sm-10">
                  <a
                    href="#"
                    class="help-texts"
                    data-type="textarea"
                    data-pk={1}
                    data-placeholder="Your comments here..."
                    data-title="Enter comments"
                    data-placement="top"
                  >
                    Awesome user!
                  </a>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="example-input-small"
                >
                  Help Text 2
                </label>
                <div className="col-sm-10">
                  <a
                    href="#"
                    class="help-texts"
                    data-type="textarea"
                    data-pk={1}
                    data-placeholder="Your comments here..."
                    data-title="Enter comments"
                  >
                    Sample text
                  </a>
                </div>
              </div>

              <h4 className="header-title">Group 2</h4>

              <div className="form-group row">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="example-input-small"
                >
                  Help Text 1
                </label>
                <div className="col-sm-10">
                  <a
                    href="#"
                    class="help-texts"
                    data-type="textarea"
                    data-pk={1}
                    data-placeholder="Your comments here..."
                    data-title="Enter comments"
                  >
                    Awesome user!
                  </a>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="example-input-small"
                >
                  Help Text 1
                </label>
                <div className="col-sm-10">
                  <a
                    href="#"
                    class="help-texts"
                    data-type="textarea"
                    data-pk={1}
                    data-placeholder="Your comments here..."
                    data-title="Enter comments"
                  >
                    Awesome user!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHelpTexts;
