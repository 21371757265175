import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminSystemAccounts from "./components/accounts";

export const AdminDashboardSystem = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard/system">System Accounts</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <AdminSystemAccounts />
        </div>
      </div>
    </>
  );
};

export default AdminDashboardSystem;
