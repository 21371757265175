import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import { useHistory } from "react-router-dom";
import { helpTextWrapper } from "app/utils/string";

HighchartsHeatmap(Highcharts);
DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const Heatmap = (props) => {
  const componentProps = {
    component: "components/charts/heatmap",
    options: { props: { planet: props.planet } },
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const history = useHistory();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState([]);

  const xAxis = Array.from({ length: 41 }, (_, index) => index);
  const yAxis = Array.from({ length: 21 }, (_, index) => index);
  const getPointCategoryName = (point, dimension) => {
    let series = point.series,
      isY = dimension === "y",
      axis = series[isY ? "yAxis" : "xAxis"];
    return axis.categories[point[isY ? "y" : "x"]];
  };

  const heatmap = useQuery([props.planet + "_heatmap", filters], async () => {
    const response = await ApiService.get("planets/heatmap", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      planet: props.planet,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let data = await response.json();
    setData(data);
  });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Map of planet`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Map of planet`}{" "}
          {props.planet}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            data-ht-value={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value
            }
            title={helpTextWrapper(
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
                `This shows the mining activity of each of the land items within the planet ${props.planet} during the timeframe selected. Darker areas indicate more activity.`,
              { PLANET: props.planet }
            )}
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {heatmap.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {heatmap.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "heatmap",
                        backgroundColor: "transparent",
                        plotBorderWidth: 1,
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      xAxis: {
                        categories: { xAxis },
                      },
                      yAxis: {
                        categories: { yAxis },
                        title: null,
                        reversed: true,
                      },
                      accessibility: {
                        point: {
                          descriptionFormatter: function (point) {
                            var ix = point.index + 1,
                              xName = getPointCategoryName(point, "x"),
                              yName = getPointCategoryName(point, "y"),
                              val = point.value;
                            return (
                              ix +
                              ". " +
                              xName +
                              " sales " +
                              yName +
                              ", " +
                              val +
                              "."
                            );
                          },
                        },
                      },
                      colorAxis: {
                        min: 0,
                        minColor: "#FFFFFF",
                        maxColor: "#8B0000",
                      },
                      legend: {
                        align: "right",
                        layout: "vertical",
                        margin: 0,
                        verticalAlign: "top",
                        y: 25,
                        symbolHeight: 300,
                      },
                      tooltip: {
                        formatter: function () {
                          return (
                            "Land: <b>" +
                            this.point.land +
                            "</b><br>Total Bounty: <b>" +
                            this.point.total +
                            "</b><br>Commission: <b>" +
                            this.point.commission +
                            "%</b><br>Land owner: <b>" +
                            this.point.landowners +
                            "</b><br>Land type: <b>" +
                            this.point.landtype +
                            "</b>"
                          );
                        },
                      },
                      series: [
                        {
                          name: "Lands in planet",
                          borderWidth: 1,
                          dataLabels: {
                            enabled: false,
                            color: "#000000",
                          },
                          data: data,
                        },
                      ],
                      responsive: {
                        rules: [
                          {
                            condition: {
                              maxWidth: 500,
                            },
                            chartOptions: {
                              yAxis: {
                                labels: {
                                  formatter: function () {
                                    return this.value.charAt(0);
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                      plotOptions: {
                        series: {
                          events: {
                            click: function (event) {
                              history.push(`/admin/nfts/${event.point.land}`);
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Heatmap;
