import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";

export const GuardRamCount = () => {
    const [value, setValue] = useState(0);
    useEffect(() => {
      var socket = SocketService.getSocket("/guard");
  
      if (socket.connected) {
        socket.emit("join", "ramCount");
      } else {
        socket.on("connect", function () {
          socket.emit("join", "ramCount");
        });
      }
  
      socket.on("ramCount", function (data) {
        setValue(data);
      });
  
      return () => {
        socket.off("ramCount");
        socket.emit("leave", "ramCount");
      };
    }, []);

    return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                    <span data-plugin="counterup">            
                    {parseFloat(value).toLocaleString()}
                    </span>
                </h3>
                <p className="text-muted mb-0">Ram count</p>
            </div>
        </div>
    );
};

export default GuardRamCount;
