import { useEffect } from "react";

export const StatBox = (props) => {
    const data = props.data;
    const title = props.title;
    
    return (
          <>
            <h3 className="text-success mt-0">
              <span>{data}</span>
            </h3>
            <p className="text-muted mb-0">{title}</p>
          </>
    );
}

export default StatBox;