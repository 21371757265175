import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import { copyToClipboard } from "app/utils/datatables";

export const TopLandowners = (props) => {
  const componentProps = {
    component: "components/landowner/top-landowners",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "top_landowners" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const planets = [
    "eyeke.world",
    "kavian.world",
    "magor.world",
    "naron.world",
    "neri.world",
    "veles.world",
  ];

  const topLandowners = useQuery([`top_landowners`, filters], async () => {
    const response = await ApiService.get("landowner/top-landowners", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topLandowners.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          columnDefs: [{ targets: "no-sort", orderable: false }],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          buttons: [
            "copy",
            { extend: "csv", title: `Landowners` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Landowner")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [topLandowners.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#top_landowners_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("top_landowners", 1);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Landowners`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value || "landowners tooltip"
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <table
            class="table dt-responsive nowrap w-100"
            ref={tableRef}
            id="top_landowners"
          >
            <thead>
              <tr>
                <th className="no-sort">
                  Landowner &nbsp;
                  <Link to="" onClick={copyWalletsToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy landowners to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Number of Lands</th>
                {planets.map((planet, index) => (
                  <th>{planet}</th>
                ))}
                <th>Total Land Valuation</th>
              </tr>
            </thead>
            <tbody>
              {topLandowners.data &&
                topLandowners.data.map((topLandowner, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/admin/account-scanner/${topLandowner.name}`}
                        >
                          {topLandowner.name}
                        </Link>
                      </td>
                      <td>{topLandowner.total_lands}</td>
                      {planets.map((planet, index) => (
                        <td>
                          {parseFloat(
                            topLandowner.planet_lands[planet]
                              ? topLandowner.planet_lands[planet]
                              : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                          })}
                        </td>
                      ))}
                      <td>
                        {parseFloat(topLandowner.land_valuation).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 4,
                          }
                        )}
                      </td>
                    </tr>
                  );
                })}

              {topLandowners.isFetching ? (
                <>
                  {new Array(10).fill(0).map((log, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      {planets.map((planet, index) => (
                        <td>
                          <Skeleton />
                        </td>
                      ))}
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
          {topLandowners.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default TopLandowners;
