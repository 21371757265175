import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { copyToClipboard } from "app/utils/datatables";
import { useHelpTexts } from "app/hooks/useSession";

export const PacksInvoices = (props) => {
  const componentProps = {
    component: "components/packs/packs-invoices",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const invoices = useQuery(["packs_invoices"], async () => {
    const response = await ApiService.get("packs/invoices");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!invoices.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Packs invoices` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("Account")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [invoices.data]);

  const copyWalletsToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#invoices_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("invoices", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Packs invoices`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the list of packs invoices."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="invoices"
        >
          <thead>
            <tr>
              <th>Pack name</th>
              <th>Date</th>
              <th className="no-sort">
                Account &nbsp;
                <Link to="" onClick={copyWalletsToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy wallets to clipboard"
                  ></i>
                </Link>
              </th>
              <th>Chain</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {invoices.data &&
              invoices.data.map((invoice, index) => {
                return (
                  <tr key={index}>
                    <td>{invoice.pack_name}</td>
                    <td title={moment(invoice.invoice_time).fromNow()}>
                      {moment
                        .tz(invoice.invoice_time, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <Link
                        to={`/admin/account-scanner/${invoice.native_address}`}
                      >
                        {invoice.native_address}
                      </Link>
                    </td>
                    <td>{invoice.chain}</td>
                    <td>{invoice.qty}</td>
                    <td className="text-end">{`${(
                      invoice.price /
                      Math.pow(
                        10,
                        JSON.parse(invoice.price_symbol).symbol.split(",")[0]
                      )
                    ).toLocaleString()} ${
                      JSON.parse(invoice.price_symbol).symbol.split(",")[1]
                    }`}</td>
                  </tr>
                );
              })}

            {invoices.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {invoices.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default PacksInvoices;
