import { useEffect } from "react";

export const AdminNftsKnobChart = (props) => {
    let textInput = null;
    const data = props.data;
    const title = props.title;
    const options = props.options;


    const percentage = parseInt((data.filtered_total/data.overall_total)*100) | 0;

    useEffect(()=>{
        window.$(textInput).knob();       
    },[textInput])
    
    return (
      <>
        <div className="knob-chart" dir="ltr">
          <input
            data-plugin="knob"
            data-width={70}
            data-height={70}
            data-fgcolor={options.color}
            data-bgcolor={options.background}
            defaultValue={percentage}
            data-skin="tron"
            data-angleoffset={0}
            data-readonly="true"
            data-thickness=".15"
            ref={(input) => { textInput = input; }}
          />
        </div>
        <div className="text-end">
          <h3 className="mb-1 mt-0">
            <span data-plugin="counterup">
              {parseInt(data.filtered_total).toLocaleString()}
            </span>
          </h3>
          <p className="text-muted mb-0">{title}</p>          
          <p className="text-muted mb-0">{parseInt(data.overall_total).toLocaleString()} <br/> Overall</p>
        </div>
      </>
    );
}

export default AdminNftsKnobChart;