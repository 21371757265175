import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";

export const CompletedUnclaimedMissions = () => {
  const filters = useFilters();

  const stats = useQuery(['mission_stats', filters], async () => {
    const response = await ApiService.get("missions/stats", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
    });
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
  });

  return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{(stats.data && stats.data.completed_unclaimed_missions)? stats.data.completed_unclaimed_missions : 0}</span>
                )}
                </h3>
                <p className="text-muted mb-0">Unclaimed Missions</p>
            </div>
        </div>
  );
};

export default CompletedUnclaimedMissions;
