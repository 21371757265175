import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import moment from "moment";

HighchartsSankey(Highcharts);
DarkUnica(Highcharts);
HighchartsBoost(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const AdminSelfTestTowerFlow = (props) => {
  const { filters } = props;
  const componentProps = {
    component: "components/self-test/tower-flow",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };

  //const filters = useFilters();
  const helpTexts = useHelpTexts();

  const dummy = Array.from(
    { length: 10 },
    () => Math.floor(Math.random() * 100) + 1
  );

  const [data, setData] = useState([]);

  const sankey = useQuery(["tower_flow", filters], async () => {
    const response = await ApiService.get("self-test/tower-flow", {
      date: filters
        ? filters.date
        : moment.utc().subtract(1, "days").format("YYYY-MM-DD"),
      timestamp: moment.utc().valueOf(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    let data = await response.json();
    setData(data);
  });

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Tower Flow`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This chart shows the flow of Tower"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {sankey.status === "loading" ? (
          <div style={{ height: "400px" }}>
            {dummy.map((value) => {
              return (
                <div className="d-flex flex-column">
                  <Skeleton width={`${value}%`} />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {sankey.isError ? (
              <Error500 />
            ) : (
              <div className="mt-3 text-center">
                <div dir="ltr">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        backgroundColor: "transparent",
                        style: {
                          fontFamily: "Roboto,sans-serif",
                        },
                      },
                      title: {
                        text: "",
                      },
                      credits: {
                        enabled: false,
                      },
                      tooltip: {
                        formatter: function () {
                          if (this.point.isNode)
                            return (
                              this.point.name +
                              ": <b>" +
                              new Intl.NumberFormat().format(this.point.sum) +
                              "</b>"
                            );
                          else
                            return (
                              this.point.from +
                              " -> " +
                              this.point.to +
                              "<br><b>" +
                              new Intl.NumberFormat().format(
                                this.point.weight
                              ) +
                              "</b>"
                            );
                        },
                      },
                      accessibility: {
                        point: {
                          valueDescriptionFormat:
                            "{index}. {point.from} to {point.to}, {point.weight}.",
                        },
                      },
                      series: [
                        {
                          keys: ["from", "to", "weight"],
                          data: data,
                          type: "sankey",
                          name: "Tower flow",
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="card-body">
        <p class="card-text">
          Ignored Applying Threshold: TOWER_ACTIVE_MINERS_TLM_THRESHOLD,
          TOWER_ACTIVE_MINERS_MINES_THRESHOLD
        </p>
        <p class="card-text">Ignored Low Score: TOWER_BOTS_SCORE</p>
        <p class="card-text">Ignored Applying StdDev: TOWER_STDEV_MULTIPLIER</p>
        <p class="card-text">
          Ignored Daily Limit: TOWER_BOTS_PER_DAY_PERCENTAGE
        </p>
      </div>
    </div>
  );
};

export default AdminSelfTestTowerFlow;
