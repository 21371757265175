import { useRef, useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ReportsService from "../../../services/reports";
import Emitter from "app/services/emitter";
import { Link } from "react-router-dom";
import { useHelpTexts } from "app/hooks/useSession";
import { useQueryParams } from "app/hooks/useQueryParams";
import config from "app/config";

const types = [
  // {
  //   type: "reportSample1",
  //   text: "Report sample1",
  //   fields: [{ field: "text", name: "account", text: "CAccount" }],
  // },
  // { type: "reportSample2", text: "Report sample2", fields: [] },
  // { type: "reportSample3", text: "Report sample3", fields: [] },
  {
    type: "reportMiningActions",
    text: "Report Mining Actions",
    fields: [
      { field: "account", name: "account", text: "Account", required: true },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: false },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTlmTransfers",
    text: "Report TLM Transfers",
    fields: [
      { field: "account", name: "account", text: "Account", required: true },
      {
        field: "datetime",
        name: "start_datetime",
        text: "Start Datetime",
        required: true,
      },
      {
        field: "datetime",
        name: "end_datetime",
        text: "End Datetime",
        required: false,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTlmMiners",
    text: "Top 100 TLM miners (non-bot)",
    fields: [
      {
        field: "datetime",
        name: "start_datetime",
        text: "Start Datetime",
        required: true,
      },
      {
        field: "datetime",
        name: "end_datetime",
        text: "End Datetime",
        required: true,
      },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportBotScoreUsers",
    text: "Report bot score users",
    fields: [
      { field: "account", name: "account", text: "Account", required: false },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportNfts",
    text: "Report NFTs with Template ID",
    fields: [
      {
        field: "text",
        name: "offset_assetid",
        text: "Offset from assetid",
        required: false,
      },
      {
        field: "date",
        name: "start_date",
        text: "Start Date",
        required: false,
      },
      { field: "date", name: "end_date", text: "End Date", required: false },
    ],
    rules: {
      range_days: 45,
    },
  },
  {
    type: "reportAccountsMiningFrequency",
    text: "Accounts mining frequency",
    fields: [{ field: "date", name: "date", text: "Date", required: true }],
  },
  {
    type: "reportMostExchangeMemos",
    text: "Most used exchange memos",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportExportNftMovementsSummary",
    text: "Export NFT movements (Summary)",
    fields: [
      {
        field: "text",
        name: "template_id",
        text: "Template Id",
        required: true,
      },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportExportNftMovementsInterval",
    text: "Export NFT movements (Interval)",
    fields: [
      {
        field: "text",
        name: "template_id",
        text: "Template Id",
        required: true,
      },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportMinesWithTemplate",
    text: "Peoples mine with tool (Template ID)",
    fields: [
      {
        field: "text",
        name: "template_id",
        text: "Template Id",
        required: true,
      },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportMultipleAuthorizationsMine",
    text: "Multiple Authorizations Mine",
    fields: [
      {
        field: "datetime",
        name: "start_datetime",
        text: "Start Datetime",
        required: true,
      },
      {
        field: "datetime",
        name: "end_datetime",
        text: "End Datetime",
        required: true,
      },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportContractLandowners",
    text: "Landowners with Contract",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportCreatedAccountsUsingNewuser",
    text: "Accounts created using newuser.wax",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "text",
        name: "min_created_accounts",
        text: "Min created accounts",
        required: false,
      },
      {
        field: "text",
        name: "max_created_accounts",
        text: "Max created accounts",
        required: false,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportNftsForensics",
    text: "NFTs Forensics",
    fields: [{ field: "date", name: "date", text: "Date", required: true }],
  },
  {
    type: "reportMiningActiveUsersMonthly",
    text: "Mining Active Users Monthly",
    fields: [
      {
        field: "datem",
        name: "start_date",
        text: "Start Date",
        required: true,
      },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTlmEarnedPerMiner",
    text: "Monthly Avg TLM amount for a user by month",
    fields: [{ field: "text", name: "miner", text: "Miner", required: true }],
  },
  {
    type: "reportTlmEarnedPerMiners",
    text: "Monthly Avg. TLM amount earned per user",
    fields: [
      {
        field: "datem",
        name: "start_date",
        text: "Start Date",
        required: true,
      },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportActiveMinersUniqueAnalysis",
    text: "Active miners unique analysis",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 10,
    },
  },
  {
    type: "reportActiveMinersCohortAnalysis",
    text: "Active miners cohort analysis",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 30,
    },
  },
  {
    type: "reportEarnSpentRatio",
    text: "Earn Vs Spent, per user, monthly",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportEarnOverThreshold",
    text: "% of active users earning more than X TLM per month",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      { field: "number", name: "threshold", text: "Threshold", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportSecondaryMarketPurchase",
    text: "*Secondary Market* Average spending in WAX per user per month",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportSecondaryMarketSale",
    text: "*Secondary Market* Average earning in WAX per user per month",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTopTlmMinersPerPlanet",
    text: "Top 100 TLM miners per planet",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "planet_name",
        name: "planet_name",
        text: "Planet",
        required: true,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTopNftPointsMinersPerPlanet",
    text: "Top 100 NFT Points miners per planet",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "planet_name",
        name: "planet_name",
        text: "Planet",
        required: true,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTopTlmMinersPerLand",
    text: "Top 100 TLM miners per land",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "text",
        name: "land_id",
        text: "Land ID",
        required: true,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportTopNftPointsMinersPerLand",
    text: "Top 100 NFT Points miners per land",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "text",
        name: "land_id",
        text: "Land ID",
        required: true,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportMiningActiveUsersPerInterval",
    text: "New miners based on first mine",
    fields: [
      {
        field: "select",
        name: "interval",
        text: "Interval",
        required: true,
        options: [
          { value: "month", label: "Monthly" },
          { value: "day", label: "Daily" },
        ],
      },
      {
        field: "date",
        name: "start_date",
        text: "Start Date",
        required: true,
      },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportActiveMinersAverageAnalysis",
    text: "Active Users Retention",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 10,
    },
  },
  {
    type: "reportNftsHeldByActiveMiners",
    text: "% NFTs held by active miners",
    fields: [     
      { field: "datem", name: "start_date", text: "Start Date", required: true },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportActiveMinersSpendCluster",
    text: "Segment Active User in 4 Cluster for TLM Spending",
    fields: [     
      { field: "datem", name: "start_date", text: "Start Date", required: true },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 90,
    },
  },
  {
    type: "reportActiveMinersChurnAnalysis",
    text: "Churn Rate as %",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 30,
    },
  },
  {
    type: "reportMinecraftUsersPerQuest",
    text: "Minecraft User per Quest Monthly",
    fields: [
      { field: "datem", name: "start_date", text: "Start Date", required: true },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportMinecraftUserPoints",
    text: "Minecraft Userpoints Monthly",
    fields: [
      { field: "datem", name: "start_date", text: "Start Date", required: true },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportPlanetsPerPools",
    text: "Rarity Pools",
    fields: [
      { field: "datem", name: "start_date", text: "Start Date", required: true },
      { field: "datem", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportDaoTxPerPlanet",
    text: "DAO All TX",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
      {
        field: "planet_name",
        name: "planet_name",
        text: "Planet",
        required: true,
      },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportDDDQ2BaselineReportMoM",
    text: "DDD Q2 Baseline Report MoM",
    fields: [
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
  {
    type: "reportBotActionProcessing",
    text: "Yeomen Flag/Unflag actions",
    fields: [
      {
        field: "select",
        name: "action",
        text: "Action",
        required: true,
        options: [
          { value: "all", label: "All" },
          { value: "block", label: "Block" },
          { value: "unblock", label: "Unblock" },
        ],
      },
      { field: "date", name: "start_date", text: "Start Date", required: true },
      { field: "date", name: "end_date", text: "End Date", required: true },
    ],
    rules: {
      range_days: 180,
    },
  },
];

export const AdminRequestReport = () => {
  const helpTexts = useHelpTexts();
  const queryParams = useQueryParams();

  const [type, setType] = useState(types[0]);
  // const [dateRange, setDateRange] = useState({
  //   start: moment().subtract(1, "month"),
  //   end: moment(),
  // });
  const [format, setFormat] = useState("csv");
  const [filters, setFilters] = useState({});
  const [autocompleteItems, setAutocompleteItems] = useState({});
  const [showAutocompleteItems, setShowAutocompleteItems] = useState({});
  const [alert, setAlert] = useState(null);

  // const handleDateRangeCallback = (start, end, label) => {
  //   setDateRange({
  //     start: start,
  //     end: end,
  //   });
  // };

  const requestReport = async (e) => {
    e.preventDefault();
    try {
      for (const field of type.fields) {
        if (field.required && !filters[field.name]) {
          window.$.toast({
            heading: "Error",
            text: "Required field: " + field.name,
            position: "top-right",
            icon: "error",
          });
          return;
        }
      }

      const response = await ReportsService.requestReport({
        type: type.type,
        //dateRange: dateRange,
        format: format,
        filters: filters,
      });

      //const data = await response.json();
      if (!response.ok) {
        const data = await response.json();
        throw Error(data.message || "Unable to process");
      }

      Emitter.emit("REQUEST_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Your report is being generated. The time taken is usually proportional to the date range selected.",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const handleOnChangeAccount = async (type, field, value) => {
    const lower_bound = value;
    const upper_bound = lower_bound.padEnd(12, "z");
    const data = await fetch(
      "https://chain.wax.io/v1/chain/get_table_by_scope",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: "eosio",
          limit: 5,
          lower_bound: lower_bound,
          table: "userres",
          upper_bound: upper_bound,
        }),
      }
    ).then((response) => response.json());

    if (data && data.rows) {
      setAutocompleteItems((prevState) => ({
        ...prevState,
        [field.name]: data.rows,
      }));
    }
  };

  const handleOnSelectAccount = (type, field, item) => {
    setShowAutocompleteItems((prevState) => ({
      ...prevState,
      [field.name]: false,
    }));
    setFilters((prevState) => ({
      ...prevState,
      [field.name]: item.scope,
    }));
    window.$(`#input_${type.type}_${field.name}`).val(item.scope);
  };

  const handleOnFocusAccount = (type, field) => {
    setShowAutocompleteItems((prevState) => ({
      ...prevState,
      [field.name]: true,
    }));
  };

  const handleOnBlurAccount = (type, field) => {
    setTimeout(function () {
      setShowAutocompleteItems((prevState) => ({
        ...prevState,
        [field.name]: false,
      }));
    }, 500);
  };

  useEffect(() => {
    window.$(".select2").select2();
    window.$(".select2").on("change", function (e) {
      e.preventDefault();
      const type = types.find((type) => type.type === e.target.value);
      setType(type);
      setFilters({});
      setAutocompleteItems([]);
      setShowAutocompleteItems([]);
      window.$.fn.tooltip &&
        window.$('[data-bs-toggle="tooltip"]').tooltip("dispose").tooltip();
    });

    if (queryParams.get("type")) {
      const type = types.find((type) => type.type === queryParams.get("type"));
      if (type)
        window.$(".select2").val(queryParams.get("type")).trigger("change");
    }
  }, []);

  useEffect(() => {
    console.log(type, filters);
    var rules = type.rules || {};
    if (rules.range_days) {
      var momentStart, momentEnd;
      var days = 0;
      if (filters.start_date) {
        momentStart = moment(filters.start_date);
        momentEnd = moment(filters.end_date || new Date());
        days = momentEnd.diff(momentStart, "days");
      } else if (filters.start_datetime) {
        momentStart = moment(filters.start_datetime);
        momentEnd = moment(filters.end_datetime || new Date());
        days = momentEnd.diff(momentStart, "days");
      }

      if (days > rules.range_days) {
        setAlert({
          type: "warning",
          message:
            "This report is likely to take longer than expected because of the date ranges selected",
        });
      } else {
        setAlert(null);
      }
    }
  }, [type, filters]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select select2"
                      id="floatingReportType"
                      // onChange={(e) => {
                      //   e.preventDefault();
                      //   const type = types.find(
                      //     (type) => type.type === e.target.value
                      //   );
                      //   setType(type);
                      //   setFilters({});
                      //   setAutocompleteItems([]);
                      //   setShowAutocompleteItems([]);
                      // }}
                    >
                      {types.map((type) => (
                        <option value={type.type}>{type.text}</option>
                      ))}
                      {/* <option value="reportSample1">Type 1</option>
              <option value="reportSample2">Type 2</option>
              <option value="reportSample3">Type 3</option> */}
                    </select>
                    {/* <label for="floatingReportType">Type</label> */}
                    {/* <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#customizeReportFields"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Customize report fields
                    </button> */}
                  </div>
                </div>
                {/* <div className="col-3">
          <div class="form-floating mb-3">
            <DateRangePicker
              id="floatingReportRange"
              initialSettings={{
                format: "DD/MM/YYYY",
                startDate: dateRange.start,
                endDate: dateRange.end,
                showDropdowns: true,
                showWeekNumbers: true,
                timePicker: false,
                timePickerIncrement: 1,
                timePicker12Hour: true,
                ranges: {
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  "Last 6 Moths": [moment().subtract(6, "month"), moment()],
                  "All time": [moment("2020-07-01"), moment()],
                },
                opens: "left",
                drops: "down",
                buttonClasses: ["btn", "btn-sm"],
                applyClass: "btn-success",
                cancelClass: "btn-secondary",
                separator: " to ",
                locale: {
                  format: "DD/MM/YYYY",
                  applyLabel: "Submit",
                  cancelLabel: "Cancel",
                  fromLabel: "From",
                  toLabel: "To",
                  customRangeLabel: "Custom",
                  daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                  monthNames: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ],
                  firstDay: 1,
                },
              }}
              onCallback={handleDateRangeCallback}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
            <label for="floatingReportType">Date Range</label>
          </div>
        </div> */}
                <div className="col-lg-3 col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingFormat"
                      onChange={(e) => {
                        e.preventDefault();
                        setFormat(e.target.value);
                      }}
                    >
                      <option value="CSV">CSV</option>
                    </select>
                    <label for="floatingFormat">Format</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>Extra filters based on report type</p>
                  <div className="row ">
                    {type &&
                      type.fields.map((field, index) => (
                        <>
                          {(() => {
                            switch (field.field) {
                              case "number":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id={`input_${type.type}_${field.name}`}
                                        placeholder={`Enter ${field.text}`}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: e.target.value,
                                          }));
                                        }}
                                      />
                                      <label
                                        for={`input_${type.type}_${field.name}`}
                                      >
                                        {field.text +
                                          (field.required
                                            ? " *"
                                            : " (optional)")}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              case "text":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id={`input_${type.type}_${field.name}`}
                                        placeholder={`Enter ${field.text}`}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: e.target.value,
                                          }));
                                        }}
                                      />
                                      <label
                                        for={`input_${type.type}_${field.name}`}
                                      >
                                        {field.text +
                                          (field.required
                                            ? " *"
                                            : " (optional)")}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              case "select":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <select
                                        class="form-select"
                                        id={`select_${type.type}_${field.name}`}
                                        placeholder={`Enter ${field.text}`}
                                        onChange={(e) => {
                                          e.preventDefault(); 
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: e.target.value,
                                          }));
                                        }}
                                      >
                                        <option value="">--Select--</option>
                                        {field.options.map((option) => (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        for={`select_${type.type}_${field.name}`}
                                      >
                                        {field.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              case "account":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3 dropdown">
                                      <input
                                        type="text"
                                        class="form-control dropdown-toggle"
                                        id={`input_${type.type}_${field.name}`}
                                        placeholder={`Enter ${field.text}`}
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        placeholder="Enter account name"
                                        aria-label="Search"
                                        autoComplete={"off"}
                                        onChange={(el) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: el.target.value,
                                          }));
                                          handleOnChangeAccount(
                                            type,
                                            field,
                                            el.target.value
                                          );
                                        }}
                                        onFocus={(el) => {
                                          handleOnFocusAccount(type, field);
                                        }}
                                        onBlur={(el) => {
                                          handleOnBlurAccount(type, field);
                                        }}
                                      />
                                      <label
                                        for={`input_${type.type}_${field.name}`}
                                      >
                                        {field.text +
                                          (field.required
                                            ? " *"
                                            : " (optional)")}
                                      </label>
                                      <div
                                        className={`dropdown-menu  ${
                                          autocompleteItems[field.name] &&
                                          autocompleteItems[field.name].length >
                                            0 &&
                                          showAutocompleteItems[field.name]
                                            ? "show"
                                            : "d-none"
                                        }`}
                                        aria-labelledby={`input_${type.type}_${field.name}`}
                                      >
                                        {autocompleteItems[field.name] &&
                                          autocompleteItems[field.name].map(
                                            (autocompleteItem, index) => (
                                              <Link
                                                className="dropdown-item"
                                                key={index}
                                                onClick={() =>
                                                  handleOnSelectAccount(
                                                    type,
                                                    field,
                                                    autocompleteItem
                                                  )
                                                }
                                              >
                                                {autocompleteItem.scope}
                                              </Link>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                                break;
                              case "datetime":
                              case "date":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <DateRangePicker
                                        id={`daterangepicker_${type.type}_${field.name}`}
                                        initialSettings={{
                                          autoUpdateInput: false,
                                          singleDatePicker: true,
                                          timePicker:
                                            field.field === "datetime"
                                              ? true
                                              : false,
                                          locale: {
                                            format:
                                              field.field === "datetime"
                                                ? "YYYY-MM-DD HH:mm:ss"
                                                : "YYYY-MM-DD",
                                          },
                                        }}
                                        onCallback={(start, end, label) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: start.format(
                                              field.field === "datetime"
                                                ? "YYYY-MM-DD HH:mm:ss"
                                                : "YYYY-MM-DD"
                                            ),
                                          }));
                                        }}
                                        onApply={(event, picker) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]:
                                              picker.startDate.format(
                                                field.field === "datetime"
                                                  ? "YYYY-MM-DD HH:mm:ss"
                                                  : "YYYY-MM-DD"
                                              ),
                                          }));
                                          window
                                            .$(event.currentTarget)
                                            .val(
                                              picker.startDate.format(
                                                field.field === "datetime"
                                                  ? "YYYY-MM-DD HH:mm:ss"
                                                  : "YYYY-MM-DD"
                                              )
                                            );
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                        />
                                      </DateRangePicker>
                                      <label
                                        for={`daterangepicker_${type.type}_${field.name}`}
                                      >
                                        {field.text +
                                          (field.required
                                            ? " *"
                                            : " (optional)")}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              case "datem":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <DateRangePicker
                                        id={`daterangepicker_${type.type}_${field.name}`}
                                        initialSettings={{
                                          autoUpdateInput: false,
                                          singleDatePicker: true,
                                          timePicker: false,
                                          locale: {
                                            format: "YYYY-MM-DD",
                                          },
                                        }}
                                        onCallback={(start, end, label) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]:
                                              field.name === "start_date"
                                                ? start
                                                    .startOf("month")
                                                    .format("YYYY-MM-DD")
                                                : start
                                                    .endOf("month")
                                                    .format("YYYY-MM-DD"),
                                          }));
                                        }}
                                        onApply={(event, picker) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]:
                                              field.name === "start_date"
                                                ? picker.startDate
                                                    .startOf("month")
                                                    .format("YYYY-MM-DD")
                                                : picker.startDate
                                                    .endOf("month")
                                                    .format("YYYY-MM-DD"),
                                          }));
                                          window
                                            .$(event.currentTarget)
                                            .val(
                                              field.name === "start_date"
                                                ? picker.startDate
                                                    .startOf("month")
                                                    .format("YYYY-MM-DD")
                                                : picker.startDate
                                                    .endOf("month")
                                                    .format("YYYY-MM-DD")
                                            );
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                        />
                                      </DateRangePicker>
                                      <label
                                        for={`daterangepicker_${type.type}_${field.name}`}
                                      >
                                        {field.text +
                                          (field.required
                                            ? " *"
                                            : " (optional)")}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              case "planet_name":
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`${type.type}_${field.name}_${index}`}
                                  >
                                    <div class="form-floating mb-3">
                                      <select
                                        class="form-select"
                                        id={`select_${type.type}_${field.name}`}
                                        placeholder={`Enter ${field.text}`}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            [field.name]: e.target.value,
                                          }));
                                        }}
                                      >
                                        <option value={''}>
                                            --Select--
                                          </option>
                                        <option value={'all'}>
                                            All Planets
                                          </option>
                                        {config.planets.map((planet) => (
                                          <option value={planet.name}>
                                            {planet.title}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        for={`select_${type.type}_${field.name}`}
                                      >
                                        {field.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                                break;
                              default:
                                break;
                            }
                          })()}
                        </>
                      ))}
                    {/* {["reportSample2"].includes(type) ? (
                  <div className="col-3">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingAccount"
                        placeholder="Enter Account"
                        onChange={(e) => {
                          e.preventDefault();
                          setFilters((prevState) => ({
                            ...prevState,
                            account: e.target.value,
                          }));
                        }}
                      />
                      <label for="floatingAccount">Account</label>
                    </div>
                  </div>
                ) : null} */}
                  </div>
                </div>
              </div>
              {alert ? (
                <div className="row">
                  <div className="col-12">
                    <div className={`alert alert-${alert.type}`}>
                      {alert.message}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-12">
                  <h4
                    class="header-title"
                    data-ht-title={
                      helpTexts.find((helpText) => helpText.key === type.type)
                        ?.title || type.text
                    }
                  >
                    {helpTexts.find((helpText) => helpText.key === type.type)
                      ?.title || type.text}
                    {/* <p
                      classname="helpText"
                      style={{
                        fontSize: ".9rem",
                        fontWeight: 400,
                        lineHeight: "1.5",
                        whiteSpace: "break-spaces",
                      }}
                    > */}
                    {/* {helpTexts.find((helpText) => helpText.key === type.type)
                      ?.value || type.type} */}
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-outline-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-ht-key={type.type}
                      title={
                        helpTexts.find((helpText) => helpText.key === type.type)
                          ?.value || type.type
                      }
                      style={{ boxShadow: "none" }}
                    >
                      <i class="fas fa-edit"></i>
                    </button>{" "}
                    {/* </p> */}
                  </h4>
                  <p className="helpText">
                    {helpTexts.find((helpText) => helpText.key === type.type)
                      ?.value || type.type}{" "}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-light"
                    onClick={requestReport}
                  >
                    Create Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRequestReport;
