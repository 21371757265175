import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams } from "react-router";
import AdminTokensTopHolders from "../../components/tokens/token-top-holders";

export const AdminTokenHolders = (props) => {
  let { code, name } = useParams();
  
 
  return (
    <>
      <BreadcrumbsItem to="/admin/token-holders">Token Holders</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <AdminTokensTopHolders code={code} name={name}/>
        </div>
      </div>      
    </>
  );
};

export default AdminTokenHolders;
