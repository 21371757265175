import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CardMenu from "../card-menu";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import { copyToClipboard } from "app/utils/datatables";

export const BscToWaxTransfers = (props) => {
  const componentProps = {
    component: "components/teleport/bsc-to-wax-transfers",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const transfers = useQuery(["bsc_to_wax_transfers2", filters], async () => {
    const response = await ApiService.get("teleport/bsc-to-wax-transfers2", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();

    return result;
  });

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!transfers.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Latest 200 BSC to WAX transfers` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [transfers.data]);

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#bsc_to_wax_transfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("bsc_to_wax_transfers", 1);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Latest 200 BSC to WAX transfers`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the latest 200 BSC to WAX transfers during the period selected."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="bsc_to_wax_transfers"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Age</th>
              <th>From</th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>TX Sent</th>
              <th>TX Received</th>
              <th>Teleport ID</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {/* {transfers.data && Array.isArray(transfers.data) && transfers.data.map((transfer, index) => {
                        transfer = transfer._source;
                        return (
                            <tr key={index}>
                            <td title={moment(transfer['@timestamp']).fromNow()}>{moment.tz(transfer['@timestamp'],"UTC").format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>{moment(transfer['@timestamp']).fromNow()}</td>
                            <td><Link to={`/admin/account-scanner/${transfer.act.data.to}`}>{transfer.act.data.to}</Link></td>
                            <td><a href={`https://bscscan.com/tx/0x${transfer.act.data.ref}`}  target="_blank">0x{transfer.act.data.ref.substring(0, 5)}..</a></td>
                            <td><a href={`${config.explorer.url}/transaction/${transfer.trx_id}`} target="_blank">{transfer.trx_id.substring(0, 5)}..</a></td>
                            <td className="text-end">{transfer.act.data.quantity.substring(0, transfer.act.data.quantity.length - 4)}</td>
                            </tr>
                        );
                        })} */}
            {transfers.data &&
              Array.isArray(transfers.data) &&
              transfers.data.map((transfer, index) => {
                return (
                  <tr key={index}>
                    <td title={moment(transfer.date).fromNow()}>
                      {moment
                        .tz(transfer.date, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>{moment(transfer.date).fromNow()}</td>
                    <td>
                      {transfer.from ? (
                        <a
                          href={`https://bscscan.com/address/${transfer.from}`}
                          target="_blank"
                        >
                          {transfer.from.substring(0, 5)}...
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <Link to={`/admin/account-scanner/${transfer.to}`}>
                        {transfer.to}
                      </Link>
                    </td>
                    <td>
                      <a
                        href={`https://bscscan.com/tx/0x${transfer.ref}`}
                        target="_blank"
                      >
                        0x{transfer.ref.substring(0, 5)}..
                      </a>
                    </td>
                    <td>
                      {transfer.trx_id ? (
                        <a
                          href={`${config.explorer.url}/transaction/${transfer.trx_id}`}
                          target="_blank"
                        >
                          {transfer.trx_id.substring(0, 5)}..
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {transfer.ref ? (
                        <a
                          href={`https://bscscan.com/tx/0x${transfer.ref}`}
                          target="_blank"
                        >
                          {transfer.id}
                        </a>
                      ) : (
                        transfer.id
                      )}
                    </td>
                    <td className="text-end">{transfer.quantity}</td>
                  </tr>
                );
              })}

            {transfers.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {transfers.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default BscToWaxTransfers;
