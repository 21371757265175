import TotalMine from "../components/mining/mine-actions";
import TotalBounty from "../components/mining/total-bounty";
import TotalMiners from "../components/mining/total-miners";
import TotalLanOwners from "../components/mining/total-landowners";
import TotalLands from "../components/mining/total-lands";
import TotalLandCommission from "../components/mining/total-land-commission";
import AvgCommission from "../components/mining/avg-commission";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AvgDelay from "../components/mining/avg-delay";
import AvgDifficulty from "../components/mining/avg-difficulty";
import AvgEase from "../components/mining/avg-ease";
import AvgLuck from "../components/mining/avg-luck";
import MiningPlanetsNumMines from "admin/components/mining/planets_num_mines";
import MiningPlanetsAvgMine from "admin/components/mining/planets_avg_mine";
import MiningPlanetsMinMine from "admin/components/mining/planets_min_mine";
import MiningPlanetsMaxMine from "admin/components/mining/planets_max_mine";
import AvgMinePerPlanet from "admin/components/mining/avg-mine-per-planet";
import MaxMinePerPlanet from "admin/components/mining/max-mine-per-planet";
import MaxMineableMinePerPlanet from "admin/components/mining/max-mineable-mine-per-planet";
import AvgMinePerDayPerPlanet from "admin/components/mining/avg-mine-per-day-per-planet";
import TopMiners from "admin/components/mining/top_miners";
import TopMinedLands from "admin/components/mining/top_mined_lands";
import ActiveStats from "../components/mining/active-stats";
import MineChart from "../components/mining/mine-chart";
import MineChart2 from "../components/mining/mine-chart2";
import TopMines from "admin/components/mining/top_mines";
import UniqueMinersPerTools from "admin/components/mining/unique_miners_per_tools";
import UniqueMinersPerToolsHeatmap from "admin/components/mining/unique_miners_per_tools_heatmap";
import UniqueMinersPerToolsChart from "admin/components/mining/unique_miners_per_tools_chart";
import Highcharts from "highcharts";
import { useEffect } from "react";
import TotalMinePerPlanet from "admin/components/mining/total-mine-per-planet";
import ToolsUtilizationChart from "admin/components/mining/tools_utilization_chart";

function AdminDashboardMining() {
  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$(".button-menu-mobile").click(function () {
      setTimeout(() => {
        window.$(Highcharts.charts).each(function (i, chart) {
          chart.reflow();
        });
      }, 1000);
    });
  });
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-tlm">Mining</BreadcrumbsItem>
      <div className="row">
        <div className="col-lg-12">
          <ActiveStats />
        </div>
      </div>
      <div className="row">
        <div class="col-xl-3 col-md-6">
          <TotalMine />
        </div>
        {/*<div class="col-xl-3 col-md-6">
          <TotalBounty />
        </div> */}
        {/*<div class="col-xl-3 col-md-6">
          <TotalMiners />
        </div>
        <div class="col-xl-3 col-md-6">
          <TotalLanOwners />
        </div>
        <div class="col-xl-3 col-md-6">
          <TotalLands />
        </div>
        <div class="col-xl-3 col-md-6">
          <TotalLandCommission />
        </div>
        <div class="col-xl-3 col-md-6">
          <AvgCommission />
        </div>
        <div class="col-xl-3 col-md-6">
          <AvgDelay />
        </div>
        <div class="col-xl-3 col-md-6">
          <AvgDifficulty />
        </div>
        <div class="col-xl-3 col-md-6">
          <AvgEase />
        </div>
        <div class="col-xl-3 col-md-6">
          <AvgLuck />
        </div> */}
      </div>
      <div className="row">
        <div class="col-12">
          <MiningPlanetsNumMines />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <MiningPlanetsAvgMine />
        </div>
      </div>
      {/* <div className="row">
        <div class="col-12">
          <MiningPlanetsMinMine />
        </div>
      </div> */}
      <div className="row">
        <div class="col-12">
          <MiningPlanetsMaxMine />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <TotalMinePerPlanet />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <AvgMinePerPlanet />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <MaxMinePerPlanet />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <MaxMineableMinePerPlanet />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <AvgMinePerDayPerPlanet />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <UniqueMinersPerTools />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <UniqueMinersPerToolsHeatmap />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <UniqueMinersPerToolsChart />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <TopMines />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <TopMiners />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <TopMinedLands />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <MineChart />
        </div>
      </div>
      <div className="row">
        <div class="col-12">
          <MineChart2 />
        </div>
      </div>

      <div className="row">
        <div class="col-12">
          <ToolsUtilizationChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardMining;
