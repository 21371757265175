import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import { useParams, useHistory } from "react-router-dom";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import IpfsImage from "admin/components/ipfs-image";
import moment from "moment-timezone";
import { copyToClipboard } from "app/utils/datatables";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQueryParams } from "app/hooks/useQueryParams";
import NftTransfers from "../forensics/nft-transfers";
import NodeEdges from "../forensics/node-edges";

export const NftsTransferFlow = (match) => {
  const componentProps = {
    component: "components/graph/NftsTransferFlow",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const queryParams = useQueryParams();
  const [account, setAccount] = useState(null);
  const [nodeEdges, setNodeEdges] = useState([]);
  //const filters = useFilters();
  //const history = useHistory();
  //const tableRef = useRef();
  //const dataTableRef = useRef();
  const history = useHistory();

  const [formFilters, setFormFilters] = useState({
    date:
      queryParams.get("date") ||
      moment().utc().subtract(1, "day").format("YYYY-MM-DD"),
    mode: queryParams.get("mode") || "direct",
    //account: "",
  });

  const [options, setOptions] = useState({
    nodes: {
      shape: "dot",
      shapeProperties: {
        interpolation: false, // 'true' for intensive zooming
      },
      font: {
        color: "#acbfd2",
        face: "Roboto,sans-serif",
      },
    },
    edges: {
      hidden: true,
      scaling: {},
    },
    interaction: {
      hover: true,
    },
    // manipulation: {
    //     enabled: true
    // },
    physics: {
      maxVelocity: 50,
      solver: "forceAtlas2Based",
      timestep: 0.8,
      stabilization: false,
    },
    // autoResize: true,
    //height: "100vh"
  });

  var nodes = new DataSet([]);
  var edges = new DataSet([]);
  const visJsRef = useRef();

  //const search = useRef(null);
  var network = useRef();
  const [isData, setIsData] = useState(true);
  //const [limit, setlimit] = useState(false);
  //const [viewAll, setViewAll] = useState(0);
  const params = useParams();
  // const account = params.accounts ? params.accounts : "";
  // const [accounts, setAccounts] = useState(account);
  const [selectedNodeData, setSelectedNodeData] = useState({
    node: "",
    data: [],
  });

  useEffect(() => {
    window.history.pushState(
      {},
      "",
      `/admin/forensics-chart/nfts?date=${formFilters.date}&mode=${formFilters.mode}`
    );
  }, []);

  const forensics = useQuery(
    [`forensicsChartNfts`, formFilters.date, formFilters.mode],
    async () => {
      const response = await ApiService.get(`forensics/chart/nfts`, {
        date: formFilters.date,
        mode: formFilters.mode,
        //mode: queryParams.get("mode") || "direct",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.edges.length === 0) setIsData(false);
      else {
        setIsData(true);
        //if (data.limit) setlimit(true);
        //else setlimit(false);
        //nodes.update(data.nodes);
        //edges.update(data.edges);
      }
      // network.current =
      //   visJsRef.current && new Network(visJsRef.current, data, options);

      if (network.current) {
        network.current.destroy();
      }
      //Destroy and appy edges hidden
      setOptions((prevOptions) => ({
        ...prevOptions,
        edges: { ...prevOptions.edges, hidden: true },
      }));
      options.edges.hidden = true;

      nodes.add(data.nodes);
      edges.add(data.edges);

      network.current =
        visJsRef.current &&
        new Network(visJsRef.current, { nodes: nodes, edges: edges }, options);

      // for (var node of data.nodes) {
      //await new Promise(resolve => setTimeout(resolve, 100));
      //  nodes.add(node);
      // }

      // for (var edge of data.edges) {
      //   edges.add(edge);
      // }

      if (network.current != null) {
        network.current.on("click", async function (event) {
          var nodeAccount = event.nodes[0];
          if (nodeAccount) {
            setAccount(nodeAccount);
            var nodeEdges = edges.get().filter(function (edge) {
              return edge.from === nodeAccount || edge.to === nodeAccount;
            });
            setNodeEdges(nodeEdges);
          }
        });
        network.current.on("afterDrawing", function (ctx) {
          var dataURL = ctx.canvas.toDataURL("image/jpeg", 1.0);
          window.$("#download").attr("href", dataURL);
          //window.$("#download").attr("download", accounts + ".jpg");
        });
      }

      setTimeout(() => {
        setOptions((prevOptions) => ({
          ...prevOptions,
          edges: { ...prevOptions.edges, hidden: false },
        }));
        options.edges.hidden = false;
        network.current.setOptions(options);
        network.current.redraw();
      }, 35000);

      return data;
    },
    {
      refetchOnWindowFocus: false,
      // notifyOnChangeProps: [isData]
    }
  );

  // useEffect(() => {
  //   if (formFilters.account === "") return;

  //   var node = network.current.getPositions([formFilters.account])[
  //     formFilters.account
  //   ];

  //   if (node) {
  //     network.current.moveTo({
  //       position: { x: node.x, y: node.y },
  //     });
  //   }
  // }, [formFilters.account]);

  const locateNode = (e) => {
    e.preventDefault();

    var searchAccount = e.target[0].value;
    if (searchAccount === "") return;

    var node = network.current.getPositions([searchAccount])[searchAccount];

    if (node) {
      network.current.moveTo({
        position: { x: node.x, y: node.y },
      });
      window.$(window.$("#locate-node-modal input")[0]).val("");
      window.$("#locate-node-modal").modal("hide");
    }
  };

  const cancelLocateNode = () => {
    window.$(window.$("#locate-node-modal input")[0]).val("");
    window.$("#locate-node-modal").modal("hide");
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
            <Link
              to=""
              className="dropdown-toggle arrow-none card-drop"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                to=""
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#locate-node-modal"
              >
                Locate Node
              </Link>
            </div>
          </div>
          {/* <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <label className="form-check-label">Locate node</label>
              &nbsp;&nbsp;
              <div className="me-2">
                <input
                  className="form-control"
                  type="text"
                  value={formFilters.account}
                  onChange={(e) => {
                    setFormFilters((formFilters) => ({
                      ...formFilters,
                      account: e.target.value,
                    }));
                  }}
                />
              </div>
            </form>
          </div> */}
          <h4 className="header-title">
            Forensics NFT
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This is a tool that helps identify patterns of how TLM moved through an account. It shows how TLM flowed into an account and out of it. The thickness of the lines are proportional to the amount of TLM flowing through it."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div class="form-floating mb-3">
                <DateRangePicker
                  id={`daterangepicker`}
                  initialSettings={{
                    autoUpdateInput: false,
                    singleDatePicker: true,
                    timePicker: false,
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                  }}
                  onCallback={(start, end, label) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      date: start.format("YYYY-MM-DD"),
                    }));
                    window.history.pushState(
                      {},
                      "",
                      `/admin/forensics-chart/nfts?date=${start.format(
                        "YYYY-MM-DD"
                      )}&mode=${formFilters.mode}`
                    );
                  }}
                  onApply={(event, picker) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      date: picker.startDate.format("YYYY-MM-DD"),
                    }));

                    window
                      .$(event.currentTarget)
                      .val(picker.startDate.format("YYYY-MM-DD"));
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={formFilters.date}
                  />
                </DateRangePicker>
                <label for={`daterangepicker`}>Date</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div class="form-floating mb-3">
                <select
                  className="form-select"
                  id="floatingMode"
                  defaultValue={formFilters.mode}
                  onChange={(e) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      mode: e.target.value,
                    }));
                    window.history.pushState(
                      {},
                      "",
                      `/admin/forensics-chart/nfts?date=${formFilters.date}&mode=${e.target.value}`
                    );
                  }}
                >
                  <option value="direct">Direct</option>
                  <option value="indirect">Indirect</option>
                  <option value="both">Both</option>
                </select>
                <label for="floatingMode">Type</label>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-4">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Account"
                  value={formFilters.account}
                  onChange={(e) => {
                    setFormFilters((formFilters) => ({
                      ...formFilters,
                      account: e.target.value,
                    }));
                  }}
                />
                <label>Search Account</label>
              </div>
            </div> */}
          </div>
          {/* <div className="row">
            <div className="col-12">
              <textarea
                cols="50"
                rows="10"
                className="form-control"
                onChange={(e) => {
                  var data;
                  try {
                    data = JSON.parse(e.target.value);
                  } catch (err) {}
                  setOptions(data);
                }}
                defaultValue={JSON.stringify(options, undefined, 4)}
              ></textarea>
              <br />
              <button
                class="btn btn-primary waves-effect waves-light me-1"
                type="submit"
                onClick={() => {
                  network.current.setOptions(options);
                  network.current.redraw();
                }}
              >
                Apply Redraw
              </button>
              <button
                class="btn btn-primary waves-effect waves-light me-1"
                type="submit"
                onClick={() => {
                  network.current.destroy();
                  network.current = new Network(
                    visJsRef.current,
                    forensics.data,
                    options
                  );
                }}
              >
                Apply Reload
              </button>
            </div>
          </div> */}

          {forensics.status === "loading" && isData == true ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : null}
          {forensics.isError ? <Error500 /> : null}

          <div className="mt-3 text-center">
            <div dir="ltr">
              {isData === true ? (
                <div ref={visJsRef} style={{ height: "100vh" }} />
              ) : (
                <p>
                  This account does not exist or does not have any transfers
                  within dates specified
                </p>
              )}
              {/* <a href="" style={{display: "none"}} >download</a> */}
            </div>
          </div>
        </div>
      </div>

      {account ? (
        <>
          <NodeEdges account={account} data={nodeEdges} />
          {/* <NftTransfers account={account} formFilters={formFilters} /> */}
        </>
      ) : null}

      <div
        id="locate-node-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="block-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="block-modalLabel">
                Locate node
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form className="px-3" action="" onSubmit={locateNode}>
              <div class="modal-body">
                <div className="mb-3">
                  <label className="form-label">Enter account name</label>
                  <input
                    className="form-control"
                    type="text"
                    //value={formFilters.account}
                    onChange={(e) => {
                      // setFormFilters((formFilters) => ({
                      //   ...formFilters,
                      //   account: e.target.value,
                      // }));
                    }}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  onClick={cancelLocateNode}
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">
                  Locate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftsTransferFlow;
