import { useEffect } from "react";
import PlanetMiningPot from "../components/planets/planet-mining-pot"
import PlanetFillRate from "../components/planets/planet-fillrate"
import PlanetDtap from "../components/planets/planet-dtap"
import Heatmap from "../components/charts/heatmap";
import BubbleChart from "../components/charts/bubble-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Highcharts from "highcharts";

function AdminDashboardPlanet() {

  const planets = ['eyeke.world', 'kavian.world', 'magor.world', 'naron.world', 'neri.world', 'veles.world']

  useEffect(() => {
    setTimeout(() => {
      window.$.Components.init();
    });

    window.$('.button-menu-mobile').click(function(){ 
      setTimeout(() => {
        window.$(Highcharts.charts).each(function(i,chart){
          chart.reflow();
        });
      }, 1000);
    });
  })

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-planet">Planet</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <PlanetMiningPot />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <PlanetFillRate />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <PlanetDtap />
        </div>
      </div>
      <div className="row">
      {planets.map((planet, index) => {
          return (
            <div className="col-lg-12">
              <Heatmap planet={planet} />
            </div>
          );
      })}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <BubbleChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardPlanet;
