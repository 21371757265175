import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useFilters } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";

export const TotalRedeemablePoints = () => {
    const filters = useFilters();

    const stats = useQuery(['user-points_stats2', filters], async () => {
      const response = await ApiService.get("user-points/stats2", {
          //startDate: filters.dates.startDate,
          //endDate: filters.dates.endDate,
      });
      if (!response.ok) {
          throw new Error("Network response was not ok");
      }
      return response.json();
    });
 
    return (
        <div className="widget-simple text-center card">
            <div className="card-body">
                <h3 className="text-success mt-0">
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{(stats.data && stats.data.total_redeemable_points)? stats.data.total_redeemable_points.toLocaleString() : 0}</span>
                )}
                </h3>
                <p className="text-muted mb-0">Total Available Redeemable Points</p>
            </div>
        </div>
  );
}

export default TotalRedeemablePoints;