import BoostedPerDayChart from "admin/components/landratings/boosted-per-day-chart";
import BoostedPerMinersChart from "admin/components/landratings/boosted-per-miners-chart";
import OpenedLandslotsChart from "admin/components/landratings/opened-landslots-chart";
import OpenslotsMinersChart from "admin/components/landratings/openslots-miners-chart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import BoostedPerDayComparisonChart from "admin/components/landratings/boosted-per-day-comparison-chart";
import DistributionPerDayChart from "admin/components/landratings/distribution-per-day-chart";
import GlobalDistributionPerDayChart from "admin/components/landratings/global-distribution-per-day-chart";

function AdminDashboardLandratings() {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-landratings">
        Landowner
      </BreadcrumbsItem>
      <div className="row">
        <div className="col-lg-12">
          <BoostedPerDayChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <BoostedPerMinersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <OpenslotsMinersChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <OpenedLandslotsChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <BoostedPerDayComparisonChart />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-12">
          <DistributionPerDayChart />
        </div>
      </div> */}
      <div className="row">
        <div className="col-lg-12">
          <GlobalDistributionPerDayChart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardLandratings;
