import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ReportsService from "../../../../services/reports";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../../../../components/card-menu";
import moment from "moment";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";


DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const BotsTasksChart = (props) => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "views/tower/report/components/bots-tasks-chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const [data, setData] = useState([]);
  const [type, setType] = useState("daily");
  const [showLinear, setShowLinear] = useState(true);
  const filters = useFilters();

  const accounts = useQuery(
    ["tower_report_bots_tasks_chart", type, filters],
    async () => {
      const response = await ApiService.get("bots/bots-tasks-chart", {
       // type,
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!accounts.data) return;

    setData(accounts.data);

    chartComponent.current.chart.hideLoading();
  }, [accounts.data]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const getTitleText = () => {
    let text;
    switch (type) {
      case "monthly":
        text = "Month";
        break;
      case "weekly":
        text = "Week";
        break;
      case "daily":
      default:
        text = "Day";
        break;
    }

    return text;
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        {/* <div className="float-sm-end">
          <form className="d-flex align-items-center flex-wrap">
            <div className="form-check me-2">
              <select
                className="form-select form-select-sm"
                name="type"
                onChange={handleChange}
                value={type}
              >
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
              </select>
            </div>
          </form>
        </div> */}
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `AW Bots API actions`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This chart shows flagged and unflagged wallets"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <form className="d-flex align-items-center flex-wrap">
          <div className="form-check me-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="outliers-check"
              defaultChecked={showLinear}
              onChange={(el) => setShowLinear(!showLinear)}
            />
            <label className="form-check-label" htmlFor="linear-check">
              Show linear
            </label>
          </div>
        </form>
        {accounts.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      showInLegend: true,
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: getTitleText(),
                    },
                    // range: moment().diff(moment().subtract(5, 'month'), 'seconds') * 1000
                  },
                  yAxis: {
                    type: showLinear ? "linear" : "logarithmic",
                    title: {
                      text: "Count",
                    },
                  },
                  tooltip: {
                    outside: true,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BotsTasksChart;
