export function copyToClipboard(table_id, btn_index) {    
    const currentLength = window.$(`#${table_id}`).DataTable().page.len();
    const maxLength = Math.max(...window.$(`#${table_id}`).DataTable().settings()[0].aLengthMenu);
    const info = window.$(`#${table_id}`).DataTable().page.info();
    if((currentLength === maxLength) || info.page > 0){
        window.$(`#${table_id}_wrapper .btn-group .buttons-copy`)[btn_index].click();  
    }else{
        window.$(`#${table_id}`).DataTable().one( 'draw', function () {        
            window.$(`#${table_id}_wrapper .btn-group .buttons-copy`)[btn_index].click();  
            setTimeout(()=>window.$(`#${table_id}`).DataTable().page.len(currentLength).draw(true),1000);       
        });
        window.$(`#${table_id}`).DataTable().page.len(maxLength).draw(true);
    }
}

export function largeResultWindowElasticSearch(tableRef) {
    const info = window.$(tableRef.current).DataTable().page.info();
    if(info.start >= 20){
      window.$("#large-result-window-elasticsearch-modal").modal("show");        
      return false;
    }

    return true;    
}
