import { useQuery } from "react-query";
import { useFilters } from "../../../../app/hooks/useSession";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";

export const AvgMineTime = (props) => {
  const filters = useFilters();

  const stats = useQuery([`account_stats_${props.account}`, props.account, filters], () => {
    return AccountService.getStats(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
    });
  });

  return (
          <div className="widget-simple text-center card mt-2">
              <div className="card-body">
                {stats.status === "loading" ? (
                    <div className="text-end">
                        <h3 className="mb-1 mt-0">
                        <Skeleton />
                        </h3>
                        <p className="text-muted mb-0">Average mine time (minutes)</p>
                    </div>
                ) : (
                    <>
                        <h3 className="text-success mt-0">
                        <span>{(stats.data && stats.data.bot_score) ? stats.data.bot_score.avg_mine_time.toFixed(2) : "-"}</span>
                        </h3>
                        <p className="text-muted mb-0">Average mine time (minutes)</p>
                    </>
                )}
              </div>
          </div>
  );
};

export default AvgMineTime;
