import { io } from "socket.io-client";
import config from "../config";

export const SocketService = {
  sockets: [],
  url: `${config.realtime.url}`,  
  getSocket: (nsp = '/') => {
    var socket = SocketService.sockets.find((socket) => socket.nsp === nsp);
    if (!socket) {
      socket = io(`${SocketService.url}${nsp}`);
      SocketService.sockets.push(socket);
      console.log(`New socket created ${nsp}`);
    } else {
      console.log(`Using already created socket ${nsp}`);
    }

    return socket;
  },
};

export default SocketService;
