import AdminNftsTemplateOwnersGridCard from "./nfts-template-owners-grid-card";
import { useInfiniteQuery } from "react-query";
import ApiService from "../../../app/services/api";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
//import { useFilters } from "../../../app/hooks/useSession";
//import moment from "moment-timezone";

export const AdminNftsTemplateOwnersGrid = (props) => {
  const [query, setQuery] = useState("");  
  //const filters = useFilters();

  const scrollLoad = (props.scrollLoad === false)?false:true;
  const templateId = props.templateId || null;
  const limit = 12;
  const accounts = useInfiniteQuery(
    [`template_${templateId}_owners`, query],
    async ({ pageParam = 1 }) => {
            
      const response = await ApiService.get(`templates/${templateId}/accounts`, {        
        query: query,
        sort: "total_assets",
        order: "DESC",
        page: pageParam,
        limit: limit,
        filter: JSON.stringify({}),
      });
      const result = await response.json();

      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => lastPage.nextPage,
      refetchOnWindowFocus: false,
    }
  );
  console.log('accounts',accounts)

  const loadMoreButtonRef = React.useRef();

  /* Scroll load more */
  useEffect(() => {
    if (!scrollLoad || !accounts.hasNextPage) {
      return;
    }
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          accounts.fetchNextPage();
        }
      })
    );
    const el = loadMoreButtonRef && loadMoreButtonRef.current;
    if (!el) {
      return;
    }
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [loadMoreButtonRef.current, accounts.hasNextPage]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-2">
          <div className="col-sm-4">
            <h4>Accounts</h4>
          </div>
          <div className="col-sm-8">
            <div className="float-sm-end">
              <form className="d-flex align-items-center flex-wrap">
                               
                <div className="me-2">
                  <label htmlFor="nftstemplateownerssearch-input" className="visually-hidden">
                    Search
                  </label>
                  <input
                    type="search"
                    className="form-control border-light"
                    id="nftstemplateownerssearch-input"
                    placeholder="Search..."
                    onChange={(el) => setQuery(el.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          {accounts.data ? (
            <>
              {accounts.data.pages.map((group, index) => (
                <React.Fragment key={index}>
                  {group.data.map((account, index) => (
                    <div className="col-xl-3 col-md-6" key={index}>
                      <AdminNftsTemplateOwnersGridCard account={account}/>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </>
          ) : null}

          {accounts.isFetching ? (          
            <>
              {new Array(limit).fill(0).map((group, index) => (
                <div className="col-xl-3 col-md-6" key={index}>
                  <div className="card product-box">
                    <div className="product-img">
                      <div className="p-3">
                        <Skeleton height={330} />
                      </div>
                    </div>
                    <div className="product-info border-top p-3">
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {accounts.data ? (
            <>
              {(accounts.data.pages &&
                accounts.data.pages[0] &&
                accounts.data.pages[0].data.length) === 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    <p>No Accounts found</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <div className="text-center my-3">
                        <button
                          href="true"
                          className="btn btn-sm btn-success"
                          ref={loadMoreButtonRef}
                          onClick={() => accounts.fetchNextPage()}
                          disabled={
                            !accounts.hasNextPage || accounts.isFetchingNextPage
                          }
                        >
                          {accounts.isFetchingNextPage
                            ? "Loading more..."
                            : accounts.hasNextPage
                            ? "Load More"
                            : "Nothing more to load"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>

      </div>
    </div>
  );
};

export default AdminNftsTemplateOwnersGrid;
