import { useState, useEffect } from "react";
import AuthService from "../../../../app/services/auth";
import ApiService from "../../../../app/services/api";

export const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  });
  const [isPassword, setIsPassword] = useState(false);
  useEffect(() => {
    AuthService.me().then((data) => {
      setIsPassword(data.user.is_password);
    });
  }, []);

  const handleChangePasswordClick = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.put("me/password", formData);
     // if (!response.ok) {
     //   throw new Error("Network response was not ok");
     // }

      const data = await response.json();

      if (data.status === "error") {
        throw new Error(data.message);
      }

      window.$.toast({
        heading: "Success",
        text: "Updated Successfully",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      //console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Update Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Change Password</h4>
        {isPassword ? (
        <>
        <p class="sub-header">Additional info</p>
        <div class="mb-3">
          <label for="currentPassword" class="form-label">
            Current Password
          </label>
          <input
            type="password"
            class="form-control"
            id="currentPassword"
            placeholder="Current Password"
            required
            value={formData.currentPassword}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                currentPassword: e.target.value,
              }))
            }
          />
        </div>
        <div class="mb-3">
          <label for="newPassword" class="form-label">
            New Password
          </label>
          <input
            type="password"
            class="form-control"
            id="newPassword"
            placeholder="New Password"
            required
            value={formData.newPassword}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                newPassword: e.target.value,
              }))
            }
          />
        </div>
        <div class="mb-3">
          <label for="confirmPassword" class="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="Confirm Password"
            required
            value={formData.confirmPassword}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                confirmPassword: e.target.value,
              }))
            }
          />
        </div>
        <div className="text-end">
          <button
            class="btn btn-primary waves-effect waves-light me-1"
            type="submit"
            onClick={handleChangePasswordClick}
          >
            Change password
          </button>
        </div>
        </>
        ): (
          <p class="sub-header">You’ve logged in with OAuth service provider eg: Google.</p>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
