//import CardMenu from "admin/components/card-menu";

import { useHelpTexts } from "app/hooks/useSession";
import AdminSystemAccountStats from "./account-stats";

export const AdminSystemAccounts = (props) => {
  const componentProps = {
    component: "views/dashboard-system/components/accounts",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();

  const systemAccounts = [
    "m.federation",
    "federation",
    "s.federation",
    "alien.worlds",
    "other.worlds",
    "team.worlds",
    "ghub.worlds",
    "fndtn.worlds",
    "mm.worlds",
    "oper2.worlds",
    "anim.worlds",
    "call.worlds",
    "oper1.worlds",
    "oper3.worlds",
    "open.worlds",
    "terra.worlds",
    "yeomenwarder",
    "yeomenguard1",
  ];

  return (
    <>
      {/* <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} /> */}
      <h4 className="header-title">
        {helpTexts.find((helpText) => helpText.key === componentProps.component)
          ?.title || `System Accounts`}
        <button
          type="button"
          className="btn btn-outline-none"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-ht-key={componentProps.component}
          title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.value || `System accounts Tooltip`
          }
          style={{ boxShadow: "none" }}
        >
          <i class="fas fa-info-circle"></i>
        </button>
      </h4>

      {systemAccounts.map((systemAccount) => (
        <div className="row">
          <div className="col-lg-12">
            <AdminSystemAccountStats account={systemAccount} />
          </div>
        </div>
      ))}

      {/* </div>
      </div> */}
    </>
  );
};

export default AdminSystemAccounts;
