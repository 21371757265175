import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import CardMenu from "../card-menu";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import { useEffect } from "react";
import { useRef } from "react";
import usePrevious from "app/hooks/usePrevious";

export const TopMiners = (props) => {
  const componentProps = {
    component: "components/mining/top_mined_lands",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 11 },
    copy_option: { id: "top_mined_lands" },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevfilters = usePrevious(filters);

  const topMinedLands = useQuery(
    [`top_mined_lands`, filters],
    async () => {
      const response = await ApiService.get("logmines/topMinedLands", {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      //initialData: new Array(10).fill(0),
    }
  );

  if (dataTableRef.current && prevfilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!topMinedLands.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: ["copy", { extend: "csv", title: `Top Mined Lands` }],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [topMinedLands.data]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Top Mined Lands`}
            <button
              type="button"
              className="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This shows the top 10 lands based on TLM mined in the selected time-frame. This also displays the minimum, maximum and average. The number of unique mines per land is also listed."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div class="responsive-table-plugin">
            <div class="table-rep-plugin">
              <div class="table-responsive" data-pattern="priority-columns">
                <table class="table mb-0" ref={tableRef} id="top_mined_lands">
                  <thead>
                    <tr>
                      <th>Land Id</th>
                      <th>Landowners</th>
                      <th>Count</th>
                      <th>Total</th>
                      <th>Min</th>
                      <th>Max</th>
                      <th>Avg</th>
                      <th>C</th>
                      <th>C%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topMinedLands.data &&
                      topMinedLands.data.map((topMinedLand, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`/admin/nfts/${topMinedLand.key}`}>
                                {topMinedLand.key}
                              </Link>
                            </td>
                            <td>
                              {topMinedLand.landowners.buckets.map(
                                (landowner, index) => (
                                  <>
                                    <Link
                                      to={`/admin/account-scanner/${landowner.key}`}
                                    >
                                      {landowner.key}
                                    </Link>
                                    {topMinedLand.landowners.buckets.length -
                                      1 !=
                                    index
                                      ? ", "
                                      : ""}
                                  </>
                                )
                              )}
                            </td>
                            <td>
                              {topMinedLand.total_mines.value.toLocaleString()}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.total_bounty.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.min_bounty.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.max_bounty.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.average_bounty.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.total_commission.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 4,
                              })}
                            </td>
                            <td>
                              {parseFloat(
                                topMinedLand.commission_rate.value
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        );
                      })}

                    {topMinedLands.isFetching ? (
                      <>
                        {new Array(10).fill(0).map((log, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {topMinedLands.isError ? <Error500 /> : null}
        </div>
      </div>
    </>
  );
};

export default TopMiners;
