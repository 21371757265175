import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import ApiService from "app/services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import HighchartsBoost from "highcharts/modules/boost";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import Error500 from "admin/components/errors/error-500";
import CardMenu from "../card-menu";
import moment from "moment";

DarkUnica(Highcharts);
HighchartsBoost(Highcharts);
indicators(Highcharts);
regressions(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export const BotActionProcessingChart = () => {
  const chartComponent = useRef(null);

  const componentProps = {
    component: "components/self-test/bot_action_processing_chart",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
    chart_component: chartComponent,
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState(filters.dates);

  const planets = useQuery(
    ["bot_action_processing_chart", filters],
    async () => {
      const response = await ApiService.get(
        "bots/bot-action-processing-chart",
        {
          startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let result = await response.json();
      return result;
    }
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!planets.data) return;

    setData(planets.data);
    setDates(filters.dates);

    chartComponent.current.chart.hideLoading();
  }, [planets.data]);

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Bot Action Processing`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "BOT action processing"
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        {planets.isError ? (
          <Error500 />
        ) : (
          <div className="mt-3 text-center">
            <div dir="ltr">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    style: {
                      fontFamily: "Roboto,sans-serif",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      showInLegend: true,
                    },
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      format: "{value:%Y-%m-%d}",
                    },
                    title: {
                      text: "Day",
                    },
                  },
                  yAxis: {
                    //tickInterval: 0.1,
                    allowDecimals: false,
                    min: 0,
                    title: {
                      text: "Number of actions",
                    },
                  },
                  tooltip: {
                    //valueDecimals: 3,
                    xDateFormat: "%Y-%m-%d",
                    pointFormat:
                      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    shared: true,
                  },
                  series: data,
                }}
                ref={chartComponent}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BotActionProcessingChart;
