import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import config from "app/config";

export const AdminSelfTestLiveCatchup = () => {
    const stats = useQuery(['wax_chain_api_status'], async () => {
        const response = await fetch(`${config.api.url}/wax_chain_api_status`);
        if (!response.ok) {
            return 'Unstable';
        }
        return 'Stable';
    });

    return (
        <div className="widget-simple text-center card">
        <div className="card-body">
            <h3 className={(stats.data && stats.data == 'Stable' ? 'text-success' : 'text-danger') + ' mt-0'}>
                {stats.status === "loading" ? (
                    <Skeleton width={50} />
                ) : (
                    <span>{stats.data}</span>
                )}
            </h3>
            <p className="text-muted mb-0">WAX chain api status</p>
        </div>
        </div>
    );
};

export default AdminSelfTestLiveCatchup;
