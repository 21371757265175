import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  copyToClipboard,
  largeResultWindowElasticSearch,
} from "app/utils/datatables";
import { setReport } from "app/state/session";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import moment from "moment";

export const OutgoingTeleports = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/staked-info",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const filters = useFilters();

  const staked = useQuery(
    [`staked_info_${props.account}`, props.account],
    async () => {
      const response = await AccountService.getStakedInfo(props.account);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  if (dataTableRef.current && prevAccount !== props.account) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!staked.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
            emptyTable: "No results found within the selected time frame",
          },
          order: [],
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            "copy",
            { extend: "csv", title: `Staked info for ${props.account}` },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: [1], //':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();

      /*       dataTableRef.current.on("preDraw", function (e) {
        const info = window.$(tableRef.current).DataTable().page.info();
        if (info.start >= 20) {
          dispatch(
            setReport({
              type: "reportTlmTransfers",
              format: "csv",
              filters: {
                start_datetime: moment(filters.dates.startDate)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss"),
                end_datetime: moment(filters.dates.endDate)
                  .endOf("day")
                  .format("YYYY-MM-DD HH:mm:ss"),
                account: props.account,
              },
            })
          );
          window
            .$("#large-result-window-elasticsearch-modal")
            .modal("show");
          return false;
        }

        return true;
      }) */
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [staked.data]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#staked_info_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("staked_info", 1);
  };
  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#staked_info_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("staked_info", 2);
  };

  return (
    <div className="card">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Staked info`
          }
        >
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Staked info`}{" "}
          for {props.account}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || "This shows the staked info for this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <table
          className="table dt-responsive nowrap w-100"
          ref={tableRef}
          id="staked_info"
        >
          <thead>
            <tr>
              <th className="no-sort">
                From &nbsp;
                <Link to="" onClick={copyFromAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy from address to clipboard"
                  ></i>
                </Link>
              </th>
              <th className="no-sort">
                To &nbsp;
                <Link to="" onClick={copyToAddressToClipboard}>
                  <i
                    className="fas fa-copy"
                    title="Copy to address to clipboard"
                  ></i>
                </Link>
              </th>
              <th>NET Staked</th>
              <th>CPU Staked</th>
              <th>Total Staked</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {staked.data &&
              staked.data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/admin/account-scanner/${row.staked_from}`}>
                        {row.staked_from}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admin/account-scanner/${row.staked_to}`}>
                        {row.staked_to}
                      </Link>
                    </td>
                    <td>{row.net_staked}</td>
                    <td>{row.cpu_staked}</td>
                    <td>{row.total_staked}</td>
                    <td>{row.status}</td>
                  </tr>
                );
              })}

            {staked.isFetching ? (
              <>
                {new Array(10).fill(0).map((log, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        {staked.isError ? <Error500 /> : null}
      </div>
    </div>
  );
};

export default OutgoingTeleports;
