import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import config from "app/config";
import { helpTextWrapper } from "app/utils/string";

export const DaoPlanetStats = ({ dac_id, stats }) => {
  const componentProps = {
    component: "components/dao/dao-planet-stats",
    options: { props: { dac_id: dac_id } },
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  //   const stats = useQuery(
  //     [`dao_planet_stats`, props.dac_id],
  //     async () => {
  //       const response = await ApiService.get("dao/dao-planet-stats", {
  //         dac_ids: props.dac_id,
  //       });
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     },
  //     {}
  //   );

  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <h4
          className="header-title"
          data-ht-title={
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || ` DAO Planet stats of PLANET`
          }
        >
          {helpTextWrapper(
            helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `DAO Planet stats of PLANET`,
            {
              PLANET: config.planets.find((planet) => planet.dac_id === dac_id)
                ?.title,
            }
          )}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            data-ht-value={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || `This chart shows the DAO planet stats of PLANET`
            }
            title={helpTextWrapper(
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value || `This chart shows the DAO planet stats of PLANET`,
              {
                PLANET: config.planets.find(
                  (planet) => planet.dac_id === dac_id
                )?.title,
              }
            )}
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.total_weight_of_votes).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Total weight of votes</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(
                        stats.number_voters
                      ).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Total votes on candidates</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(
                        stats.number_active_candidates
                      ).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Active candidates</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.pending_proposals).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Pending proposals</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.executed_proposals).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Completed proposals</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.expired_proposals).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Expired proposals</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.circulating_supply).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Circulating Supply</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>{parseInt(stats.total_stake).toLocaleString()}</span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">
                  Syndicate tokens pledged to vote power
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 className="text-success mt-0">
                  {stats ? (
                    <span>
                      {parseInt(stats.leftover_budget).toLocaleString()}
                    </span>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </h3>
                <p className="text-muted mb-0">Leftover Budget</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaoPlanetStats;
