import { useQuery, useQueryClient, useMutation } from "react-query";
import ReportsService from "../../../services/reports";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { filter } from "minimatch";
import Emitter from "app/services/emitter";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { formatBytes } from "app/utils/format";
import { ucfirst } from "app/utils/string";
import Error500 from "admin/components/errors/error-500";

export const AdminListReports = () => {
  const tableRef = useRef();
  const dataTableRef = useRef();
  const queryClient = useQueryClient();

  // const mutation = useMutation(repor, {
  //   onSuccess: data => {
  //     queryClient.setQueryData("list_reports", data)
  //   }
  // })

  useEffect(() => {
    Emitter.on("REQUEST_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    Emitter.on("CLEAR_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    Emitter.on("RETRY_REPORT_SUCCESS", (newValue) => {
      reports.refetch();
    });

    return () => {
      Emitter.off("REQUEST_REPORT_SUCCESS");
      Emitter.off("CLEAR_REPORT_SUCCESS");
      Emitter.off("RETRY_REPORT_SUCCESS");
    };
  });

  const reports = useQuery(
    "list_reports",
    async () => {
      const response = await ReportsService.getReports({
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    { refetchInterval: 30 * 1000, cacheTime: 1000 }
  );

  // if (dataTableRef.current) {
  //   dataTableRef.current.destroy();
  //   dataTableRef.current = null;
  // }

  useEffect(() => {
    if (!reports.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [reports.data]);

  const onClickDownload = async (report) => {
    try {
      const response = await ReportsService.downloadReport(report.job_id);

      if (!response) {
        throw Error("Unable to process");
      }

      let report_title = ReportsService.getTypeTitle(report.type).replace(
        /\s/g,
        "_"
      );
      let [filename, extension] = report.generate_file.split(".");
      let [filename_id, filename_timestamp] = filename.split("_");

      let url = window.URL.createObjectURL(response);
      let a = document.createElement("a");
      a.href = url;
      //a.download = `${report.generate_file}`;
      a.download = `${report_title}_${filename_timestamp}_${filename_id}.${extension}`;
      a.click();

      window.$.toast({
        heading: "Success",
        text: "Report Downloading ...",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Report Download Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const onClickClear = async (report) => {
    try {
      const response = await ReportsService.clearReport(report.job_id);

      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("CLEAR_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Report Cleared ...",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Report Clear Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const onClickRetry = async (report) => {
    try {
      const response = await ReportsService.retryReport(report.job_id);

      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("RETRY_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "This report request has been queued and is being generated.",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Report Retry Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const humanize = (str) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const displayStatus = (report) => {
    if (report.status === "new") {
      return <span className="badge badge-outline-primary">New</span>;
    } else if (report.status === "pending") {
      return <span className="badge badge-outline-warning">Pending</span>;
    } else if (report.status === "processing") {
      return <span className="badge badge-outline-warning">Processing</span>;
    } else if (report.status === "completed") {
      return <span className="badge badge-outline-success">Completed</span>;
    } else if (report.status === "failed") {
      return <span className="badge badge-outline-danger">Failed</span>;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <table
              class="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="list_reports"
            >
              <thead>
                <tr>
                  <th>Report Type</th>
                  <th>Request Date</th>
                  <th>Parameters</th>
                  <th>Format</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports.isLoading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {reports.data &&
                      reports.data.map((report, index) => {
                        var type;
                        switch (report.type) {
                          // case "reportSample1":
                          //   var type = "Report sample1";
                          //   break;
                          // case "reportSample2":
                          //   var type = "Report sample2";
                          //   break;
                          // case "reportSample3":
                          //   var type = "Report sample3";
                          //   break;
                          case "reportMiningActions":
                            type = "Report Mining Actions";
                            break;
                          case "reportTlmTransfers":
                            type = "Report TLM Transfers";
                            break;
                          case "reportTlmMiners":
                            type = "Top 100 TLM miners (non-bot)";
                            break;
                          case "reportBotScoreUsers":
                            type = "Report bot score users";
                            break;
                          case "reportNfts":
                            type = "Report NFTs with Template ID";
                            break;
                          case "reportAccountsMiningFrequency":
                            type = "Accounts mining frequency";
                            break;
                          case "reportMostExchangeMemos":
                            type = "Most used exchange memos";
                            break;
                          case "reportExportNftMovementsSummary":
                            type = "Export NFT movements (Summary)";
                            break;
                          case "reportExportNftMovementsInterval":
                            type = "Export NFT movements (Interval)";
                            break;
                          case "reportMinesWithTemplate":
                            type = "Peoples mine with tool (Template ID)";
                            break;
                          case "reportExportMining":
                            type = "Export csv from mining explorer";
                            break;
                          case "reportMultipleAuthorizationsMine":
                            type = "Multiple Authorizations Mine";
                            break;
                          case "reportContractLandowners":
                            type = "Landowners with Contract";
                            break;
                          case "reportExportTlmTransfers":
                            type = "Export csv from Tlm transfers lookup";
                            break;
                          case "reportExportWaxTransfers":
                            type = "Export csv from Wax transfers lookup";
                            break;
                          case "reportExportNftTransfers":
                            type = "Export csv from NFT transfers lookup";
                            break;
                          case "reportExportNftsTransfersDeduped":
                            type = "Export csv from NFTs Transfers Deduped";
                            break;
                          case "reportCreatedAccountsUsingNewuser":
                            type = "Accounts created using newuser.wax";
                            break;
                          case "reportNftsForensics":
                            type = "NFTs Forensics";
                            break;
                          case "reportMiningActiveUsersMonthly":
                            type = "Mining Active Users Monthly";
                            break;
                          case "reportTlmEarnedPerMiner":
                            type = "Monthly Avg TLM amount for a user by month";
                            break;
                          case "reportTlmEarnedPerMiners":
                            type = "Monthly Avg. TLM amount earned per user";
                            break;
                          case "reportActiveMinersUniqueAnalysis":
                            type = "Active miners unique analysis";
                            break;
                          case "reportActiveMinersCohortAnalysis":
                            type = "Active miners cohort analysis";
                            break;
                          case "reportEarnSpentRatio":
                            type = "Earn Vs Spent, per user, monthly";
                            break;
                          case "reportEarnOverThreshold":
                            type =
                              "% of active users earning more than X TLM per month";
                            break;
                          case "reportSecondaryMarketPurchase":
                            type =
                              "*Secondary Market* Average spending in WAX per user per month";
                            break;
                          case "reportSecondaryMarketSale":
                            type =
                              "*Secondary Market* Average earning in WAX per user per month";
                            break;
                          case "reportTopTlmMinersPerPlanet":
                            type = "Top 100 TLM miners per planet";
                            break;
                          case "reportTopNftPointsMinersPerPlanet":
                            type = "Top 100 NFT Points miners per planet";
                            break;
                          case "reportTopTlmMinersPerLand":
                            type = "Top 100 TLM miners per land";
                            break;
                          case "reportTopNftPointsMinersPerLand":
                            type = "Top 100 NFT Points miners per land";
                            break;
                          case "reportMiningActiveUsersPerInterval":
                            type = "New miners based on first mine";
                            break;
                          case "reportActiveMinersAverageAnalysis":
                            type = "Active Users Retention";
                            break;
                          case "reportNftsHeldByActiveMiners":
                            type = "% NFTs held by active miners";
                            break;
                          case "reportActiveMinersSpendCluster":
                            type =
                              "Segment Active User in 4 Cluster for TLM Spending";
                            break;
                          case "reportActiveMinersChurnAnalysis":
                            type = "Churn Rate as %";
                            break;
                          case "reportMinecraftUsersPerQuest":
                            type = "Minecraft User per Quest Monthly";
                            break;
                          case "reportMinecraftUserPoints":
                            type = "Minecraft Userpoints Monthly";
                            break;
                          case "reportPlanetsPerPools":
                            type = "Rarity Pools";
                            break;
                          case "reportDaoTxPerPlanet":
                            type = "DAO All TX";
                            break;
                          case "reportDDDQ2BaselineReportMoM":
                            type = "DDD Q2 Baseline Report MoM";
                            break;
                          case "reportBotActionProcessing":
                            type = "Yeomen Flag/Unflag actions";
                            break;
                          default:
                            break;
                        }
                        var filters = [];
                        if (report.filters) {
                          Object.entries(JSON.parse(report.filters)).forEach(
                            function (entry) {
                              const [key, value] = entry;
                              filters.push(humanize(key) + ": " + value);
                            }
                          );
                        }
                        return (
                          <tr key={index}>
                            <td>{type}</td>
                            <td
                              title={moment(report.request_datetime).fromNow()}
                            >
                              {moment
                                .tz(report.request_datetime, "UTC")
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              {filters.map((filter, index) => (
                                <p className="lh-1" key={index}>
                                  {filter}
                                </p>
                              ))}
                            </td>
                            <td>{report.format}</td>
                            <td>{displayStatus(report)}</td>
                            <td>
                              {report.status === "new" ? "In progress" : null}
                              {report.status === "processing"
                                ? `In progress ${
                                    report.job_progress > 0
                                      ? `(${report.job_progress}%)`
                                      : ""
                                  }`
                                : null}
                              {report.status === "completed" ? (
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) => onClickDownload(report)}
                                >
                                  Download
                                  {report.generate_file_size
                                    ? `(${formatBytes(
                                        report.generate_file_size,
                                        2
                                      )})`
                                    : null}
                                </button>
                              ) : null}
                              {(report.status === "completed" &&
                                moment
                                  .tz(report.generate_datetime, "UTC")
                                  .add(1, "days")
                                  .format("YYYY-MM-DD HH:mm:ss") <=
                                  moment
                                    .tz("UTC")
                                    .format("YYYY-MM-DD HH:mm:ss")) ||
                              report.status === "failed" ? (
                                <button
                                  className="btn btn-link text-danger p-0 d-block"
                                  onClick={(e) => onClickClear(report)}
                                >
                                  {report.clear_attempts}
                                  Clear
                                </button>
                              ) : null}
                              {report.status === "completed" ||
                              report.status === "failed" ? (
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) => onClickRetry(report)}
                                >
                                  Retry
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {reports.isError ? <Error500 /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminListReports;
