import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AccountService from "../../services/account";
import AccountStats from "./components/stats";
import MiningChart from "./components/mining-chart";
import MiningActions from "./components/mining-actions";
import NftTransfers from "./components/nft-transfers";
import TlmTransfers from "./components/tlm-transfers";
import WaxTransfers from "./components/wax-transfers";
import TlmSwapped from "./components/tlm-swapped";
import IncommingTeleports from "./components/incomming-teleports";
import OutgoingTeleports from "./components/outgoing-teleports";
import StakedInfo from "./components/staked-info";
import AccountBottomStats from "./components/bottom-stats";
import Tools from "./components/tools";
import Profile from "./components/profile";
import { Link } from "react-router-dom";
import AssetsTransfers from "./components/assets-transfers";
import { BotScore } from "./components/bot-score";
import UserPointStats from "./components/user-point-stats";
import { UnclaimedNfts } from "./components/unclaimed-nfts";
import { ConnectedExchangeMemo } from "./components/connected-exchange-memo";
import { ConnectedNftTransfer } from "./components/connected-nft-transfer";
import { ConnectedMalformedTxs } from "./components/connected-malformed-txs";
import { SharedNewUserWAX } from "./components/shared-new-users-wax";
import { OneHopAccounts } from "./components/one-hop-accounts";
import Error500 from "admin/components/errors/error-500";
import EthBscStats from "./components/eth-bsc-stats";
import Missions from "./components/missions";
import MissionsNft from "./components/missions-nft";
import EthBscInboundTransfers from "./components/eth-bsc-inbound-transfers";
import EthBscOutboundTransfers from "./components/eth-bsc-outbound-transfers";
import EthBscTlmSwap from "./components/eth-bsc-tlm-swap";
import CreatedAccounts from "./components/created-accounts";
import usePrevious from "app/hooks/usePrevious";
import WaxTransfersBacked from "./components/wax-transfers-backed";
import NftsTransfers from "./components/nfts-transfers";
import BigqueryMineActions from "./components/bigquery-mine-actions";
import { NftsBoughtSold } from "./components/nfts-bought-sold";
import Appeals from "./components/appeals";
import { BotTasks } from "./components/bot-tasks";

export const AccountDetails = () => {
  const history = useHistory();
  let location = useLocation();

  const params = useParams();
  const prevParams = usePrevious(params);
  let param = params.account?params.account : '';
  const search = useRef(null);
  const [ account, setAccount ] = useState(param);
  const [ autocompleteItems, setAutocompleteItems ] = useState([]);
  const [ showAutocompleteItems, setShowAutocompleteItems ] = useState(false);

  const accountCheck = useQuery([`account_check`, account], async () => {
    if(account !== '') {
      const response = await AccountService.checkAccount(account);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
    else
      return null;
  });

  // if(account && account != param)
  //   setAccount(param);

  const searchAccount = (event) => {
      event.preventDefault();
      setAccount(event.target.search.value);
      history.push({
          pathname: "/admin/account-scanner/"+event.target.search.value
      });
  };

  useEffect(async () => {
    search.current.value = account;
  }, [account]);

  useEffect(() => {
    if (prevParams && prevParams.account !== params.account) {
      setAccount(params.account)
    }
  }, [params, prevParams]);

  const autocompleteAccount = async (el) => {  
    const value = el.target.value.toLowerCase();
    search.current.value = value;
    
    const lower_bound = value;
    const upper_bound = lower_bound.padEnd(12, 'z');
    const data = await fetch('https://chain.wax.io/v1/chain/get_table_by_scope',{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        code: "eosio",
        limit: 5,
        lower_bound: lower_bound,
        table: "userres",
        upper_bound: upper_bound
      }),
    }).then(response => response.json());
   
    if(data && data.rows){
        setAutocompleteItems(data.rows);
        if(data.rows.length == 1) {
          search.current.blur();
          selectItem(data.rows[0]);
        }
          
    }

  }

  const selectItem = (item) =>{
    setShowAutocompleteItems(false);
    setAccount(item.scope);
    history.push({
        pathname: "/admin/account-scanner/"+ item.scope
    });
  }

  const handleOnFocus = () => {    
    setShowAutocompleteItems(true);
  }
  
  const handleOnBlur = () => {   
    setTimeout(function(){
      setShowAutocompleteItems(false)
    },1000);
    
  }

  return (
    <>
      <BreadcrumbsItem to="/admin/account-scanner">Account Scanner</BreadcrumbsItem>
 
      <div className="row">
        <div className="col-lg-12">
        <div className="dropdown">
          <form className="d-flex align-items-center" onSubmit={searchAccount}>
                    <input
                    type="text"
                    name="search"
                    className="form-control dropdown-toggle"       
                    id="autocomplateAccountName"      
                    data-bs-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"     
                    placeholder="Enter account name"
                    aria-label="Search"
                    autoComplete={'off'}
                    ref={search}
                    onChange={autocompleteAccount}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    />
                    <button
                        type="submit"
                        className="btn btn-success mb-2 mb-sm-0"
                    >
                    Search
                    </button>                    
                    <div className={`dropdown-menu ${(autocompleteItems.length > 0 && showAutocompleteItems)?'show':'d-none'}`} aria-labelledby="autocomplateAccountName">
                        {autocompleteItems.map((autocompleteItem, index)=>(
                          <Link className="dropdown-item" key={index}  onClick={()=>selectItem(autocompleteItem)}>{autocompleteItem.scope}</Link>
                        ))}                        
                    </div>  
                   
            </form>
          </div>    
        </div>
      </div>
      {account !== '' ? (
        <>
        {accountCheck.status === "loading" ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
        ) : null }

        {accountCheck.data && accountCheck.data.is_account ? (
          <>
          { accountCheck.data.is_bsc || accountCheck.data.is_eth ? (
            <>
              <EthBscStats account={account} isBsc={accountCheck.data.is_bsc} isEth={accountCheck.data.is_eth} />

              { accountCheck.data.is_bsc ? (
                <>
                <div className="row">
                  <div class="col-lg-12">
                    <Missions account={account} />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <MissionsNft account={account} />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscInboundTransfers account={account} type="bsc" />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscOutboundTransfers account={account} type="bsc" />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscTlmSwap account={account} type="bsc" />
                  </div>
                </div>
                </>
              ) : null }
              { accountCheck.data.is_eth ? (
                <>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscInboundTransfers account={account} type="eth" />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscOutboundTransfers account={account} type="eth" />
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <EthBscTlmSwap account={account} type="eth" />
                  </div>
                </div>
                </>
              ) : null }
            </>
          ) : (
          <>
            <div className="row">
              <div class="col-lg-4">
                <Profile account={account} />
              </div>
              <div class="col-lg-8">
                <AccountStats account={account} isMiner={accountCheck.data.is_miner} />
              </div>
            </div>            
            {accountCheck.data && accountCheck.data.is_miner ? (
            <>
            <BotScore account={account} />
            <div className="row">
              <div className="col-lg-12">
                <UserPointStats account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <MiningChart account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <MiningActions account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <ConnectedExchangeMemo account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <ConnectedNftTransfer  account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <ConnectedMalformedTxs  account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <OneHopAccounts  account={account} />
              </div>
            </div>
            <UnclaimedNfts account={account} />
            </>
            ) : null }
            <div className="row">
              <div class="col-lg-12">
                <SharedNewUserWAX  account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <CreatedAccounts  account={account} />
              </div>
            </div>
            <NftTransfers account={account}/>
            <div className="row">
              <div class="col-lg-12">
                <TlmTransfers account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <WaxTransfers account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <TlmSwapped account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <IncommingTeleports account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <OutgoingTeleports account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <StakedInfo account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <WaxTransfersBacked account={account} />
              </div>
            </div>
            {accountCheck.data && accountCheck.data.is_miner ? (
            <div className="row">
              <div class="col-lg-12">
                <Tools account={account} />
              </div>
            </div>
            ) : null }
            {/* <div className="row">
              <div class="col-lg-12">
                <AssetsTransfers  account={account} />
              </div>
            </div> */}
            <div className="row">
              <div class="col-lg-12">
                <NftsTransfers  account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <BigqueryMineActions  account={account} />
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12">
                <NftsBoughtSold  account={account} />
              </div>
            </div>    
            <div className="row">
              <div class="col-lg-12">
                <Appeals account={account} />
              </div>
            </div> 
            <div className="row">
              <div class="col-lg-12">
                <BotTasks account={account} />
              </div>
            </div>       
            <AccountBottomStats account={account} />
          </>
          )}
          </>
        ) : (
          <>
          {accountCheck.data && !accountCheck.data.is_account && !accountCheck.isError ? (
          <div className="row justify-content-center my-5">
            <div className="col-lg-6 col-xl-4 mb-4">
              <div className="text-center">
                <img
                  src="/assets/images/error.svg"
                  alt="errorimage"
                  width="200"
                />
              </div>
              <div className="text-center">
                <h3 className="mt-2 mb-2">Account not found </h3>
              </div>
            </div>
          </div>
          ) : null}
          </>
        )}

        {accountCheck.isError ? <Error500 /> : null}
        </>
      ) : null }
    </>
  );
};

export default AccountDetails;
