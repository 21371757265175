import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import config from "app/config";

export const AdminSelfTestLatestBotCheckStatus = () => {
  const stats = useQuery(["latest_bot_check_status"], async () => {
    const response = await fetch(`${config.api.url}/latest_bot_check_status`);
    if (!response.ok) {
      return null;
    }
   
    return response.json();
  });

  return (
    <div className="widget-simple text-center card">
      <div className="card-body">
        <h3 className="text-success mt-0">
          {stats.status === "loading" ? (
            <Skeleton width={50} />
          ) : (
            <span >
              {moment.tz(stats.data, "UTC").fromNow()}
            </span>
          )}
        </h3>
        <p className="text-muted mb-0">Latest Bot check status</p>
      </div>
    </div>
  );
};

export default AdminSelfTestLatestBotCheckStatus;
