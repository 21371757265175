import { useQueryParams } from "app/hooks/useQueryParams";
import moment from "moment";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminTowerMinersStats from "./components/tower_miners_stats";
import AdminTowerMinersBots from "./components/tower_miners_bots";
import AdminTowerMinersScoresHistogram from "./components/tower_miners_scores_histogram";
import AdminSelfTestTowerTimeline from "admin/components/self-test/tower_timeline";
import AdminSelfTestTowerFlow from "admin/components/self-test/tower_flow";
import AdminTowerMinersScatter from "./components/tower_miners_scatter";
import AdminTowerMinersGraph3d from "./components/tower_miners_graph3d";
import AdminTowerSignalsStats from "./components/tower_signals_stats";
// import AdminTowerMinersScatter3d from "./components/tower_miners_scatter3d";

export const AdminTowerDebug = () => {
  const queryParams = useQueryParams();
  const [formFilters, setFormFilters] = useState({
    date:
      queryParams.get("date") ||
      moment().utc().subtract(1, "day").format("YYYY-MM-DD"),
  });
  return (
    <>
      <BreadcrumbsItem to="/admin/tower">Tower</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/tower/debug">Debug</BreadcrumbsItem>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div class="form-floating mb-3">
                <DateRangePicker
                  id={`daterangepicker`}
                  initialSettings={{
                    autoUpdateInput: false,
                    singleDatePicker: true,
                    timePicker: false,
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                  }}
                  onCallback={(start, end, label) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      date: start.format("YYYY-MM-DD"),
                    }));
                    window.history.pushState(
                      {},
                      "",
                      `/admin/tower/debug?date=${start.format("YYYY-MM-DD")}`
                    );
                  }}
                  onApply={(event, picker) => {
                    setFormFilters((prevState) => ({
                      ...prevState,
                      date: picker.startDate.format("YYYY-MM-DD"),
                    }));

                    window
                      .$(event.currentTarget)
                      .val(picker.startDate.format("YYYY-MM-DD"));
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={formFilters.date}
                  />
                </DateRangePicker>
                <label for={`daterangepicker`}>Date</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminTowerMinersScoresHistogram filters={{ date: formFilters.date }} />
      <AdminTowerMinersBots filters={{ date: formFilters.date }} />
      <AdminTowerMinersStats filters={{ date: formFilters.date }} />
      <AdminTowerSignalsStats filters={{ date: formFilters.date }} />

      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerTimeline filters={{ date: formFilters.date }} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerFlow filters={{ date: formFilters.date }} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminTowerMinersScatter filters={{ date: formFilters.date }} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminTowerMinersGraph3d filters={{ date: formFilters.date }} />
        </div>
      </div>
    </>
  );
};

export default AdminTowerDebug;
