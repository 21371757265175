import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import ZendeskStats from "../../components/zendesk/zendesk-stats";

const AdminDashboardZendesk = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-zendesk">Zendesk</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <ZendeskStats />
        </div>
      </div>
    </>
  );
};

export default AdminDashboardZendesk;
