import TotalWax from "./total-wax";
import Staked from "./staked";
import TotalRam from "./total-ram";

export const AccountBottomStats = (props) => {

  return (
      <div className="row">
        <div class="col-xl-4 col-md-12">
          <TotalWax account={props.account} />
        </div>
        <div class="col-xl-4 col-md-12">
          <Staked account={props.account} />
        </div>
        <div class="col-xl-4 col-md-12">
          <TotalRam account={props.account} />
        </div>
      </div>
  );
};

export default AccountBottomStats;
