import moment from "moment-timezone";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import { apiHeaders } from "app/services/api";
import config from "app/config";
import usePrevious from "app/hooks/usePrevious";
import { useEffect, useRef, useState } from "react";

export const AdminTowerAppealsList = (props) => {
  const componentProps = {
    component: "views/tower/appeals/components/tower-appeals-list",
    options: { props: {} },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const tableRef = useRef();
  const dataTableRef = useRef();
  //const filters = useFilters();
  const {filters} = props;
  const prevFilters = usePrevious(filters);
  const [content, setContent] = useState(null);

  if (dataTableRef.current && prevFilters !== filters) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!results.data) {
    //   return;
    // }

    window
      .$(document)
      .on("click", "table#tower_appeals_list .btn-read-more", function () {
        var content = window.$(this).attr("data-content");
        setContent(content);
        window.$("#read-more-tower_appeals_list-modal").modal("show");
      });

    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window
          .$(tableRef.current)
          .DataTable({
            language: {
              paginate: {
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>",
              },
            },
            order: [[4, "desc"]],
            lengthMenu: [10, 100, 500, 1000],
            searching: true,
            processing: true,
            serverSide: true,
            ajax: {
              url: `${config.api.url}/api/${config.api.version}/tower/tower-appeals`,
              type: "GET",
              headers: {
                ...apiHeaders(),
              },
              data: function (data) {
                data.timestamp = moment().utc().valueOf();
                data.startDate = filters.dates.startDate;
                data.endDate = filters.dates.endDate;
              },
            },
            columns: [
              {
                data: "wallet",
                name: "wallet",
                render: function (data, type, row) {
                  return `<a href="/admin/account-scanner/${data}" target="_blank">${data}</a>`;
                },
              },
              {
                data: "reason",
                name: "reason",
                render: function (data, type, row) {
                  return `${data.slice(0, 150)}${
                    data.length > 150
                      ? `...<button class="btn btn-link btn-read-more" data-content="${data}">Read more</button>`
                      : ""
                  }`;
                },
              },
              { data: "status", name: "status" },
              { data: "tower_status", name: "tower_status" },
              {
                data: "created_at",
                name: "created_at",
                render: function (data, type, row) {
                  return moment.tz(data, "UTC").format("YYYY-MM-DD HH:mm:ss");
                },
              },
              {
                data: "updated_at",
                name: "updated_at",
                render: function (data, type, row) {
                  return moment.tz(data, "UTC").format("YYYY-MM-DD HH:mm:ss");
                },
              },
              {
                data: "cooling_until",
                name: "cooling_until",
                render: function (data, type, row) {
                  return data
                    ? moment.tz(data, "UTC").format("YYYY-MM-DD HH:mm:ss")
                    : "N/A";
                },
              },
            ],
            columnDefs: [],
          });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [filters]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title">Tower Appeals</h4>
              <table
                class="table dt-responsive  w-100"
                ref={tableRef}
                id="tower_appeals_list"
              >
                <thead>
                  <tr>
                    <th>Wallet</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Tower Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Cooling Until</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        id="read-more-tower_appeals_list-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content" style={{height:'400px'}}>
            {/* <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div> */}
            <div className="modal-body" style={{ whiteSpace: 'pre-line' }}>{content}</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#read-more-tower_appeals_list-modal").modal("hide");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTowerAppealsList;
