import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, incrementByAmount } from "../../app/state/counter";
import SampleHighchart from "../components/sample-highchart";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function AdminDashboardOverview() {
  const count = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-overview">Overview</BreadcrumbsItem>
      <div>Dashboard Overview</div>
      <div>
        <span>{count}</span>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(incrementByAmount(25))}
        >
          Increment By Amount
        </button>
      </div>

      <div className="row">
        <div className="col-xl-6 col-md-6">
          <SampleHighchart />
        </div>
        <div className="col-xl-6 col-md-6">
          <SampleHighchart />
        </div>
      </div>
    </>
  );
}

export default AdminDashboardOverview;
