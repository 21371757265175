export const config = {
    api: {
        url: process.env.REACT_APP_API_URL || null,
        fast_url: process.env.REACT_APP_API_FAST_URL || process.env.REACT_APP_API_URL,
        version: process.env.REACT_APP_API_VERSION || null,
    },
    realtime: {
        url: process.env.REACT_APP_REALTIME_URL || null,
    },
    admin: {
        url: process.env.REACT_APP_ADMIN_URL || null,
    },
    explorer: {
        url: "https://wax.eosauthority.com",
    },
    atomichub: {
        url: "https://wax.atomichub.io",
    },
    dac_ids: [
        "nerix",
        "magor",
        "naron",
        "eyeke",
        "kavian",
        "veles"
    ],
    planets: [
        {dac_id: 'eyeke', name: 'eyeke.world', symbol: 'EYE', title: 'Eyeke'},
        {dac_id: 'kavian', name: 'kavian.world', symbol: 'KAV', title: 'Kavian'},
        {dac_id: 'magor', name: 'magor.world', symbol: 'MAG', title: 'Magor'},
        {dac_id: 'naron', name: 'naron.world', symbol: 'NAR', title: 'Naron'},
        {dac_id: 'nerix', name: 'neri.world', symbol: 'NER', title: 'Neri'},
        {dac_id: 'veles', name: 'veles.world', symbol: 'VEL', title: 'Veles'}
    ],
    planet_pools: [
        {key: 'abundant', name: 'Abundant', order: 1},
        {key: 'common', name: 'Common', order: 2},
        {key: 'rare', name: 'Rare', order: 3},
        {key: 'epic', name: 'Epic', order: 4},
        {key: 'legendary', name: 'Legendary', order: 5},
        {key: 'mythical', name: 'Mythical', order: 6}
    ],
    ipfs: {
        url: "https://atomichub-ipfs.com",
    }
};

export default config;
