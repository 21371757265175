import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminSelfTestLiveCatchup from "admin/components/self-test/live_catchup";
import AdminBotActionProcessingChart from "admin/components/self-test/bot_action_pricessing_chart";
import AdminApiLogs from "admin/components/self-test/api_logs";
import AdminBullLogs from "admin/components/self-test/bull_logs";
import AdminSelfTestElasticsearchCatchup from "admin/components/self-test/elasticsearch_catchup";
import AdminSelfTestMetricsCatchup from "admin/components/self-test/metrics_catchup";
import AdminSelfTestLatestReports from "admin/components/self-test/latest-reports";
import AdminSelfTestApiCachingCalls from "admin/components/self-test/api_caching_calls";
import AdminSelfTestGuardCounters from "admin/components/self-test/guard_counters";
import AdminSelfTestTowerMiners from "admin/components/self-test/tower_miners";
import AdminSelfTestTowerScores from "admin/components/self-test/tower_scores";
import { AdminSelfTestTowerStats } from "admin/components/self-test/tower_stats";
import TowerScoresHistogram from "admin/components/self-test/tower_scores_histogram";
import AdminSelfTestTowerTimeline from "admin/components/self-test/tower_timeline";
import AdminSelfTestTowerFlow from "admin/components/self-test/tower_flow";

export const AdminSelfTest = () => {
  return (
    <>
      <BreadcrumbsItem to="/admin/self-test">Self Test</BreadcrumbsItem>

      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestLiveCatchup />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestElasticsearchCatchup />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestMetricsCatchup />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestGuardCounters />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerStats />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminBotActionProcessingChart />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminApiLogs />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminBullLogs />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestLatestReports />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestApiCachingCalls />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerMiners />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerScores />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <TowerScoresHistogram />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerTimeline />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <AdminSelfTestTowerFlow />
        </div>
      </div>
    </>
  );
};

export default AdminSelfTest;
