import CardMenu from "admin/components/card-menu";
import { useHelpTexts } from "app/hooks/useSession";
import PlanetStaking from "./planet-staking";
import TotalTlm from "./total_tlm";
import TotalTlmBoosted from "./total_tlm_boosted";

export const SinkStats = (props) => {
  const componentProps = {
    component: "components/shining/sink-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();
  return (
    <div className="card bg-transparent">
      <div className="card-body">
        <CardMenu {...componentProps} {...props} />
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Sink stats`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              `Planet staking -TLM staked by all planets. <br>
                            `
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row">
          <div class="col-xl-4 col-md-6">
            <PlanetStaking />
          </div>
          <div class="col-xl-4 col-md-6">
            <TotalTlm />
          </div>
          <div class="col-xl-4 col-md-6">
            <TotalTlmBoosted />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinkStats;
