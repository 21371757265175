import { useSelector } from "react-redux";

export const useIpfsImageLoaded = () => {
  const loaded = useSelector((state) => state.ipfsImage.loaded);
  return loaded;
};

export const useIpfsImageFailed = () => {
  const failed = useSelector((state) => state.ipfsImage.failed);
  return failed;
};



