import Emitter from "app/services/emitter";
import { useEffect } from "react";
import { useRef } from "react";
import { useQuery } from "react-query";
import moment from "moment-timezone";
import ApiService from "app/services/api";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import { useState } from "react";

export const AdminListAlerts = () => {
  const tableRef = useRef();
  const dataTableRef = useRef();

  const [deleteAlert, setDeleteAlert] = useState(null);

  useEffect(() => {
    Emitter.on("CREATE_ALERT_SUCCESS", (newValue) => {
      alerts.refetch();
    });

    Emitter.on("DELETE_ALERT_SUCCESS", (newValue) => {
      alerts.refetch();
    });

    return () => {
      Emitter.off("CREATE_ALERT_SUCCESS");
      Emitter.off("DELETE_ALERT_SUCCESS");
    };
  });

  const alerts = useQuery(
    "list_alerts",
    async () => {
      const response = await ApiService.get("alerts", {
        timestamp: moment.utc().valueOf(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    { refetchInterval: 30 * 1000, cacheTime: 1000 }
  );

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    if (!alerts.data) {
      return;
    }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          order: [],
        });
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [alerts.data]);

  const onClickNew = async () => {
    Emitter.emit("NEW_ALERT");
  };

  const onClickEdit = async (alert) => {
    Emitter.emit("EDIT_ALERT", alert);
  };

  const onClickDeleteConfirm = async (alert) => {
    setDeleteAlert(alert);
    window.$("#delete-alert-modal").modal("show");
  };

  const onClickDelete = async (alert) => {
    try {
      const response = await ApiService.delete(`alerts/${alert.id}`, {});

      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("DELETE_ALERT_SUCCESS");
      Emitter.emit("LOAD_DASHBOARD_DATA");

      window.$.toast({
        heading: "Success",
        text: "Alert removed.",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      // console.log(err.message);
      window.$.toast({
        heading: "Error",
        text: "Alert remove Failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="float-sm-end">
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={(e) => onClickNew()}
                >
                  Create New
                </button>
              </div>
              <table
                class="table dt-responsive nowrap w-100"
                ref={tableRef}
                id="list_alerts"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Alert Type</th>
                    <th>Alert Name</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.isLoading ? (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {alerts.data &&
                        alerts.data.map((alert, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {(() => {
                                  switch (alert.status) {
                                    case "alarm_active":
                                      return (
                                        <i
                                          className="fas fa-volume-up  fa-lg"
                                          style={{ color: "red" }}
                                        ></i>
                                      );
                                    case "alarm_inactive":
                                      return (
                                        <i className="fas fa-volume-off  fa-lg"></i>
                                      );
                                    case "completed":
                                      return (
                                        <i className="fas fa-thumbs-up  fa-lg"></i>
                                      );
                                    default:
                                      return (
                                        <i className="fas fa-hourglass-half  fa-lg"></i>
                                      );
                                  }
                                })()}
                               
                              </td>
                              <td>{alert.type}</td>
                              <td>{alert.name}</td>
                              <td
                                title={moment(alert.created_datetime).fromNow()}
                              >
                                {moment
                                  .tz(alert.created_datetime, "UTC")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td>{alert.status}</td>
                              <td>
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) => onClickEdit(alert)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-link p-0 d-block"
                                  onClick={(e) => onClickDeleteConfirm(alert)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
              {alerts.isError ? <Error500 /> : null}
            </div>
          </div>
        </div>
      </div>

      <div
        id="delete-alert-modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
            </div>
            <div className="modal-body">Are you sure you want to delete?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  window.$("#delete-alert-modal").modal("hide");
                  onClickDelete(deleteAlert);
                }}
              >
                OK
              </button>
              <button
                type="button"
                class="btn btn-white"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#delete-alert-modal").modal("hide");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminListAlerts;
