import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ApiService from "app/services/api";
import moment from "moment";
import Emitter from "app/services/emitter";
import config from "app/config";

export const CardMenu = (props) => {
  const component = props.component || null;
  const options = props.options || {};
  const grid_options = props.grid_options || {};
  const dashboardComponent = props.dashboardComponent || null;
  const chartComponent = props.chart_component || null;
  const componentId = component.replaceAll("/", "-");

  const indicators = [
    {
      id: "abands",
      name: "Acceleration Bands",
      period: false,
    },
    {
      id: "bb",
      name: "Bollinger Bands",
      period: false,
    },
    // {
    //   id: "dema",
    //   name: "DEMA (Double Exponential Moving Average)",
    //   period: true
    // },
    {
      id: "ema",
      name: "EMA (Exponential Moving Average)",
      period: true,
    },
    {
      id: "linearRegression",
      name: "Linear Regression",
      period: true,
    },
    {
      id: "sma",
      name: "SMA (Simple Moving Average)",
      period: true,
    },
    // {
    //   id: "tema",
    //   name: "TEMA (Triple Exponential Moving Average)",
    //   period: true
    // },
    // {
    //   id: "wma",
    //   name: "WMA (Weighted Moving Average)",
    //   period: true
    // },
  ];
  const [serieses, setSerieses] = useState([]);

  const [selectedIndicator, setSelectedIndicator] = useState(indicators[0]);
  
  //const seriesesInterval = useRef(null);
  useEffect(() => {
    if(serieses.length) return;
    setTimeout(() => {
      if (
        chartComponent &&
        chartComponent.current &&
        chartComponent.current.chart.series.length > 0
      ) {
        var serieses = chartComponent.current.chart.series.filter((series) => {
          var item = indicators.find(
            (indicator) => indicator.id === series.initialType
          );
          if (item) return false;
          else return true;
        });
        setSerieses([...serieses]);
      }
    }, 1000);
  });

  const onClickAddToDahsboard = async (e) => {
    e.preventDefault();

    try {
      const response = await ApiService.post("me/dashboard/components", {
        component: component,
        options: options,
        grid_options: grid_options,
      })
        .then((response) => response.json())
        .catch();

      if (response.status === "error") {
        throw new Error(response.message);
      }

      Emitter.emit("ADD_TO_DASHBOARD_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: `Added to My Dashboard successfully <br/> <a href="/admin/dashboard">Go to My Dashboard</a>`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Add to My Dashboard failed <br/> " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const onClickRemoveFromDahsboard = (e) => {
    e.preventDefault();

    props.removeFromDashboard(dashboardComponent);

    // try {
    //     const response = ApiService.delete("me/dashboard/components", {
    //       id: dashboardComponent.id,
    //       component: component

    //     })
    //       .then((response) => response.json())
    //       .catch();

    //     if (response.status === "error") {
    //       throw new Error(response.message);
    //     }

    //     props.removeWidget(dashboardComponent)

    //     window.$.toast({
    //       heading: "Success",
    //       text: "Remove from dashboard success",
    //       position: "top-right",
    //       icon: "success",
    //     });

    //   } catch (err) {
    //     console.log(err);
    //     window.$.toast({
    //       heading: "Error",
    //       text: "Remove from dashboard failed",
    //       position: "top-right",
    //       icon: "error",
    //     });
    //   }
  };

  const copyToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#${props.copy_option.id}_wrapper .btn-group .buttons-copy`)[0].click();
    const currentLength = window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .page.len();
    const maxLength = Math.max(
      ...window.$(`#${props.copy_option.id}`).DataTable().settings()[0]
        .aLengthMenu
    );
    window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .one("draw", function () {
        window
          .$(`#${props.copy_option.id}_wrapper .btn-group .buttons-copy`)[0]
          .click();
        setTimeout(
          () =>
            window
              .$(`#${props.copy_option.id}`)
              .DataTable()
              .page.len(currentLength)
              .draw(true),
          1000
        );
      });
    window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .page.len(maxLength)
      .draw(true);
  };

  const downloadCSV = (e) => {
    e.preventDefault();

    //window.$(`#${props.copy_option.id}_wrapper .btn-group .buttons-csv`).click();
    const currentLength = window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .page.len();
    const maxLength = Math.max(
      ...window.$(`#${props.copy_option.id}`).DataTable().settings()[0]
        .aLengthMenu
    );
    window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .one("draw", function () {
        window
          .$(`#${props.copy_option.id}_wrapper .btn-group .buttons-csv`)
          .click();
        setTimeout(
          () =>
            window
              .$(`#${props.copy_option.id}`)
              .DataTable()
              .page.len(currentLength)
              .draw(true),
          1000
        );
      });
    window
      .$(`#${props.copy_option.id}`)
      .DataTable()
      .page.len(maxLength)
      .draw(true);
  };

  // const copyWalletsToClipboard = async (e) => {
  //   e.preventDefault();

  //   window.$(`#${props.copy_option.id}_wrapper .btn-group .buttons-copy`)[1].click();
  // };

  const markIndicators = async (e) => {
    e.preventDefault();

    var series = e.target[0].value;

    // var inputIndicators = [];
    // for(var i = 1; i <= indicators.length; i++) {
    //   if(e.target[i].checked)
    //     inputIndicators.push(indicators[i-1]);
    // }

    // for(var i = 0; i < chartComponent.current.chart.series.length; i++) {
    //   var series = indicators.find(indicator => indicator.id == chartComponent.current.chart.series[i].userOptions.type);
    //   if(series) {
    //     chartComponent.current.chart.series[i].remove();
    //     i--;
    //   }
    // }
    // inputIndicators.forEach(indicator => {
    //   if(indicator == 'sma')
    //     chartComponent.current.chart.addSeries({
    //       type: 'sma',
    //       linkedTo: series,
    //       params: {
    //         period: 7
    //       }
    //     })
    //   else if(indicator == 'linearRegression')
    //     chartComponent.current.chart.addSeries({
    //       type: 'linearRegression',
    //       linkedTo: series,
    //       params: {
    //         period: 10
    //       }
    //     })
    // })

    var indicator = e.target[1].value;
    if (selectedIndicator.period) {
      var period = e.target[2].value;
      chartComponent.current.chart.addSeries({
        type: indicator,
        linkedTo: series,
        params: {
          period: period,
        },
      });
    } else
      chartComponent.current.chart.addSeries({
        type: indicator,
        linkedTo: series,
      });
  };

  const clearIndicators = () => {
    for (var i = 0; i < chartComponent.current.chart.series.length; i++) {
      var series = indicators.find(
        (indicator) =>
          indicator.id ==
          chartComponent.current.chart.series[i].userOptions.type
      );
      if (series) {
        chartComponent.current.chart.series[i].remove();
        i--;
      }
    }
  };
 // console.log("serieses", serieses);
  return (
    <>
      <div className="dropdown float-end">
        <Link
          to=""
          className="dropdown-toggle arrow-none card-drop"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          {dashboardComponent ? (
            <Link
              to=""
              className="dropdown-item"
              onClick={onClickRemoveFromDahsboard}
            >
              Remove from dashboard
            </Link>
          ) : (
            <Link
              to=""
              className="dropdown-item"
              onClick={onClickAddToDahsboard}
            >
              Add to dashboard
            </Link>
          )}
          {component === "views/dashboard-system/components/account-stats" ? (
            <a
              href={`${config.explorer.url}/account/${options.props.account}`}
              className="dropdown-item"
              target="_blank"
            >
              View in explorer
            </a>
          ) : null}
          {props.copy_option ? (
            <>
              {/* <Link to="" className="dropdown-item" onClick={copyToClipboard}>
          Copy to clipboard
        </Link> */}
              <Link to="" className="dropdown-item" onClick={downloadCSV}>
                Download csv
              </Link>
              {/* <Link to="" className="dropdown-item" onClick={copyWalletsToClipboard}>
          Copy wallets to clipboard
        </Link> */}
            </>
          ) : null}
          {props.chart_component && serieses.length > 0 ? (
            <Link
              to=""
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target={`#manage-indicators-${componentId}`}
            >
              Technical Indicators
            </Link>
          ) : null}
        </div>
      </div>

      {props.chart_component ? (
        <div
          id={`manage-indicators-${componentId}`}
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="block-modalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="block-modalLabel">
                  Technical indicators
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form className="px-3" action="" onSubmit={markIndicators}>
                <div class="modal-body">
                  <h6>Select series and indicators</h6>
                  <div className="mb-3">
                    <label for="series" className="form-label">
                      Series
                    </label>
                    <select className="form-select" name="series" id="series">
                      {serieses.map((series) => (
                        <option value={series.userOptions.id}>
                          {series.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="mb-3">
                        <label for="indicators" className="form-label">Indicators</label>
                        {indicators.map(indicator => (
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" name="indicator" value="" id={indicator} />
                              <label class="form-check-label" for={indicator}>
                                {indicator}
                              </label>
                            </div>
                        ))}
                      </div> */}
                  <div className="mb-3">
                    <label for="indicator" className="form-label">
                      Indicator
                    </label>
                    <select
                      className="form-select"
                      name="indicator"
                      id="indicator"
                      onChange={(e) =>
                        setSelectedIndicator(
                          indicators.find(
                            (indicator) => indicator.id == e.target.value
                          )
                        )
                      }
                    >
                      {indicators.map((indicator) => (
                        <option value={indicator.id}>{indicator.name}</option>
                      ))}
                    </select>
                  </div>
                  {selectedIndicator.period ? (
                    <div className="mb-3">
                      <label for="period" className="form-label">
                        Period
                      </label>
                      <select className="form-select" name="period" id="period">
                        <option value="7">7</option>
                        <option value="30">30</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    onClick={clearIndicators}
                  >
                    Clear indicators
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CardMenu;
