import ApiService from "../../app/services/api";

export const ToolCalculatorService = {
  getLandsAndTools: async () => {
    const response = await ApiService.get("tool-calculator");
    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    return response;
  },
  getTemplate: async (templateId) => {
    const response = await ApiService.get("tool-calculator/" + templateId);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  }
};

export default ToolCalculatorService;
