export function ucfirst(string) {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function helpTextWrapper(string, replacements = {}) {
  string = string.replace(
    new RegExp(Object.keys(replacements).join("|"), "g"),
    (match) => replacements[match]
  );
  return string;
}
