import { useQuery } from "react-query";
import ApiService from "../../../app/services/api";
import DaoPlanetStats from "./dao-planet-stats";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { useHelpTexts } from "app/hooks/useSession";

export const DaoPlanetsStats = (props) => {
  const componentProps = {
    component: "components/dao/dao-planets-stats",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 5 },
  };
  const helpTexts = useHelpTexts();

  const dac_ids = ["nerix", "magor", "naron", "eyeke", "kavian", "veles"];

  const stats = useQuery(
    [`dao_planets_stats`],
    async () => {
      const response = await ApiService.get("dao/dao-planet-stats", {});
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {}
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card bg-transparent">
            <div className="card-body">
              <h4 className="header-title">
                {helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || `DAO Planets stats`}
                <button
                  type="button"
                  className="btn btn-outline-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-ht-key={componentProps.component}
                  title={
                    helpTexts.find(
                      (helpText) => helpText.key === componentProps.component
                    )?.value || "DAO Planets stats"
                  }
                  style={{ boxShadow: "none" }}
                >
                  <i className="fas fa-info-circle"></i>
                </button>
              </h4>
              <div className="row">
                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].total_weight_of_votes
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Total weight of votes</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].number_voters
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">
                        Total votes on candidates
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].number_active_candidates
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Active candidates</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].pending_proposals
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Pending proposals</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].executed_proposals
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Completed proposals</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].expired_proposals
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Expired proposals</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].circulating_supply
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Circulating Supply</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].total_stake
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">
                        Syndicate tokens pledged to vote power
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div className="widget-simple text-center card">
                    <div className="card-body">
                      <h3 className="text-success mt-0">
                        {stats && stats.data ? (
                          <span>
                            {parseInt(
                              stats.data["all"].leftover_budget
                            ).toLocaleString()}
                          </span>
                        ) : (
                          <Skeleton width={50} />
                        )}
                      </h3>
                      <p className="text-muted mb-0">Leftover Budget</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {dac_ids.map((dac_id, index) => {
          return (
            <div className="col-lg-12">
              <DaoPlanetStats
                dac_id={dac_id}
                stats={(stats && stats.data && stats.data[dac_id]) || null}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DaoPlanetsStats;
