import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


function SampleHighchart() {
  const options = {
    title: {
      text: "My chart",
    },
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}


export default SampleHighchart;