import ApiService from "app/services/api";
import SocketService from "app/services/socket";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

export const AdminSelfTestGuardCounters = (props) => {
  const counters = useQuery([`self-test_guard-counters`], async () => {
    const response = await ApiService.get("self-test/guard/counters", {});
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  });


  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <h4 className="header-title">Guard Counters</h4>
          <div className="row">
            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:guard-ratelimit"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">guard-ratelimit</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:platform-guard"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">platform-guard</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:paywall-failed"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">paywall-failed</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key ===
                            "guard-eosio:counters:paywall-success"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">paywall-success</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-bypass"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-bypass</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-failed"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-failed</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <h3 className="text-success mt-0">
                    {!counters.data ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {counters.data.find(
                          (counter) =>
                            counter.key === "guard-eosio:counters:trx-success"
                        )?.value || 0}
                      </span>
                    )}
                  </h3>
                  <p className="text-muted mb-0">trx-success</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSelfTestGuardCounters;
